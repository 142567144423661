import React, { FunctionComponent, ReactElement } from "react";

import { AudienceUserType } from "./types";

import "./styles.scss";

export type AudienceListResultProps = {
  userList: Array<AudienceUserType>;
};

export const AudienceListResult: FunctionComponent<AudienceListResultProps> = (props): ReactElement => {
  const { userList } = props;

  const filteredList = userList.reduce((acc: Array<AudienceUserType>, current: AudienceUserType) => {
    const x = acc.find((item: AudienceUserType) => {
      return item.id === current.id;
    });
    if (!x) {
      return acc.concat([current]);
    }
    return acc;
  }, []);

  return (
    <div className="survey-audiencelist audience-result">
      <div className="survey-audiencelist__header">
        <span>Users receiving survey</span>
        <span>{`${filteredList.length} Users`}</span>
      </div>
      <ul className="survey-audience-result-panel">
        {filteredList.length === 0 ? (
          <p>No users added yet</p>
        ) : (
          filteredList.map((user, index) => {
            return (
              <li key={index}>
                <h5>{user.name}</h5>
                {/* <span>{user.orgName}</span> */}
              </li>
            );
          })
        )}
      </ul>
    </div>
  );
};

export default AudienceListResult;
