import {
  PBI_EMBED_TOKEN,
  PBI_UPDATE_DATASET,
  PBI_RESET
  // PBI_UPDATE_DATASET_SUCCESS,
  // PBI_UPDATE_DATASET_FAILURE
} from "src/actions/pbi/types";

const initialState = { isLoading: false };

// eslint-disable-next-line @typescript-eslint/default-param-last
export default function pbiReducer(state = initialState, action) {
  switch (action.type) {
    case PBI_EMBED_TOKEN:
      return { ...state, ...action.payload };
    case PBI_UPDATE_DATASET:
      return { ...state, ...action.payload };
    case PBI_RESET:
      return { ...initialState };
    default:
      return state;
  }
}
