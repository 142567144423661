import React, { FunctionComponent, ReactElement } from "react";
import CheckBox from "src/assets/images/graphics/checkbox-wbn-4x.png";
import CheckBoxChecked from "src/assets/images/graphics/checkbox-checked-wbn-4x.png";
import CheckBoxIntermediate from "src/assets/images/graphics/checkbox-intermediate-wbn-4x.png";

import "./styles.scss";

export type ResetPasswordCheckboxProps = {
  className?: string;
  label: string;
  checked?: boolean;
  intermediate?: boolean;
  onClick: () => void;
  image?: string;
  style?: object;
};
export const ResetPasswordCheckbox: FunctionComponent<ResetPasswordCheckboxProps> = ({
  className,
  checked,
  intermediate,
  label,
  onClick,
  image,
  style: propStyle = {}
}): ReactElement => {
  const getCheckedIcon = (isChecked, isInterm) => {
    if (!isChecked) {
      return isInterm ? CheckBoxIntermediate : CheckBox;
    }
    return CheckBoxChecked;
  };

  return (
    <div className={`reset-password-checkbox-container ${className || ""}`} onClick={onClick} style={{ ...propStyle }}>
      <img className="checkbox-check-icon" src={getCheckedIcon(checked, intermediate)} alt="CheckBox" />
      {image && <img src={image} alt="WellbeingStatus" />}
      <label>{label}</label>
    </div>
  );
};

export default ResetPasswordCheckbox;
