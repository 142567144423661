import {FunctionComponent, ReactElement, useEffect, useRef, useState} from "react";
import "./styles.scss";
import ResetPasswordCheckbox from "../ResetPasswordCheckbox";
import SeityModal from "src/components/SeityModal";
import ResetPasswordButton from "../ResetPasswordButton";
import CoreStrings from "src/_core/strings/strings";

export type ResetPasswordButtonMenuModalProps = {
    position?: { top: number; left: number };
    data: any[];
    isOpen: boolean;
    title?: string;
    selectedOptions: any[];
    setSelectedOptions: (selectedOptions: any) => void;
    onApply: () => void;
    wrapperClassName?: string;
};

// The following style is declared here because it must override styles
// that are elsewhere flagged as !important.
const resetButtonStyle = {
    color: "black",
    padding: 0,
    marginRight: 8,
    fontWeight: "normal"
};

export const ResetPasswordButtonMenuModal: FunctionComponent<ResetPasswordButtonMenuModalProps> =
    ({
         position,
         isOpen = false,
         title = "Show",
         data,
         selectedOptions,
         setSelectedOptions,
         onApply
     }): ReactElement => {

        const [options, setOptions] = useState(selectedOptions);
        const modalRef = useRef<HTMLDivElement>(null);
        const [bottomSpacing, setBottomSpacing] = useState<number | null>(null);

        const adjustModalBottomSpace = () => {
            const viewportHeight = window.innerHeight;

            if (modalRef.current) {
                const modalRect = modalRef.current.getBoundingClientRect();
                const modalBottomPosition = modalRect.bottom;

                if (modalBottomPosition > viewportHeight) {
                    setBottomSpacing(20);
                } else {
                    setBottomSpacing(null);
                }
            }
        };

        const handleOptionsSelection = (item: any) => {

            if (options.includes(item.companyID)) {
                setOptions(
                    options.filter((companyID) => {
                        return companyID !== item.companyID;
                    })
                );
            } else {
                setOptions([...options, item.companyID]);
            }
        };

        const parseOptions = (arr) => {
            return arr.map((elem, index) => {
                return (
                    <ResetPasswordCheckbox
                        key={index}
                        label={elem.companyNameShort || elem.companyName}
                        onClick={() => {
                            handleOptionsSelection(elem);
                        }}
                        checked={options.includes(elem.companyID)}
                    />
                );
            });
        };

        const style: any = {
            position: "absolute",
            top: position?.top,
            left: position?.left
        };

        if (bottomSpacing) {
            style.bottom = bottomSpacing + 'px';
        }

        return (
            <SeityModal
                overlayClassName={"reset-password-disabled-overlay"}
                isOpen={isOpen}
                wrapperClassName="reset-password-button-menu-modal"
                style={position && style}
                onClose={() => {
                    onApply();
                }}
                afterOpenModal={adjustModalBottomSpace}
            >
                <div ref={modalRef} className="reset-password-modal-children-holder">
                    {title?.length > 0 && <h3>{title}</h3>}
                    <div className="reset-password-modal-content-wrap">{parseOptions(data)}</div>
                    <div className="reset-password-modal-btn-wrap">
                        <ResetPasswordButton
                            type="plain-text"
                            label={CoreStrings.clearAll}
                            customClass="reset-password-modal-button"
                            style={{
                                ...resetButtonStyle
                            }}
                            onClick={() => {
                                const allOptions = data.map((c) => {
                                    return c.companyID;
                                });
                                /*setOptions(allOptions);
                                setSelectedOptions(allOptions);*/

                                setOptions([]);
                            }}
                        />
                        <ResetPasswordButton
                            type="submit"
                            label={CoreStrings.apply}
                            customClass="reset-password-modal-button"
                            onClick={() => {
                                setSelectedOptions(options);
                                onApply();
                            }}
                        />
                    </div>
                </div>
            </SeityModal>
        );
    };

export default ResetPasswordButtonMenuModal;
