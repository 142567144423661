import React, { FunctionComponent } from "react";

import SurveyButton from "src/components/survey/SurveyButton";
import SurveyPreviewQuestion from "./SurveyPreviewQuestion";

import "./styles.scss";
import { SurveyQuestionType } from "../types";

export type SurveyPreviewProps = {
  surveyTitle: string;
  surveyDescription: string;
  handleClosePreview?: () => void;
  questionData: Array<SurveyQuestionType>;
};

export const SurveyPreview: FunctionComponent<SurveyPreviewProps> = ({
  surveyTitle,
  surveyDescription,
  handleClosePreview,
  questionData
}) => {
  return (
    <div className="survey-preview">
      <ul className="question-content">
        <li>
          <h1>{surveyTitle}</h1>
          <span>{surveyDescription}</span>
        </li>
        {questionData.map((question) => {
          return (
            <li>
              <SurveyPreviewQuestion question={question} />
            </li>
          );
        })}
      </ul>
      <div className="question-footer">
        <SurveyButton label="Close Preview" onClick={handleClosePreview} />
      </div>
    </div>
  );
};

export default SurveyPreview;
