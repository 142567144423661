import { createSurveyWizardAudience } from "src/api/survey/seityHealthAPI-audience";
import {
  createSurveyWizardSchedule,
  getSurveyFrequency,
  getSurveyWizardSchedule,
  updateSurveyWizardSchedule
} from "src/api/survey/seityHealthAPI-survey";
import { SurveyAudience, SurveySchedule, SURVEY_AUDIENCE_STAFF } from "src/api/survey/surveyTypes";
import { OptionType } from "src/components/survey/SurveySwitch";
import { addSurveyScheduleIDToSurvey, handleError, setIsLoading, setSurveyFrequencyList } from "src/reducers/survey/surveySlice";
import { validateToken } from "src/reducers/utils";

export const sendGetSurveyScheduleRequest = (surveyWizardScheduleID: number, callback: (surveySchedule?: SurveySchedule) => void) => {
  return async (dispatch: any) => {
    try {
      dispatch(setIsLoading(true));
      const token = await validateToken(dispatch);

      const surveyWizardScheduleResponse = await getSurveyWizardSchedule(surveyWizardScheduleID, token);

      if (surveyWizardScheduleResponse.success) {
        callback(surveyWizardScheduleResponse.data);
      }
    } catch (err) {
      console.error(err);
      handleError(err, dispatch);
    }
  };
};

// SurveyWizard - Schedule - Create/Update/Get
export const sendPostSurveyWizardScheduleRequest = (
  surveyWizardID: number,
  surveyWizardFrequencyID: number,
  startDateTime: string,
  endDateTime: string,
  everyWeeks: number,
  dayOfWeek: number,
  needStaffUpdate: boolean, // If true, this will update the surveyWizardAudienceType with 1 (Staff)
  callback?: (survey?: SurveySchedule) => void
) => {
  return async (dispatch: any) => {
    try {
      dispatch(setIsLoading(true));
      const token = await validateToken(dispatch);
      const surveyWizardScheduleResponse = await createSurveyWizardSchedule(
        surveyWizardID,
        surveyWizardFrequencyID,
        startDateTime,
        endDateTime,
        everyWeeks,
        dayOfWeek,
        token
      );
      if (surveyWizardScheduleResponse.success) {
        dispatch(addSurveyScheduleIDToSurvey(surveyWizardScheduleResponse.data));
        if (needStaffUpdate) {
          const param: SurveyAudience = {
            surveyWizardScheduleID: surveyWizardScheduleResponse.data.surveyWizardScheduleID,
            surveyWizardAudienceTypeID: SURVEY_AUDIENCE_STAFF,
            companyID: 0,
            teacherID: 0,
            classID: 0,
            departmentIDs: [],
            groupIDs: []
          };
          await createSurveyWizardAudience(param, token);
        }
        if (callback) {
          callback(surveyWizardScheduleResponse.data);
        }
        return;
      }
    } catch (err) {
      console.error(err);
      handleError(err, dispatch);
    }
  };
};

export const sendPutUpdateSurveyWizardScheduleRequest = (
  surveyWizardScheduleID: number,
  surveyWizardID: number,
  surveyWizardFrequencyID: number,
  startDateTime: string,
  endDateTime: string,
  everyWeeks: number,
  dayOfWeek: number,
  callback?: (survey?: SurveySchedule) => void
) => {
  return async (dispatch: any) => {
    try {
      dispatch(setIsLoading(true));
      const token = await validateToken(dispatch);
      const surveyWizardScheduleResponse = await updateSurveyWizardSchedule(
        surveyWizardScheduleID,
        surveyWizardID,
        surveyWizardFrequencyID,
        startDateTime,
        endDateTime,
        everyWeeks,
        dayOfWeek,
        token
      );
      if (surveyWizardScheduleResponse.success) {
        if (callback) {
          callback(surveyWizardScheduleResponse.data);
        }
        return;
      }
    } catch (err) {
      console.error(err);
      handleError(err, dispatch);
    }
  };
};

export const sendGetSurveyFrequencyRequest = (callback?: (surveyFrequencies?: OptionType[]) => void) => {
  return async (dispatch: any) => {
    try {
      dispatch(setIsLoading(true));
      const token = await validateToken(dispatch);
      const surveyScheduleResponse = await getSurveyFrequency(token);

      const surveyFrequencyFormated = surveyScheduleResponse.data.map((survey) => {
        return {
          label: survey.name,
          value: survey.surveyWizardFrequencyID
        };
      });
      if (surveyScheduleResponse.success) {
        dispatch(setSurveyFrequencyList(surveyFrequencyFormated));

        if (callback) {
          callback(surveyFrequencyFormated);
        }
        return;
      }
    } catch (err) {
      console.error("sendGetSurveyFrequencyRequest error", err);

      handleError(err, dispatch);
      if (callback) {
        callback();
      }
    }
  };
};
