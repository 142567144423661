import axios from "axios";
import { BASE_URL, PLATFORM_ID } from "./apiConstants";
import { SeityAuthRefreshResponse, SeityLoginResponse, SeitySignupResponse } from "./authTypes";
import { SeityResponse } from "./baseTypes";
import { DateTime } from "luxon";

export async function login(username: string, password: string): Promise<SeityLoginResponse> {
  const url = BASE_URL + "/Auth/login";
  const clientTimeOffset = DateTime.local().offset / 60;
  try {
    const loginResponse = await axios.post<SeityLoginResponse>(url, {
      emailAddress: username,
      password,
      devicePlatformId: PLATFORM_ID,
      clientTimeOffset: clientTimeOffset
    });
    // DON'T LEAK CREDENTIALS TO LOGS/CONSOLE
    // console.log('Login:', loginResponse);

    return loginResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function forgotPassword(email: string): Promise<SeityResponse> {
  const url = BASE_URL + "/Auth/TemporaryPassword";
  try {
    const response = await axios.post<SeityResponse>(url, {
      eMailAddress: email
    });
    if (response.data === null || response.data.success === false) {
      throw { error: "apiError" };
    }
    return response.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}

export async function signup(
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  groupActivationCode: string
): Promise<SeitySignupResponse> {
  const url = BASE_URL + "/Auth/Register";
  try {
    const response = await axios.post<SeitySignupResponse>(url, {
      emailAddress: email,
      password,
      firstName,
      lastName,
      groupActivationCode
    });
    return response.data;
  } catch (err) {
    throw err;
  }
}

export async function getNewToken(refreshKey: string, token: string = ""): Promise<SeityAuthRefreshResponse> {
  const url = BASE_URL + "/Auth/Refresh";
  const clientTimeOffset = DateTime.local().offset / 60;
  try {
    const response = await axios.post<SeityAuthRefreshResponse>(url, {
      refreshKey,
      token,
      devicePlatformId: PLATFORM_ID,
      clientTimeOffset: clientTimeOffset
    });
    return response.data;
  } catch (err) {
    throw err;
  }
}
