import { FunctionComponent, ReactElement, useState } from "react";

import "./styles.scss";
import WbnCheckbox from "../WbnCheckbox";
import { Company } from "src/api/wbn/wbnTypes";

type WbnDistrictFilterProp = {
  title: string;
  schools: Array<Company>;
  entityId: number;
  selected: boolean;
  intermediate: boolean;
  onSelOption: (companyId?: number) => void;
};

export const WbnDistrictFilter: FunctionComponent<WbnDistrictFilterProp> = (props): ReactElement => {
  const { title, schools, selected, intermediate, onSelOption } = props;
  const [isOpen, setOpen] = useState(false);

  return (
    <div className="wbn-districtFilter">
      <div className="wbn-districtFilter__header">
        <WbnCheckbox
          label={title}
          onClick={() => {
            onSelOption();
          }}
          checked={selected}
          intermediate={intermediate}
        />
        <button
          onClick={() => {
            setOpen(!isOpen);
          }}
        >
          <img className={`${isOpen ? "open" : ""}`} src={require("src/assets/wbn/caret-down-small.png")} />
        </button>
      </div>
      <div className={`wbn-districtFilter__body ${isOpen ? "open" : "close"}`}>
        {schools.map((s, index) => {
          return (
            <WbnCheckbox
              key={index}
              label={s.name ?? ""}
              onClick={() => {
                onSelOption(s.companyID);
              }}
              checked={s.selected}
            />
          );
        })}
      </div>
    </div>
  );
};

export default WbnDistrictFilter;
