import React, { FunctionComponent } from "react";

import "./styles.scss";

export type SurveyModalProps = {
  isOpen: boolean;
  onClose?: () => void;
  className?: string;
};

export const SurveyModal: FunctionComponent<SurveyModalProps> = ({ isOpen = false, onClose, className, children }) => {
  return isOpen ? (
    <div className={`survey-modal-container ${className || ""}`}>
      <div className="survey-modal-overlay" />

      <div className="survey-modal">
        {onClose && (
          <a
            className="button-close"
            onClick={() => {
              onClose();
            }}
          >
            <img src={require("src/assets/survey/btn-close.png")} alt="" />
          </a>
        )}
        {children}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default SurveyModal;
