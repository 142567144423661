import { FunctionComponent, ReactElement, useEffect, useRef, useState } from "react";

import CoreStrings from "src/_core/strings/strings";

import "./styles.scss";

type WbnTextInputProp = {
  value: string;
  placeholder: string;
  maxlen?: number;
  onChange: (val: string) => void;
};

export const WbnTextInput: FunctionComponent<WbnTextInputProp> = (props): ReactElement => {
  const { value, placeholder, maxlen = 100, onChange } = props;

  const [focus, setFocus] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <div className="wbn-textInput">
      <div className={`wbn-textInput__content ${focus ? "focus" : ""} ${value.length > maxlen ? "error" : ""}`}>
        <input
          placeholder={placeholder}
          value={value}
          ref={inputRef}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          onFocus={() => {
            setFocus(true);
          }}
          onBlur={() => {
            setFocus(false);
          }}
        />
        {value.length > 0 && (
          <span
            onClick={() => {
              onChange("");
            }}
          >
            {CoreStrings.clear}
          </span>
        )}
      </div>
      <div className={`wbn-textInput__error ${value.length > maxlen ? "error" : ""}`}>
        <span>{`View Name exceeds ${maxlen} characters`}</span>
        <span>{`${value.length}/${maxlen}`}</span>
      </div>
    </div>
  );
};

export default WbnTextInput;
