import React, { FunctionComponent, ReactElement, useMemo } from "react";
import { useSelector } from "react-redux";
import { SurveyState } from "src/api/survey/surveyTypes";
import { RootState } from "src/reducers";
import SurveyButton from "../SurveyButton";

import "./styles.scss";

type MenuItemType = {
  title: string;
  subTitle: string;
  icon: string;
  value: SurveyState | null;
};

const menuItem: MenuItemType[] = [
  {
    title: "Bank",
    subTitle: "Surveys",
    icon: require("src/assets/survey/icon-bank.png"),
    value: null
  },
  {
    title: "Drafts",
    subTitle: "Drafts",
    icon: require("src/assets/survey/icon-drafts.png"),
    value: "Draft"
  },
  {
    title: "Scheduled",
    subTitle: "Scheduled",
    icon: require("src/assets/survey/icon-scheduled.png"),
    value: "Scheduled"
  },
  {
    title: "Active",
    subTitle: "Active",
    icon: require("src/assets/survey/icon-active.png"),
    value: "Active"
  },
  {
    title: "Completed",
    subTitle: "Completed",
    icon: require("src/assets/survey/icon-completed.png"),
    value: "Complete"
  }
];

export type SurveySideMenuProps = {
  title: string;
  selIdx: number;
  itemHeight: number;
  handleMenuPress: (index: number, status: SurveyState | null) => void;
  handlePressCreateSurvey: () => void;
};

export const SurveySideMenu: FunctionComponent<SurveySideMenuProps> = ({
  title,
  selIdx,
  itemHeight,
  handleMenuPress,
  handlePressCreateSurvey,
  children
}): ReactElement => {
  const { surveyWizardList } = useSelector((state: RootState) => {
    return state.survey;
  });
  const totalSurvey = useMemo(() => {
    const totalTemp = {
      null: 0,
      Draft: 0,
      Scheduled: 0,
      Active: 0,
      Complete: 0
    };
    surveyWizardList.forEach((survey) => {
      totalTemp[survey.status] += 1;
      totalTemp.null += 1;
    });

    return totalTemp;
  }, [surveyWizardList]);

  return (
    <div className="survey-sidemenu">
      <h1>{title}</h1>
      <ul>
        {menuItem.map((item, index) => {
          return (
            <li
              value={index}
              onClick={() => {
                handleMenuPress(index, item.value);
              }}
              key={`${item.title}`}
            >
              <a className={`sidemenu-item ${index === selIdx ? "active" : ""}`} style={{ height: itemHeight }}>
                <img src={item.icon} alt={item.title} />
                <div className="sidemenu-item__desc">
                  <p className="desc-title">{item.title}</p>
                  <p className="desc-title desc-subtitle">
                    {" "}
                    {totalSurvey[item.value || "null"]} {item.subTitle}
                  </p>
                </div>
              </a>
            </li>
          );
        })}
      </ul>
      <SurveyButton
        label="Create Survey"
        onClick={() => {
          handlePressCreateSurvey();
        }}
      />
      {children}
    </div>
  );
};

export default SurveySideMenu;
