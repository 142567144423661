/* eslint-disable */
import React from "react";
import { MoreInfoIcon } from "../SeityInfoIcon/InfoIcon";
import { SeitySelectBox, SeitySelectBoxProps } from "./SeitySelectBox";

import "./styles.scss";

interface SeityFormSelectBoxProps {
  controlId?: string;
  inputLabelText?: string;
  moreInfoText?: string;
  isInvalid?: boolean;
}

export const SeityFormSelectBox = (props: SeityFormSelectBoxProps & SeitySelectBoxProps) => {
  const { onChange, value, children, controlId, inputLabelText, moreInfoText, isInvalid, inputStyle } = props;
  return (
    <div className="seity-select-box-form-group">
      {inputLabelText && <label>{inputLabelText}</label>}
      {moreInfoText && <MoreInfoIcon text={moreInfoText} imageStyle={{ paddingBottom: "8px", marginLeft: "5px" }} />}
      <SeitySelectBox isInvalid={isInvalid} onChange={onChange} inputStyle={inputStyle} value={value}>
        {children}
      </SeitySelectBox>
    </div>
  );
};
