import React, { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { StepWizardChildProps } from "react-step-wizard";
import { SurveyAudience, SURVEY_AUDIENCE_STAFF, SURVEY_AUDIENCE_STUDENTS } from "src/api/survey/surveyTypes";
import SeityAlert from "src/components/analytic/SeityAlert";

import SurveyButton from "src/components/survey/SurveyButton";
import { sendGetAudienceRequest } from "src/reducers/survey/surveyAudienceSlice";
import { RootState } from "src/reducers";
import { clearSurveyAudienceError } from "src/reducers/survey/surveySlice";
import strings from "src/_core/strings/strings";
import SelectStaff from "./SelectStaff";
import SelectStudents from "./SelectStudents";
import { AudienceUserType, SurveyAudienceProps } from "./types";

import "./styles.scss";

export const SurveyAudienceWrap: FunctionComponent<SurveyAudienceProps & Partial<StepWizardChildProps>> = (props): ReactElement => {
  const dispatch = useDispatch();

  const { proSettings } = useSelector((store: RootState) => {
    return store.account;
  });
  const { surveyAudienceError } = useSelector((store: RootState) => {
    return store.survey;
  });
  // If the company is not a School, the user can only add audiences with departments and groups
  const isCompanyASchool = proSettings?.entityTypeID === 2;

  const [audienceType, setAudienceType] = useState<1 | 2 | null>(null);
  const [step, setStep] = useState(0);
  const [, setStaffAudience] = useState<Array<AudienceUserType>>([]);
  const [, setStudentsAudience] = useState({});
  const [curSurveyAudience, setCurSurveyAudience] = useState<SurveyAudience | null>(null);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(strings.apiErrorMsg);

  const { previousStep, curSurveySchedule } = props;
  let handleNextButton = () => {};

  useEffect(() => {
    if (curSurveySchedule) {
      dispatch(
        sendGetAudienceRequest(curSurveySchedule.surveyWizardScheduleID, (audience) => {
          if (audience && audience.surveyWizardAudienceTypeID !== 0) {
            setCurSurveyAudience(audience);
            // Disable it for now
            // setAudienceType(audience.surveyWizardAudienceTypeID);
            // setStep(1);
          }
        })
      );
    }
  }, [curSurveySchedule]);

  useEffect(() => {
    if ((surveyAudienceError as any)?.audienceError) {
      setErrorMessage((surveyAudienceError as any).audienceError);
    }
  }, [surveyAudienceError]);

  return (
    <div className="survey-audience-wrapper">
      <div className="question-content">
        <h2 className="survey-modal-scheduler-title">Select the audience you would like to send your survey to.</h2>
        {step === 0 && isCompanyASchool && (
          <div className="audience-type-wrap">
            <a
              onClick={() => {
                setAudienceType(SURVEY_AUDIENCE_STUDENTS);
              }}
            >
              <img
                src={
                  audienceType === SURVEY_AUDIENCE_STUDENTS
                    ? require("src/assets/survey/img-audience-students-sel.png")
                    : require("src/assets/survey/img-audience-students.png")
                }
                alt="survey audience type students"
              />
            </a>
            <a
              onClick={() => {
                setAudienceType(SURVEY_AUDIENCE_STAFF);
              }}
            >
              <img
                src={
                  audienceType === SURVEY_AUDIENCE_STAFF
                    ? require("src/assets/survey/img-audience-staff-sel.png")
                    : require("src/assets/survey/img-audience-staff.png")
                }
                alt="survey audience type staff"
              />
            </a>
          </div>
        )}
        {step === 1 && audienceType === SURVEY_AUDIENCE_STUDENTS && (
          <SelectStudents
            {...props}
            toggleNextBtn={(toggle: () => void) => {
              handleNextButton = toggle;
            }}
            onUpdateAudience={(schoolId, teacherId, classId) => {
              setStudentsAudience({ schoolId, teacherId, classId });
            }}
            curSurveyAudience={curSurveyAudience}
          />
        )}
        {((step === 1 && audienceType === SURVEY_AUDIENCE_STAFF) || !isCompanyASchool) && (
          <SelectStaff
            {...props}
            toggleNextBtn={(toggle: () => void) => {
              handleNextButton = toggle;
            }}
            onUpdateAudience={(addedUsers: Array<AudienceUserType>) => {
              setStaffAudience(addedUsers);
            }}
            curSurveyAudience={curSurveyAudience}
          />
        )}
        <div className="modal-footer-container">
          <div className="question-footer">
            <SurveyButton
              customClass="back-button"
              type="outline"
              onClick={() => {
                if (step === 1) {
                  setStep(0);
                  setAudienceType(null);
                } else if (previousStep) {
                  previousStep();
                }
              }}
              label="← Back"
            />
            {/* <SurveyButton
              type="text"
              onClick={() => {

              }}
              label="Save and continue later"
            /> */}
            <SurveyButton
              disabled={isCompanyASchool && step === 0 && audienceType === null}
              onClick={() => {
                if (step === 0 && isCompanyASchool) {
                  if (audienceType) {
                    setStep(1); // move to audience detail page: SelectStudents/SelectStaff
                  }
                } else {
                  setIsSaveLoading(true);
                  handleNextButton();
                }
              }}
              label={step === 0 && isCompanyASchool ? "Next" : "Send"}
              loading={isSaveLoading}
            />
          </div>
        </div>
      </div>
      <SeityAlert
        visible={!!surveyAudienceError}
        onToggle={() => {
          setIsSaveLoading(false);
          dispatch(clearSurveyAudienceError());
        }}
        title="Error Message"
        classNameOverlay="survey-modal-error"
        subTitle={errorMessage}
      />
    </div>
  );
};

export default SurveyAudienceWrap;
