import React, {
    MouseEventHandler,
    FunctionComponent,
    ReactElement, useState, useCallback, useEffect, Dispatch, SetStateAction
} from 'react';

import modalCloseX from '../../../assets/resetPassword/modalClose.png';
import backNav from '../../../assets/resetPassword/back-nav.png';
import './styles.scss';
import {SeityTextInput} from "../../analytic/SeityTextInput/SeityTextInput";
import CoreStrings from "../../../_core/strings/strings";
import ResetPasswordButton from "../ResetPasswordButton";
import {getFullDateFromDateString, getTimeStringFromDateString} from "src/_core/utils/dateUtils/dateUtils";
import {useToasts} from "react-toast-notifications";
import SeityLoader from "../../SeityLoader";
import {appendGradeSuffix} from "../../../app/utils";
import strings from '../../../_core/strings/strings';

export type ResetPasswordOffCanvasProps = {
    isOpen: boolean;
    onClose: MouseEventHandler;
    accountID: number;
    firstName: string;
    lastName: string;
    emailAddress: string;
    companyName: string;
    companyNameShort: string;
    companyID: number;
    studentID: number;
    companyTypeID: number;
    gender: string;
    dateOfBirth: string;
    doNotAllowPasswordChange: boolean;
    setShowResetPasswordOffCanvas: Dispatch<SetStateAction<boolean>>;
    lastPasswordChangeDate: string;
    sendAutoGeneratePasswordRequest: () => Promise<void>;
    autoGeneratedPassword: string;
    setAutoGeneratedPassword: React.Dispatch<React.SetStateAction<string>>;
    sendTemporaryPasswordRequest: (accountID: number) => Promise<void>;
    sendManualResetPasswordRequest: (accountID: number, password: string, autoGenerated: boolean) => Promise<void>;
    changePassword: boolean;
    sendTemporaryPassword: boolean;
    manuallyChangePassword: boolean;
    autoGeneratePassword: boolean;
    generalEmailEnabled: boolean;
    grade: number;
};

export const ResetPasswordOffCanvas: FunctionComponent<ResetPasswordOffCanvasProps> =
    ({
         isOpen,
         onClose,
         accountID,
         firstName,
         lastName,
         emailAddress,
         companyName,
         companyNameShort,
         companyID,
         studentID,
         companyTypeID,
         gender,
         dateOfBirth,
         doNotAllowPasswordChange,
         setShowResetPasswordOffCanvas,
         lastPasswordChangeDate,
         sendAutoGeneratePasswordRequest,
         autoGeneratedPassword,
         setAutoGeneratedPassword,
         sendTemporaryPasswordRequest,
         sendManualResetPasswordRequest,
         changePassword,
         sendTemporaryPassword,
         manuallyChangePassword,
         autoGeneratePassword,
         generalEmailEnabled,
         grade

     }): ReactElement | null => {


        const [activeAccordion, setActiveAccordion] = useState<string | null>(null);
        const [password, setPassword] = useState('');
        const [confirmPassword, setConfirmPassword] = useState('');
        const [passwordError, setPasswordError] = useState('');
        const [isLoading, setLoading] = useState(false);
        const {addToast} = useToasts();

        useEffect(() => {

            if (isOpen) {
                setActiveAccordion(null);
            }

        }, [isOpen])

        useEffect(() => {

            if (autoGeneratedPassword) {
                setPassword(autoGeneratedPassword);
            }

        }, [autoGeneratedPassword])

        const resetFormFields = () => {
            setPassword('');
            setConfirmPassword('');
            setPasswordError('');
            setAutoGeneratedPassword('');
        }

        const CustomToast = ({title, body}) => (
            <div>
                <strong>{title}</strong>
                <p>{body}</p>
            </div>
        );

        const showToast = (title, body) => {
            addToast(<CustomToast title={title} body={body}/>, {
                appearance: 'success',
                autoDismiss: true
            });
        };

        const showErrorToast = (title, body) => {
            addToast(<CustomToast title={title} body={body}/>, {
                appearance: 'error',
                autoDismiss: true
            });
        };

        const handleCopyClipboardClick = (value) => {
            navigator.clipboard.writeText(value);
            addToast("Copied to clipboard!", {appearance: "success", autoDismiss: true});
        };

        const handleAccordionClick = (accordionName: string) => {
            resetFormFields();
            setActiveAccordion(accordionName);
        };

        const handleBackToProfile = () => {
            setActiveAccordion(null);
        };

        const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(e.target.value);
            if (passwordError) setPasswordError('');
        };

        const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            setConfirmPassword(e.target.value);
            if (passwordError) setPasswordError('');
        };

        const handleSubmitManualReset = useCallback(() => {

            if (!password || !confirmPassword) {
                setPasswordError('Both password fields are required');
                return;
            }

            if (password !== confirmPassword) {
                setPasswordError('Passwords do not match');
                return;
            }

            setPasswordError('');
            setLoading(true);

            sendManualResetPasswordRequest(accountID, password, false).then(() => {
                setPassword('');
                setConfirmPassword('');
                setShowResetPasswordOffCanvas(false);
                let body = `${firstName} ${lastName}'s password has been reset.`;
                showToast("Successfully reset password", body);
            }).finally(() => {
                setLoading(false);
            }).catch(() => {
                let body = `${firstName} ${lastName}'s password reset attempt was unsuccessful.`;
                showErrorToast("Failed to reset password", body);
            });

        }, [password, confirmPassword, accountID]);

        const handleSubmitAutoGenerate = useCallback(() => {

            if (!password) {
                setPasswordError('Password field required');
                return;
            }

            setPasswordError('');
            setLoading(true);

            sendManualResetPasswordRequest(accountID, password, true).then(() => {
                setPassword('');
                setShowResetPasswordOffCanvas(false);
                let body = `${firstName} ${lastName}'s password has been reset.`;
                showToast("Successfully reset password", body);
            }).finally(() => {
                setLoading(false);
            }).catch(() => {
                let body = `${firstName} ${lastName}'s password reset attempt was unsuccessful.`;
                showErrorToast("Failed to reset password", body);
            });

        }, [password, accountID]);

        const handleAutoGeneratePassword = useCallback(() => {
            sendAutoGeneratePasswordRequest();
        }, []);

        const handleSendTempPasswordButtonPress = useCallback(() => {
            setLoading(true);
            sendTemporaryPasswordRequest(accountID).then(() => {
                setPassword('');
                setShowResetPasswordOffCanvas(false);
                let body = `${firstName} ${lastName}'s password has been reset.`;
                showToast("Successfully reset password", body);
            }).finally(() => {
                setLoading(false);
            }).catch(() => {
                let body = `${firstName} ${lastName}'s password reset attempt was unsuccessful.`;
                showErrorToast("Failed to reset password", body);
            });
        }, [accountID])

        const renderBackToProfileLink = () => (
            <div className="reset-panel__back-to-profile" onClick={handleBackToProfile}>
                <img src={backNav} alt="back nav"/>
                <p>Back to user profile</p>
            </div>
        );

        const renderForcePasswordChangeDisclaimer = () => (
            <div className="reset-panel__force-password-change-disclaimer">
                <h4 className="reset-panel__paragraph">Force Password Change</h4>
                <p className="reset-panel__force-password-change-description">User will be required to create a new
                    password upon logging in.</p>
            </div>
        );

        const renderDefaultContent = () => (
            <div className="reset-panel__default">
                <h1 className="mb20 reset-panel__heading--green">{firstName} {lastName}</h1>

                <div className="reset-panel__info">
                    <h3 className="mt20 mb20 reset-panel__subheading">User Information</h3>

                    {companyTypeID === 2 ? (
                        <div className="reset-panel__grid">
                            <div className="reset-panel__grid-item">
                                <div className="reset-panel__grid-title">Student ID</div>
                                <div className="reset-panel__grid-desc">{studentID}</div>
                            </div>

                            <div className="reset-panel__grid-item">
                                <div className="reset-panel__grid-title">Date Of Birth</div>
                                <div className="reset-panel__grid-desc">{dateOfBirth}</div>
                            </div>

                            <div className="reset-panel__grid-item">
                                <div className="reset-panel__grid-title">Email</div>
                                <div className="reset-panel__grid-desc">{emailAddress}</div>
                            </div>

                            <div className="reset-panel__grid-item">
                                <div className="reset-panel__grid-title">Gender</div>
                                <div className="reset-panel__grid-desc">{gender}</div>
                            </div>

                            <div className="reset-panel__grid-item">
                                <div className="reset-panel__grid-title">User Group</div>
                                <div className="reset-panel__grid-desc">{companyNameShort}</div>
                            </div>

                            <div className="reset-panel__grid-item">
                                <div className="reset-panel__grid-title">Grade</div>
                                <div className="reset-panel__grid-desc">{appendGradeSuffix(grade)}</div>
                            </div>

                        </div>

                    ) : (
                        <div className="reset-panel__grid">
                            <div className="reset-panel__grid-item">
                                <div className="reset-panel__grid-title">Gender</div>
                                <div className="reset-panel__grid-desc">{gender}</div>
                            </div>

                            <div className="reset-panel__grid-item">
                                <div className="reset-panel__grid-title">User Group</div>
                                <div className="reset-panel__grid-desc">{companyNameShort}</div>
                            </div>
                            <div className="reset-panel__grid-item">
                                <div className="reset-panel__grid-title">Email</div>
                                <div className="reset-panel__grid-desc">{emailAddress}</div>
                            </div>
                        </div>
                    )}
                </div>

                <hr/>

                {changePassword && (manuallyChangePassword || sendTemporaryPassword || autoGeneratePassword) &&
                  <div className="reset-panel__info">
                    <div className="flex-container">
                      <h3 className="mt20 mb20 reset-panel__subheading">Reset Password</h3>
                      <p className="reset-panel__last-updated-password">
                          {lastPasswordChangeDate ?
                              `last updated: ${getFullDateFromDateString(lastPasswordChangeDate)} ${getTimeStringFromDateString(lastPasswordChangeDate)}`
                              : ''}
                      </p>
                    </div>

                    <div className="reset-panel__accordion">
                        {sendTemporaryPassword && generalEmailEnabled && <div
                          className="reset-panel__accordion-row"
                          onClick={() => handleAccordionClick('sendTempPassword')}
                        >
                          <div className="reset-panel__accordion-title">{strings.sendTemporaryPassword}</div>
                        </div>}

                        {manuallyChangePassword && <div
                          className="reset-panel__accordion-row"
                          onClick={() => handleAccordionClick('manualReset')}
                        >
                          <div className="reset-panel__accordion-title">{strings.manuallyResetPassword}</div>
                        </div>}
                        {autoGeneratePassword && <div
                          className="reset-panel__accordion-row"
                          onClick={() => handleAccordionClick('autoGenerate')}
                        >
                          <div className="reset-panel__accordion-title">{strings.autoGeneratePassword}</div>
                        </div>}
                    </div>
                  </div>
                }

            </div>
        );

        const renderSendTempPassword = () => (
            <div className="reset-panel__sendTmpPassword">
                {renderBackToProfileLink()}
                <h2 className="reset-panel__heading">Send Temporary Password</h2>
                <p className="mb10 reset-panel__paragraph">A temporary password will be sent to the user to access their
                    account.</p>
                {!doNotAllowPasswordChange &&
                  <p className="reset-panel__paragraph">Upon recovery of the account, the user will be required to
                    create
                    a new password.</p>
                }


                {!doNotAllowPasswordChange && renderForcePasswordChangeDisclaimer()}

                <div className="reset-panel__button-container">
                    <ResetPasswordButton
                        type="submit"
                        label={CoreStrings.sendButtonText}
                        onClick={() => {
                            handleSendTempPasswordButtonPress();
                        }}
                    />
                </div>

            </div>
        );

        const renderAutoGenerate = () => (
            <div className="reset-panel__autoGenerate">
                {renderBackToProfileLink()}
                <h2 className="mb20 mt20 reset-panel__heading">Auto Generate Password</h2>

                <p className="mb10 reset-panel__paragraph reset-panel__mb40">Generate a random password for the user.
                    Don’t forget
                    to send this to them securely.</p>

                <div className="reset-panel__button-container">
                    <ResetPasswordButton
                        type="normal"
                        customClass="reset-panel__button-auto-generate"
                        label={CoreStrings.generate}
                        onClick={() => {
                            handleAutoGeneratePassword()
                        }}
                    />
                </div>

                <div className="mb20">
                    <label className="reset-panel__paragraph--light-black">New Password</label>
                    <SeityTextInput
                        value={password}
                        type="password"
                        onChange={handlePasswordChange}
                        placeholder="New Password"
                        isInvalid={!!passwordError}
                        className="reset-panel__custom-text-input"
                        hasCopyToClipboardIcon={true}
                        handleCopyClipboardClick={handleCopyClipboardClick}
                    />
                </div>

                {passwordError && <div className="reset-panel__error-message">{passwordError}</div>}

                <div className="reset-panel__button-container">
                    <ResetPasswordButton
                        type="submit"
                        label={CoreStrings.saveButtonText}
                        onClick={() => {
                            handleSubmitAutoGenerate()
                        }}
                    />
                </div>
            </div>
        );

        const renderManualReset = () => (
            <div className="reset-panel__manualReset">
                {renderBackToProfileLink()}
                <h2 className="mb20 mt20 reset-panel__heading">Manually Reset Password</h2>

                <p className="reset-panel__paragraph reset-panel__mb40">Create a new password for this user. Don’t
                    forget to share
                    this with them securely.</p>

                <div className="mb20">
                    <label className="reset-panel__paragraph--light-black">Create New Password</label>
                    <SeityTextInput
                        value={password}
                        type="password"
                        onChange={handlePasswordChange}
                        placeholder="Create New Password"
                        isInvalid={!!passwordError}
                        className="reset-panel__custom-text-input"
                    />
                </div>

                <div className="mb20">
                    <label className="reset-panel__paragraph--light-black">Confirm Password</label>
                    <SeityTextInput
                        value={confirmPassword}
                        type="password"
                        onChange={handleConfirmPasswordChange}
                        placeholder="Confirm Password"
                        isInvalid={!!passwordError}
                        className="reset-panel__custom-text-input mb20"
                    />
                </div>


                {passwordError && <div className="reset-panel__error-message">{passwordError}</div>}

                <div className="reset-panel__button-container">
                    <ResetPasswordButton
                        type="submit"
                        label={CoreStrings.saveButtonText}
                        onClick={() => {
                            handleSubmitManualReset()
                        }}
                    />
                </div>
            </div>
        );

        const renderContent = () => {
            switch (activeAccordion) {
                case 'sendTempPassword':
                    return renderSendTempPassword();
                case 'manualReset':
                    return renderManualReset();
                case 'autoGenerate':
                    return renderAutoGenerate();
                default:
                    return renderDefaultContent();
            }
        };

        return isOpen ? (
            <div className="reset-panel">
                <div className="reset-panel__overlay">
                    <div className="reset-panel__main">
                        <button onClick={onClose} className="reset-panel__close-btn">
                            <img src={modalCloseX} alt="close x"/>
                        </button>
                        <div className="reset-panel__content">
                            {isLoading && <SeityLoader/>}
                            {renderContent()}
                        </div>
                    </div>
                </div>
            </div>

        ) : null;
    };

export default ResetPasswordOffCanvas;
