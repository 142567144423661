import React, { FunctionComponent, ReactElement, useState } from "react";
import { useDispatch } from "react-redux";
import { format } from "date-fns";

import { SurveyListItem, SurveyState } from "src/api/survey/surveyTypes";
import { sendCopySurveyWizardGroupRequest, sendToggleLikeSurveyWizardRequest } from "src/reducers/survey/surveySlice";

import { SubSurvey } from "./SubSurvey";
import SurveyAction from "./SurveyAction";

import "./styles.scss";

export type SurveyItemProps = {
  title: string;
  questionCount: number;
  dateCreated: string;
  surveyState: SurveyState;
  surveyWizardID: number;
  isLiked?: boolean;
  listItem?: SurveyListItem[];
  onScheduleSurvey: (surveyWizardId: number) => void;
  onShowMenu: () => void;
};

export const SurveyItem: FunctionComponent<SurveyItemProps> = ({
  dateCreated,
  questionCount,
  surveyState,
  title,
  surveyWizardID,
  isLiked = false,
  listItem,
  onShowMenu
}): ReactElement => {
  const dispatch = useDispatch();
  const [showListItem, setShowListItem] = useState(false);
  const hasListItems = !!listItem?.length;

  const onLikeClicked = (surveyID: number) => {
    dispatch(sendToggleLikeSurveyWizardRequest(surveyID));
  };

  const onDuplicateClicked = (surveyID: number) => {
    dispatch(sendCopySurveyWizardGroupRequest(surveyID));
  };

  return (
    <div className={`container-survey ${hasListItems && "has-sub-survey"}`}>
      <div
        className={`survey-container-item ${hasListItems && "has-sub-survey"}`}
        onClick={() => {
          return setShowListItem((value) => {
            return !value;
          });
        }}
      >
        <div className={`survey-item-section line-space ${hasListItems && "has-sub-survey"}`}>
          <span className="survey-title">{title}</span>
          {hasListItems && (
            <div className="item-list-count">
              <img src={require("src/assets/images/graphics/tree.png")} className="tree-icon" alt="tree icon" />
              <span> {listItem.length}</span>
              <img src={require("src/assets/images/graphics/arrow_up.png")} className="arrow-icon" alt="arrow up" />
            </div>
          )}
        </div>

        <div className="survey-item-section line-space">
          <div className="survey-question-container">
            <span className="survey-question">{questionCount} Questions</span>
            <span>Created: {format(new Date(dateCreated) || new Date(), "MM/dd/yyyy")}</span>
          </div>
        </div>

        <div className="survey-item-section line-space">
          <span className={`survey-status-${surveyState}`}>{surveyState} </span>
        </div>

        <div className="survey-item-section actions">
          <SurveyAction
            icon={require("src/assets/images/graphics/duplicate-1.png")}
            onClick={() => {
              onDuplicateClicked(surveyWizardID);
            }}
          />

          <SurveyAction
            icon={`${isLiked ? require("src/assets/images/graphics/like.png") : require("src/assets/images/graphics/like-empty.png")}`}
            onClick={() => {
              onLikeClicked(surveyWizardID);
            }}
          />

          <SurveyAction
            icon={require("src/assets/images/graphics/actions.png")}
            onClick={() => {
              return onShowMenu();
            }}
          />
        </div>
      </div>
      <div className={`sub-survey-list-container ${showListItem ? "fadein" : "fadeout"}`}>
        {showListItem &&
          listItem?.map((item) => {
            return (
              <SubSurvey
                key={`${item.title}-${item.startDate}`}
                startDate={item.startDate}
                endDate={item.endDate}
                surveyState={item.surveyState}
                title={item.title}
              />
            );
          })}
      </div>
    </div>
  );
};

export default SurveyItem;
