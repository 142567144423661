import React, { useEffect } from "react";
import { RootState } from "src/reducers";
import { useSelector, useDispatch, connect } from "react-redux";
import { sendGetProSettingsRequest } from "src/reducers/auth/accountSlice";
import { createPBIEmbedToken, updateDatasetToken } from "src/actions/pbi";
import { SeityLoader } from "src/components/SeityLoader";
import { sendGetAnalyticsViewRequest } from "src/reducers/auth/analyticsSlice";
import Welcome from "./Welcome";

import "./styles.scss";

export const Landing = ({ createPBIToken, embedToken, isPBITokenLoading }) => {
  const dispatch = useDispatch();
  const { proSettings, isLoading } = useSelector((state: RootState) => {
    return state.account;
  });
  const { groupID } = useSelector((state: RootState) => {
    return state.account.accountInfo;
  });

  useEffect(() => {
    if (!proSettings) {
      dispatch(sendGetProSettingsRequest());
    }
    dispatch(sendGetAnalyticsViewRequest());
  }, []);

  useEffect(() => {
    if (!embedToken && groupID) {
      createPBIToken(groupID);
    }
  }, [groupID]);

  return (
    <div className="landing-container">
      <Welcome proSettings={proSettings} embedToken={embedToken} />
      {(isLoading || isPBITokenLoading) && <SeityLoader showBackgroundMask />}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    createPBIToken: (groupID: string) => {
      return dispatch(createPBIEmbedToken(groupID));
    },
    updateDataset: (accessToken, datasetId, pToken) => {
      return dispatch(updateDatasetToken(accessToken, datasetId, pToken));
    }
  };
};

const mapStateToProps = (state) => {
  return {
    embedToken: state.pbi.pbiEmbedToken,
    accessToken: state.pbi.pbiAccessToken,
    isPBITokenLoading: state.pbi.isLoading,
    studentDatasetId: state.pbi.studentDatasetId,
    staffDatasetId: state.pbi.staffDatasetId
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
