import React, { FunctionComponent, ReactElement } from "react";

export type SurveyActionProps = {
  icon: string;
  onClick?: ((event: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined;
};

const SurveyAction: FunctionComponent<SurveyActionProps> = ({ icon, onClick }): ReactElement => {
  return (
    <div onClick={onClick} className="survey-action">
      <img src={icon} alt="survey action" />
    </div>
  );
};

export default SurveyAction;
