import React from "react";
import PropTypes from "prop-types";

import SeityModal from "../../SeityModal";
import { SeityButton } from "../SeityButton";

import "./styles.scss";

type SeityAlertProps = {
  visible: boolean;
  title: string;
  subTitle: string;
  classNameOverlay?: string;
  onToggle: () => void;
};
export const SeityAlert = ({ visible = false, title = "Alert", subTitle = "", classNameOverlay = "", onToggle }: SeityAlertProps) => {
  return (
    <SeityModal
      isOpen={visible}
      overlayClassName={classNameOverlay}
      onClose={() => {
        return onToggle();
      }}
    >
      <div className="seity-alert">
        <img src={require("src/assets/images/graphics/icon-alert.png")} width="36px" alt="Icon Alert" />
        {title.split("\n").map((i, key) => {
          return (
            <div className="seity-alert__title" key={key}>
              {i}
            </div>
          );
        })}
        <p className="seity-alert__subtitle">{subTitle}</p>
        <SeityButton label="Close" onClick={onToggle} />
      </div>
    </SeityModal>
  );
};

SeityAlert.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  onToggle: PropTypes.func
};

export default SeityAlert;
