import React, { FunctionComponent, ReactElement } from "react";
import ReactSelect, { StylesConfig } from "react-select";
import "./styles.scss";

export type OptionType = { label: string | undefined; value: any };
export type OptionsType = Array<OptionType>;

export type SurveySelectBoxProps = {
  className?: string;
  clearValue?: () => void;
  getStyles?: (string, any) => {};
  hasValue?: boolean;
  isMulti?: boolean;
  options: OptionsType | any[];
  styles?: StylesConfig;
  selectOption?: (OptionType) => void;
  selectProps?: any;
  setValue?: (ValueType, ActionTypes) => void;
  emotion?: any;
  defaultInputValue?: string;
  defaultValue?: OptionType;
  name?: string;
  value?: string | OptionType | null;
};

export const SurveySelectBox: FunctionComponent<SurveySelectBoxProps> = ({
  className,
  styles,
  selectOption,
  defaultValue,
  ...props
}): ReactElement => {
  const customStyles: StylesConfig = {
    dropdownIndicator: (provided) => {
      return {
        ...provided,
        color: "black"
        // none of react-select's styles are passed to <Control />
      };
    },
    indicatorSeparator: () => {
      return {
        display: "none"
      };
    }
  };
  const finalStyle = styles ? { ...customStyles, ...styles } : customStyles;
  return (
    <div className={`survey-select-container ${className || ""}`}>
      <ReactSelect {...props} styles={finalStyle} onChange={selectOption} defaultValue={defaultValue} />
    </div>
  );
};

export default SurveySelectBox;
