import {FunctionComponent, ReactElement, useEffect, useRef, useState} from "react";

import "./styles.scss";

type ResetPasswordNumberInputProps = {
    value: string | null;
    placeholder: string;
    maxLen?: number;
    onChange: (val: string) => void;
};

export const ResetPasswordNumberInput: FunctionComponent<ResetPasswordNumberInputProps> = (props): ReactElement => {
    const {value, placeholder, maxLen = 100, onChange} = props;

    const [focus, setFocus] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        inputRef.current?.focus();
    }, []);

    return (
        <div className="reset-password-NumberInput">
            <div
                className={`reset-password-NumberInput__content ${focus ? "focus" : ""}`}>
                <input
                    maxLength={maxLen}
                    type={"number"}
                    placeholder={placeholder}
                    value={value || ""}
                    ref={inputRef}
                    onChange={(e) => {

                        let inputValue = e.target.value;
                        if (inputValue.length <= maxLen) {
                            onChange(inputValue);
                        }

                    }}
                    onFocus={() => {
                        setFocus(true);
                    }}
                    onBlur={() => {
                        setFocus(false);
                    }}
                />
            </div>
        </div>
    );
};

export default ResetPasswordNumberInput;
