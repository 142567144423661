import { createSlice } from "@reduxjs/toolkit";
import { SeityAuthenticationError } from "../../api/authTypes";
import { SeityDashboardPayloadResponse, ProSettings } from "../../api/account/types/accountTypes";
import {
  getAccountInfo,
  getAccountLists,
  getDashboardData,
  sendAccountMaintenanceUpdate,
  sendChangePasswordUpdate,
  getMessageCenterList,
  getProSettings
} from "../../api/account/seityHealthAPI-account";

import {
  SeityAccountListsItemResponse,
  SeityAccountMaintenancePayloadRequest,
  SeityGetAccountInformationPayloadResponse
} from "../../api/account/types/maintenanceTypes";
import { SeityAccountMessageCenter } from "../../api/account/types/messageCenterListTypes";
import { setRedirectToLogin } from "./authSlice";
import {validateToken} from "../utils";

export interface AccountState {
  readonly isLoading: boolean;
  readonly accountError: string | null;
  readonly dashboardResponse: SeityDashboardPayloadResponse | null;
  readonly isAccountUpdateSuccessful: boolean; // Used to indicate a non-registration account update
  readonly isPasswordChangeSuccessful: boolean;
  readonly accountLists: [SeityAccountListsItemResponse] | null;
  readonly accountInfo: SeityGetAccountInformationPayloadResponse;
  readonly messageCenterList: [SeityAccountMessageCenter];
  readonly proSettings: ProSettings;
}

const defaultAccountInformation = () => {
  return {
    dateOfBirth: "", // MM/dd/yyyy
    status: "",
    lastName: "",
    firstName: "",
    occupation: "",
    maritalStatusID: 0,
    accountStatusID: 0,
    accountStatus: "",
    statusID: 0,
    zipcode: "",
    maritalStatus: "",
    eMailAddress: "",
    genderID: 0,
    occupationID: 0,
    education: "",
    educationID: 0,
    gender: "",
    cellPhone: "",
    accountID: 0,
    groupID: "",
    isCVOnly: false,
    assessmentLevelID: 0
  };
};

export const setInitialState = {
  isLoading: false,
  accountError: null,
  dashboardResponse: null,
  isAccountUpdateSuccessful: false,
  isPasswordChangeSuccessful: false,
  accountLists: null,
  accountInfo: defaultAccountInformation()
} as AccountState;

const accountSlice = createSlice({
  name: "account",
  initialState: setInitialState,
  reducers: {
    setDashboardResponse(state, action) {
      state.dashboardResponse = action.payload.dashboardResponse;
      state.isLoading = false;
      state.accountError = null;
    },
    // If a value is not passed, it's assumed
    // to be true
    setIsLoading(state, action) {
      if (action && action.payload && action.payload.isLoading !== undefined && action.payload.isLoading !== null) {
        state.isLoading = action.payload.isLoading;
      } else {
        state.isLoading = true;
      }
    },
    setAccountError(state, action) {
      state.accountError = action.payload.accountError;
      state.isLoading = false;
    },
    setAccountLists(state, action) {
      state.accountLists = action.payload.accountLists;
    },
    setAccountInfo(state, action) {
      state.accountInfo = {
        ...action.payload.accountInfo,
        isCVOnly: action.payload.accountInfo.assessmentLevelID === 2
      };
      state.isLoading = false;
    },
    setMessageCenterList(state, action) {
      state.messageCenterList = action.payload.messageCenterList;
    },
    setAccountInfoValues(state, action) {
      let payload = action.payload as SeityGetAccountInformationPayloadResponse;
      if (payload.dateOfBirth !== undefined) {
        state.accountInfo.dateOfBirth = payload.dateOfBirth;
      }
      if (payload.status !== undefined) {
        state.accountInfo.status = payload.status;
      }
      if (payload.lastName !== undefined) {
        state.accountInfo.lastName = payload.lastName;
      }
      if (payload.firstName !== undefined) {
        state.accountInfo.firstName = payload.firstName;
      }
      if (payload.occupation !== undefined) {
        state.accountInfo.occupation = payload.occupation;
      }
      if (payload.maritalStatusID !== undefined) {
        state.accountInfo.maritalStatusID = payload.maritalStatusID;
      }
      if (payload.accountStatusID !== undefined) {
        state.accountInfo.accountStatusID = payload.accountStatusID;
      }
      if (payload.accountStatus !== undefined) {
        state.accountInfo.accountStatus = payload.accountStatus;
      }
      if (payload.statusID !== undefined) {
        state.accountInfo.statusID = payload.statusID;
      }
      if (payload.zipcode !== undefined) {
        state.accountInfo.zipcode = payload.zipcode;
      }
      if (payload.maritalStatus !== undefined) {
        state.accountInfo.maritalStatus = payload.maritalStatus;
      }
      if (payload.eMailAddress !== undefined) {
        state.accountInfo.eMailAddress = payload.eMailAddress;
      }
      if (payload.genderID !== undefined) {
        state.accountInfo.genderID = payload.genderID;
      }
      if (payload.occupationID !== undefined) {
        state.accountInfo.occupationID = payload.occupationID;
      }
      if (payload.education !== undefined) {
        state.accountInfo.education = payload.education;
      }
      if (payload.educationID !== undefined) {
        state.accountInfo.educationID = payload.educationID;
      }
      if (payload.gender !== undefined) {
        state.accountInfo.gender = payload.gender;
      }
      if (payload.cellPhone !== undefined) {
        state.accountInfo.cellPhone = payload.cellPhone;
      }
      if (payload.accountID !== undefined) {
        state.accountInfo.accountID = payload.accountID;
      }
    },
    setAccountUpdateSuccessful(state, action) {
      state.isAccountUpdateSuccessful = action.payload.accountUpdateSuccessful;
    },
    setChangePasswordSuccessful(state, action) {
      state.isPasswordChangeSuccessful = action.payload.passwordChangeSuccessful;
    },
    setProSettings(state, action) {
      const payload = action.payload as ProSettings;
      state.proSettings = payload;
      state.isLoading = false;
    },
    resetAccountSlice: () => setInitialState,
    clearAccountError(state) {
      state.accountError = null;
    }
  }
});

export const {
  setIsLoading,
  setAccountError,
  setDashboardResponse,
  setAccountUpdateSuccessful,
  setAccountLists,
  setAccountInfo,
  setChangePasswordSuccessful,
  setAccountInfoValues,
  setMessageCenterList,
  resetAccountSlice,
  clearAccountError,
  setProSettings
} = accountSlice.actions;
export default accountSlice.reducer;

export const sendDashboardRequest = () => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({}));

    const token = await validateToken(dispatch);

    console.log("Getting dashboard data...");
    const dashboardResponse = await getDashboardData(token);
    if (dashboardResponse.success === false || dashboardResponse.message || !dashboardResponse.data) {
      dispatch(setAccountError({ accountError: dashboardResponse.message ?? "There was an error getting the dashboard information." }));
      return;
    }
    console.log("Received dashboard data...");
    dispatch(setDashboardResponse({ dashboardResponse: dashboardResponse.data }));

    const msgCenterListResponse = await getMessageCenterList(token);
    if (
      msgCenterListResponse.success === false ||
      msgCenterListResponse.message ||
      !msgCenterListResponse.data ||
      !msgCenterListResponse.data.messageCenterList ||
      msgCenterListResponse.data.messageCenterList.length < 1
    ) {
      dispatch(
        setAccountError({ accountError: msgCenterListResponse.message ?? "There was an error getting the messageCenterList information." })
      );
      return;
    }

    console.log("Received messageCenterList data...", msgCenterListResponse.data.messageCenterList);
    dispatch(setMessageCenterList({ messageCenterList: msgCenterListResponse.data.messageCenterList }));
  } catch (err) {
    console.error(err);
    handleError(err, dispatch);
  }
};

export const sendMessageCenterListRequest = () => async (dispatch: any) => {
  console.log("Retrieving message center data...");

  const token = await validateToken(dispatch);

  const msgCenterListResponse = await getMessageCenterList(token);
  if (
    msgCenterListResponse.success === false ||
    msgCenterListResponse.message ||
    !msgCenterListResponse.data ||
    !msgCenterListResponse.data.messageCenterList ||
    msgCenterListResponse.data.messageCenterList.length < 1
  ) {
    dispatch(
      setAccountError({ accountError: msgCenterListResponse.message ?? "There was an error getting the messageCenterList information." })
    );
    return;
  }

  console.log("Received messageCenterList data...", msgCenterListResponse.data.messageCenterList);
  dispatch(setMessageCenterList({ messageCenterList: msgCenterListResponse.data.messageCenterList }));
};

export const sendGetAccountListsRequest =
  (accountStatus: boolean, status: boolean, education: boolean, occupation: boolean, gender: boolean, maritalStatus: boolean) =>
  async (dispatch: any) => {
    try {
      dispatch(setIsLoading({}));

      const token = await validateToken(dispatch);

      console.log("Getting account lists...");
      const listResponse = await getAccountLists(token, accountStatus, status, education, occupation, gender, maritalStatus);
      dispatch(setIsLoading({ isLoading: false }));
      if (
        listResponse.success === false ||
        listResponse.message ||
        !listResponse.data ||
        !listResponse.data.accountLists ||
        listResponse.data.accountLists.length < 1
      ) {
        dispatch(setAccountError({ accountError: listResponse.message || "There was an error getting the data for the screen." }));
        return;
      }
      console.log("Received account lists response.");
      dispatch(setAccountLists({ accountLists: listResponse.data?.accountLists }));
    } catch (err) {
      handleError(err, dispatch);
    }
  };

export const sendGetAccountInfoRequest = () => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({}));

    const token = await validateToken(dispatch);

    console.log("Getting account information...");
    const accountResponse = await getAccountInfo(token);
    if (accountResponse.success === false || accountResponse.message || !accountResponse.data) {
      dispatch(setAccountError({ accountError: accountResponse.message || "There was an error getting the data for the screen." }));
      return;
    }
    dispatch(setAccountInfo({ accountInfo: accountResponse.data }));
  } catch (err) {
    handleError(err, dispatch);
  }
};

export const sendChangePasswordRequest = (oldPassword: string, newPassword: string) => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({}));

    const token = await validateToken(dispatch);

    console.log("Changing password...");
    const changePasswordResponse = await sendChangePasswordUpdate(token, oldPassword, newPassword);
    if (changePasswordResponse.success === false || changePasswordResponse.message) {
      dispatch(setAccountError({ accountError: changePasswordResponse.message || "There was an error getting the data for the screen." }));
      return;
    }
    console.log("Password Changed.");
    dispatch(setChangePasswordSuccessful({ passwordChangeSuccessful: true }));
    dispatch(setAccountError({ accountError: "" }));
  } catch (err) {
    dispatch(setChangePasswordSuccessful({ passwordChangeSuccessful: false }));
    handleError(err, dispatch);
  }
};

export const sendAccountMaintenanceUpdateRequest = (maintenanceData: SeityAccountMaintenancePayloadRequest) => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({}));
    dispatch(clearAccountError());

    const token = await validateToken(dispatch);

    console.log("Sending account update...");
    const accountUpdateResponse = await sendAccountMaintenanceUpdate(token, maintenanceData);
    if (accountUpdateResponse.success === false || accountUpdateResponse.message) {
      dispatch(setAccountError({ accountError: accountUpdateResponse.message || "There was an error updating your account details." }));
      return;
    }
    console.log("Received full account update response.");
    dispatch(setIsLoading({ isLoading: false }));
    dispatch(setAccountUpdateSuccessful({ accountUpdateSuccessful: true }));
  } catch (err) {
    console.error(err);
    handleError(err, dispatch);
  }
};

export const sendGetProSettingsRequest = () => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({}));

    const token = await validateToken(dispatch);

    const response = await getProSettings(token);
    if (response.success === false || response.message || !response.data) {
      dispatch(setAccountError({ accountError: response.userMessage || "There was an error getting the data for the screen." }));
      return;
    }
    dispatch(setProSettings(response.data));
  } catch (err) {
    handleError(err, dispatch);
  }
};

function handleError(err: any, dispatch: any) {
  console.error(err);
  dispatch(setAccountError({ accountError: err.toString() }));
}
