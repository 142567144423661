import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { useWindowSize } from "src/hooks/Layout";

import { RootState } from "src/reducers";
import { sendGetSurveyRecurrenceRequest, sendGetSurveyResultsRequest, sendGetSurveyWizardRequest } from "src/reducers/survey/surveySlice";

import SurveyResultsSideMenu from "src/components/survey/SurveyResultsSideMenu";
import SeityLoader from "src/components/SeityLoader";
import SurveyChart from "src/components/survey/SurveyChart";
import ResultsHeader from "./ResultsHeader";
import ResultsSubHeader from "./ResultsSubHeader";

import "./styles.scss";

export const SurveyResults = () => {
  const [selMenu, setMenuItem] = useState(0);
  const dispatch = useDispatch();

  const location = useLocation();
  const history = useHistory();
  const [showShadow, setShadow] = useState(false);
  const { surveyWizardID } = location.state;
  const [recurrenceID, setRecurrenceID] = useState(-1);

  const size = useWindowSize();
  const contentListHeight = size.height - 307.5 - 75;
  const sidemenuHeight = size.height - 210 - 75;

  const handleScroll = (event) => {
    const { scrollTop } = event.target;
    if (scrollTop > 5 && !showShadow) {
      setShadow(true);
    } else if (scrollTop < 5 && showShadow) {
      setShadow(false);
    }
  };

  const { isLoading, surveyResults, currentSurvey, surveyRecurrence } = useSelector((state: RootState) => {
    return state.survey;
  });

  useEffect(() => {
    if (!surveyWizardID) {
      history.push("/survey");
    }
    dispatch(
      sendGetSurveyRecurrenceRequest(surveyWizardID, (recurrences = []) => {
        if (recurrences && recurrences.length > 0) {
          const firstRecur = recurrences.reduce((op, item) => {
            // eslint-disable-next-line no-return-assign
            return op.recurrence < item.recurrence ? op : item;
          }, recurrences[0]);
          setRecurrenceID(firstRecur.surveyWizardRecurrenceID);
        }
      })
    );

    dispatch(sendGetSurveyWizardRequest(surveyWizardID));

    window.addEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (recurrenceID !== -1) {
      dispatch(sendGetSurveyResultsRequest(recurrenceID));
    }
  }, [recurrenceID]);

  return (
    <div className="survey-results">
      <div className="sidemenu-wrap">
        <SurveyResultsSideMenu
          recurrenceData={surveyRecurrence}
          title="All Surveys"
          height={sidemenuHeight}
          selIdx={selMenu}
          handleMenuPress={(index, surveyWizardRecurrenceID) => {
            setMenuItem(index);
            setRecurrenceID(surveyWizardRecurrenceID);
          }}
        />
      </div>
      {(isLoading || !currentSurvey || !surveyResults) && <SeityLoader />}
      <div className="survey-results__content">
        <ResultsHeader surveyTitle={currentSurvey?.name} startDate={surveyResults?.startDate} endDate={surveyResults?.endDate} />
        <ResultsSubHeader
          showShadow={showShadow}
          questionNumber={surveyResults?.questionNumber}
          participantNumber={surveyResults?.participantNumber}
        />
        <div className="survey-results-graphs" style={{ height: contentListHeight }} onScroll={handleScroll}>
          {surveyResults &&
            surveyResults.questionResults?.map((question, index) => {
              return (
                <SurveyChart
                  sequence={index + 1}
                  answerData={question.answerResults}
                  question={question.question}
                  key={`Chart ${question.question}-${index}`}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default SurveyResults;
