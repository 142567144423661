import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import apiClientWithAuth from "src/api/apiClient";
import { WbnFilter, WbnViewFilter } from "src/api/wbn/wbnTypes";
import { FILTERS_DATA } from "src/features/WellbeingNavigator/data";

export type WbnState = {
  isLoading: boolean;
  error: object;
  wbnFilterData: WbnFilter;
  savedViews: Array<WbnViewFilter>;
  selSavedView: WbnViewFilter | null;
};

const setInitialState = {
  isLoading: false,
  error: {},
  wbnFilterData: FILTERS_DATA, // For testing purpose only
  savedViews: [],
  selSavedView: null
} as WbnState;

const wbnSlice = createSlice({
  name: "ats",
  initialState: setInitialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setAtsError(state, action: PayloadAction<object>) {
      state.error = action.payload;
    },
    setWbnFilterData(state, action: PayloadAction<WbnFilter>) {
      state.wbnFilterData = action.payload;
    },
    setWbnSavedViews(state, action: PayloadAction<WbnViewFilter[]>) {
      state.savedViews = action.payload;
    },
    setWbnSavedViewDetail(state, action: PayloadAction<WbnViewFilter>) {
      state.selSavedView = action.payload;
    }
  }
});

export const { setIsLoading, setAtsError, setWbnFilterData, setWbnSavedViews, setWbnSavedViewDetail } = wbnSlice.actions;

export const sendGetWbnSavedViews = () => { return async (dispatch: any) => {
  try {
    dispatch(setIsLoading(true));
    const res = await apiClientWithAuth.get("/WellbeingNavigatorData/savedFilters");
    if (res.data.success) {
      dispatch(setWbnSavedViews(res.data.data));
    }
    dispatch(setIsLoading(false));
  } catch (err: any) {
    dispatch(setIsLoading(false));
    console.error(err);
  }
}};

export const sendGetWbnSavedViewDetail = (viewId: number) => { return async (dispatch: any) => {
  try {
    dispatch(setIsLoading(true));
    const res = await apiClientWithAuth.get(`/WellbeingNavigatorData/savedFilter?wBNViewFilterID=${viewId}`);
    if (res.data.success) {
      dispatch(setWbnSavedViewDetail(res.data.data));
    }
    dispatch(setIsLoading(false));
  } catch (err: any) {
    dispatch(setIsLoading(false));
    console.error(err);
  }
}};

export const sendPostWbnSavedView = (data: any) => { return async (dispatch: any) => {
  try {
    dispatch(setIsLoading(true));
    await apiClientWithAuth.post("/WellbeingNavigatorData/savedFilter", data);
    dispatch(setIsLoading(false));
  } catch (err: any) {
    dispatch(setIsLoading(false));
    console.error(err);
  }
}};

export const sendPutWbnSavedView = (data: any) => { return async (dispatch: any) => {
  try {
    dispatch(setIsLoading(true));
    await apiClientWithAuth.put("/WellbeingNavigatorData/savedFilter", data);
    dispatch(setIsLoading(false));
  } catch (err: any) {
    dispatch(setIsLoading(false));
    console.error(err);
  }
}};

export const sendDeleteWbnSavedView = (viewId: number, callback?: (success: boolean) => void) => { return async (dispatch: any) => {
  try {
    dispatch(setIsLoading(true));
    const res = await apiClientWithAuth.delete(`/WellbeingNavigatorData/savedFilter/${viewId}`);
    if (callback) {
      callback(res.data.success);
    }
    dispatch(setIsLoading(false));
  } catch (err: any) {
    dispatch(setIsLoading(false));
    console.error(err);
  }
}};

export default wbnSlice.reducer;
