import { WbnFilter, WbnViewFilter } from "src/api/wbn/wbnTypes";

export const FILTERS_DATA: WbnFilter = {
  entities: [
    {
      entityID: 1,
      name: "District 1",
      companies: [
        {
          companyID: 1,
          name: "School 1"
        },
        {
          companyID: 2,
          name: "School 2"
        },
        {
          companyID: 3,
          name: "School 3"
        },
        {
          companyID: 4,
          name: "School 4"
        }
      ]
    },
    {
      entityID: 2,
      name: "District 2",
      companies: [
        {
          companyID: 5,
          name: "School 5"
        },
        {
          companyID: 6,
          name: "School 6"
        },
        {
          companyID: 7,
          name: "School 7"
        },
        {
          companyID: 8,
          name: "School 8"
        }
      ]
    }
  ],
  gradeLevels: [
    {
      gradeLevelID: 1,
      description: "1st"
    },
    {
      gradeLevelID: 2,
      description: "2nd"
    },
    {
      gradeLevelID: 3,
      description: "3rd"
    },
    {
      gradeLevelID: 4,
      description: "1th"
    }
  ],
  lastCheckinDate: {
    startDate: null,
    endDate: null
  },
  attentionCodes: [
    {
      attentionCodeID: 1,
      iconName: "sad_face"
    },
    {
      attentionCodeID: 2,
      iconName: "neutral_face"
    }
  ],
  races: [
    {
      raceID: 1,
      description: "race 1"
    },
    {
      raceID: 2,
      description: "race 2"
    },
    {
      raceID: 3,
      description: "race 3"
    },
    {
      raceID: 4,
      description: "race 4"
    }
  ],
  genderIDs: [
    {
      genderID: 1,
      description: "gender 1"
    },
    {
      genderID: 2,
      description: "gender 2"
    },
    {
      genderID: 3,
      description: "gender 3"
    },
    {
      genderID: 4,
      description: "gender 4"
    },
    {
      genderID: 5,
      description: "gender 5"
    }
  ]
};

export const SAVED_VIEWS_DATA: WbnViewFilter = {
  wbnViewFilterID: 1,
  viewName: "Test View",
  entities: [
    {
      entityID: 13,
      companies: [
        {
          companyID: 84
        }
      ]
    }
  ],
  gradeLevels: [
    {
      gradeLevelID: 9,
      description: ""
    }
  ],
  lastCheckinDate: {
    startDate: null,
    endDate: null
  },
  attentionCodes: [],
  races: [],
  genderIDs: []
};

export const FILTER_OPTIONS_SAMPLE: WbnFilter = {
  entities: [],
  gradeLevels: [],
  lastCheckinDate: {
    startDate: null,
    endDate: null
  },
  attentionCodes: [],
  races: [],
  genderIDs: []
};

export const COLUMNS_DATA = [
  {
    id: 1,
    title: "Last Name",
    colName: "lastName",
    selected: true
  },
  {
    id: 2,
    title: "First Name",
    colName: "firstName",
    selected: true
  },
  {
    id: 3,
    title: "Last Check-in",
    colName: "lastCheckinDate",
    selected: true
  },
  {
    id: 4,
    title: "Wellbeing",
    colName: "attentionCode",
    selected: true
  },
  {
    id: 5,
    title: "Emailed",
    colName: "outreachReason",
    selected: true
  },
  {
    id: 6,
    title: "School",
    colName: "school",
    selected: true
  },
  {
    id: 7,
    title: "Grade Level",
    colName: "grade",
    selected: true
  },
  {
    id: 8,
    title: "Race",
    colName: "raceDescription",
    selected: true
  },
  {
    id: 9,
    title: "Gender",
    colName: "genderDescription",
    selected: true
  }
  // { this is removed for a while
  //   id: 7,
  //   title: "Outreach Reason",
  //   selected: false
  // },
];
