import { createSlice } from "@reduxjs/toolkit";
import { SeityCompanyCodePayloadResponse } from "../../api/register/registerTypes";
import { register, validateCompanyCode } from "../../api/register/seityHealthAPI-Register";
import { cacheToken, saveExpiration, saveRefreshKey } from "./useSessionStorageToken";
import strings from "../../_core/strings/strings";

export interface RegistrationSubmissionInfo {
  invitationCode: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  dateOfBirth: string;
  password: string;
  groupActivationCode: string;
  companyName: string;
}

export interface RegistrationState {
  readonly isLoading: boolean;
  readonly registerError: string | null;
  readonly current: RegistrationSubmissionInfo;
  readonly companyCodeResponse: SeityCompanyCodePayloadResponse | null;
  readonly registerSuccess: boolean;
}

const getDefaultSubmissionInfo = () => {
  return {
    firstName: "",
    lastName: "",
    emailAddress: "",
    password: "",
    groupActivationCode: "",
    companyName: "",
    dateOfBirth: ""
  };
};

const setInitialState = {
  isLoading: false,
  registerError: null,
  current: getDefaultSubmissionInfo(),
  companyCodeResponse: null,
  registerSuccess: false
} as RegistrationState;

const registerSlice = createSlice({
  name: "register",
  initialState: setInitialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = true;
    },
    setRegisterSuccess(state, action) {
      let payload = action.payload as boolean;
      state.registerSuccess = payload;
    },
    setRegisterError(state, action) {
      state.registerError = action.payload.registerError;
      state.isLoading = false;
    },
    setCompanyCodeResponse(state, action) {
      state.companyCodeResponse = action.payload.companyCodeResponse;
      if (state.companyCodeResponse?.companyCode) {
        state.current.groupActivationCode = state.companyCodeResponse.companyCode;
        state.current.companyName = state.companyCodeResponse.companyName;
      }
      state.isLoading = false;
    },
    setCurrentInfo(state, action) {
      let payload = action.payload as RegistrationSubmissionInfo;
      if (payload.invitationCode !== undefined) {
        state.current.invitationCode = payload.invitationCode;
      }
      if (payload.firstName !== undefined) {
        state.current.firstName = payload.firstName;
      }
      if (payload.lastName !== undefined) {
        state.current.lastName = payload.lastName;
      }
      if (payload.emailAddress !== undefined) {
        state.current.emailAddress = payload.emailAddress;
      }
      if (payload.password !== undefined) {
        state.current.password = payload.password;
      }
      if (payload.dateOfBirth !== undefined) {
        state.current.dateOfBirth = payload.dateOfBirth;
      }
      // Group activation is automatically set once validated with backend
    },
    clearCompanyCodeResponse(state, action) {
      state.companyCodeResponse = null;
    },
    clearError(state, action) {
      state.registerError = null;
    },
    resetRegisterSlice: () => setInitialState
  }
});

export const {
  setIsLoading,
  setRegisterSuccess,
  setRegisterError,
  setCompanyCodeResponse,
  setCurrentInfo,
  clearError,
  clearCompanyCodeResponse,
  resetRegisterSlice
} = registerSlice.actions;

export default registerSlice.reducer;

export const sendValidateCompanyCodeRequest =
  (invitationCode: string, dateOfBirth: string, firstName: string, lastName: string, callback?: () => void) => async (dispatch: any) => {
    try {
      dispatch(setIsLoading({}));
      dispatch(clearError({}));
      console.log("Sending validate company code...");
      //TODO create error codes in API
      const companyCodeResponse = await validateCompanyCode(invitationCode);
      if (companyCodeResponse.success === false || companyCodeResponse.message || !companyCodeResponse.data) {
        dispatch(setRegisterError({ registerError: companyCodeResponse.message || "There was an error validating your invitation code." }));
        return;
      }
      console.log("Received validate company code response.");
      dispatch(setCompanyCodeResponse({ companyCodeResponse: companyCodeResponse.data }));
      dispatch(setCurrentInfo({ dateOfBirth, firstName, lastName }));
      if (callback) {
        callback();
      }
    } catch (err) {
      handleError(err, dispatch);
    }
  };

function handleError(err: any, dispatch: any) {
  console.error(err);
  dispatch(setRegisterError({ registerError: err.message }));
}

export const sendEmailAddressRequest =
  (firstName: string, lastName: string, email: string, password: string, groupActivationCode: string) => async (dispatch: any) => {
    try {
      dispatch(setIsLoading({}));
      dispatch(clearError({}));
      const emailResponse = await register(firstName, lastName, email, password, groupActivationCode);
      switch (emailResponse.data.returnCode) {
        case 0: {
          dispatch(setRegisterSuccess(true));

          if (emailResponse.data.token != null && emailResponse.data.expirationTime != null && emailResponse.data.refreshKey != null) {
            console.log("Register email success...");
            cacheToken(emailResponse.data.token);
            saveRefreshKey(emailResponse.data.refreshKey);
            saveExpiration(emailResponse.data.expirationTime);
            dispatch(setCurrentInfo({ emailAddress: email }));
            break;
          } else {
            dispatch(setRegisterError({ registerError: strings.registrationError }));
            break;
          }
        }
        case 1: {
          dispatch(setRegisterError({ registerError: strings.emailAlreadyRegistered }));
          break;
        }
        case 2: {
          dispatch(setRegisterError({ registerError: strings.invalidInvitationCode }));
          break;
        }
        default: {
          dispatch(setRegisterError({ registerError: strings.registrationError }));
          break;
        }
      }
    } catch (err) {
      handleError(err, dispatch);
    }
  };
