// useful maps
const dayMap = {
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
  7: "Sunday"
};

const dateMap = {
  0: "0",
  1: "1st",
  2: "2nd",
  3: "3rd",
  4: "4th",
  5: "5th",
  6: "6th",
  7: "7th",
  8: "8th",
  9: "9th",
  10: "10th",
  11: "11th",
  12: "12th",
  13: "13th",
  14: "14th",
  15: "15th",
  16: "16th",
  17: "17th",
  18: "18th",
  19: "19th",
  20: "20th",
  21: "21st",
  22: "22nd",
  23: "23rd",
  24: "24th",
  25: "25th",
  26: "26th",
  27: "27th",
  28: "28th",
  29: "29th",
  30: "30th",
  31: "31st"
};

const monthMap = {
  0: "January",
  1: "February",
  2: "March",
  3: "April",
  4: "May",
  5: "June",
  6: "July",
  7: "August",
  8: "September",
  9: "October",
  10: "November",
  11: "December"
};

// given two date objects returns the amount of days difference as an int
export const getDateDifferenceInDays = (date1: Date, date2: Date) => {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;

  const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
  const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
};

export const getDateDifferenceInDaysWithTime = (date1: Date, date2: Date) => {
  const _MS_PER_Minute = 1000;

  const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate(), date1.getHours(), date1.getMinutes());
  const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate(), date2.getHours(), date2.getMinutes());

  return Math.floor((utc2 - utc1) / _MS_PER_Minute);
};

export const getFullDate = (date: Date) => {
  const year: string = date.getFullYear() + "";
  let month: number | string = date.getMonth() + 1;
  if (month < 10) {
    month = "0" + month;
  }
  let day: number | string = date.getDate();
  if (day < 10) {
    day = "0" + day;
  }
  let fullDate: string = month + "/" + day + "/" + year;

  return fullDate;
};

export const getTimeStringFromTimeString = (time: string) => {
  const hours = Number(time.slice(0, 2));
  const minutes = Number(time.slice(3, 5));
  const date = new Date(2022, 0, 1, hours, minutes);
  return getTimeStringFromDate(date);
};

export const getDateStringFromDate = (date: Date) => {
  return `${dayMap[date.getDay()]} ${monthMap[date.getMonth()]} ${dateMap[date.getDate()]}, ${date.getFullYear()}`;
};

export const getTimeStringFromDate = (date: Date) => {
  return date.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit", hour12: true });
};

export const getDateStringFromDateString = (date: string) => {
  const dateObj = new Date(date);
  return `${dayMap[dateObj.getDay()]} ${monthMap[dateObj.getMonth()]} ${dateMap[dateObj.getDate()]}, ${dateObj.getFullYear()}`;
};

export const getTimeStringFromDateString = (date: string) => {
  return new Date(date).toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit", hour12: true });
};

export const getFullDateFromDateString = (dateString: string) => {
  const date = new Date(dateString);
  const year: string = `${date.getFullYear()}`;
  let month: number | string = date.getMonth() + 1;
  if (month < 10) {
    month = `0${month}`;
  }
  let day: number | string = date.getDate();
  if (day < 10) {
    day = `0${day}`;
  }
  const fullDate: string = `${month}/${day}/${year}`;

  return fullDate;
};
