import React, { FunctionComponent, ReactElement } from "react";
import { StepWizardChildProps } from "react-step-wizard";

import "./styles.scss";

export const SurveyStepper: FunctionComponent<Partial<StepWizardChildProps>> = ({ currentStep = 1 }): ReactElement => {
  const stepTitle = ["Question Setup", "Select Schedule", "Select Audience"];
  const getStep = (curStep: number) => {
    const isCompleted = curStep < currentStep;
    const isFocused = curStep === currentStep;

    return (
      <>
        {/* eslint-disable-next-line no-nested-ternary */}
        <span className={`${isCompleted ? "completed" : !isFocused ? "unfocused" : ""}`}>
          {isCompleted ? <img alt="Survey Step Completed" src={require("src/assets/survey/survey-step-check.png")} /> : curStep}
        </span>
        <p className={`${!isFocused ? "unfocused" : ""}`}>{stepTitle[curStep - 1]}</p>
      </>
    );
  };

  return (
    <div className="stepper-container">
      {getStep(1)}
      <div />
      {getStep(2)}
      <div />
      {getStep(3)}
    </div>
  );
};

export default SurveyStepper;
