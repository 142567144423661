/* eslint no-param-reassign: "error" */
import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import {
  SurveyWizard,
  SurveyWizardAnswerGroup,
  SurveyState as SurveyStatus,
  SurveySchedule,
  SurveyResults,
  SurveyRecurrence,
  SurveyWizardAnswer
} from "src/api/survey/surveyTypes";

import {
  getSurveyWizard,
  deleteSurveyWizardQuestion,
  postSurveyWizardQuestion,
  copySurveyWizard,
  toggleLikeSurveyWizard,
  deleteSurveyWizard,
  getSurveyWizardById,
  getSurveyRecurrence,
  getSurveyRecurrenceResults
} from "src/api/survey/seityHealthAPI-survey";

import { OptionType } from "src/components/survey/SurveySelectBox/SurveySelectBox";
import { RootState } from "..";

import { validateToken } from "../utils";

export type SorttByType = "dateCreated" | "dateModified" | null;

export interface SurveyState {
  isLoading: boolean;
  surveyWizardList: SurveyWizard[];
  surveyError: object;
  surveyAudienceError: object | null;
  surveyWizardAnswerGroup: SurveyWizardAnswerGroup[];
  surveyWizardAnswer: { [key: string]: SurveyWizardAnswer[] };
  surveyFrequencyList: OptionType[];
  sortBy: SorttByType;
  filterByStatus: SurveyStatus | null;
  filterByFavorite: boolean;
  currentSurvey: SurveyWizard | null;
  schoolList: OptionType[];
  teacherList: OptionType[];
  classList: OptionType[];
  surveyResults: SurveyResults | null;
  surveyRecurrence: SurveyRecurrence[];
}

export const setInitialState = {
  isLoading: false,
  surveyWizardList: [],
  surveyError: {},
  surveyAudienceError: null,
  surveyWizardAnswerGroup: [],
  surveyWizardAnswer: {},
  surveyFrequencyList: [],
  sortBy: null,
  filterByStatus: null,
  filterByFavorite: false,
  currentSurvey: null,
  schoolList: [],
  teacherList: [],
  classList: [],
  surveyResults: null,
  surveyRecurrence: []
} as SurveyState;

const surveySlice = createSlice({
  name: "survey",
  initialState: setInitialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setSurveyError(state, action: PayloadAction<object>) {
      state.surveyError = action.payload;
    },
    clearSurveyAudienceError(state) {
      state.surveyAudienceError = null;
    },
    setSurveyAudienceError(state, action: PayloadAction<object>) {
      state.surveyAudienceError = action.payload;
    },
    setSurveyWizardList(state, action: PayloadAction<SurveyWizard[]>) {
      state.surveyWizardList = action.payload;
    },
    addSurveyWizardToList(state, action: PayloadAction<SurveyWizard>) {
      state.surveyWizardList.push(action.payload);
    },
    setSurveyWizardAnswerGroup(state, action: PayloadAction<SurveyWizardAnswerGroup[]>) {
      state.surveyWizardAnswerGroup = action.payload;
    },
    setSurveyWizardAnswer(state, action: PayloadAction<{ groupAnswerID: number; answers: SurveyWizardAnswer[] }>) {
      state.surveyWizardAnswer[action.payload.groupAnswerID] = action.payload.answers;
    },
    setSurveyFrequencyList(state, action: PayloadAction<OptionType[]>) {
      state.surveyFrequencyList = action.payload;
    },
    setSurveyResults(state, action: PayloadAction<SurveyResults>) {
      state.surveyResults = action.payload;
    },
    setSurveyRecurrence(state, action: PayloadAction<SurveyRecurrence[]>) {
      state.surveyRecurrence = action.payload;
    },
    setSortBy(state, action: PayloadAction<SorttByType>) {
      state.sortBy = action.payload;
    },
    setFilterByStatus(state, action: PayloadAction<SurveyStatus | null>) {
      state.filterByStatus = action.payload;
    },
    setFilterByFavorite(state, action: PayloadAction<boolean>) {
      state.filterByFavorite = action.payload;
    },
    deleteSurveyWizardById(state, action: PayloadAction<number>) {
      const idx = state.surveyWizardList.findIndex((s) => {
        return s.surveyWizardID === action.payload;
      });
      if (state.surveyWizardList[idx]) {
        state.surveyWizardList.splice(idx, 1);
      }
    },
    updateSurveyWizardByIndex(state, action: PayloadAction<{ index: number; survey: SurveyWizard }>) {
      if (state.surveyWizardList[action.payload.index]) {
        state.surveyWizardList[action.payload.index] = {
          ...action.payload.survey,
          status: state.surveyWizardList[action.payload.index].status
        };
      }
    },
    toggleLikeOnSurvey(state, action: PayloadAction<number>) {
      if (state.surveyWizardList) {
        const toUpdateIndex = state.surveyWizardList.findIndex((surveyItem) => {
          return action.payload === surveyItem.surveyWizardID;
        });
        if (toUpdateIndex !== -1) {
          state.surveyWizardList[toUpdateIndex].isLiked = !state.surveyWizardList[toUpdateIndex].isLiked;
        }
      }
    },
    addSurveyScheduleIDToSurvey(state, action: PayloadAction<SurveySchedule>) {
      const surveyIndex = state.surveyWizardList.findIndex((survey) => {
        return survey.surveyWizardID === action.payload.surveyWizardID;
      });

      if (surveyIndex !== -1) {
        state.surveyWizardList[surveyIndex].surveyWizardScheduleID = action.payload.surveyWizardScheduleID;
        state.surveyWizardList[surveyIndex].status = "Scheduled";
      }
    },
    resetSurveySlice: () => {
      return setInitialState;
    },
    setCurrentSurvey(state, action: PayloadAction<SurveyWizard | null>) {
      state.currentSurvey = action.payload;
    },
    setSchoolList(state, action: PayloadAction<OptionType[]>) {
      state.schoolList = action.payload;
    },
    setTeacherList(state, action: PayloadAction<OptionType[]>) {
      state.teacherList = action.payload;
    },
    setClassList(state, action: PayloadAction<OptionType[]>) {
      state.classList = action.payload;
    }
  }
});

export const {
  setIsLoading,
  setSurveyError,
  setSurveyAudienceError,
  clearSurveyAudienceError,
  setSurveyWizardList,
  addSurveyWizardToList,
  setSurveyWizardAnswerGroup,
  setSurveyFrequencyList,
  setSurveyResults,
  setSurveyRecurrence,
  setSortBy,
  setFilterByStatus,
  setFilterByFavorite,
  deleteSurveyWizardById,
  toggleLikeOnSurvey,
  resetSurveySlice,
  setCurrentSurvey,
  updateSurveyWizardByIndex,
  setSchoolList,
  setTeacherList,
  setClassList,
  addSurveyScheduleIDToSurvey,
  setSurveyWizardAnswer
} = surveySlice.actions;

export const selectSurveyWizardList = (state: RootState) => {
  return state.survey.surveyWizardList;
};
export const selectSortBy = (state: RootState) => {
  return state.survey.sortBy;
};
export const selectFilterByStatus = (state: RootState) => {
  return state.survey.filterByStatus;
};
export const selectFilterByFavorite = (state: RootState) => {
  return state.survey.filterByFavorite;
};

export const selectSurveyWizardListFiltered = createSelector(
  selectSurveyWizardList,
  selectSortBy,
  selectFilterByStatus,
  selectFilterByFavorite,
  (surveyWizardList, sortBy, filterByStatus, filterByFavorite) => {
    let surveyWizardResult = surveyWizardList;
    if (sortBy === "dateCreated") {
      surveyWizardResult = surveyWizardList.slice().sort((a, b) => {
        return new Date(b.createDate).getTime() - new Date(a.createDate).getTime();
      });
    }

    if (sortBy === "dateModified") {
      surveyWizardResult = surveyWizardList.slice().sort((a, b) => {
        return new Date(b.updateDate).getTime() - new Date(a.updateDate).getTime();
      });
    }
    if (filterByStatus || filterByFavorite) {
      surveyWizardResult = surveyWizardResult.filter((survey) => {
        if (filterByFavorite && filterByStatus) {
          return survey.status.toLocaleLowerCase() === filterByStatus.toLocaleLowerCase() && survey.isLiked;
        }
        if (filterByStatus) {
          return survey.status.toLocaleLowerCase() === filterByStatus.toLocaleLowerCase();
        }
        if (filterByFavorite) {
          return survey.isLiked;
        }
        return false;
      });
    }
    return surveyWizardResult;
  }
);

export default surveySlice.reducer;

export const handleError = (err: any, dispatch: any) => {
  console.error(err);
  dispatch(setSurveyError({ accountError: err.toString() }));
};

export const sendGetSurveyRequest = () => {
  return async (dispatch: any) => {
    try {
      dispatch(setIsLoading(true));
      const token = await validateToken(dispatch);
      const surveyScheduleResponse = await getSurveyWizard(token);
      dispatch(setIsLoading(false));
      if (surveyScheduleResponse.success) {
        dispatch(setSurveyWizardList(surveyScheduleResponse.data));
        return;
      }
    } catch (err) {
      dispatch(setIsLoading(false));
      console.error(err);
      handleError(err, dispatch);
    }
  };
};

export const sendGetSurveyWizardRequest = (surveyWizardID: number) => {
  return async (dispatch: any) => {
    try {
      dispatch(setIsLoading(true));
      const token = await validateToken(dispatch);
      const surveyScheduleResponse = await getSurveyWizardById(surveyWizardID, token);
      dispatch(setIsLoading(false));
      if (surveyScheduleResponse.success) {
        dispatch(setCurrentSurvey(surveyScheduleResponse.data));
        return;
      }
    } catch (err) {
      console.error(err);
      handleError(err, dispatch);
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const sendDeleteSurveyWizardQuestionRequest = (surveyWizardQuestionID: number) => {
  return async (dispatch: any) => {
    try {
      dispatch(setIsLoading(true));
      const token = await validateToken(dispatch);
      const surveyWizardScheduleResponse = await deleteSurveyWizardQuestion(surveyWizardQuestionID, token);
      dispatch(setIsLoading(false));
      if (surveyWizardScheduleResponse.success) {
        console.log("Successfully deleted survey question");
        return;
      }
    } catch (err) {
      dispatch(setIsLoading(false));
      console.error(err);
      handleError(err, dispatch);
    }
  };
};

export const sendDeleteSurveyWizardRequest = (surveyWizardID: number | undefined) => {
  return async (dispatch: any) => {
    try {
      if (!surveyWizardID) return;
      dispatch(setIsLoading(true));
      const token = await validateToken(dispatch);
      const surveyWizardScheduleResponse = await deleteSurveyWizard(surveyWizardID, token);
      dispatch(setIsLoading(false));
      if (surveyWizardScheduleResponse.success) {
        dispatch(deleteSurveyWizardById(surveyWizardID));
        return;
      }
    } catch (err) {
      dispatch(setIsLoading(false));
      console.error(err);
      handleError(err, dispatch);
    }
  };
};

export const sendPostSurveyWizardQuestionRequest = (
  surveyWizardID: number,
  question: string,
  surveyWizardAnswerGroupID: number,
  sortOrder: number
) => {
  return async (dispatch: any) => {
    try {
      dispatch(setIsLoading(true));
      const token = await validateToken(dispatch);
      const surveyWizardScheduleResponse = await postSurveyWizardQuestion(
        surveyWizardID,
        question,
        surveyWizardAnswerGroupID,
        sortOrder,
        token
      );
      dispatch(setIsLoading(false));
      if (surveyWizardScheduleResponse.success) {
        // handle success
        return;
      }
    } catch (err) {
      dispatch(setIsLoading(false));
      console.error(err);
      handleError(err, dispatch);
    }
  };
};

export const sendCopySurveyWizardGroupRequest = (surveyWizardID: number) => {
  return async (dispatch: any) => {
    try {
      dispatch(setIsLoading(true));
      const token = await validateToken(dispatch);
      const copySurveyWizardResponse = await copySurveyWizard(surveyWizardID, token);
      dispatch(setIsLoading(false));
      if (copySurveyWizardResponse.success) {
        dispatch(addSurveyWizardToList(copySurveyWizardResponse.data));
        return;
      }
    } catch (err) {
      dispatch(setIsLoading(false));
      console.error(err);
      handleError(err, dispatch);
    }
  };
};

export const sendToggleLikeSurveyWizardRequest = (surveyWizardID: number) => {
  return async (dispatch: any) => {
    try {
      dispatch(setIsLoading(true));
      const token = await validateToken(dispatch);
      const toggleLikeResponse = await toggleLikeSurveyWizard(surveyWizardID, token);
      dispatch(setIsLoading(false));
      if (toggleLikeResponse.success) {
        dispatch(toggleLikeOnSurvey(surveyWizardID));
        return;
      }
    } catch (err) {
      dispatch(setIsLoading(false));
      console.error(err);
      handleError(err, dispatch);
    }
  };
};

export const sendGetSurveyResultsRequest = (surveyWizardRecurID: number) => {
  return async (dispatch: any) => {
    try {
      dispatch(setIsLoading(true));
      const token = await validateToken(dispatch);
      const surveyResultsResponse = await getSurveyRecurrenceResults(surveyWizardRecurID, token);
      dispatch(setIsLoading(false));
      if (surveyResultsResponse.success) {
        dispatch(setSurveyResults(surveyResultsResponse.data));
        dispatch(setIsLoading(false));
        return;
      }
    } catch (err) {
      console.error(err);
      handleError(err, dispatch);
    }
    dispatch(setIsLoading(false));
  };
};

export const sendGetSurveyRecurrenceRequest = (
  surveyWizardID: number,
  callback?: (surveyRecurrenceResponse: SurveyRecurrence[]) => void
) => {
  return async (dispatch: any) => {
    try {
      dispatch(setIsLoading(true));
      const token = await validateToken(dispatch);
      const surveyRecurrenceResponse = await getSurveyRecurrence(surveyWizardID, token);
      if (surveyRecurrenceResponse.success) {
        dispatch(setSurveyRecurrence(surveyRecurrenceResponse.data));

        if (callback) {
          callback(surveyRecurrenceResponse.data);
        }
      }
      dispatch(setIsLoading(false));
    } catch (err) {
      dispatch(setIsLoading(false));
      console.error(err);
      handleError(err, dispatch);
    }
  };
};
