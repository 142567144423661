import { FunctionComponent, ReactElement, useState } from "react";

import CoreStrings from "src/_core/strings/strings";

import "./styles.scss";

type WbnSearchInputProp = {
  value: string;
  placeholder: string;
  onChange: (val: string) => void;
};

export const WbnSearchInput: FunctionComponent<WbnSearchInputProp> = (props): ReactElement => {
  const { value, placeholder, onChange } = props;

  const [focus, setFocus] = useState(false);

  return (
    <div className={`wbn-searchInput ${focus ? "focus" : ""}`}>
      <img src={require("src/assets/wbn/icon-finder.png")} width={17.5} height={17.5} />
      <input
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        onFocus={() => {
          setFocus(true);
        }}
        onBlur={() => {
          setFocus(false);
        }}
      />
      {value.length > 0 && (
        <span
          onClick={() => {
            onChange("");
          }}
        >
          {CoreStrings.clear}
        </span>
      )}
    </div>
  );
};

export default WbnSearchInput;
