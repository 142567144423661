import React, { FunctionComponent, ReactElement } from "react";
import { useDispatch } from "react-redux";
import { setFilterByFavorite } from "src/reducers/survey/surveySlice";

export type SurveyTabMenuProps = {
  selectedIdx: number;
  onClick: (selIndex: number) => void;
};

export const SurveyTabMenu: FunctionComponent<SurveyTabMenuProps> = ({ selectedIdx, onClick }): ReactElement => {
  const dispatch = useDispatch();

  function onFavoriteToggle(filterByFavorite: boolean) {
    dispatch(setFilterByFavorite(filterByFavorite));
  }

  return (
    <div className="survey-tabmenu">
      <button
        type="button"
        className={`${selectedIdx === 0 ? "active" : ""}`}
        onClick={() => {
          onClick(0);
          onFavoriteToggle(false);
        }}
      >
        <img src={require("src/assets/survey/icon-all.png")} alt="" />
        <span>All</span>
      </button>
      <button
        type="button"
        className={`${selectedIdx === 1 ? "active" : ""}`}
        onClick={() => {
          onClick(1);
          onFavoriteToggle(true);
        }}
      >
        <img src={require("src/assets/survey/icon-fav.png")} alt="" />
        <span>Favorites</span>
      </button>
    </div>
  );
};

export default SurveyTabMenu;
