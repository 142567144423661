import React, { FunctionComponent, ReactElement } from "react";

import "./styles.scss";

type MenuItemType = {
  title: string;
  subMenu?: string[];
};

const menuItem: MenuItemType[] = [
  {
    title: "Brand Overview"
  },
  {
    title: "Core Values"
  },
  {
    title: "Lifestyle Practices",
    subMenu: ["World", "Body", "Mind", "Spirit"]
  },
  {
    title: "Wellbeing Check-In"
  }
  /*   {
    title: 'Brand Guide',
    subMenu: [
      'Typography',
      'Colors'
    ]
  } */
];

export type AtsSideMenuProps = {
  selIdx: number;
  selSubIdx: number;
  handleMenuPress: (index: number, subIndex?: number, menuName?: string) => void;
};

export const AtsSideMenu: FunctionComponent<AtsSideMenuProps> = ({ selIdx, selSubIdx, handleMenuPress }): ReactElement => {
  return (
    <div className="ats-sidemenu">
      <ul>
        {menuItem.map((item, index) => {
          return (
            <li value={index} key={`${item.title}`}>
              <a
                className={`ats-sidemenu__sidemenu-item ${index === selIdx ? "active" : ""}`}
                onClick={() => {
                  console.log("onclick parent");
                  handleMenuPress(index);
                }}
              >
                {item.title}
              </a>
              {item.subMenu && (
                <ul className={`ats-sidemenu__submenu ${index === selIdx ? "show" : ""}`}>
                  {item.subMenu.map((subItem, subIndex) => {
                    return (
                      <li value={subIndex} key={`${subItem}`}>
                        <a
                          className={`ats-sidemenu__sidemenu-item submenu-item ${subIndex === selSubIdx ? "active" : ""}`}
                          onClick={() => {
                            handleMenuPress(index, subIndex, subItem);
                          }}
                        >
                          {subItem}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default AtsSideMenu;
