import React, { ReactElement, useEffect, useState } from "react";
import { ToastProvider } from "react-toast-notifications";

import AtsSideMenu from "src/components/ats/AtsSideMenu";
import AtsBrandOverview from "src/components/ats/AtsBrandOverview";

import "./styles.scss";
import AtsCoreValues from "src/components/ats/AtsCoreValues";
import AtsOutcomeCategories from "src/components/ats/AtsOutcomeCategories";
import AtsWellBeingCheckInCategories from "src/components/ats/AtsWellBeingCheckIn";
import { scrollToID } from "src/_core/utils/utils";
import { AtsBrandTypography } from "src/components/ats/AtsBrandTypography";
import AtsBrandColors from "src/components/ats/AtsBrandColors";

const MENU_TITLE = ["Brand Overview", "Core Values", "Lifestyle Practices", "Wellbeing Check-In", "Visual Assets", "Brand Guide"];

export const AtsHome = (): ReactElement => {
  const [selMenu, setMenuItem] = useState(0);
  const [selSubmenu, setSubmenuItem] = useState(1);
  const [scrollPos, setScrollPos] = useState(0);

  const handleMenuPress = (index: number, subIndex?: number, menuName?: string) => {
    setMenuItem(index);
    console.log(index, "--", subIndex);
    if (subIndex || subIndex === 0) {
      setSubmenuItem(subIndex);
    } else {
      setSubmenuItem(-1);
    }
    if (index === 2 && subIndex !== undefined) {
      scrollToID(menuName);
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPos(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <ToastProvider>
      <div className="ats-container">
        <div className="ats-banner ocean">
          <div className="ats-banner__content">
            <img src={require("src/assets/ats/logo-ats.png")} alt="ats banner bg" />
            <div className="banner-title">
              <h1>{MENU_TITLE[selMenu]}</h1>
              <p>
                • The key brand resources and guidelines to get started with Seity
                <br />• Find logos, colors, icons, and more
              </p>
            </div>
          </div>
        </div>
        <div className="ats-main">
          <div className={`ats-main__sidemenu-wrap ${scrollPos > 300 ? "hangon" : ""}`}>
            <AtsSideMenu selIdx={selMenu} selSubIdx={selSubmenu} handleMenuPress={handleMenuPress} />
          </div>
          <div className={`ats-main__content-wrap ${scrollPos > 300 ? "hangon" : ""}`}>
            <div className="content-wrap">
              {selMenu === 0 && <AtsBrandOverview />}
              {selMenu === 1 && <AtsCoreValues />}
              {selMenu === 2 && (
                <AtsOutcomeCategories
                  subMenuItem={selSubmenu}
                  onCategoryClicked={(subMenu, menuName) => {
                    return handleMenuPress(2, subMenu, menuName);
                  }}
                />
              )}
              {selMenu === 3 && <AtsWellBeingCheckInCategories />}
              {selMenu === 4 && selSubmenu !== 1 && (
                <AtsBrandTypography
                  onCategoryClicked={(subMenu) => {
                    return handleMenuPress(4, subMenu);
                  }}
                />
              )}
              {selMenu === 4 && selSubmenu === 1 && (
                <AtsBrandColors
                  onCategoryClicked={(subMenu) => {
                    return handleMenuPress(4, subMenu);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </ToastProvider>
  );
};

export default AtsHome;
