import { FunctionComponent, ReactElement } from "react";
import "./styles.scss";

export type WbnTooltipListitemProps = {
  text: string;
  iconSize?: number;
  icon?: string;
  align?: string;
  textSize?: number;
  color?: string;
};

export const WbnTooltipListitem: FunctionComponent<WbnTooltipListitemProps> = ({
  text,
  iconSize = 24,
  icon,
  align = "flex-start",
  textSize = 16,
  color = "#000"
}): ReactElement => {
  return (
    <div className={`wbn-tooltip-listitem-container wbn-tooltip-itemalign-${align}`}>
      {icon && iconSize && <img className={`wbn-tooltip-icon-${iconSize}`} src={icon} />}
      <p className="wbn-tooltip-listitem-text" style={{ fontSize: textSize, color: color }}>{text}</p>
    </div>
  );
};

export default WbnTooltipListitem;
