import React, { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { StylesConfig } from "react-select";
import SurveySelectBox from "../SurveySelectBox/SurveySelectBox";
import { HOURS, MINUTES } from "./SurveyDatePickerConst";

import "./styles.scss";

export type SurveyTimePickerProps = {
  className?: string;
  onChange: (value: string) => void;
  value?: string;
};
export const SurveyTimePicker: FunctionComponent<SurveyTimePickerProps> = ({ className, onChange, value }): ReactElement => {
  const [hour, setHour] = useState("00");
  const [minutes, setMinutes] = useState("00");
  const customStyles: StylesConfig = {
    control: (provided) => {
      return {
        ...provided,
        height: 48,
        border: "1px solid #c1c4ba"
      };
    }
  };

  function onChangeHandler(val: string, name: string) {
    if (name === "hour") {
      onChange(`${val}:${minutes}`);
      setHour(val);
    } else {
      onChange(`${hour}:${val}`);
      setMinutes(val);
    }
  }

  useEffect(() => {
    if (value) {
      const timeSplit = value?.split(":");
      setHour(timeSplit[0]);
      setMinutes(timeSplit[1]);
    }
  }, [value]);

  return (
    <div className={`survey-timepicker-container ${className || ""}`}>
      <SurveySelectBox
        options={HOURS}
        selectOption={(opt) => {
          onChangeHandler(opt.value, "hour");
        }}
        className="survey-timepicker-select"
        value={{ label: hour, value: hour }}
        styles={customStyles}
      />
      <span>:</span>
      <SurveySelectBox
        className="survey-timepicker-select"
        options={MINUTES}
        value={{ label: minutes, value: minutes }}
        // defaultValue={{ value: '59', label: '59' }}
        selectOption={(opt) => {
          return onChangeHandler(opt.value, "minute");
        }}
        styles={customStyles}
      />
    </div>
  );
};

export default SurveyTimePicker;
