import axios from "axios";
import { BASE_URL } from "../apiConstants";
import { SurveyAudienceResponse, SurveyAudience } from "./surveyTypes";

const SECTION = "/SurveyWizardAudience";

export async function createSurveyWizardAudience(param: SurveyAudience, token: string): Promise<SurveyAudienceResponse> {
  const url = BASE_URL + SECTION;
  try {
    const surveyResponse = await axios.post<SurveyAudienceResponse>(
      url,
      {
        surveyWizardScheduleID: param.surveyWizardScheduleID,
        surveyWizardAudienceTypeID: param.surveyWizardAudienceTypeID,
        companyID: param.companyID,
        teacherID: param.teacherID,
        classID: param.classID,
        departmentIDs: param.departmentIDs,
        groupIDs: param.groupIDs
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return surveyResponse.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}

export async function updateSurveyWizardAudience(param: SurveyAudience, token: string): Promise<SurveyAudienceResponse> {
  const url = BASE_URL + SECTION;
  try {
    const surveyResponse = await axios.post<SurveyAudienceResponse>(
      url,
      {
        surveyWizardScheduleID: param.surveyWizardScheduleID,
        surveyWizardAudienceTypeID: param.surveyWizardAudienceTypeID,
        companyID: param.companyID,
        teacherID: param.teacherID,
        classID: param.classID,
        departmentIDs: param.departmentIDs,
        groupIDs: param.groupIDs
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return surveyResponse.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}

export async function getSurveyWizardAudience(surveyWizardScheduleID: number, token: string): Promise<SurveyAudienceResponse> {
  const url = BASE_URL + SECTION + "?surveyWizardScheduleID=" + surveyWizardScheduleID;
  try {
    const surveyResponse = await axios.get<SurveyAudienceResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return surveyResponse.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}
