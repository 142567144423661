/* eslint-disable @typescript-eslint/no-unused-vars */
import { FunctionComponent, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from "react";

import CoreStrings from "src/_core/strings/strings";

import "./styles.scss";
import WbnFilterWrap from "src/components/wbn/WbnFilterWrap";
import WbnDistrictFilter from "src/components/wbn/WbnDistrictFilter";
import { COLUMNS_DATA, FILTER_OPTIONS_SAMPLE } from "./data";
import WbnButton from "src/components/wbn/WbnButton";
import WbnSearchInput from "src/components/wbn/WbnSearchInput";
import WbnDatePicker from "src/components/wbn/WbnDatePicker";
import WbnGrid from "src/components/wbn/WbnGrid";
import {
  WBNFilterOptionType,
  WbnRowData,
  WbnViewFilter
} from "src/api/wbn/wbnTypes";
import {
  buildSavedViewData,
  buildSelFilterData,
  compareSavedViews,
  convertStrToDate,
  getAttentionCodeDesc,
  getUpdatedColumns,
  getUpdatedFiltersDataOnSchoolSelection,
  getUpdatedFiltersOnRemove,
  getUpdatedFiltersOnSelection
} from "./helpers";
import moment from "moment";
import WbnCheckList from "src/components/wbn/WbnCheckList";
import WbnImageCheckList from "src/components/wbn/WbnImageCheckList";
import WbnButtonMenuModal from "src/components/wbn/WbnButtonMenuModal";
import WbnFilterOptions from "src/components/wbn/WbnFilterOptions";
import SeityLoader from "src/components/SeityLoader";
import { getNumberWithOrdinal } from "src/app/utils";
import WbnSavedViewsModal from "src/components/wbn/WbnSavedViewsModal";
import WbnModal from "src/components/wbn/WbnModal";
import { useWbnData } from "./hooks";
import {
  sendDeleteWbnSavedView, sendGetWbnSavedViewDetail, sendGetWbnSavedViews, sendPostWbnSavedView, sendPutWbnSavedView, setWbnSavedViews
} from "src/reducers/wbnSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/reducers";

const DATE_FORMAT = "YYYY-MM-DD";

const HANGON_SCROLL_POS = 120;

export const WellbeingNavigator: FunctionComponent = (): ReactElement => {
  const dispatch = useDispatch();

  const { savedViews, selSavedView, isLoading: isSliceLoading } = useSelector((state: RootState) => {
    return state.wbn;
  });

  const [scrollPos, setScrollPos] = useState(0);
  const [showSavedViews, setShowSavedViews] = useState(false);
  const [savedViewIdx, setSavedViewIdx] = useState(-1);
  const [targetViewIdx, setTargetViewIdx] = useState(-1);
  const [searchName, setSearchName] = useState("");
  const [debouncedSearchName, setDebouncedSearchName] = useState("");

  const {
    isLoading,
    filterOptions,
    wbnApiData,
    primWbnRows,
    selFilterData,
    setSelFilterData
  } = useWbnData();

  const [columns, setColumns] = useState(COLUMNS_DATA);
  const [showColumnsModal, setShowColumnsModal] = useState(false);
  const [wbnRows, setWbnRows] = useState<Array<WbnRowData>>(primWbnRows || []);

  const [hangon, setHangon] = useState(false);

  const columnsRef = useRef<HTMLDivElement>(null);
  const savedViewsRef = useRef<HTMLDivElement>(null);

  const [showSaveViewModal, setShowSaveViewModal] = useState(false);
  const [showDelViewModal, setShowDelViewModal] = useState(false);
  const [showDupViewModal, setShowDupViewModal] = useState(false);
  const [showLeaveViewModal, setShowLeaveViewModal] = useState(false);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPos(position);
    setHangon(position > HANGON_SCROLL_POS);
  };

  useEffect(() => {
    if (showSavedViews || showColumnsModal) {
      if (typeof window !== "undefined" && window.document) {
        document.body.style.overflow = "hidden";
      }
      if (showSavedViews) {
        dispatch(sendGetWbnSavedViews());
      }
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showSavedViews, showColumnsModal]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const searchTimer = setTimeout(() => {
      setDebouncedSearchName(searchName);
    }, 500);
    return () => {
      return clearTimeout(searchTimer);
    };
  }, [searchName, 500]);

  useEffect(() => {
    if (selSavedView) {
      setSelFilterData(buildSelFilterData(selSavedView as unknown as WbnViewFilter, wbnApiData));
    }
  }, [selSavedView])

  useEffect(() => {
    // Manage the data of grid table
    const ret = primWbnRows?.filter((r) => {
      const key = debouncedSearchName.toLocaleLowerCase();
      return r.firstName.toLocaleLowerCase().includes(key) || r.lastName.toLocaleLowerCase().includes(key);
    });
    setWbnRows(ret || []);
  }, [debouncedSearchName, primWbnRows]);

  const handleSchoolSelection = useCallback(
    (entityId: number, companyId?: number) => {
      setSelFilterData(getUpdatedFiltersDataOnSchoolSelection(selFilterData, entityId, companyId));
    },
    [selFilterData]
  );

  const resetFilters = useCallback(() => {
    setSelFilterData(buildSelFilterData(FILTER_OPTIONS_SAMPLE, wbnApiData));
  }, [wbnApiData]);

  const GridTable = useMemo(() => {
    return wbnRows ? <WbnGrid data={wbnRows} columns={columns} hangon={hangon} /> : undefined;
  }, [columns, wbnRows]);

  const SavedViewHeader = useMemo(() => {
    if (savedViewIdx > -1 && selSavedView) {
      const v = savedViews[savedViewIdx];
      return (
        <div className="wbn-body__savedView-header">
          <h3>{v.viewName}</h3>
          <button
            onClick={() => {
              if (compareSavedViews(selSavedView, buildSavedViewData(selFilterData, v.viewName))) {
                setShowLeaveViewModal(true);
              } else {
                resetFilters();
                setSavedViewIdx(-1);
              }
            }}
          >
            <img src={require("../../assets/wbn/btn-close.png")} />
          </button>
        </div>
      );
    } else {
      return undefined;
    }
  }, [savedViewIdx, selFilterData, selSavedView]);

  return (
    <div className="wbn">
      {(isLoading || isSliceLoading) && <SeityLoader />}
      <h1>{CoreStrings.wellbeingNavigator}</h1>
      <div className="wbn-main">
        <div className={`wbn-left ${hangon ? "hangon" : ""}`}>
          <div className="wbn-savedViews-wrap" ref={savedViewsRef}>
            <WbnButton
              label="Saved Views"
              type="select"
              isSel={showSavedViews}
              onClick={() => {
                setShowSavedViews(!showSavedViews);
              }}
            />
          </div>
          <div className="wbn-left__filters">
            <p>{CoreStrings.filters}</p>
            {selFilterData.entities.length > 0 && (
              <WbnFilterWrap title={CoreStrings.schools}>
                {selFilterData.entities.map((d, index) => {
                  return (
                    <WbnDistrictFilter
                      key={index}
                      title={d.name ?? ""}
                      schools={d.companies}
                      entityId={d.entityID}
                      selected={d.selected ?? false}
                      intermediate={d.intermediate ?? false}
                      onSelOption={(companyId) => {
                        handleSchoolSelection(d.entityID, companyId);
                      }}
                    />
                  );
                })}
              </WbnFilterWrap>
            )}
            {selFilterData.gradeLevels.length > 0 && (
              <WbnFilterWrap title={CoreStrings.gradeLevel}>
                <WbnCheckList
                  data={selFilterData.gradeLevels.map((g) => {
                    return { id: g.gradeLevelID, title: getNumberWithOrdinal(g.gradeLevelID) };
                  })}
                  selOptions={selFilterData.gradeLevels
                    .filter((g) => {
                      return g.selected;
                    })
                    .map((gr) => {
                      return gr.gradeLevelID;
                    })}
                  onSelOptions={(options, selId) => {
                    setSelFilterData(getUpdatedFiltersOnSelection(WBNFilterOptionType.Grade, options, selFilterData, selId));
                  }}
                />
                {/* {selFilterData.gradeLevels.map(grade => {
                return (
                  <WbnCheckbox label={grade.description} onClick={() => {
                    setSelFilterData(getUpdatedFiltersOnSelection(WBNFilterOptionType.Grade, grade.gradeLevelID, selFilterData));
                  }} checked={grade.selected} />
                );
              })} */}
              </WbnFilterWrap>
            )}
            <WbnFilterWrap title={CoreStrings.lastCheckIn}>
              <WbnDatePicker
                fromDate={convertStrToDate(selFilterData.lastCheckinDate?.startDate, DATE_FORMAT)}
                toDate={convertStrToDate(selFilterData.lastCheckinDate?.endDate, DATE_FORMAT)}
                dateFormat="MM-dd-yyyy"
                onChangeFromDate={(date) => {
                  const fromD = date ? moment(date).format(DATE_FORMAT) : null;
                  const temp = { ...selFilterData };
                  temp.lastCheckinDate = { ...selFilterData.lastCheckinDate, startDate: fromD, updatedAt: Date.now() };
                  setSelFilterData(temp);
                }}
                onChangeToDate={(date) => {
                  const toD = date ? moment(date).format(DATE_FORMAT) : null;
                  const temp = { ...selFilterData };
                  temp.lastCheckinDate = { ...selFilterData.lastCheckinDate, endDate: toD, updatedAt: Date.now() };
                  setSelFilterData(temp);
                }}
              />
            </WbnFilterWrap>
            {selFilterData.attentionCodes.length > 0 && (
              <WbnFilterWrap title={CoreStrings.wellbeing}>
                <WbnImageCheckList
                  data={selFilterData.attentionCodes.map((g) => {
                    return { id: g.attentionCodeID, image: g.iconName, title: getAttentionCodeDesc(g.attentionCodeID) };
                  })}
                  selOptions={selFilterData.attentionCodes
                    .filter((g) => {
                      return g.selected;
                    })
                    .map((gr) => {
                      return gr.attentionCodeID;
                    })}
                  onSelOptions={(options, selId) => {
                    setSelFilterData(getUpdatedFiltersOnSelection(WBNFilterOptionType.AttentionCode, options, selFilterData, selId));
                  }}
                />
              </WbnFilterWrap>
            )}
            {selFilterData.races.length > 0 && (
              <WbnFilterWrap title="Race">
                <WbnCheckList
                  data={selFilterData.races.map((g) => {
                    return { id: g.raceID, title: g.description };
                  })}
                  selOptions={selFilterData.races
                    .filter((g) => {
                      return g.selected;
                    })
                    .map((gr) => {
                      return gr.raceID;
                    })}
                  onSelOptions={(options, selId) => {
                    setSelFilterData(getUpdatedFiltersOnSelection(WBNFilterOptionType.Race, options, selFilterData, selId));
                  }}
                  showSelectAll={true}
                />
              </WbnFilterWrap>
            )}
            {selFilterData.genderIDs.length > 0 && (
              <WbnFilterWrap title="Gender">
                <WbnCheckList
                  data={selFilterData.genderIDs.map((g) => {
                    return { id: g.genderID, title: g.description };
                  })}
                  selOptions={selFilterData.genderIDs
                    .filter((g) => {
                      return g.selected;
                    })
                    .map((gr) => {
                      return gr.genderID;
                    })}
                  onSelOptions={(options, selId) => {
                    setSelFilterData(getUpdatedFiltersOnSelection(WBNFilterOptionType.Gender, options, selFilterData, selId));
                  }}
                  showSelectAll={true}
                />
              </WbnFilterWrap>
            )}
          </div>
        </div>
        <div className={`wbn-body ${hangon ? "hangon" : ""} ${savedViewIdx !== -1 ? "savedView" : ""}`}>
          {/* Body area with Search Field, Filter Options, Saved View, Table component */}
          {SavedViewHeader}
          <WbnSearchInput
            value={searchName}
            placeholder={CoreStrings.searchName}
            onChange={(val) => {
              setSearchName(val);
            }}
          />
          <div className="wbn-body__buttons">
            {filterOptions?.length > 0 && (
              <WbnButton
                type="plain-text"
                label={CoreStrings.clearAllFilters}
                onClick={() => {
                  resetFilters();
                }}
              />
            )}
            <div className="wbn-body__buttons-columns" ref={columnsRef}>
              <WbnButton
                type="select"
                isSel={showColumnsModal}
                label={CoreStrings.columns}
                customClass="btn-columns"
                onClick={() => {
                  setShowColumnsModal(!showColumnsModal);
                }}
              />
            </div>
            <WbnButton
              disabled={filterOptions?.length === 0 || savedViewIdx !== -1}
              image={require("src/assets/wbn/icon-save.png")}
              label={CoreStrings.saveView}
              onClick={() => {
                setShowSaveViewModal(true);
              }}
            />
          </div>
          <WbnFilterOptions
            data={filterOptions ?? []}
            onRemove={(option) => {
              setSelFilterData(getUpdatedFiltersOnRemove(option.type, selFilterData, option.id));
            }}
          />
          {GridTable}
        </div>
      </div>
      {showColumnsModal && (
        <WbnButtonMenuModal
          position={{
            top: (columnsRef.current?.offsetTop ?? 0) - (hangon ? scrollPos : 0) + 40,
            left: (columnsRef.current?.offsetLeft ?? 0)  - 50
          }}
          data={columns}
          isOpen={showColumnsModal}
          selectedColumns={columns
            .filter((c) => {
              return c.selected;
            })
            .map((cc) => {
              return cc.id;
            })}
          setSelectedColumns={(selectedColumns) => {
            setColumns(getUpdatedColumns(selectedColumns, columns));
          }}
          onApply={() => {
            setShowColumnsModal(false);
          }}
        />
      )}
      {showSavedViews && (
        <WbnSavedViewsModal
          position={{
            top: hangon ? 160 : (savedViewsRef.current?.offsetTop ?? 0) - scrollPos + 40,
            left: savedViewsRef.current?.offsetLeft ?? 0
          }}
          title={CoreStrings.savedViews}
          data={savedViews}
          isOpen={showSavedViews}
          savedViewIdx={savedViewIdx}
          setSavedViewIdx={(idx) => {
            setSavedViewIdx(idx);
            dispatch(sendGetWbnSavedViewDetail(savedViews[idx].wbnViewFilterID!));
          }}
          onApply={() => {
            setShowSavedViews(false);
          }}
          onDelete={(idx) => {
            setShowSavedViews(false);
            setTargetViewIdx(idx);
            setShowDelViewModal(true);
          }}
        />
      )}
      {showSaveViewModal && (
        <WbnModal
          isOpen={showSaveViewModal}
          type="SaveView"
          name={""}
          onClose={() => {
            setShowSaveViewModal(false);
          }}
          onClickActions={(idx, viewName) => {
            if (idx === 1) {
              // save button
              // api call
              const dupIdx = savedViews.findIndex((v) => {
                return v.viewName === viewName;
              });
              if (dupIdx !== -1) {
                setTargetViewIdx(dupIdx);
                setShowDupViewModal(true);
              } else {
                const newView = buildSavedViewData(selFilterData, viewName!);
                dispatch(sendPostWbnSavedView(newView));
                setShowSaveViewModal(false);
                // not -1 means this is a case of `Save as new` from LeaveView Modal. So the filters should be reset
                if (savedViewIdx !== -1) {
                  setSavedViewIdx(-1);
                  resetFilters();
                }
              }
            } else {
              setShowSaveViewModal(false);
            }
          }}
        />
      )}
      {showDelViewModal && (
        <WbnModal
          title={CoreStrings.deleteSavedView}
          isOpen={showDelViewModal}
          type="DeleteView"
          name={targetViewIdx > -1 ? savedViews[targetViewIdx].viewName : ""}
          onClose={() => {
            setShowDelViewModal(false);
          }}
          onClickActions={(idx, viewName) => {
            setShowDelViewModal(false);
            if (idx === 1) { // click Delete
              // api call
              dispatch(sendDeleteWbnSavedView(savedViews[targetViewIdx].wbnViewFilterID!, (success) => {
                if (success) {
                  dispatch(sendGetWbnSavedViews());
                  if (targetViewIdx === savedViewIdx) {
                    setSavedViewIdx(-1);
                    resetFilters();
                  }
                }
              }));
            }
          }}
        />
      )}
      {showDupViewModal && (
        <WbnModal
          title={`${savedViews[targetViewIdx].viewName} ${CoreStrings.alreadyExists}`}
          isOpen={showDupViewModal}
          type="DupView"
          name={targetViewIdx > -1 ? savedViews[targetViewIdx].viewName : ""}
          onClose={() => {
            setShowDupViewModal(false);
          }}
          onClickActions={(idx, viewName) => {
            setShowDupViewModal(false);
            if (idx === 1) {
              // overwrite
              const newView = buildSavedViewData(selFilterData, viewName!);
              // api call
              dispatch(sendPutWbnSavedView({...newView, filterID: savedViews[targetViewIdx].wbnViewFilterID}));

              setShowSaveViewModal(false);
              setShowDupViewModal(false);
              setTargetViewIdx(-1);
              resetFilters();
            }
          }}
        />
      )}
      {showLeaveViewModal && (
        <WbnModal
          title={CoreStrings.areYouSureLeave}
          isOpen={showLeaveViewModal}
          type="LeaveView"
          name={savedViewIdx > -1 ? savedViews[savedViewIdx].viewName : ""}
          onClose={() => {
            setShowLeaveViewModal(false);
          }}
          onClickActions={(idx, viewName) => {
            setShowLeaveViewModal(false);
            if (idx === 1) {
              // save as new
              setShowSaveViewModal(true);
            } else if (idx === 2) {
              // save
              const viewData = buildSavedViewData(selFilterData, savedViews[savedViewIdx].viewName);
              dispatch(sendPutWbnSavedView({...viewData, filterID: savedViews[savedViewIdx].wbnViewFilterID}));

              setSavedViewIdx(-1);
              resetFilters();
            } else {
              // discard changes
              setSavedViewIdx(-1);
              resetFilters();
            }
          }}
        />
      )}
    </div>
  );
};

export default WellbeingNavigator;
