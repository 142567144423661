import React from "react";
import { useSelector } from "react-redux";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import SurveySection from "src/components/survey/SurveySection";
import SeityFormSelectBox from "src/components/survey/SurveySelectBox/SurveyFormSelectBox";
import SurveyFormTextInput from "src/components/survey/SurveyTextInput/SurveyFormTextInput";
import SurveyRadioButtonGroup from "src/components/survey/SurveyRadioButton/SurveyRadioButtonGroup";

import { RootState } from "src/reducers";
import { SurveyQuestionType, SurveyAnswerGroup } from "./types";

const checkDuplicate = (items: Array<SurveyQuestionType>, item: SurveyQuestionType) => {
  return items.find((e) => {
    const isQuestionTextDuplicate = e.question === item.question;
    const isQuestionTextUndefined = e.question === undefined;
    const isIdUndefined = e.id === undefined;
    const idIdDuplicated = e.id === item.id;
    return !isQuestionTextUndefined && !isIdUndefined && isQuestionTextDuplicate && !idIdDuplicated;
  });
};

export const SurveyQuestionElement = SortableElement(({ i, onChangeInput, value, dup, handleDuplicate, handleRemove, options }) => {
  return (
    <SurveySection className={`question-item ${dup ? "question-item__error" : ""}`}>
      <SeityFormSelectBox
        options={options}
        defaultValue={
          value.surveyWizardAnswerGroupID
            ? { label: options[value.surveyWizardAnswerGroupID - 1]?.label, value: options[value.surveyWizardAnswerGroupID - 1]?.value }
            : undefined
        }
        selectOption={(type) => {
          onChangeInput(type, "Select", i);
        }}
        label="Select Question Type"
        handleDuplicate={() => {
          handleDuplicate(value);
        }}
        handleRemove={() => {
          handleRemove(value);
        }}
      />

      {!!value?.surveyWizardAnswerGroupID && (
        <>
          <SurveyFormTextInput
            label="Question"
            value={value.question}
            onChange={(event) => {
              onChangeInput(event.target.value, "Text", i);
            }}
          />
          <SurveyRadioButtonGroup
            type={value.surveyWizardAnswerGroupID}
            onChange={(val) => {
              console.log(val);
            }}
            selected=""
          />
        </>
      )}
    </SurveySection>
  );
});

export const SurveyQuestionList = SortableContainer(({ items, onChangeInput, handleDuplicate, handleRemove }) => {
  const formatAnswerGroupToOptions = (answerGroup: SurveyAnswerGroup[]) => {
    return answerGroup.map((answer) => {
      return {
        label: answer.description,
        value: answer.surveyWizardAnswerGroupID
      };
    });
  };

  const surveyWizardAnswerGroup = useSelector((state: RootState) => {
    const initialData = state.survey.surveyWizardAnswerGroup;
    const formattedData = formatAnswerGroupToOptions(initialData);
    return formattedData;
  });

  if (!surveyWizardAnswerGroup.length) return <div />;
  return (
    <ul className="SortableList">
      {items.map((value: SurveyQuestionType, index: number) => {
        return (
          <SurveyQuestionElement
            key={`item-${value.id}`}
            index={index}
            i={index}
            value={value}
            options={surveyWizardAnswerGroup}
            onChangeInput={onChangeInput}
            dup={checkDuplicate(items, value)}
            handleDuplicate={handleDuplicate}
            handleRemove={handleRemove}
          />
        );
      })}
    </ul>
  );
});
