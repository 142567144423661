/* eslint-disable */
import React, { useState } from "react";
import { SeityFormSelectBox } from "src/components/analytic/SeitySelectBox/SeityFormSelectBox";
import { SeityFormTextInput } from "src/components/analytic/SeityTextInput/SeityFormTextInput";
import AnalyticModal from "../AnalyticModal";
import SeityButton from "../SeityButton";

import "./styles.scss";

export interface SeitySavedViewsProps {
  isOpen: boolean;
  onClose?: ((event: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined;
}

const SavedViewItem = () => {
  return (
    <div className="item-wrap">
      <div className="left">
        <div className="left__text-wrap">
          <span style={{ marginBottom: 5 }}>John B.Allard</span>
          <span>Pacific Islander</span>
        </div>
        <span className="left__date">11/23/21</span>
      </div>
      <a
        className="btn-editname"
        onClick={() => {
          const divRef = document.querySelector("#viewName");
          if (divRef) {
            divRef.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }}
      >
        Edit Name
      </a>
      <a onClick={() => {}}>
        <img src={require("src/assets/images/web-icons/Delete.png")} />
      </a>
    </div>
  );
};

export const SeitySavedViews = (props: SeitySavedViewsProps) => {
  const { isOpen, onClose } = props;

  return (
    <AnalyticModal isOpen={isOpen}>
      <div className="scroll-container">
        <div className="scroll-container__content">
          <div className="savedviews-container" id="savedViews">
            <h2 className="title">Saved Views</h2>
            <div className="content">
              <SavedViewItem />
              <SavedViewItem />
              <SavedViewItem />
              <SavedViewItem />
              <SavedViewItem />
            </div>
            <div style={{ display: "flex", justifyContent: "center", marginBottom: 10 }}>
              <SeityButton
                label="Cancel"
                type="cancel"
                style={{
                  height: 48,
                  width: 108,
                  padding: 0,
                  marginRight: 10
                }}
                onClick={(e) => {
                  if (onClose) {
                    onClose(e);
                  }
                }}
              />
              <SeityButton
                label="Apply"
                type="next"
                style={{
                  height: 48,
                  width: 119,
                  padding: 0,
                  marginRight: 10
                }}
                onClick={(e) => {
                  if (onClose) {
                    onClose(e);
                  }
                }}
              />
            </div>
          </div>
          <div className="savedviews-container viewname-container" id="viewName">
            <div>
              <a
                onClick={() => {
                  const divRef = document.querySelector("#savedViews");
                  if (divRef) {
                    divRef.scrollIntoView({ behavior: "smooth", block: "center" });
                  }
                }}
              >
                <img src={require("src/assets/images/web-icons/back-nav.png")} />
              </a>
              <h2 className="title" style={{ marginTop: -30 }}>
                View Name
              </h2>
            </div>
            <div className="content">
              <SeityFormTextInput
                isInvalid={false}
                type="text"
                inputLabelText=""
                formStyle={{ width: "400px", marginLeft: "auto", marginRight: "auto" }}
              />
              <div style={{ marginTop: 80, marginLeft: "25%" }}>
                <span style={{ width: 100 }}>Location:</span>
                <span className="text-bold">SMA (Stanislaus Military Acad) Female</span>
              </div>
              <div style={{ marginTop: 10, marginLeft: "25%" }}>
                <span style={{ width: 100 }}>Filters:</span>
                <span className="text-bold">Female, African American</span>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center", marginBottom: 10 }}>
              <SeityButton
                label="Discard"
                type="cancel"
                style={{
                  height: 48,
                  width: 108,
                  padding: 0,
                  marginRight: 10
                }}
                onClick={(e) => {
                  if (onClose) {
                    onClose(e);
                  }
                }}
              />
              <SeityButton
                label="Save Name"
                type="next"
                style={{
                  height: 48,
                  width: 119,
                  padding: 0,
                  marginRight: 10
                }}
                onClick={(e) => {
                  if (onClose) {
                    onClose(e);
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </AnalyticModal>
  );
};

export default SeitySavedViews;
