import React, { FunctionComponent, ReactElement } from "react";
import { SurveySelectBoxProps, SurveySelectBox } from "./SurveySelectBox";
import "./styles.scss";

export type SurveyFormSelectBoxProps = SurveySelectBoxProps & {
  label: string;
  name?: string;
  handleDuplicate?: React.MouseEventHandler<HTMLAnchorElement>;
  handleRemove?: React.MouseEventHandler<HTMLAnchorElement>;
  hideActionButtons?: boolean;
};

export const SurveyFormSelectBox: FunctionComponent<SurveyFormSelectBoxProps> = ({
  className,
  label,
  name = "survey-form-select-box",
  handleDuplicate,
  handleRemove,
  hideActionButtons = false,
  ...props
}): ReactElement => {
  return (
    <div className={`survey-select-container ${className || ""}`}>
      <div className="survey-select-container__top">
        <label htmlFor={name}>{label}</label>
        {!hideActionButtons && (
          <div className="survey-select-container__top-button">
            <a
              onClick={(e) => {
                if (handleDuplicate) {
                  handleDuplicate(e);
                }
              }}
            >
              <img src={require("src/assets/survey/btn-duplicate.png")} alt="Duplicate Button" width={16} height={16} />
            </a>
            <a
              onClick={(e) => {
                if (handleRemove) {
                  handleRemove(e);
                }
              }}
            >
              <img src={require("src/assets/survey/btn-recycle.png")} alt="Remove Button" width={16} height={16} />
            </a>
          </div>
        )}
      </div>
      <SurveySelectBox {...props} name={name} className="survey-child-select" />
    </div>
  );
};

export default SurveyFormSelectBox;
