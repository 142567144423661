import React, { FunctionComponent, ReactElement } from "react";
import SurveyRadioButtonGroup from "src/components/survey/SurveyRadioButton/SurveyRadioButtonGroup";
import { SurveyQuestionType } from "../types";

import "./styles.scss";

export type SurveyPreviewQuestionProps = {
  question: SurveyQuestionType;
};

export const SurveyPreviewQuestion: FunctionComponent<SurveyPreviewQuestionProps> = ({ question }): ReactElement => {
  if (question.question !== "") {
    return (
      <div className="survey-preview-question">
        <span>{question.question}</span>
        <SurveyRadioButtonGroup
          type={question?.surveyWizardAnswerGroupID ? question?.surveyWizardAnswerGroupID.toString() : "Yes or No"}
          onChange={(val) => {
            console.log(val);
          }}
          selected=""
        />
      </div>
    );
  }
  return <></>;
};

export default SurveyPreviewQuestion;
