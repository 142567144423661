export function validPassword(password: string): boolean {
  if (!/\d/.test(password) || !/[a-z]/.test(password) || !/[A-Z]/.test(password) || password.length < 8) {
    return false;
  }

  return true;
}

/*
at least one character before "@" symbol
at least one character between "@" and "."
at least one character after "."
*/
export function validEmailAddress(emailAddress: string) {
  if (!/(.)+@(.)+\.(.)/.test(emailAddress)) {
    return false;
  }

  return true;
}
