/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";

import "./styles.scss";

export const AnalyticModal = ({
  headerClassName = "",
  isOpen = false,
  header = true,
  customStyle = {},
  title = "",
  classNameOverlay = "",
  children,
  afterOpenModal = () => {},
  onClose = () => {}
}) => {
  if (process.env.NODE_ENV !== "test") Modal.setAppElement("#root");

  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={onClose}
      className="analytic-modal"
      overlayClassName={"analytic-modal-overlay " + classNameOverlay || ""}
      contentLabel="Analytic Modal"
      style={customStyle}
    >
      {header ? (
        <div className={`${"analytic-modal-header" + " "}${headerClassName}`}>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              onClose();
            }}
          >
            <img src={require("src/assets/images/graphics/close.png")} />
          </a>
          {title ? <h2>{title}</h2> : null}
        </div>
      ) : null}
      {children}
    </Modal>
  );
};

AnalyticModal.propTypes = {
  headerClassName: PropTypes.string,
  isOpen: PropTypes.bool,
  header: PropTypes.bool,
  title: PropTypes.string,
  onAfterOpen: PropTypes.func,
  onClose: PropTypes.func,
  children: PropTypes.element,
  customStyle: PropTypes.object
};

export default AnalyticModal;
