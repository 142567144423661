/* eslint no-param-reassign: "error" */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AtsAllOutcomeSubcategories, AtsCategoryBase, AtsCoreValue, AtsOutcomeSubcategory } from "src/api/ats/atsTypes";
import {
  getAtsAllOutcomeSubcategories,
  getAtsCoreValues,
  getAtsDownloadCoreValueIcons,
  getAtsOutcomeSubcategories,
  getAtsWellBeingCheckInCategories
} from "src/api/ats/seityHealthAPI-ats";

import { validateToken } from "../utils";

export type SorttByType = "dateCreated" | "dateModified" | null;

export interface AtsState {
  isLoading: boolean;
  coreValuesList: AtsCoreValue[];
  outcomeCategoryList: AtsAllOutcomeSubcategories[];
  outComeSubcategoryList: AtsOutcomeSubcategory[];
  outcomeAccordionSelected: string;
  wellBeingCheckInCategoryList: AtsCategoryBase[];
  atsError: object;
  searchText: string;
}

const setInitialState = {
  isLoading: false,
  coreValuesList: [],
  outcomeCategoryList: [],
  outComeSubcategoryList: [],
  outcomeAccordionSelected: "",
  wellBeingCheckInCategoryList: [],
  atsError: {},
  searchText: ""
} as AtsState;

const atsSlice = createSlice({
  name: "ats",
  initialState: setInitialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setAtsError(state, action: PayloadAction<object>) {
      state.atsError = action.payload;
    },
    setAtsCoreValueList(state, action: PayloadAction<AtsCoreValue[]>) {
      state.coreValuesList = action.payload;
    },
    setAtsOutcomeCategoryList(state, action: PayloadAction<AtsAllOutcomeSubcategories[]>) {
      state.outcomeCategoryList = action.payload;
    },
    setOutcomeAccordionSelected(state, action: PayloadAction<string>) {
      state.outcomeAccordionSelected = action.payload;
    },
    setOutcomeSubcategoryList(state, action: PayloadAction<AtsOutcomeSubcategory[]>) {
      state.outComeSubcategoryList = action.payload;
    },
    setWellBeingCheckInCategoryList(state, action: PayloadAction<AtsCategoryBase[]>) {
      state.wellBeingCheckInCategoryList = action.payload;
    },
    setSearchText(state, action: PayloadAction<string>) {
      state.searchText = action.payload;
    }
  }
});

export const {
  setIsLoading,
  setAtsError,
  setAtsCoreValueList,
  setAtsOutcomeCategoryList,
  setOutcomeAccordionSelected,
  setOutcomeSubcategoryList,
  setWellBeingCheckInCategoryList,
  setSearchText
} = atsSlice.actions;

export default atsSlice.reducer;

function handleError(err: any, dispatch: any) {
  console.error(err);
  dispatch(setIsLoading(false));
  dispatch(setAtsError({ atsError: err.toString() }));
}

// API Requests
export const sendGetAtsCoreValuesRequest = () => {
  return async (dispatch: any) => {
    try {
      dispatch(setIsLoading(true));
      const token = await validateToken(dispatch);
      const response = await getAtsCoreValues(token);
      dispatch(setIsLoading(false));
      if (response.success) {
        dispatch(setAtsCoreValueList(response.data));
        return;
      }
    } catch (err) {
      console.error(err);
      handleError(err, dispatch);
    }
  };
};

export const sendGetAtsOutcomeCategoriesRequest = () => {
  return async (dispatch: any) => {
    try {
      dispatch(setIsLoading(true));
      const token = await validateToken(dispatch);
      const response = await getAtsAllOutcomeSubcategories(token);
      dispatch(setIsLoading(false));
      if (response.success) {
        dispatch(setAtsOutcomeCategoryList(response.data));
        return;
      }
    } catch (err) {
      console.error(err);
      handleError(err, dispatch);
    }
  };
};

export const sendGetAtsOutcomeSubcategoriesRequest = (outcomeCategoryID: number, callback?: () => void) => {
  return async (dispatch: any) => {
    try {
      dispatch(setIsLoading(true));
      const token = await validateToken(dispatch);
      const response = await getAtsOutcomeSubcategories(outcomeCategoryID, token);
      dispatch(setIsLoading(false));
      if (response.success) {
        dispatch(setOutcomeSubcategoryList(response.data.subcategories));

        if (callback) callback();
        return;
      }
    } catch (err) {
      console.error(err);
      handleError(err, dispatch);
    }
  };
};

export const sendGetAtsWellBeingCheckInCategoriesRequest = () => {
  return async (dispatch: any) => {
    try {
      dispatch(setIsLoading(true));
      const token = await validateToken(dispatch);
      const response = await getAtsWellBeingCheckInCategories(token);
      dispatch(setIsLoading(false));
      if (response.success) {
        dispatch(setWellBeingCheckInCategoryList(response.data));
        return;
      }
    } catch (err) {
      console.error(err);
      handleError(err, dispatch);
    }
  };
};

export const sendGetAtsDownloadCoreValueIconsRequest = (callback?: (downloadBody) => void) => {
  return async (dispatch: any) => {
    try {
      dispatch(setIsLoading(true));
      const token = await validateToken(dispatch);
      const response = await getAtsDownloadCoreValueIcons(token);
      dispatch(setIsLoading(false));
      if (callback) {
        callback(response);
      }
    } catch (err) {
      console.error(err);
      handleError(err, dispatch);
    }
  };
};
