import React, { FunctionComponent, ReactElement } from "react";

import "./styles.scss";
import { DepartmentItem } from "src/api/groupsAndDepartments/departmentsTypes";
import { GroupItem } from "src/api/groupsAndDepartments/groupsTypes";

export type AudienceListBoxProps = {
  boxType: "Add" | "Remove";
  audienceType: "Department" | "Group";
  audienceList: Array<DepartmentItem> | Array<GroupItem>;
  onClickAll: () => void;
  onClickElement: (item: DepartmentItem | GroupItem) => void;
};

export const AudienceListBox: FunctionComponent<AudienceListBoxProps> = (props): ReactElement => {
  const { boxType, audienceType, audienceList, onClickAll, onClickElement } = props;

  const getHeaderTitle = () => {
    return audienceType === "Department" ? `Departments ${boxType === "Add" ? "" : "added"}` : `Groups ${boxType === "Add" ? "" : "added"}`;
  };
  const getAudienceInfo = (audience: any) => {
    if (audienceType === "Department") {
      const user: DepartmentItem = audience;
      return {
        name: user.departmentName,
        numOfMembers: user.numberOfMembers
      };
    }
    if (audienceType === "Group") {
      const user: GroupItem = audience;
      return {
        name: user.groupName,
        numOfMembers: user.members.length
      };
    }
    return {};
  };

  return (
    <div className="survey-audiencelist">
      <div className="survey-audiencelist__header">
        <span>{getHeaderTitle()}</span>
        <a
          onClick={() => {
            onClickAll();
          }}
        >
          <span>{boxType === "Add" ? "Add all" : "Remove all"}</span>
        </a>
      </div>
      <ul className="survey-audience-panel">
        {audienceList.map((item, index) => {
          return (
            <li key={index}>
              <a
                onClick={() => {
                  onClickElement(item);
                }}
              >
                <img
                  alt="Organization wide"
                  src={boxType === "Add" ? require("src/assets/survey/audience-plus.png") : require("src/assets/survey/audience-minus.png")}
                />
                <h5>{getAudienceInfo(item)?.name}</h5>
              </a>
              <span>{getAudienceInfo(item)?.numOfMembers} Members</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default AudienceListBox;
