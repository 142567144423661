import React, { useState, useEffect } from "react";

import { models, Report, Embed, service, VisualDescriptor, Page } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";

import { PbiFilter } from "src/features/ReportHome/types";
import { compareFilter } from "src/utils/helper";
import { SeityLoader } from "../../SeityLoader";

import "./styles.scss";

const GROUPVIEW_RESET_BOOKMARK = "Bookmarkca8f1f1d3c93e1441874";
const GROUPROST_RESET_BOOKMARK = "Bookmarkf9094ccb245610ba0be6";
interface SeityPbiReportProps {
  pageName: string | null;
  embedToken: string;
  embedUrl: string;
  curBookmark: models.IReportBookmark | null;
  filterVal: PbiFilter | null;
  reportType: "Student" | "Staff";
  bookmarkListHandler: ((_: models.IReportBookmark[]) => void) | undefined;
  tokenExpiredHandler: () => void | undefined;
  filterValsHandler: (_: VisualDescriptor) => void;
}

const SeityPbiReport = ({
  pageName = null,
  embedToken = "",
  embedUrl = "",
  curBookmark = null,
  filterVal = null,
  reportType,
  bookmarkListHandler,
  tokenExpiredHandler,
  filterValsHandler
}: SeityPbiReportProps) => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  let slicer;
  const raceFilters = ["Asian Indian", "White", "Samon"];
  let filterValues: VisualDescriptor;

  const [embedReport, setEmbedReport] = useState<Report | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState<PbiFilter | null>(null);
  const [pbiPages, setPbiPages] = useState<Page[]>();

  let gReport: Report;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const raceFilter = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "accountid",
      column: "Race"
    },
    operator: "In",
    values: raceFilters,
    filterType: models.FilterType.Basic
  };

  async function removeFilter() {
    console.log("Removing filter");
    const eReport = embedReport || gReport;
    try {
      if (eReport) {
        await eReport.updateFilters(models.FiltersOperations.RemoveAll);
        await eReport.refresh();
        console.log("Report filters were removed.");
      }
    } catch (errors) {
      console.log("applyFilter error:", errors);
    }
  }

  async function removeSlicer() {
    const eReport = embedReport || gReport;
    try {
      if (eReport && curBookmark) {
        console.log("Removing slicer");
        await eReport.bookmarksManager.apply(
          curBookmark?.displayName === "GroupView" ? GROUPVIEW_RESET_BOOKMARK : GROUPROST_RESET_BOOKMARK
        );
        console.log("Report slicers were removed.");
      }
    } catch (errors) {
      console.log("applySlicer error:", errors);
    }
  }

  async function applyFilter(tfilter) {
    // Retrieve the page collection and get the visuals for the active page.
    const eReport = embedReport || gReport;
    try {
      if (eReport) {
        // const pages = await embedReport.getPages();
        // Retrieve the active page.
        // const page = pages.filter((pgg) => {
        //   return pgg.isActive;
        // })[0];

        // Retrieve the target visual.
        // const visuals = await page.getVisuals();
        // slicer = visuals.filter((visual) => {
        //   return visual.type === 'slicer' && visual.name === '7a8cb7c0f612765bc6fd';
        // })[0];
        // setSlicer(slicer);
        // console.log('slicer:', slicer1);
        // Set the slicer state which contains the slicer filters.
        // await slicer?.setSlicerState({ filters: [raceFilter] });

        // const reportFilter = await eReport.getFilters();
        // console.log('report filter:', reportFilter);

        // user replace all to update filters
        await eReport.updateFilters(models.FiltersOperations.ReplaceAll, [tfilter]);
        console.log("Report filters were replaced.", [tfilter]);
      }
    } catch (errors) {
      console.log("applyFilter error:", errors);
    }
  }

  const applyClassIdn = async () => {
    let columnStr = "";
    let tableStr = "Class01";
    if (filterVal) {
      // eslint-disable-next-line no-nested-ternary
      columnStr = filterVal?.type === "Class" ? "Class01ID" : filterVal?.type === "Teacher" ? "StaffID" : "CompanyID";
      tableStr = reportType === "Staff" ? "Participation01" : "Class01";
    } else {
      return;
    }

    const classFilter = {
      $schema: "http://powerbi.com/product/schema#basic",
      target: {
        table: tableStr,
        column: columnStr
      },
      operator: "In",
      values: filterVal?.value?.map((num) => {
        return num.toString();
      }) || [],
      filterType: models.FilterType.Basic
    };
    try {
      applyFilter(classFilter);
    } catch (error) {
      console.log("applyFilter error:", error);
    }
  };

  const [sampleReportConfig, setReportConfig] = useState<models.IReportEmbedConfiguration>({
    type: "report",
    embedUrl: width <= 768 ? embedUrl : embedUrl, // Todo: need to update when mobile embed url is available
    tokenType: models.TokenType.Embed,
    accessToken: embedToken,
    settings: {
      panes: {
        filters: {
          expanded: false,
          visible: false
        }
      },
      navContentPaneEnabled: false,
      background: models.BackgroundType.Transparent,
      layoutType: models.LayoutType.Custom,
      customLayout: {
        displayOption: models.DisplayOption.FitToPage
      }
    }
  });

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    setFilter(filterVal);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    setReportConfig({
      ...sampleReportConfig,
      embedUrl: width <= 1152 ? embedUrl : embedUrl
    });
  }, [width]);

  useEffect(() => {
    setReportConfig({
      ...sampleReportConfig,
      accessToken: embedToken
    });
  }, [embedToken]);

  useEffect(() => {
    if (curBookmark && embedReport) {
      try {
        if (curBookmark.displayName === "World") {
          embedReport.setPage("ReportSection6d9596b2ebd1a2d0a7ac");
        } else {
          embedReport.bookmarksManager.apply(curBookmark.name);
        }
      } catch (errors) {
        console.log(errors);
      }
    }
  }, [curBookmark]);

  useEffect(() => {
    if (pageName && embedReport && pbiPages) {
      try {
        const page = pbiPages.find((p) => {
          return p.displayName === pageName;
        });
        if (page) {
          embedReport.setPage(page.name);
        } else {
          console.log("can not find page for ", pageName);
        }
      } catch (errors) {
        console.log(errors);
      }
    }
  }, [pageName, embedReport, pbiPages]);

  useEffect(() => {
    if (!compareFilter(filter, filterVal)) {
      setFilter(filterVal);
      if (filterVal) {
        removeSlicer();
        applyClassIdn();
      } else {
        try {
          removeFilter();
        } catch (error) {
          console.log("removeFilter error:", error);
        }
      }
    }
  }, [filterVal]);

  // Map of event handlers to be applied to the embedding report
  const eventHandlersMap = new Map([
    // eslint-disable-next-line func-names
    [
      "loaded",
      async function () {
        if (gReport) {
          const bookmarks = await gReport.bookmarksManager.getBookmarks();
          if (bookmarkListHandler) {
            bookmarkListHandler(bookmarks);
          }
          const pages = await gReport.getPages();
          setPbiPages(pages);
          if (filterVal) {
            applyClassIdn();
          } else {
            removeFilter();
          }
        }
      }
    ],
    // eslint-disable-next-line func-names
    [
      "rendered",
      async function () {
        console.log("Report has rendered");
        // if (embedReport) {
        //   embedReport.getFilters().then(filters => {
        //     console.log('Filters:', filters);
        //   }).catch( error => {
        //     console.log('Filters error:', error);
        //   });
        // }

        if (slicer) {
          const state = await slicer?.getSlicerState();
          console.log("getSlicerState:", state);
          filterValues = state.filters[0].values;
          filterValsHandler(filterValues);
        }
        setIsLoading(false);
      }
    ],
    // eslint-disable-next-line func-names
    [
      "error",
      function (event?: service.ICustomEvent<any>) {
        if (event) {
          if (event.type === "error" && event.detail.message === "TokenExpired") {
            console.log("Token Expired!");
            tokenExpiredHandler();
          }
        }
        setIsLoading(false);
      }
    ]
  ]);

  return (
    <>
      {embedToken ? (
        <>
          <PowerBIEmbed
            embedConfig={sampleReportConfig}
            eventHandlers={eventHandlersMap}
            cssClassName={`report-style-class ${isLoading && reportType === "Staff" ? "hide-report" : ""}`}
            getEmbeddedComponent={(embedObject: Embed) => {
              setEmbedReport(embedObject as Report);
              gReport = embedObject as Report;
            }}
          />
          {isLoading && <SeityLoader showBackgroundMask />}
        </>
      ) : null}
    </>
  );
};

export default SeityPbiReport;
