import { FunctionComponent, ReactElement } from "react";
import "./styles.scss";
import WbnTooltipListitem from "../WbnTooltipListitem";

export type WbnTooltipInteriorProps = {
  content: any[];
  title?: string;
};

export const WbnTooltipInterior: FunctionComponent<WbnTooltipInteriorProps> = ({ content, title }): ReactElement => {
  const parsedContent = content.map((item, index) => {
    return <WbnTooltipListitem key={index} {...item} />;
  });

  return (
    <>
      <h5 className="wbn-tooltip-title">{title}</h5>
      <div className="wbn-component-tooltip-content">{parsedContent}</div>
    </>
  );
};

export default WbnTooltipInterior;
