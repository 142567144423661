import React, { FunctionComponent, ReactElement, useRef } from "react";
import DatePicker from "react-datepicker";
import "./styles.scss";
import { getMonth, getYear } from "date-fns";
import calendar from "src/assets/images/graphics/calendar.png";

export type SurveyDatePickerProps = {
  className?: string;
  value: Date;
  onChange?: (value: Date) => void;
};

export const SurveyDatePicker: FunctionComponent<SurveyDatePickerProps> = ({ className, onChange, value }): ReactElement => {
  const datepickerRef = useRef<DatePicker>(null);
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  const openDateModal = () => {
    datepickerRef.current.setOpen(true);
  };

  return (
    <div className={`survey-datepicker-container ${className || ""}`}>
      <DatePicker
        selected={value}
        onChange={onChange}
        ref={datepickerRef}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          decreaseYear,
          increaseYear,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
          prevYearButtonDisabled,
          nextYearButtonDisabled
        }) => {
          return (
            <div className="survey-datepicker-header">
              <div className="survey-date-item-container">
                <span className="d" onClick={prevMonthButtonDisabled ? null : decreaseMonth}>
                  {"<"}
                </span>
                <span className="current-date-value">{months[getMonth(date)]}</span>

                <span onClick={nextMonthButtonDisabled ? null : increaseMonth}>{">"}</span>
              </div>

              <div className="survey-date-item-container">
                <span className="d" onClick={prevYearButtonDisabled ? null : decreaseYear}>
                  {"<"}
                </span>
                <span className="current-date-value">{getYear(date)}</span>
                <span onClick={nextYearButtonDisabled ? null : increaseYear}>{">"}</span>
              </div>
            </div>
          );
        }}
      />
      <div onClick={openDateModal} className="survey-datepicker-input-icon">
        <img src={calendar} alt="Calendar Icon" />
      </div>
    </div>
  );
};

export default SurveyDatePicker;
