import React, { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";

import { RootState } from "src/reducers";
import { GroupItem, MemberItem } from "src/api/groupsAndDepartments/groupsTypes";
import {
  addAllUsersToGroup,
  addUserToGroup,
  filterSearchedUsersNotInGroup,
  removeAllUsersFromGroup,
  removeUserFromGroup,
  resetSearchUsersNotInGroup,
  sendPostAssignToGroupRequest
} from "src/reducers/groupsAndDepartments/groupsSlice";

import "./styles.scss";

const closeIcon = require("../../../assets/groupsAndDepartments/close.svg").default;
const searchIcon = require("../../../assets/groupsAndDepartments/search_icon.svg").default;
const addMemberIcon = require("../../../assets/groupsAndDepartments/add_member_icon.svg").default;
const removeMemberIcon = require("../../../assets/groupsAndDepartments/remove_member_icon.svg").default;

export type ModalProps = {
  isOpen: boolean;
  onClose: Function;
  isLoading: boolean;
  focusedItem: GroupItem | undefined;
};

export const EditMembersModal: FunctionComponent<ModalProps> = ({ isOpen, onClose, focusedItem }): ReactElement => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");

  const { usersInGroup, searchedUsersNotInGroup, usersNotInGroup } = useSelector((state: RootState) => {
    return state.groups;
  });

  useEffect(() => {
    dispatch(filterSearchedUsersNotInGroup(searchText));
  }, [searchText]);

  const onClearHandler = () => {
    setSearchText("");
    dispatch(resetSearchUsersNotInGroup());
  };

  const addAllMembersToGroupHandler = () => {
    dispatch(addAllUsersToGroup());
    dispatch(filterSearchedUsersNotInGroup(searchText));
  };

  const removeAllMembersFromGroupHandler = () => {
    dispatch(removeAllUsersFromGroup());
    dispatch(filterSearchedUsersNotInGroup(searchText));
  };

  const addMemberToGroupHandler = (member: MemberItem) => {
    dispatch(addUserToGroup(member));
    dispatch(filterSearchedUsersNotInGroup(searchText));
  };

  const removeMemberFromGroupHandler = (member: MemberItem) => {
    dispatch(removeUserFromGroup(member));
    dispatch(filterSearchedUsersNotInGroup(searchText));
  };

  const onCloseHandler = () => {
    setSearchText("");
    onClose();
  };

  const onSaveHandler = () => {
    if (focusedItem) {
      dispatch(
        sendPostAssignToGroupRequest(
          usersInGroup.map((member: MemberItem) => {
            return member.accountID;
          }),
          focusedItem?.groupID
        )
      );
    }
    setSearchText("");
    onClose();
  };

  return (
    <Modal isOpen={isOpen} className="gad-modal" overlayClassName="gad-modal-overlay">
      <div className="gad-wrapper">
        <div className="gad-header">
          Add or remove members
          <button onClick={onCloseHandler} type="button" className="gad-close-btn">
            <img src={closeIcon} alt="close" />
          </button>
        </div>
        <div className="gad-main">
          <div className="gad-main-upper">
            <img src={searchIcon} alt="search" />
            <input
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              placeholder="Search members"
            />
            <button style={{ opacity: searchText.length > 0 ? 1 : 0.5 }} onClick={onClearHandler} type="button">
              Clear
            </button>
          </div>
          <div className="gad-main-lower">
            <div className="gad-main-lower-left">
              <div className="gad-member-details">
                {usersNotInGroup.length} Members
                <button onClick={addAllMembersToGroupHandler} type="button">
                  Add all
                </button>
              </div>
              <div className="gad-member-container">
                {searchedUsersNotInGroup.map((memberItem: MemberItem) => {
                  return (
                    <button
                      onClick={() => {
                        addMemberToGroupHandler(memberItem);
                      }}
                      className="gad-member-item"
                      key={memberItem.accountID}
                      type="button"
                    >
                      <img src={addMemberIcon} alt="add member" />
                      {memberItem.accountName}
                    </button>
                  );
                })}
              </div>
            </div>
            <div className="gad-main-lower-right">
              <div className="gad-member-details">
                {usersInGroup.length} Added
                <button onClick={removeAllMembersFromGroupHandler} type="button">
                  Remove all
                </button>
              </div>
              <div className="gad-member-container">
                {usersInGroup.map((memberItem: MemberItem) => {
                  return (
                    <button
                      onClick={() => {
                        removeMemberFromGroupHandler(memberItem);
                      }}
                      className="gad-member-item"
                      key={memberItem.accountID}
                      type="button"
                    >
                      <img src={removeMemberIcon} alt="remove member" />
                      {memberItem.accountName}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="gad-footer">
          <button onClick={onCloseHandler} className="gad-cancel-btn" type="button">
            Cancel
          </button>
          <button onClick={onSaveHandler} className="gad-create-btn" type="submit">
            Save
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default EditMembersModal;
