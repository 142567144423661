/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/quotes */
import React from "react";

import { MoreInfoIcon } from "../SeityInfoIcon/InfoIcon";
import { SeityTextInput, SeityTextInputProps } from "./SeityTextInput";
import "./styles.scss";

interface SeityFormTextInputProps {
  inputLabelText: string;
  moreInfoText?: string;
  formStyle?: {};
  controlId?: string;
}

export const SeityFormTextInput = (props: SeityFormTextInputProps & SeityTextInputProps) => {
  const {
    type,
    onChange,
    placeholder,
    inputStyle,
    value,
    className,
    autoComplete,
    inputLabelText,
    moreInfoText,
    isInvalid,
    leftIcon,
    formStyle
  } = props;

  return (
    <div className="seity-input-with-label" style={formStyle}>
      <label>{inputLabelText}</label>
      {moreInfoText && (
        <MoreInfoIcon
          text={moreInfoText}
          imageStyle={{
            marginLeft: "5px",
            paddingBottom: "8px"
          }}
        />
      )}
      <SeityTextInput
        type={type}
        placeholder={placeholder}
        inputStyle={inputStyle}
        value={value}
        onChange={onChange}
        autoComplete={autoComplete}
        className={className}
        isInvalid={isInvalid}
        leftIcon={leftIcon}
      />
    </div>
  );
};
