import React, { FunctionComponent } from "react";

import SurveyModal from "src/components/survey/SurveyModal";
import SurveyButton from "src/components/survey/SurveyButton";

import { getDateStringFromDateString, getTimeStringFromDateString } from "src/_core/utils/dateUtils/dateUtils";

import "./styles.scss";

export type SurveyScheduledModalProps = {
  isOpen: boolean;
  onClose: () => void;
  startDate: string;
  endDate: string;
  startDay: string;
  frequency: number;
};

export const SurveyScheduledModal: FunctionComponent<SurveyScheduledModalProps> = ({
  isOpen = false,
  onClose,
  startDate,
  endDate,
  startDay,
  frequency
}) => {
  const scheduledText = (frequencyValue: number) => {
    let frequencyText: string;
    switch (frequencyValue) {
      case 1: {
        frequencyText = ` will start, ${getDateStringFromDateString(startDate)} at 
        ${getTimeStringFromDateString(startDate)}. Survey will end 
        on ${getDateStringFromDateString(endDate)} at ${getTimeStringFromDateString(endDate)}.`;
        break;
      }
      case 2: {
        frequencyText = ` will start, ${getDateStringFromDateString(startDate)} at 
        ${getTimeStringFromDateString(startDate)}. Survey will repeat daily and end on ${getDateStringFromDateString(endDate)} 
        at ${getTimeStringFromDateString(endDate)}.`;
        break;
      }
      case 3: {
        frequencyText = ` will start, ${getDateStringFromDateString(startDate)} at 
        ${getTimeStringFromDateString(startDate)}. Survey will repeat weekly on ${startDay} and end 
        on ${getDateStringFromDateString(endDate)} at ${getTimeStringFromDateString(endDate)}.`;
        break;
      }
      default:
        frequencyText = " is now scheduled";
        break;
    }
    return frequencyText;
  };

  return (
    <SurveyModal isOpen={isOpen} onClose={onClose}>
      <div className="survey-scheduled-modal">
        <h1>Your Survey Has Been Scheduled!</h1>
        <img src={require("../../assets/images/graphics/survey-scheduled-modal.png")} alt="survey scheduled art" />
        <h4 className="mb30">
          <b>Overall Wellbeing Check-In Survey</b>
          {scheduledText(frequency)}
        </h4>
        <SurveyButton
          label="Close"
          onClick={() => {
            onClose();
          }}
        />
      </div>
    </SurveyModal>
  );
};

export default SurveyScheduledModal;
