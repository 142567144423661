import React from "react";

import CoreStrings from "src/_core/strings/strings";
import { toast } from "react-toastify";
import moment from "moment";

export const toastError = (msg?: string) => {
  toast.error(msg ?? CoreStrings.genericErrorMsg, {
    position: "top-center",
    containerId: "general-noti",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
    // progress: 1,
  });
};

export const toastSuccess = (msg: string) => {
  toast.success(msg, {
    position: "top-center",
    containerId: "general-noti",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
    // progress: 1,
  });
};

export const getNewLineText = (str: string) => {
  return str.split("\n").map((s) => {
    return React.createElement("p", null, s);
  });
};

export const getLanguageStr = (code: number) => {
  return code === 1 ? "en-US" : "es";
};

export const switchLanguage = (code: number) => {
  const langStr = getLanguageStr(code);
  window.localStorage.setItem("language", langStr);
  CoreStrings.setLanguage(langStr);
  moment.locale(langStr === "en-US" ? "en" : langStr);
};

export const isValidHttpUrl = (val) => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(val);
};

export const getNumberWithOrdinal = (n: number) => {
  var s = ["th", "st", "nd", "rd"],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const appendGradeSuffix = (grade) => {
  if (grade === 0) return "N/A";

  const lastDigit = grade % 10;
  switch (lastDigit) {
    case 1:
      if (grade === 11) return grade + "th"; // Special case for 11
      return grade + "st";
    case 2:
      if (grade === 12) return grade + "th"; // Special case for 12
      return grade + "nd";
    case 3:
      return grade + "rd";
    default:
      return grade + "th";
  }
}

