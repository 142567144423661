/* eslint-disable max-len */
import React, { FunctionComponent, ReactElement } from "react";
import ColorPikcer from "src/assets/ats/color-picker.png";
import ColorSlider from "src/assets/ats/color-slider.png";
import AtsBrandColorItem from "../AtsBrandColorsItem";
import { PRIMARY_COLORS, SECONDARY_COLORS } from "./colors";

import "./styles.scss";

export type AtsBrandColorsProps = {
  onCategoryClicked: (index: number, menuName: string) => void;
};

export const AtsBrandColors: FunctionComponent<AtsBrandColorsProps> = ({ onCategoryClicked }): ReactElement => {
  return (
    <div className="ats-brand-colors">
      <div className="ats-brand-colors__item-wrapper">
        <span
          onClick={() => {
            return onCategoryClicked(0, "Typography");
          }}
        >
          Typography
        </span>
        <span
          onClick={() => {
            return onCategoryClicked(1, "Colors");
          }}
          className="selected"
        >
          Colors
        </span>
      </div>
      <span className="ats-brand-colors-text">
        These are the splashes of colors that should appear the most in our User Interfaces, they are the colors that determine the overall
        “look” of the brand.
      </span>

      <span className="ats-brand-colors-text">
        When you are using our colors in Powerpoint or Google Slides you will use the color menu to pick a color. To get the precise color
        of our brand copy and paste the Hex color number (Ex: #56A3A6) in the menu, or you may use the RGB code color for PowerPoint.
      </span>

      <div className="ats-brand-colors__image-wrapper">
        <div className="ats-brand-colors__image-container">
          <img src={ColorPikcer} alt="color picker" />

          <span className="color-image-text">ScreenShor from Google Slides</span>
        </div>

        <div className="ats-brand-colors__image-container">
          <img src={ColorSlider} alt="color picker" />

          <span className="color-image-text">ScreenShor from PowerPoint</span>
        </div>
      </div>

      <span className="ats-brand-colors-text">
        *When using colors the palette, please consider the medium you’re using it for. The same color looks different on paper and web.*
      </span>

      <div className="ats-brand-colors__section-container">
        <h1 className="ats-brand-colors__section-title">Primary Colors</h1>
        <div className="border" />

        <div className="ats-brand-colors__container-color-list">
          {PRIMARY_COLORS.map((color) => {
            return <AtsBrandColorItem colorHex={color.colorHex} colorName={color.colorName} colorRGB={color.colorRGB} />;
          })}
        </div>
      </div>

      <div className="ats-brand-colors__section-container">
        <h1 className="ats-brand-colors__section-title">Secondary Colors</h1>
        <div className="border" />

        <div className="ats-brand-colors__container-color-list">
          {SECONDARY_COLORS.map((color) => {
            return <AtsBrandColorItem colorHex={color.colorHex} colorName={color.colorName} colorRGB={color.colorRGB} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default AtsBrandColors;
