import { Class, School } from "src/api/school/schoolTypes";
import {
  getClassList,
  getSchoolList,
  getTeacherClassList,
  getTeacherList,
  getTeacherSchoolList
} from "src/api/school/seityHealthAPI-school";
import { createSurveyWizardAudience, getSurveyWizardAudience } from "src/api/survey/seityHealthAPI-audience";
import { SurveyAudience } from "src/api/survey/surveyTypes";
import { OptionType } from "src/components/survey/SurveySwitch";
import { defaultValue } from "src/features/SurveyCreate/stepComponents/SurveyAudience/surveySchedulerConst";
import {
  setIsLoading,
  setSurveyAudienceError,
  clearSurveyAudienceError,
  setSchoolList,
  setTeacherList,
  setClassList
} from "src/reducers/survey/surveySlice";
import { validateToken } from "src/reducers/utils";

const handleAudienceError = (err: any, dispatch: any) => {
  console.error(err);
  dispatch(setSurveyAudienceError({ audienceError: err.toString() }));
};

export const sendGetSchoolListRequest = (allStudentAccess: boolean) => {
  return async (dispatch: any) => {
    try {
      dispatch(setIsLoading(true));
      dispatch(clearSurveyAudienceError());
      const token = await validateToken(dispatch);
      let success = false;
      let schoolList: School[] = [];
      if (allStudentAccess) {
        const temp = await getSchoolList(token);
        success = temp.success;
        schoolList = temp.data.schoolList;
      } else {
        const temp = await getTeacherSchoolList(token);
        success = temp.success;
        schoolList = temp.data.teacherSchoolList;
      }
      if (success) {
        const schoolListFormated = schoolList.map((school) => {
          return {
            label: school.schoolName,
            value: school.companyID
          };
        });

        dispatch(setSchoolList([defaultValue, ...schoolListFormated]));
        return;
      }
    } catch (err) {
      console.error("sendGetSchoolListRequest", err);
      // Need error message handler, this should be improved with the error message from server.
      handleAudienceError("There is currently no school information for this organization!", dispatch);
    }
  };
};

export const sendGetTeacherlListRequest = (companyID: number, callback?: (teacherList: OptionType[]) => void) => {
  return async (dispatch: any) => {
    try {
      dispatch(setIsLoading(true));
      dispatch(clearSurveyAudienceError());
      const token = await validateToken(dispatch);
      const teacherListResponse = await getTeacherList(companyID, token);

      if (teacherListResponse.success) {
        const teacherListFormated = teacherListResponse.data.teacherList.map((teacher) => {
          return {
            label: `${teacher.firstName} ${teacher.lastName}`,
            value: teacher.accountID
          };
        });

        dispatch(setTeacherList([defaultValue, ...teacherListFormated]));

        if (callback) callback([defaultValue, ...teacherListFormated]);

        return;
      }
    } catch (err) {
      console.error(err);
      handleAudienceError(err, dispatch);
    }
  };
};

export const sendGetClasslListRequest = (
  companyID: number,
  accountID: number,
  allStudentAccess: boolean,
  callback?: (classList: OptionType[]) => void
) => {
  return async (dispatch: any) => {
    try {
      dispatch(setIsLoading(true));
      dispatch(clearSurveyAudienceError());
      const token = await validateToken(dispatch);

      let success = false;
      let classList: Class[] = [];
      if (allStudentAccess) {
        const temp = await getClassList(companyID, accountID, token);
        success = temp.success;
        classList = temp.data.classList;
      } else {
        const temp = await getTeacherClassList(companyID, token);
        success = temp.success;
        classList = temp.data.teacherClassList;
      }

      if (success) {
        const classListFormated = classList.map((_class) => {
          return {
            label: _class.classDescription,
            value: _class.class01ID
          };
        });

        dispatch(setClassList([defaultValue, ...classListFormated]));
        if (callback) callback([defaultValue, ...classListFormated]);
        return;
      }
    } catch (err) {
      console.error(err);
      handleAudienceError(err, dispatch);
    }
  };
};

// Staff APIs
export const sendPostAudienceRequest = (param: SurveyAudience, callback?: (sccuess: boolean) => void) => {
  return async (dispatch: any) => {
    try {
      dispatch(setIsLoading(true));
      dispatch(clearSurveyAudienceError());
      const token = await validateToken(dispatch);
      const response = await createSurveyWizardAudience(param, token);
      // handle success
      if (callback) {
        callback(response.success);
      }
    } catch (err) {
      console.error(err);
      handleAudienceError(err, dispatch);
    }
  };
};

export const sendPutAudienceRequest = (param: SurveyAudience, callback?: (sccuess: boolean) => void) => {
  return async (dispatch: any) => {
    try {
      dispatch(setIsLoading(true));
      dispatch(clearSurveyAudienceError());
      const token = await validateToken(dispatch);
      const response = await createSurveyWizardAudience(param, token);
      // handle success
      if (callback) {
        callback(response.success);
      }
    } catch (err) {
      console.error(err);
      handleAudienceError(err, dispatch);
    }
  };
};

export const sendGetAudienceRequest = (surveyScheduleID: number, callback?: (surveyAudience: SurveyAudience) => void) => {
  return async (dispatch: any) => {
    try {
      dispatch(setIsLoading(true));
      dispatch(clearSurveyAudienceError());
      const token = await validateToken(dispatch);
      const response = await getSurveyWizardAudience(surveyScheduleID, token);
      if (response.success) {
        // handle success
        if (callback) {
          callback(response.data);
        }
      }
    } catch (err) {
      console.error(err);
      handleAudienceError(err, dispatch);
    }
  };
};
