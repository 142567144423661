import React, { FunctionComponent, ReactElement, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { RootState } from "src/reducers";
import { sendGetDepartmentsRequest } from "src/reducers/groupsAndDepartments/departmentsSlice";
import { DepartmentItem } from "src/api/groupsAndDepartments/departmentsTypes";
import { sendGetProSettingsRequest } from "src/reducers/auth/accountSlice";

import SeityAlert from "src/components/analytic/SeityAlert";
import CreateDepartmentModal from "./CreateDepartmentModal";
import DeleteDepartmentModal from "./DeleteDepartmentsModal";
import EditDepartmentModal from "./EditDepartmentModal";

import "./styles.scss";

const plusIcon = require("../../../assets/groupsAndDepartments/plus_icon.svg").default;
const editIcon = require("../../../assets/groupsAndDepartments/edit.svg").default;
const deleteIcon = require("../../../assets/groupsAndDepartments/delete.svg").default;
const loaderDots = require("../../../assets/groupsAndDepartments/loader_dots.gif");

export const Departments: FunctionComponent = (): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [showUnauthAlert, setShowUnauthAlert] = useState(false);
  const [showCreateDepartmentModal, setShowCreateDepartmentModal] = useState(false);
  const [showEditDepartmentModal, setShowEditDepartmentModal] = useState(false);
  const [showDeleteDepartmentModal, setShowDeleteDepartmentModal] = useState(false);
  const [focusedItem, setFocusedItem] = useState<DepartmentItem>();

  const onEditHandler = (item: DepartmentItem) => {
    setFocusedItem(item);
    setShowEditDepartmentModal(true);
  };

  const onDeleteHandler = (item: DepartmentItem) => {
    setFocusedItem(item);
    setShowDeleteDepartmentModal(true);
  };

  const { isListLoading, departmentsList } = useSelector((state: RootState) => {
    return state.departments;
  });

  const { proSettings } = useSelector((state: RootState) => {
    return state.account;
  });

  useEffect(() => {
    dispatch(sendGetDepartmentsRequest());
  }, []);

  useEffect(() => {
    if (proSettings) {
      if (proSettings.departmentManagement === false) {
        setShowUnauthAlert(true);
      }
    }
    if (!proSettings) {
      dispatch(sendGetProSettingsRequest());
    }
  }, [proSettings]);

  return (
    <div className="gv-container">
      {showUnauthAlert ? (
        <SeityAlert
          visible={showUnauthAlert}
          onToggle={() => {
            setShowUnauthAlert(false);
            history.push("/");
          }}
          title={"Oops!\nYou do not have access to this page!"}
          subTitle="You will be sent to the home page,
          please contact Seity Customer Support at support@seityhealth.com if you need further assistance."
        />
      ) : (
        <>
          <div className="gv-header">
            <div className="gv-header-left">
              <h1>Departments</h1>
              <h4>{departmentsList.length} Departments</h4>
            </div>
            <div className="gv-header-right">
              <button
                onClick={() => {
                  setShowCreateDepartmentModal(true);
                }}
                className="gv-create-btn"
                type="button"
              >
                <img className="gv-plus" src={plusIcon} alt="plus" />
                Create Department
              </button>
            </div>
          </div>
          <div className="gv-main">
            {isListLoading ? (
              <img className="gv-main-loader" src={loaderDots} alt="loader" />
            ) : (
              departmentsList.map((departmentItem: DepartmentItem) => {
                return (
                  <div key={departmentItem.departmentId} className="gv-item-wrapper">
                    <div className="gv-item-left">
                      <div>{departmentItem.departmentName}</div>
                      <button
                        onClick={() => {
                          onEditHandler(departmentItem);
                        }}
                        className="gv-edit-btn"
                        type="button"
                      >
                        <img src={editIcon} alt="edit group name" />
                      </button>
                    </div>
                    <div className="gv-item-center">
                      <div>{departmentItem.numberOfMembers} Members</div>
                    </div>
                    <div className="gv-item-right">
                      <button
                        onClick={() => {
                          onDeleteHandler(departmentItem);
                        }}
                        className="gv-delete-btn"
                        type="button"
                      >
                        <img src={deleteIcon} alt="delete group" />
                        Delete
                      </button>
                    </div>
                  </div>
                );
              })
            )}
          </div>
          <CreateDepartmentModal
            onClose={() => {
              setShowCreateDepartmentModal(false);
            }}
            isOpen={showCreateDepartmentModal}
          />
          <EditDepartmentModal
            focusedID={focusedItem?.departmentId}
            onClose={() => {
              setShowEditDepartmentModal(false);
            }}
            isOpen={showEditDepartmentModal}
          />
          <DeleteDepartmentModal
            focusedID={focusedItem?.departmentId}
            departmentName={focusedItem?.departmentName}
            isOpen={showDeleteDepartmentModal}
            onClose={() => {
              setShowDeleteDepartmentModal(false);
            }}
          />
        </>
      )}
    </div>
  );
};

export default Departments;
