import axios from "axios";
import { BASE_URL } from "../apiConstants";
import { GetDepartmentsResponse, PostDepartmentResponse, PutDepartmentResponse, DeleteDepartmentResponse } from "./departmentsTypes";

const DEPARTMENTSECTION = "/Department/";

export async function getDepartments(token: string): Promise<GetDepartmentsResponse> {
  const url = BASE_URL + DEPARTMENTSECTION;
  try {
    const response = await axios.get<GetDepartmentsResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}

export async function postDepartment(departmentName: string, token: string): Promise<PostDepartmentResponse> {
  const url = BASE_URL + DEPARTMENTSECTION;
  try {
    const response = await axios.post<PostDepartmentResponse>(
      url,
      {
        departmentName
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}

export async function putDepartment(departmentId: number, newName: string, token: string): Promise<PutDepartmentResponse> {
  const url = BASE_URL + DEPARTMENTSECTION;
  try {
    const response = await axios.put<PutDepartmentResponse>(
      url,
      {
        departmentId,
        newName
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}

export async function deleteDepartment(departmentId: number, token: string): Promise<DeleteDepartmentResponse> {
  const url = BASE_URL + DEPARTMENTSECTION;
  try {
    const response = await axios.delete<DeleteDepartmentResponse>(url, {
      data: { departmentId },
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}
