import React, { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StepWizardChildProps } from "react-step-wizard";

import {
  sendGetClasslListRequest,
  sendGetSchoolListRequest,
  sendGetTeacherlListRequest,
  sendPostAudienceRequest,
  sendPutAudienceRequest
} from "src/reducers/survey/surveyAudienceSlice";
import { RootState } from "src/reducers";
import SeityAlert from "src/components/analytic/SeityAlert";
import SurveyFormSelectBox from "src/components/survey/SurveySelectBox/SurveyFormSelectBox";
import { OptionType } from "src/components/survey/SurveySelectBox/SurveySelectBox";
import { SurveyAudience, SURVEY_AUDIENCE_STUDENTS } from "src/api/survey/surveyTypes";
import { getDateDifferenceInDaysWithTime } from "src/_core/utils/dateUtils/dateUtils";
import SeityLoader from "src/components/SeityLoader";
import { defaultValue } from "./surveySchedulerConst";

import { SelectStudentsProps, SurveyAudienceProps } from "./types";

import "./styles.scss";

export const SelectStudents: FunctionComponent<SelectStudentsProps & SurveyAudienceProps & Partial<StepWizardChildProps>> = (
  props
): ReactElement => {
  const { toggleNextBtn, onSurveyActive, onSurveyScheduled, onUpdateAudience, curSurveySchedule, curSurveyAudience } = props;

  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [schoolSelected, setSchoolSelected] = useState<OptionType>(defaultValue);
  const [teacherSelected, setTeacherSelected] = useState<OptionType>(defaultValue);
  const [classSelected, setClassSelected] = useState<OptionType | null>(defaultValue);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { schoolList, teacherList, classList } = useSelector((store: RootState) => {
    return store.survey;
  });
  const { proSettings } = useSelector((store: RootState) => {
    return store.account;
  });

  function cleanSelect() {
    setTeacherSelected(defaultValue);
    setClassSelected(defaultValue);
  }

  // TODO: Refactor this with async/await api calls
  async function onSchoolSelected(val: OptionType): Promise<{ classList: OptionType[]; teacherList: OptionType[] }> {
    let result;
    setSchoolSelected(val);
    onUpdateAudience(val.value, 0, 0);
    if (proSettings?.allStudentAccess) {
      await dispatch(
        sendGetTeacherlListRequest(val?.value, (teacherListCB) => {
          result = { teacherList: teacherListCB };
        })
      );
    } else {
      await dispatch(
        sendGetClasslListRequest(val?.value, 0, proSettings?.allStudentAccess, (classListCB) => {
          result = { classList: classListCB };
        })
      );
    }
    cleanSelect();
    return result;
  }

  async function onTeacherSelelcted(val: OptionType, schoolOption: OptionType | null = null): Promise<OptionType[]> {
    let result;

    if (!schoolOption) {
      onUpdateAudience(schoolSelected.value, val.value, 0);
    }
    setTeacherSelected(val);
    dispatch(
      sendGetClasslListRequest(schoolOption ? schoolOption.value : schoolSelected?.value, val?.value, true, (classListCB) => {
        result = classListCB;
      })
    );
    setClassSelected(defaultValue);

    return result;
  }

  function nextPressed() {
    const param: SurveyAudience = {
      surveyWizardScheduleID: curSurveySchedule?.surveyWizardScheduleID || 0,
      surveyWizardAudienceTypeID: SURVEY_AUDIENCE_STUDENTS,
      companyID: schoolSelected?.value || 0,
      teacherID: teacherSelected?.value || 0,
      classID: classSelected?.value || 0,
      departmentIDs: [],
      groupIDs: []
    };
    const startDateDiffFromNow = getDateDifferenceInDaysWithTime(new Date(), new Date(curSurveySchedule?.startDateTime || ""));

    if (curSurveyAudience) {
      dispatch(
        sendPutAudienceRequest(param, (scheduledSurvey) => {
          if (scheduledSurvey) {
            if (startDateDiffFromNow <= 0) {
              onSurveyActive();
            } else {
              onSurveyScheduled();
            }
          }
        })
      );
    } else {
      dispatch(
        sendPostAudienceRequest(param, (scheduledSurvey) => {
          if (scheduledSurvey) {
            if (startDateDiffFromNow <= 0) {
              onSurveyActive();
            } else {
              onSurveyScheduled();
            }
          }
        })
      );
    }
  }

  useEffect(() => {
    toggleNextBtn(nextPressed);
  }, [toggleNextBtn]);

  useEffect(() => {
    if (proSettings) {
      dispatch(sendGetSchoolListRequest(proSettings?.allStudentAccess));
    }
  }, [proSettings]);

  useEffect(() => {
    // This loads selection of Schools, Teachers, Classes for the existing SurveyAudience
    const updateSchoolSel = async () => {
      if (schoolList.length > 0 && curSurveyAudience && curSurveyAudience.companyID !== 0) {
        setIsLoading(true);
        const selSchoolOpt = schoolList.find((s) => {
          return s.value === curSurveyAudience.companyID;
        });
        if (selSchoolOpt) {
          setSchoolSelected(selSchoolOpt);
          if (proSettings?.allStudentAccess) {
            const { teacherList: tList } = await onSchoolSelected(selSchoolOpt);
            if (curSurveyAudience.teacherID !== 0 && tList) {
              const selTeacherOpt = tList.find((t) => {
                return t.value === curSurveyAudience.teacherID;
              });
              if (selTeacherOpt) {
                setTeacherSelected(selTeacherOpt);
                const cList = await onTeacherSelelcted(selTeacherOpt, selSchoolOpt);
                if (curSurveyAudience.classID !== 0 && cList) {
                  const selClassOpt = cList.find((c) => {
                    return c.value === curSurveyAudience.classID;
                  });
                  if (selClassOpt) {
                    setClassSelected(selClassOpt);
                  }
                }
              }
            }
          } else if (curSurveyAudience.classID !== 0) {
            const { classList: cList } = await onSchoolSelected(selSchoolOpt);
            const selClassOpt = cList.find((c) => {
              return c.value === curSurveyAudience.classID;
            });
            if (selClassOpt) {
              setClassSelected(selClassOpt);
            }
          }
        }
      }
      setIsLoading(false);
    };

    updateSchoolSel();
  }, [schoolList]);

  return (
    <>
      <div className="input-spacing">
        <SurveyFormSelectBox
          className="select-audience"
          options={schoolList}
          value={schoolSelected}
          selectOption={(val) => {
            onSchoolSelected(val);
          }}
          label="Site"
          hideActionButtons
        />
      </div>
      {proSettings?.allStudentAccess && (
        <div className="input-spacing">
          <SurveyFormSelectBox
            className="select-audience"
            options={teacherList}
            value={teacherSelected}
            selectOption={(val) => {
              onTeacherSelelcted(val);
            }}
            label="Teachers"
            hideActionButtons
          />
        </div>
      )}

      <div className="input-spacing">
        <SurveyFormSelectBox
          className="select-audience"
          options={classList}
          label="Class"
          value={classSelected}
          selectOption={(val) => {
            onUpdateAudience(schoolSelected.value, teacherSelected.value, val.value);
            setClassSelected(val);
          }}
          hideActionButtons
        />
      </div>
      <SeityAlert
        visible={!!errorMessage}
        onToggle={() => {
          setErrorMessage("");
        }}
        title="Error Message"
        classNameOverlay="survey-modal-error"
        subTitle={errorMessage}
      />
      {isLoading && <SeityLoader showBackgroundMask={false} />}
    </>
  );
};

export default SelectStudents;
