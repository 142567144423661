import {useEffect, useState} from "react";
import apiClientWithAuth from "src/api/apiClient";
import {
    GetAutoGeneratedPasswordResponse,
    ResetPasswordAccountsResponse,
    ResetPasswordAccountsResponseItem, ResetPasswordCompaniesResponse,
    ResetPasswordCompaniesResponseItem, SendTemporaryPasswordResponse
} from "../../api/resetPassword/resetPasswordTypes";

export const useResetPasswordData = () => {

    const [isLoading, setLoading] = useState(false);
    const [autoGeneratedPassword, setAutoGeneratedPassword] = useState('');
    const [resetPasswordRows, setResetPasswordRows] = useState<Array<ResetPasswordAccountsResponseItem>>();
    const [resetPasswordUserGroups, setResetPasswordUserGroups] = useState<Array<ResetPasswordCompaniesResponseItem>>([]);

    const updateLastPasswordChangeDate = (accountID: number, date: string) => {

        setResetPasswordRows(prevRows => {
            if (!prevRows) return [];
            return prevRows.map(item => {
                if (item.accountID === accountID) {
                    return {...item, lastPasswordChangeDate: date};
                }
                return item;
            });
        });
    };

    const fetchData = async (email: string = '', firstName: string = '', lastName: string = '', companyIds: Array<number> = [], studentID: string | null = null) => {

        setLoading(true);

        const res = await apiClientWithAuth.post<ResetPasswordAccountsResponse>("/ResetPassword/Accounts", {
            Email: email,
            FirstName: firstName,
            LastName: lastName,
            CompanyIds: companyIds,
            StudentID: studentID
        });

        if (res.data.success) {
            setResetPasswordRows(res.data.data.resetPasswordData);
        }
        setLoading(false);
    };

    const sendAutoGeneratePasswordRequest = async () => {

        setLoading(true);
        const res = await apiClientWithAuth.get<GetAutoGeneratedPasswordResponse>("/ResetPassword/AutoGeneratePassword");

        if (res.data.success) {
            setAutoGeneratedPassword(res.data.data.tempPassword);
        }

        setLoading(false);
    };

    const sendTemporaryPasswordRequest = async (accountID: number) => {
        const res = await apiClientWithAuth.post<SendTemporaryPasswordResponse>("/ResetPassword/SendTemporaryPassword", {
            accountID: accountID
        });

        if (res.data.success) {
            updateLastPasswordChangeDate(accountID, res.data.data.lastPasswordChangeDate);
        }
    };

    const sendManualResetPasswordRequest = async (accountID: number, password: string, autoGenerated: boolean = false) => {

        const res = await apiClientWithAuth.post<SendTemporaryPasswordResponse>("/ResetPassword/ManualResetPassword", {
            accountID: accountID,
            password: password,
            autoGenerated: autoGenerated
        });

        if (res.data.success) {
            updateLastPasswordChangeDate(accountID, res.data.data.lastPasswordChangeDate);
        }
    };

    const fetchUserGroupFilters = async () => {
        const res = await apiClientWithAuth.get<ResetPasswordCompaniesResponse>("/ResetPassword/Companies", {});
        if (res.data.success) {
            setResetPasswordUserGroups(res.data.data.resetPasswordDataUserGroups);
        }
    };

    useEffect(() => {
        fetchData();
        fetchUserGroupFilters();
    }, []);

    return {
        fetchData,
        isLoading,
        resetPasswordRows,
        resetPasswordUserGroups,
        sendAutoGeneratePasswordRequest,
        autoGeneratedPassword,
        setAutoGeneratedPassword,
        sendTemporaryPasswordRequest,
        sendManualResetPasswordRequest,
        updateLastPasswordChangeDate
    };
};
