/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { RootState } from "src/reducers";
import {
  sendGetAccountListsRequest,
  sendGetAccountInfoRequest,
  sendAccountMaintenanceUpdateRequest,
  clearAccountError,
  setAccountUpdateSuccessful
} from "src/reducers/auth/accountSlice";
import { SeityAccountListsItemResponse, SeityAccountListType } from "src/api/account/types/maintenanceTypes";

import { SeityFormSelectBox } from "src/components/analytic/SeitySelectBox/SeityFormSelectBox";
import { SeityPrimaryCancelButtons } from "src/components/analytic/SeityNavigationButtons";
import strings from "src/_core/strings/strings";
import "./maintenance.scss";
import { SeityAppContext } from "src/app/SeityAppContext";

function MaintenanceThree() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { token } = useSelector((state: RootState) => {
    return state.auth;
  });

  const { accountLists, accountInfo, accountError, isAccountUpdateSuccessful } = useSelector((state: RootState) => {
    return state.account;
  });

  useEffect(() => {
    dispatch(clearAccountError());
    dispatch(sendGetAccountInfoRequest());
    dispatch(sendGetAccountListsRequest(true, true, true, true, true, true));
  }, []);

  useEffect(() => {
    const language = window.localStorage.getItem("language") || strings.getLanguage();
    appContext.setLanguage(language);
  }, []);

  useEffect(() => {
    setWorkStatusID(accountInfo?.statusID ?? 0);
    setEducationID(accountInfo?.educationID ?? 0);
    setOccupationID(accountInfo?.occupationID ?? 0);
  }, [accountInfo]);

  const statusList: SeityAccountListsItemResponse[] = [];
  const educationList: SeityAccountListsItemResponse[] = [];
  const occupationList: SeityAccountListsItemResponse[] = [];
  const languageList: Array<string> = ["en-US", "es"];

  statusList.push({ description: "None", listID: -1, accountListsType: SeityAccountListType.STATUS });
  educationList.push({ description: "None", listID: -1, accountListsType: SeityAccountListType.EDUCATION });
  occupationList.push({ description: "None", listID: -1, accountListsType: SeityAccountListType.OCCUPATION });

  accountLists?.forEach((list) => {
    switch (list.accountListsType) {
      case SeityAccountListType.STATUS:
        statusList.push(list);
        break;
      case SeityAccountListType.EDUCATION:
        educationList.push(list);
        break;
      case SeityAccountListType.OCCUPATION:
        occupationList.push(list);
        break;
      default:
        break;
    }
  });

  statusList.sort(function (a, b) {
    return a.listID - b.listID;
  });
  educationList.sort(function (a, b) {
    return a.listID - b.listID;
  });
  occupationList.sort(function (a, b) {
    return a.listID - b.listID;
  });

  const [statusID, setWorkStatusID] = useState(accountInfo?.statusID ?? 0);
  const [educationID, setEducationID] = useState(accountInfo?.educationID ?? 0);
  const [occupationID, setOccupationID] = useState(accountInfo?.occupationID ?? 0);
  const appContext = useContext(SeityAppContext);
  const [language, setLanguage] = useState<string>("en-US");

  const onLanguageChanged = (language: string) => {
    strings.setLanguage(language);
    appContext.setLanguage(language);
    window.localStorage.setItem("language", language);
  };
  const updateAccountInfo = () => {
    dispatch(
      sendAccountMaintenanceUpdateRequest({
        firstName: null,
        lastName: null,
        eMailAddress: null,
        accountStatusID: null,
        dateOfBirth: null,
        statusID,
        educationID,
        occupationID,
        genderID: null,
        maritalStatusID: null,
        zipcode: null,
        cellPhone: null
      })
    );
  };

  if (isAccountUpdateSuccessful) {
    dispatch(setAccountUpdateSuccessful({ accountUpdateSuccessful: false }));
    history.push("/profile");
  }

  const cancelButtonHandler = () => {
    history.push("/profile");
  };

  const disabledHandler = () => {
    if (statusID !== -1 && educationID !== -1 && occupationID !== -1) {
      return false;
    }
    return true;
  };

  if (accountLists) {
    return (
      <div className="maintenance-container">
        <h1>{strings.edit}</h1>
        <div className="maintenance-content-wrap">
          <SeityFormSelectBox
            isInvalid={statusID === -1}
            value={statusID !== -1 ? statusList[statusID].description : "None"}
            inputLabelText={strings.workEdStatusLabel}
            onChange={(e) => {
              return setWorkStatusID(parseInt(e.target.value));
            }}
          >
            <ul>
              {statusList.map((item) => {
                return (
                  <li key={item.listID} value={item.listID}>
                    {item.description}
                  </li>
                );
              })}
            </ul>
          </SeityFormSelectBox>
          <SeityFormSelectBox
            isInvalid={educationID === -1}
            inputLabelText={strings.educationLabel}
            value={educationID !== -1 ? educationList[educationID].description : "None"}
            onChange={(e) => {
              return setEducationID(parseInt(e.target.value));
            }}
          >
            <ul>
              {educationList.map((item) => {
                return (
                  <li key={item.listID} value={item.listID}>
                    {item.description}
                  </li>
                );
              })}
            </ul>
          </SeityFormSelectBox>
          <SeityFormSelectBox
            isInvalid={occupationID === -1}
            value={occupationID !== -1 ? occupationList[occupationID].description : "None"}
            inputLabelText={strings.occupationLabel}
            onChange={(e) => {
              return setOccupationID(parseInt(e.target.value));
            }}
          >
            <ul>
              {occupationList.map((item) => {
                return (
                  <li key={item.listID} value={item.listID}>
                    {item.description}
                  </li>
                );
              })}
            </ul>
          </SeityFormSelectBox>
          <SeityFormSelectBox
            value={appContext.language}
            inputLabelText={strings.language}
            inputStyle={{ marginBottom: "-20px" }}
            onChange={(e) => {
              return onLanguageChanged(e.target.textContent || "");
            }}
          >
            <ul>
              {languageList.map((item) => {
                return (
                  <li key={item} value={item}>
                    {item}
                  </li>
                );
              })}
            </ul>
          </SeityFormSelectBox>
        </div>
        <div>
          <SeityPrimaryCancelButtons
            cancelAction={cancelButtonHandler}
            cancelTitle={strings.cancelButtonText}
            primaryAction={updateAccountInfo}
            primaryTitle={strings.saveButtonText}
            primaryDisabled={disabledHandler()}
            errorMessage={accountError}
            showCancel
          />
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}

export default MaintenanceThree;
