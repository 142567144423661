import React, { useState } from "react";
import SurveySection from "src/components/survey/SurveySection";
import SurveyTextArea from "src/components/survey/SurveyTextArea";
import SurveyFormSelectBox from "src/components/survey/SurveySelectBox/SurveyFormSelectBox";
import { SurveyFormTextInput } from "src/components/survey/SurveyTextInput/SurveyFormTextInput";
import SurveyRadioButtonGroup from "src/components/survey/SurveyRadioButton/SurveyRadioButtonGroup";

import "./styles.scss";
import SurveyButton from "src/components/survey/SurveyButton";

const SortByOpts = [
  { label: "Date Created", value: 1 },
  { label: "Date Modified", value: 2 }
];

export const Examples = () => {
  const [value, setValue] = useState("");
  const [valueSelected, setValueSelected] = useState<number | string>(1);
  const [, setOpen] = useState(false);

  return (
    <div
      style={{
        width: "100%",
        margin: "rem(20)"
      }}
    >
      <SurveySection>
        <SurveyTextArea
          label="Survey Description"
          onChange={(e) => {
            return setValue(e.target.value);
          }}
          value={value}
        />
      </SurveySection>
      <SurveySection className="section-example">
        <SurveyButton
          label="Open Modal"
          onClick={() => {
            return setOpen(true);
          }}
        />
      </SurveySection>

      <SurveySection className="section-example">
        <SurveyFormSelectBox
          options={SortByOpts}
          selectOption={(val) => {
            console.log(val);
          }}
          label="Select Question Type"
        />
      </SurveySection>

      <SurveySection className="section-example">
        <SurveyFormTextInput label="Question" placeholder="Placeholder" />

        <SurveyRadioButtonGroup
          type="Yes or No"
          selected={valueSelected}
          onChange={(val) => {
            setValueSelected(val);
            console.log(val);
            console.log("testing");
          }}
        />
      </SurveySection>
      {/* <SurveyScheduler isOpen={open} onClose={() => { return setOpen(false); }} /> */}
    </div>
  );
};

export default Examples;
