import axios from "axios";
import { BASE_URL } from "../apiConstants";
import { ReportResponse } from "./staffReportsTypes";

const SECTION = "/CompanyReports/";

export async function getUserGroupListReport(token: string): Promise<ReportResponse> {
  const url = BASE_URL + SECTION + "SeityGroupReportPdfStream";
  try {
    const response = await axios.get<ReportResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}

export async function getUserAccountListReport(token: string): Promise<ReportResponse> {
  const url = BASE_URL + SECTION + "SeityUserAccountsPdfStream";
  try {
    const response = await axios.get<ReportResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}

export async function getChallengeStatusReport(token: string): Promise<ReportResponse> {
  const url = BASE_URL + SECTION + "ChallengeStatusPdfStream";
  try {
    const response = await axios.get<ReportResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}

export async function getSeityProAccessReport(token: string): Promise<ReportResponse> {
  const url = BASE_URL + SECTION + "SeityProAccessPdfStream";
  try {
    const response = await axios.get<ReportResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}
