import CoreStrings from "src/_core/strings/strings";
import { WbnTooltipTemplate } from "src/api/wbn/wbnTypes";
import greenFlag from "src/assets/images/web-icons/green-flag.png";
import redFlag from "src/assets/images/web-icons/red-flag.png";
import sadFace from "src/assets/images/web-icons/sad_face.png";
import happyFace from "src/assets/images/web-icons/happy_face.png";
import noFace from "src/assets/images/web-icons/no_face.png";
import neutralFace from "src/assets/images/web-icons/neutral_face.png";
import check from "src/assets/images/web-icons/check.png";

const TOOLTIP_DATA: any = {
  noWbcAfter35Drop: {
    type: "basic",
    message: CoreStrings.noWbcAfter35Drop
  },
  scoreLowAfterDrop: {
    type: "basic",
    message: CoreStrings.scoreLowAfterDrop
  },
  scoreLow: {
    type: "basic",
    message: CoreStrings.scoreLow
  },
  scoreDrop35PctAfter30d: {
    type: "basic",
    message: CoreStrings.scoreDrop35PctAfter30d
  },
  scoreUpFromAvg: {
    type: "basic",
    message: CoreStrings.scoreUpFromAvg
  },
  scoreDrop35PctWithin30d: {
    type: "basic",
    message: CoreStrings.scoreDrop35PctWithin30d
  },
  scoreDropFromAvg: {
    type: "basic",
    message: CoreStrings.scoreDropFromAvg
  },
  outreachReason: {
    type: "component",
    title: CoreStrings.emailed,
    content: [
      {
        text: CoreStrings.outreachRedFlag,
        iconSize: 24,
        icon: redFlag,
        align: "flex-start"
      },
      {
        text: CoreStrings.outreachGreenFlag,
        iconSize: 24,
        icon: greenFlag,
        align: "flex-start"
      },
      {
        text: CoreStrings.emailedDesc,
        align: "center",
        textSize: 14,
        color: "#677280"
      }
    ]
  },
  attentionCode: {
    type: "component",
    title: CoreStrings.wellbeing,
    content: [
      {
        text: CoreStrings.wbScoreLow,
        iconSize: 32,
        icon: sadFace,
        align: "flex-start"
      },
      {
        text: CoreStrings.wbScoreMed,
        iconSize: 32,
        icon: neutralFace,
        align: "flex-start"
      },
      {
        text: CoreStrings.wbScoreHigh,
        iconSize: 32,
        icon: happyFace,
        align: "flex-start"
      },
      {
        text: CoreStrings.noCheckin3PlusDays,
        iconSize: 32,
        icon: noFace,
        align: "flex-start"
      }
    ]
  },
  emailSent: {
    type: "component",
    title: CoreStrings.emailSent,
    content: [
      {
        text: CoreStrings.emailSentLong,
        iconSize: 24,
        icon: check,
        align: "flex-start"
      }
    ]
  }
};

export function getTooltipFromID(id: string): WbnTooltipTemplate {
  return TOOLTIP_DATA[id];
}
