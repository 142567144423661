import React, { FunctionComponent } from "react";

import SurveyModal from "src/components/survey/SurveyModal";
import SurveyButton from "src/components/survey/SurveyButton";

import "./styles.scss";

export type SurveySavedModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const SurveySavedModal: FunctionComponent<SurveySavedModalProps> = ({ isOpen = false, onClose }) => {
  return (
    <SurveyModal isOpen={isOpen} onClose={onClose}>
      <div className="survey-saved-modal">
        <h1>Your Survey Has Been Saved!</h1>
        <img src={require("../../assets/images/graphics/survey-saved-modal.png")} alt="survey saved art" />
        <SurveyButton label="Close" onClick={onClose} />
      </div>
    </SurveyModal>
  );
};

export default SurveySavedModal;
