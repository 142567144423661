import axios from "axios";
import { BASE_URL } from "../apiConstants";
import {
  ClassListResponse,
  SchoolListResponse,
  TeacherClassListResponse,
  TeacherListResponse,
  TeacherSchoolListResponse
} from "./schoolTypes";

const SECTION = "/SchoolNav/";

export async function getSchoolList(token: string): Promise<SchoolListResponse> {
  const url = BASE_URL + SECTION + "SchoolList";
  try {
    const schoolListResponse = await axios.get<SchoolListResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return schoolListResponse.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}

export async function getTeacherSchoolList(token: string): Promise<TeacherSchoolListResponse> {
  const url = BASE_URL + SECTION + "TeacherSchoolList";
  try {
    const schoolListResponse = await axios.get<TeacherSchoolListResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return schoolListResponse.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}

export async function getTeacherList(companyID: number, token: string): Promise<TeacherListResponse> {
  const url = BASE_URL + SECTION + "TeacherList?CompanyID=" + companyID;
  try {
    const schoolListResponse = await axios.get<TeacherListResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return schoolListResponse.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}

export async function getClassList(companyID: number, accountID: number, token: string): Promise<ClassListResponse> {
  const url = BASE_URL + SECTION + `ClassList`;
  try {
    const schoolListResponse = await axios.get<ClassListResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
        CompanyID: companyID,
        AccountID: accountID
      }
    });
    return schoolListResponse.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}

export async function getTeacherClassList(companyID: number, token: string): Promise<TeacherClassListResponse> {
  const url = BASE_URL + SECTION + `TeacherClassList`;
  try {
    const schoolListResponse = await axios.get<TeacherClassListResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
        CompanyID: companyID
      }
    });
    return schoolListResponse.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}
