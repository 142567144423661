import axios from "axios";
import { BASE_URL } from "../apiConstants";
import { PracticeScoresResponse, OutcomeScoresResponse, SeityDashboardResponse, ProSettingsResponse } from "./types/accountTypes";
import {
  SeityAccountListsResponse,
  SeityAccountStatusListResponse,
  SeityFullAccountUpdateResponse,
  SeityGetAccountInformationResponse,
  SeityAccountMaintenancePayloadRequest
} from "./types/maintenanceTypes";
import { SeityResponse } from "../baseTypes";
import { SeityAccountMessageCenterListResponse } from "./types/messageCenterListTypes";

const SECTION = "/Account/";
const AUTH_SECTION = "/Auth/";
const PRO_SECTION = "/SeityPro/";

export async function getDashboardData(token: string): Promise<SeityDashboardResponse> {
  const url = `${BASE_URL + SECTION}Dashboard`;
  // eslint-disable-next-line no-useless-catch
  try {
    const dashboardResponse = await axios.get<SeityDashboardResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return dashboardResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function getAccountStatusList(token: string): Promise<SeityAccountStatusListResponse> {
  const url = `${BASE_URL + SECTION}AccountStatusList`;
  // eslint-disable-next-line no-useless-catch
  try {
    const accountStatusResponse = await axios.get<SeityAccountStatusListResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return accountStatusResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function getMessageCenterList(token: string): Promise<SeityAccountMessageCenterListResponse> {
  const url = BASE_URL + SECTION + "MessageCenterList";
  try {
    const msgCenterListResponse = await axios.post<SeityAccountMessageCenterListResponse>(
      url,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return msgCenterListResponse.data;
  } catch (err) {
    throw err;
  }
}
// Strings and numbers should be a value other
// than "" and -1 otherwise the API will ignore
// them
export async function sendFullAccountUpdate(
  token: string,
  accountStatusId?: number,
  dateOfBirth?: string,
  statusId?: number,
  educationId?: number,
  occupationId?: number,
  genderId?: number,
  maritalStatusId?: number,
  zipCode?: string,
  cellPhone?: string
): Promise<SeityFullAccountUpdateResponse> {
  const url = BASE_URL + SECTION + "AccountUpdate01";
  try {
    const updateResponse = await axios.post<SeityFullAccountUpdateResponse>(
      url,
      {
        accountStatusId,
        dateOfBirth,
        statusId,
        educationId,
        occupationId,
        genderId,
        maritalStatusId,
        zipCode,
        cellPhone
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return updateResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function sendChangePasswordUpdate(token: string, oldPassword: string, newPassword: string): Promise<SeityResponse> {
  const url = BASE_URL + AUTH_SECTION + "Security";
  try {
    const updatePasswordResponse = await axios.post<SeityResponse>(
      url,
      {
        oldPassword,
        newPassword
      },
      {
        headers: {
          Authorization: "Bearer " + token
        }
      }
    );
    return updatePasswordResponse.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}

export async function getAccountInfo(token: string): Promise<SeityGetAccountInformationResponse> {
  const url = BASE_URL + SECTION + "Information";
  try {
    const accountResponse = await axios.get<SeityGetAccountInformationResponse>(url, {
      headers: {
        Authorization: "Bearer " + token
      }
    });
    return accountResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function getAccountLists(
  token: string,
  accountStatus: boolean,
  status: boolean,
  education: boolean,
  occupation: boolean,
  gender: boolean,
  maritalStatus: boolean
): Promise<SeityAccountListsResponse> {
  const url = BASE_URL + SECTION + "AccountLists";
  try {
    const listResponse = await axios.post<SeityAccountListsResponse>(
      url,
      {
        accountStatus,
        status,
        education,
        occupation,
        gender,
        maritalStatus
      },
      {
        headers: {
          Authorization: "Bearer " + token
        }
      }
    );
    return listResponse.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}

export async function sendAccountMaintenanceUpdate(
  token: string,
  maintenanceData: SeityAccountMaintenancePayloadRequest
): Promise<SeityResponse> {
  const url = BASE_URL + SECTION + "AccountMaintenance";
  try {
    const listResponse = await axios.put<SeityAccountListsResponse>(url, maintenanceData, {
      headers: {
        Authorization: "Bearer " + token
      }
    });
    return listResponse.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}

export async function getOutcomeScores(token: string): Promise<OutcomeScoresResponse> {
  const url = BASE_URL + SECTION + "OutcomeScores";
  try {
    const outcomeScoresResponse = await axios.get<OutcomeScoresResponse>(url, {
      headers: {
        Authorization: "Bearer " + token
      }
    });

    return outcomeScoresResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function getPracticeScores(token: string): Promise<PracticeScoresResponse> {
  const url = BASE_URL + SECTION + "PracticeScores";
  try {
    const practiceScoresResponse = await axios.get<PracticeScoresResponse>(url, {
      headers: {
        Authorization: "Bearer " + token
      }
    });

    return practiceScoresResponse.data;
  } catch (err) {
    throw err;
  }
}

export async function getProSettings(token: string): Promise<ProSettingsResponse> {
  const url = BASE_URL + "/Account/Settings";
  try {
    const response = await axios.get<ProSettingsResponse>(url, {
      headers: {
        Authorization: "Bearer " + token
      }
    });

    return response.data;
  } catch (err) {
    throw err;
  }
}
