import axios from "axios";
import { BASE_URL } from "../apiConstants";
import {
  AtsAllOutcomeSubcategoriesResponse,
  AtsCoreValuesResponse,
  AtsOutcomeCategoriesResponse,
  AtsOutcomeSubcategoriesResponse,
  AtsWellBeingCheckInCategoriesResponse
} from "./atsTypes";

const SECTION = "/ATS/";

export async function getAtsCoreValues(token: string): Promise<AtsCoreValuesResponse> {
  const url = BASE_URL + SECTION + "CoreValues";
  try {
    const response = await axios.get<AtsCoreValuesResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}

export async function getAtsOutcomeCategories(token: string): Promise<AtsOutcomeCategoriesResponse> {
  const url = BASE_URL + SECTION + "OutcomeCategories";
  try {
    const response = await axios.get<AtsOutcomeCategoriesResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}

export async function getAtsOutcomeSubcategories(outcomeCategoryID: number, token: string): Promise<AtsOutcomeSubcategoriesResponse> {
  const url = BASE_URL + SECTION + "OutcomeSubcategories?outcomeCategoryID=" + outcomeCategoryID;
  try {
    const response = await axios.get<AtsOutcomeSubcategoriesResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}

export async function getAtsAllOutcomeSubcategories(token: string): Promise<AtsAllOutcomeSubcategoriesResponse> {
  const url = BASE_URL + SECTION + "AllOutcomeSubcategories";
  try {
    const response = await axios.get<AtsAllOutcomeSubcategoriesResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}

export async function getAtsWellBeingCheckInCategories(token: string): Promise<AtsWellBeingCheckInCategoriesResponse> {
  const url = BASE_URL + SECTION + "WellBeingCheckInCategories";
  try {
    const response = await axios.get<AtsWellBeingCheckInCategoriesResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}

export async function getAtsDownloadCoreValueIcons(token: string): Promise<void> {
  const url = BASE_URL + SECTION + "DownloadCoreValueIcons";
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}
