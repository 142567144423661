import React, { useState, useEffect } from "react";

import { models, Report, Embed, service, VisualDescriptor } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";

import { PbiFilter } from "src/features/ReportHome/types";
import { compareFilter } from "src/utils/helper";
import { SeityLoader } from "../../SeityLoader";

import "./styles.scss";

// eslint-disable-next-line max-len
const EMBED_URL_WEB =
  // eslint-disable-next-line max-len
  "https://app.powerbi.com/reportEmbed?reportId=c61d8a43-76e3-4172-a9d5-a8ce43821793&groupId=743945f6-e40d-4374-93ac-5d7323b210f2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtVVMtQi1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwiYW5ndWxhck9ubHlSZXBvcnRFbWJlZCI6dHJ1ZSwiY2VydGlmaWVkVGVsZW1ldHJ5RW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d%22";
// eslint-disable-next-line max-len
const EMBED_URL_MOBILE =
  // eslint-disable-next-line max-len
  "https://app.powerbi.com/reportEmbed?reportId=c61d8a43-76e3-4172-a9d5-a8ce43821793&groupId=743945f6-e40d-4374-93ac-5d7323b210f2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtVVMtQi1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwiYW5ndWxhck9ubHlSZXBvcnRFbWJlZCI6dHJ1ZSwiY2VydGlmaWVkVGVsZW1ldHJ5RW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d%22";

// Survey Page Name (Single/Multi)
const PAGE_NAME = {
  Single: "ReportSectionf0f4707b9190cd1275e0",
  Multi: "ReportSectiona3fa92add00c3b833e3b"
};

interface SurveyPbiReportProps {
  reportType: "Single" | "Multi";
  embedToken: string;
  filterVal: PbiFilter | null;
  tokenExpiredHandler: () => void | undefined;
  filterValsHandler: (_: VisualDescriptor) => void;
}

const SurveyPbiReport = ({
  reportType = "Single",
  embedToken = "",
  filterVal = null,
  tokenExpiredHandler,
  filterValsHandler
}: SurveyPbiReportProps) => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  let slicer;
  let filterValues: VisualDescriptor;

  const [embedReport, setEmbedReport] = useState<Report | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState<PbiFilter | null>(null);

  let gReport: Report;

  async function removeFilter() {
    console.log("Removing filter");
    const eReport = embedReport || gReport;
    try {
      if (eReport) {
        await eReport.updateFilters(models.FiltersOperations.RemoveAll);
        await eReport.refresh();
        console.log("Report filters were removed.");
      }
    } catch (errors) {
      console.log("applyFilter error:", errors);
    }
  }

  async function applyFilter(tfilter) {
    // Retrieve the page collection and get the visuals for the active page.
    const eReport = embedReport || gReport;
    try {
      if (eReport) {
        await eReport.updateFilters(models.FiltersOperations.RemoveAll);
        console.log("Report filters were removed.");
        await eReport.updateFilters(models.FiltersOperations.Add, [tfilter]);
      }
    } catch (errors) {
      console.log("applyFilter error:", errors);
    }
  }

  const applyClassIdn = async () => {
    let columnStr = "";
    let tableStr = "Class01";
    if (filterVal) {
      // eslint-disable-next-line no-nested-ternary
      columnStr = filterVal?.type === "Class" ? "Class01ID" : filterVal?.type === "Teacher" ? "StaffID" : "CompanyID";
      tableStr = "Class01"; // Table name to be applied
    } else {
      return;
    }

    const classFilter = {
      $schema: "http://powerbi.com/product/schema#basic",
      target: {
        table: tableStr,
        column: columnStr
      },
      operator: "In",
      values: filterVal?.value,
      filterType: models.FilterType.Basic
    };
    try {
      applyFilter(classFilter);
    } catch (error) {
      console.log("applyFilter error:", error);
    }
  };

  const [sampleReportConfig, setReportConfig] = useState<models.IReportEmbedConfiguration>({
    type: "report",
    embedUrl: width <= 768 ? EMBED_URL_MOBILE : EMBED_URL_WEB,
    tokenType: models.TokenType.Embed,
    accessToken: embedToken,
    settings: {
      panes: {
        filters: {
          expanded: false,
          visible: false
        }
      },
      navContentPaneEnabled: false,
      background: models.BackgroundType.Transparent,
      layoutType: models.LayoutType.Custom,
      customLayout: {
        displayOption: models.DisplayOption.FitToPage
      }
    }
  });

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    setFilter(filterVal);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    setReportConfig({
      ...sampleReportConfig,
      embedUrl: width <= 1152 ? EMBED_URL_MOBILE : EMBED_URL_WEB
    });
  }, [width]);

  useEffect(() => {
    setReportConfig({
      ...sampleReportConfig,
      accessToken: embedToken
    });
  }, [embedToken]);

  useEffect(() => {
    if (!compareFilter(filter, filterVal)) {
      setFilter(filterVal);
      if (filterVal) {
        applyClassIdn();
      } else {
        try {
          removeFilter();
        } catch (error) {
          console.log("removeFilter error:", error);
        }
      }
    }
  }, [filterVal]);

  // Map of event handlers to be applied to the embedding report
  const eventHandlersMap = new Map([
    // eslint-disable-next-line func-names
    [
      "loaded",
      async function () {
        if (gReport) {
          gReport.setPage(PAGE_NAME[reportType]);
          // gReport.bookmarksManager.apply('Bookmark728025e0e0aec5cf051a');
          if (filterVal) {
            applyClassIdn();
          } else {
            removeFilter();
          }
        }
      }
    ],
    // eslint-disable-next-line func-names
    [
      "rendered",
      async function () {
        console.log("Report has rendered");

        if (slicer) {
          const state = await slicer?.getSlicerState();
          console.log("getSlicerState:", state);
          filterValues = state.filters[0].values;
          filterValsHandler(filterValues);
        }
        setIsLoading(false);
      }
    ],
    // eslint-disable-next-line func-names
    [
      "error",
      function (event?: service.ICustomEvent<any>) {
        if (event) {
          if (event.type === "error" && event.detail.message === "TokenExpired") {
            console.log("Token Expired!");
            tokenExpiredHandler();
          }
        }
        setIsLoading(false);
      }
    ]
  ]);

  return (
    <>
      {embedToken ? (
        <>
          <PowerBIEmbed
            embedConfig={sampleReportConfig}
            eventHandlers={eventHandlersMap}
            cssClassName={`survey-report-style ${isLoading ? "hide-report" : ""}`}
            getEmbeddedComponent={(embedObject: Embed) => {
              console.log(`Embedded object of type "${embedObject.embedtype}" received`);
              setEmbedReport(embedObject as Report);
              gReport = embedObject as Report;
            }}
          />
          {isLoading && <SeityLoader showBackgroundMask />}
        </>
      ) : null}
    </>
  );
};

export default SurveyPbiReport;
