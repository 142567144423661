import { FunctionComponent, ReactElement, useState } from "react";

import "./styles.scss";
import { WbnFilterOption } from "src/api/wbn/wbnTypes";

const close = require("src/assets/images/web-icons/close.svg").default;
const carrotUp = require("src/assets/wbn/caret-up.png");
const carrotDown = require("src/assets/wbn/caret-down.png");

type WbnFilterChipProps = {
  label: string;
  className?: string;
  count?: number;
  onClose: () => void;
  style?: object;
};

export const WbnFilterChip: FunctionComponent<WbnFilterChipProps> = (props): ReactElement => {
  const { label, className, onClose, count, style: propStyle = {} } = props;

  return (
    <div className={`wbn-filter-chip ${className || ""}`} style={{ ...propStyle }}>
      <p>
        {label}
        {count && <span>{count}</span>}
      </p>
      <img
        src={close}
        alt="close"
        onClick={() => {
          onClose();
        }}
      />
    </div>
  );
};

type WbnFilterOptionsProps = {
  data: WbnFilterOption[];
  onRemove?: (item: any) => void;
};

const cutOffCounter = 6;

export const WbnFilterOptions: FunctionComponent<WbnFilterOptionsProps> = (props): ReactElement => {
  const { data: options, onRemove } = props;
  const [showAll, setShowAll] = useState(false);

  let showingFilters = options;
  if (options?.length > cutOffCounter && !showAll) {
    showingFilters = options.slice(0, cutOffCounter);
  }

  return (
    <div className="wbn-filter-options">
      {showingFilters.map((item, index) => {
        return (
          <WbnFilterChip
            key={index}
            label={`${item.name}`}
            count={item.count}
            onClose={() => {
              if (onRemove) {
                onRemove(item);
              }
            }}
          />
        );
      })}
      {options?.length > cutOffCounter && (
        <div
          className="wbn-filter-options__show-all"
          onClick={() => {
            setShowAll(!showAll);
          }}
        >
          <p>Show All</p>
          <img src={showAll ? carrotUp : carrotDown} alt="carrot" />
        </div>
      )}
    </div>
  );
};

export default WbnFilterOptions;
