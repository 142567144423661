import { FunctionComponent, ReactElement, useState } from "react";
import "./styles.scss";
import SeityModal from "src/components/SeityModal";
import { getModalContent } from "./data";

export type WbnModalProps = {
  position?: { top: number; left: number };
  isOpen: boolean;
  title?: string;
  type: "SaveView" | "DeleteView" | "DupView" | "LeaveView";
  name: string;
  onClose: () => void;
  onClickActions: (idx: number, viewName?: string) => void;
  wrapperClassName?: string;
};

export const INPUT_MAX_LEN = 100;

export const WbnModal: FunctionComponent<WbnModalProps> = ({
  position,
  isOpen = false,
  title = "Save View",
  type,
  name,
  onClose,
  onClickActions
}): ReactElement => {
  const style = {
    position: "absolute",
    top: position?.top,
    left: position?.left,
    height: window.innerHeight - (position?.top ?? 0) - 30
  };

  const [viewName, setViewName] = useState(name);
  const modalContent = getModalContent(type, viewName, setViewName);

  return (
    <SeityModal
      isOpen={isOpen}
      wrapperClassName="wbn-modal"
      style={position && style}
      actionButtons={modalContent?.buttons as any}
      onClose={() => {
        onClose();
      }}
      onClickActions={(idx) => {
        onClickActions(idx, viewName);
      }}
    >
      <div className="wbn-modal__wrap">
        <div className="wbn-modal__header">
          {title?.length > 0 && <h3>{title}</h3>}
          <button
            onClick={() => {
              onClose();
            }}
          >
            <img src={require("src/assets/wbn/btn-close.png")} width={24} height={24} />
          </button>
        </div>
        <div className="wbn-modal__content">{modalContent?.content()}</div>
      </div>
    </SeityModal>
  );
};

export default WbnModal;
