import React, { FunctionComponent, ReactElement } from "react";
import "./styles.scss";

export type SurveyTextAreaProps = {
  label: string;
  onChange: (value: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value: string;
  placeholder?: string;
  cols?: number;
  rows?: number;
  className?: string;
  name?: string;
};

export const SurveyTextArea: FunctionComponent<SurveyTextAreaProps> = ({
  label,
  onChange,
  value,
  placeholder = "",
  cols = 30,
  rows = 6,
  className,
  name = "survey-text-area"
}): ReactElement => {
  return (
    <div className={`survey-textarea-container ${className || ""}`}>
      <label htmlFor={name}>{label}</label>
      <textarea value={value} placeholder={placeholder} cols={cols} rows={rows} name={name} onChange={onChange} />
    </div>
  );
};

export default SurveyTextArea;
