export const PRIMARY_COLORS = [
  {
    colorName: "Seity Teal",
    colorHex: "#56A3A6",
    colorRGB: "RGB (247, 90, 59)"
  },
  {
    colorName: "Cream",
    colorHex: "#FFF8EB",
    colorRGB: "RGB (255,248,235)"
  },
  {
    colorName: "Whole Health",
    colorHex: "#F08080",
    colorRGB: "RGB (240, 128, 128)"
  },
  {
    colorName: "Dark Green",
    colorHex: "#41625E",
    colorRGB: "RGB (65, 90, 94)"
  },
  {
    colorName: "Seity Yellow",
    colorHex: "#FDCB5D",
    colorRGB: "RGB (86, 163, 166)"
  }
];

export const SECONDARY_COLORS = [
  {
    colorName: "World",
    colorHex: "#2F696C",
    colorRGB: "RGB (47,105,108)"
  },
  {
    colorName: "Body",
    colorHex: "#56A3A6",
    colorRGB: "RGB (247, 90, 59)"
  },
  {
    colorName: "Mind",
    colorHex: "#9AC8CA",
    colorRGB: "RGB (154,200,202)"
  },
  {
    colorName: "Spirit",
    colorHex: "#BBDADB",
    colorRGB: "RGB (187,218,219)"
  },
  {
    colorName: "Core",
    colorHex: "#F75A3B",
    colorRGB: "RGB (253, 203, 93)"
  }
];
