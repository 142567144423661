import axios from "axios";
import { BASE_URL } from "../apiConstants";
import {
  SeityTeacherSchoolListResponse,
  SeityTeacherClassListResponse,
  SeityTeacherListResponse,
  AnalyticsViewResponse
} from "./types/analyticsTypes";

const SECTION = "/SchoolNav/";

export async function getTeacherSchoolList(token: string, isAdmin: boolean): Promise<SeityTeacherSchoolListResponse> {
  const url = `${BASE_URL + SECTION}${isAdmin ? "SchoolList" : "TeacherSchoolList"}`;
  // eslint-disable-next-line no-useless-catch
  try {
    const schoolListRes = await axios.get<SeityTeacherSchoolListResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return schoolListRes.data;
  } catch (err) {
    throw err;
  }
}

export async function getTeacherClassList(token: string, companyID: number): Promise<SeityTeacherClassListResponse> {
  const url = `${BASE_URL + SECTION}TeacherClassList`;
  // eslint-disable-next-line no-useless-catch
  try {
    const teacherClassRes = await axios.get<SeityTeacherClassListResponse>(url, {
      params: {
        companyID
      },
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return teacherClassRes.data;
  } catch (err) {
    throw err;
  }
}

export async function getClassList(token: string, companyID: number, accountID: number): Promise<SeityTeacherClassListResponse> {
  const url = `${BASE_URL + SECTION}ClassList`;
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await axios.get<SeityTeacherClassListResponse>(url, {
      params: {
        companyID,
        accountID
      },
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (err) {
    throw err;
  }
}

export async function getTeacherList(token: string, companyID: number): Promise<SeityTeacherListResponse> {
  const url = `${BASE_URL + SECTION}TeacherList`;
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await axios.get<SeityTeacherListResponse>(url, {
      params: {
        companyID
      },
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (err) {
    throw err;
  }
}

export async function getAnalyticsView(token: string): Promise<AnalyticsViewResponse> {
  const url = `${BASE_URL}/AnalyticsView`;
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await axios.get<AnalyticsViewResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (err) {
    throw err;
  }
}
