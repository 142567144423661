import { FunctionComponent, ReactElement, useState } from "react";

import "./styles.scss";

type WbnFilterWrapProp = {
  title: string;
  children: React.ReactNode;
};

export const WbnFilterWrap: FunctionComponent<WbnFilterWrapProp> = (props): ReactElement => {
  const { title, children } = props;
  const [isOpen, setOpen] = useState(false);

  return (
    <div className="wbn-filterWrap">
      <div
        className="wbn-filterWrap__header"
        onClick={() => {
          setOpen(!isOpen);
        }}
      >
        <h5>{title}</h5>
        <img className={`${isOpen ? "open" : ""}`} src={require("src/assets/wbn/caret-down.png")} />
      </div>
      <div className={`wbn-filterWrap__body ${isOpen ? "open" : "close"}`}>{children}</div>
    </div>
  );
};

export default WbnFilterWrap;
