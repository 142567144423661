// Imports
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/reducers";
import { getIsAdmin, getIsIndividualCompanyAccess } from "src/utils/helper";
import {
  TeacherSchool,
  TeacherClass,
  TeacherList,
  SeityAdminClassListPayloadResponse,
  SeityTeacherClassListPayloadResponse
} from "src/api/analytics/types/analyticsTypes";
import {
  sendGetSchoolListRequestRequest,
  setCurTeacherSchool,
  setCurTeacherClass,
  setCurTeacher,
  setTeacherClassList,
  setTeacherSchoolList,
  setTeacherList
} from "src/reducers/auth/analyticsSlice";
import { sendGetAccountInfoRequest } from "src/reducers/auth/accountSlice";

import { getRefreshedToken } from "src/reducers/auth/useSessionStorageToken";
import { getClassList, getTeacherClassList, getTeacherList } from "../../../api/analytics/seityHealthAPI-analytics";

import { AnalyticModal } from "../AnalyticModal";
import { SeityButton } from "../SeityButton";
import { SeityLoader } from "../../SeityLoader";
import SelectBox from "./SelectBox";
import FilterButton from "./FilterButton";

import "./styles.scss";
import {setRedirectToLogin} from "../../../reducers/auth/authSlice";

export interface SeityEditLocationProps {
  isOpen: boolean;
  isLanding: boolean;
  reportType: "Student" | "Staff";
  onClose?: ((event: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined;
  onCancel?: ((event: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined;
}

const getSiteId = (curTSData: TeacherSchool | null, tsList: TeacherSchool[] | null) => {
  if (curTSData && tsList) {
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < tsList.length; index++) {
      if (tsList[index].companyID === curTSData.companyID) {
        return index;
      }
    }
  }

  return getIsAdmin() ? -1 : 0;
};

const getClassId = (curTCData: TeacherClass | null, tcList: TeacherClass[]) => {
  if (curTCData) {
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < tcList.length; index++) {
      if (tcList[index].class01ID === curTCData.class01ID) {
        return index;
      }
    }
  }
  
  return -1;
};

const getTeacherId = (curTeacherData: TeacherList | null, tcList: TeacherList[]) => {
  if (curTeacherData) {
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < tcList.length; index++) {
      if (tcList[index].accountID === curTeacherData.accountID) {
        return index;
      }
    }
  }
  return -1;
};

export const SeityEditLocation = (props: SeityEditLocationProps) => {
  const history = useHistory();
  const { isOpen, isLanding, reportType = "Student", onClose, onCancel } = props;
  // const [classList, setClassList] = useState<TeacherClass[]>([]);

  const { curTeacherClass, curTeacherSchool, curTeacher, teacherClassList, teacherSchoolList, teacherList, isLoading } = useSelector(
    (state: RootState) => {
      return state.analytics;
    }
  );

  const { accountInfo, proSettings } = useSelector((state: RootState) => {
    return state.account;
  });

  const dispatch = useDispatch();

  const [showSelection, setShowSelction] = useState<"Teacher" | "Class" | "Site" | null>(getIsAdmin() ? null : "Site"); // null: Meaning nothing selected

  const [schoolData, setSchoolData] = useState(teacherSchoolList);
  const [teacherData, setTeacherData] = useState(teacherList);
  const [classData, setClassData] = useState(teacherClassList);
  const initialSiteId = getSiteId(curTeacherSchool, schoolData);
  const [siteId, setSiteId] = useState(initialSiteId);
  const [teacherId, setTeacherId] = useState(getTeacherId(curTeacher, teacherData));
  const [classId, setClassId] = useState(getClassId(curTeacherClass, classData));

  const isAdmin = getIsAdmin();
  const isIndividualCompanyAccess = getIsIndividualCompanyAccess();

  const onChangeSite = async (idx: number) => {
    setSiteId(idx);
    // Reset all other selection than Site cause need to get new data (Teacher & Class) for the selected Site
    setTeacherId(-1);
    setClassId(-1);
    setTeacherData([]);
    setClassData([]);

    if (idx === -1) {
      // Select All Sites
      return;
    }

    const { token } = await getRefreshedToken();

    if (token === null) {
      dispatch(setRedirectToLogin({ shouldRedirectToLogin: true }));
      return;
    }

    if ((isAdmin || isIndividualCompanyAccess) && teacherData) {
      const response = await getTeacherList(token, schoolData[idx].companyID);
      if (response.data) {
        setTeacherData(response.data?.teacherList);
      }
    } else if (!isAdmin) {
      const response = await getTeacherClassList(token, schoolData[idx].companyID);
      if (response.data) {
        // eslint-disable-next-line max-len
        const data = (response.data as SeityTeacherClassListPayloadResponse).teacherClassList;
        setClassData(data);
      }
    }
  };

  useEffect(() => {
    if (!proSettings) {
      history.push("/");
      return;
    }
    if (accountInfo.firstName === "") {
      dispatch(sendGetAccountInfoRequest());
    }
    dispatch(sendGetSchoolListRequestRequest(isAdmin));
  }, []);

  useEffect(() => {
    setSchoolData(teacherSchoolList);
    setTeacherData(teacherList);
    setClassData(teacherClassList);
  }, [teacherSchoolList, teacherList, teacherClassList]);


  const onChangeClass = (idx: number) => {
    setClassId(idx);
  };

  const onChangeTeacher = async (idx: number) => {
    setTeacherId(idx);
    // Reset Class data
    setClassId(-1);
    setClassData([]);

    if (idx === -1) {
      // Select All Teachers
      return;
    }

    // Get new class data
    const { token } = await getRefreshedToken();

    if (token === null) {
      dispatch(setRedirectToLogin({ shouldRedirectToLogin: true }));
      return;
    }

    if (idx !== -1 && teacherData && schoolData) {
      // eslint-disable-next-line max-len
      const response = (isAdmin || isIndividualCompanyAccess)
        ? await getClassList(token, schoolData[siteId].companyID, teacherData[idx].accountID)
        : await getTeacherClassList(token, schoolData[siteId].companyID);
      if (response.data) {
        // eslint-disable-next-line max-len
        const data = (isAdmin || isIndividualCompanyAccess)
          ? (response.data as SeityAdminClassListPayloadResponse).classList
          : (response.data as SeityTeacherClassListPayloadResponse).teacherClassList;
        setClassData(data);
      }
    }
  };

  const resetSelection = () => {
    // Fresh out all selection (Site, Teacher, Class)
    if (isAdmin) {
      setSiteId(-1);
    } else {
      setSiteId(0);
    }
    setClassId(-1);
    setTeacherId(-1);
    setClassData([]);
    setTeacherData([]);
  };

  const customStyles = {
    content: {
      height: "550px",
      maxHeight: "550px"
    }
  };

  const renderContent = () => {
    return (
      <div className="editlocation-container">
        <h2 className="title">Select population</h2>
        <div className="content">
          <div style={{ display: "flex", justifyContent: "center" }}>
            {/* <a className='button-category'>POPULATION</a> */}
            <span className="button-category active">{`${reportType === "Student" ? "Students" : "Staff"}`}</span>
          </div>
          <div className="border-bottom" />
          <div className="item-wrap">
            <span className="left" style={{ width: "100%" }}>
              {proSettings?.companyName}
            </span>
          </div>
          <div className="item-wrap">
            <span className="left">Site</span>
            <FilterButton
              title={siteId !== -1 ? schoolData && schoolData[siteId] && `${schoolData[siteId].schoolName}`
              : "All Schools"}
              onClick={() => {
                setShowSelction("Site");
              }}
            />
          </div>
          {accountInfo && reportType === "Student" && (
            <div className="item-wrap">
              <span className="left">Teacher</span>
              {(isAdmin || isIndividualCompanyAccess) ? (
                <FilterButton
                  title={
                    teacherId !== -1
                      ? teacherData && `${teacherData[teacherId].firstName} ${teacherData[teacherId].lastName}`
                      : "All Teachers"
                  }
                  onClick={() => {
                    setShowSelction("Teacher");
                  }}
                />
              ) : (
                <span className="right text">
                  {accountInfo.firstName} {accountInfo.lastName}
                </span>
              )}
            </div>
          )}
          {reportType === "Student" && (
            <div className="item-wrap">
              <span className="left">Class</span>
              <FilterButton
                title={classId !== -1 ? classData && `${classData[classId].classDescription}` : "All Classes"}
                onClick={() => {
                  setShowSelction("Class");
                }}
              />
            </div>
          )}
        </div>
        <div style={{ display: "flex", justifyContent: "center", marginBottom: 10 }}>
          <SeityButton
            label="Reset"
            type="cancel"
            style={{
              height: 48,
              width: 108,
              padding: 0,
              marginRight: 10
            }}
            onClick={() => {
              resetSelection();
              // if (onClose) {
              //   onClose(e);
              // }
            }}
          />
          <SeityButton
            label="Cancel"
            type="cancel"
            style={{
              height: 48,
              width: 108,
              padding: 0,
              marginRight: 10
            }}
            onClick={(e) => {
              if (onCancel) {
                onCancel(e);
              }
            }}
          />
          <SeityButton
            label="Apply"
            type="next"
            style={{ height: 48, width: 108, padding: 0 }}
            onClick={(e) => {
              // Store all selection and data to Redux
              dispatch(setCurTeacherSchool(schoolData && siteId > -1 ? schoolData[siteId] : null));
              dispatch(setCurTeacherClass(classData && classId > -1 ? classData[classId] : null));
              dispatch(setCurTeacher(teacherData && teacherId > -1 ? teacherData[teacherId] : null));
              dispatch(setTeacherSchoolList(schoolData));
              dispatch(setTeacherClassList(classData));
              dispatch(setTeacherList(teacherData));

              if (onClose) {
                onClose(e);
              }
            }}
          />
        </div>
        {isLoading && <SeityLoader showBackgroundMask />}
      </div>
    );
  };
  return (
    <>
      {isLanding ? (
        renderContent()
      ) : (
        <AnalyticModal isOpen={isOpen} header={false} customStyle={customStyles}>
          {renderContent()}
        </AnalyticModal>
      )}
      {showSelection ? (
        <SelectBox
          type={showSelection}
          // eslint-disable-next-line no-nested-ternary
          selIdx={showSelection === "Teacher" ? teacherId : showSelection === "Site" ? siteId : classId}
          // eslint-disable-next-line no-nested-ternary
          options={showSelection === "Teacher" ? teacherData : showSelection === "Site" ? schoolData : classData}
          onClose={() => {
            setShowSelction(null);
          }}
          onChange={(selIdx) => {
            if (showSelection === "Teacher") {
              onChangeTeacher(selIdx);
            } else if (showSelection === "Site") {
              onChangeSite(selIdx);
            } else if (showSelection === "Class") {
              onChangeClass(selIdx);
            }
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default SeityEditLocation;
