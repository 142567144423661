import React, { FunctionComponent } from "react";

import "./styles.scss";

export type ResultsSubHeaderProps = {
  questionNumber: number | undefined;
  participantNumber: number | undefined;
  showShadow: boolean;
};

export const ResultsSubHeader: FunctionComponent<ResultsSubHeaderProps> = ({ questionNumber, participantNumber, showShadow }) => {
  return (
    <div className={`survey-results-subheader  ${showShadow ? "shadow" : ""}`}>
      <h4>Overview</h4>
      <div className="survey-results-subheader__wrap">
        <div className="survey-results-subheader-count">
          <div>
            <span className="count-title">Questions</span>
            <span className="count-number">{questionNumber}</span>
          </div>
          <div>
            <span className="count-title">Participants</span>
            <span className="count-number">{participantNumber}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultsSubHeader;
