import axios from "axios";
import { getRefreshedToken, getToken } from "src/reducers/auth/useSessionStorageToken";
import { BASE_URL } from "./apiConstants";
import { toastError } from "../app/utils";

export const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-type": "application/json"
  }
});

export const apiClientWithAuth = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-type": "application/json"
  }
});

apiClientWithAuth.interceptors.request.use((config) => {
  // Modify the request config
  config.headers.Authorization = `Bearer ${getToken()}`;
  return config;
});

apiClientWithAuth.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // console.log('interceptor response:', response);

    if (!response.data.success) {
      // Handle backend specific errors
      // Need to put delay on it until relevant components render fully
      setTimeout(() => {
        toastError(response.data.message);
      }, 1000);
    }
    return response;
  },
  async function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response) {
      const { status, data } = error.response;
      switch (status) {
        case 401: {
          // check if 401 error was token, need to update backend server first with appropriate error messages for expired token or invalid token
          // if (data.message === "") {
          try {
            const { token } = await getRefreshedToken();
            const orgRequest = error.config;
            orgRequest._retry = true;
            orgRequest.headers.Authorization = `Bearer ${token} `;
            return axios(orgRequest);
          } catch (e) {
            setTimeout(() => {
              toastError(data.message);
            }, 1000);
            return {
              data: { success: false, message: data.message }
            };
          }
          // } else {
          //   return {
          //     data: { success: false, data.message }
          //   };
          // }
        }
        case 500: {
          return (window.location.href = "/login");
        }
        default: {
          setTimeout(() => {
            toastError(data?.message);
          }, 1000);
          return {
            data: { success: false, message: data?.message }
          };
        }
      }
    }
    return Promise.reject(error);
  }
);

export default apiClientWithAuth;
