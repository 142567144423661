import React, { FunctionComponent, ReactElement } from "react";
import { useDispatch } from "react-redux";
import Modal from "react-modal";

import { sendDeleteGroupRequest } from "src/reducers/groupsAndDepartments/groupsSlice";

import "./styles.scss";

const closeIcon = require("../../../assets/groupsAndDepartments/close.svg").default;

export type ModalProps = {
  isOpen: boolean;
  onClose: Function;
  focusedID: number | undefined;
  groupName: string | undefined;
};

export const DeleteGroupModal: FunctionComponent<ModalProps> = ({ isOpen, onClose, focusedID, groupName }): ReactElement => {
  const dispatch = useDispatch();

  const onCloseHandler = () => {
    onClose();
  };

  const onDeleteHandler = () => {
    if (focusedID) {
      dispatch(sendDeleteGroupRequest(focusedID));
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} className="gad-modal" overlayClassName="gad-modal-overlay">
      <div className="gad-wrapper">
        <div className="gad-header">
          Delete {groupName}
          <button onClick={onCloseHandler} type="button" className="gad-close-btn">
            <img src={closeIcon} alt="close" />
          </button>
        </div>
        <div className="gad-main">Are you sure you want to delete {groupName}?</div>
        <div className="gad-footer">
          <button onClick={onCloseHandler} className="gad-cancel-btn" type="button">
            Cancel
          </button>
          <button onClick={onDeleteHandler} className="gad-create-btn" type="submit">
            Delete
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteGroupModal;
