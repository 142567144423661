import { FunctionComponent, ReactElement, useState } from "react";

import WbnCheckbox from "../WbnCheckbox";

import "./styles.scss";

type WbnCheckListProps = {
  data: any[];
  selOptions: any[];
  onSelOptions: (filteredList: any[], selId: number) => void;
  showSelectAll?: boolean;
};

/**
 * to access the filtered list from the parent use the following in the parent
 * const [selOptions, onSelOptions] = useState<any[]>([]);
 */

export const WbnCheckList: FunctionComponent<WbnCheckListProps> = (props): ReactElement => {
  const { data, selOptions, onSelOptions, showSelectAll = false } = props;
  const [showAll, setShowAll] = useState(false);

  const handleSelectedChange = (item: any) => {
    if (selOptions.includes(item.id)) {
      onSelOptions(
        selOptions.filter((id) => {
          return id !== item.id;
        }),
        item.id
      );
    } else {
      onSelOptions([...selOptions, item.id], item.id);
    }
  };

  const getLabel = (item: any) => {
    // adding a count to the label if it exists. eg: Option (3)
    return item?.title + (item?.count ? ` (${item.count})` : "");
  };

  const handleSelectAll = () => {
    const tempSelected: string[] = [];
    data.forEach((item) => {
      tempSelected.push(item?.id);
    });
    onSelOptions(tempSelected, -1);
    setShowAll(true);
  };

  const showingDataList = showAll ? data : data.slice(0, 4);
  const allSelected = selOptions.length === data.length;

  return (
    <div className="wbn-checklist">
      {showSelectAll && (
        <p
          className="text bold-text bottom-margin"
          onClick={() => {
            if (allSelected) {
              onSelOptions([], -1);
              return;
            }
            handleSelectAll();
          }}
          style={{ cursor: "pointer" }}
        >
          {allSelected ? `Deselect all` : `Select all`}
        </p>
      )}
      {showingDataList.map((item, index) => {
        return (
          <WbnCheckbox
            key={index}
            className="filter-list align-item-center"
            label={getLabel(item)}
            onClick={() => {
              handleSelectedChange(item);
            }}
            checked={selOptions.includes(item.id)}
          />
        );
      })}
      {data.length > 4 && (
        <div
          className="show-all bottom-margin"
          onClick={() => {
            setShowAll(showAll ? false : true);
          }}
        >
          <p className="text" style={{ cursor: "pointer" }}>
            {showAll ? "Show less" : `Show all [${data.length}]`}
          </p>
        </div>
      )}
    </div>
  );
};

export default WbnCheckList;
