/* eslint-disable max-len */
import React, { FunctionComponent, ReactElement, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "src/reducers";
import { sendGetAtsOutcomeCategoriesRequest, setOutcomeAccordionSelected, setSearchText } from "src/reducers/ats/atsSlice";
import { AtsAllOutcomeSubcategories } from "src/api/ats/atsTypes";

import SeityLoader from "src/components/SeityLoader";
import "./styles.scss";
import { downloadImageFromUrl, scrollToID } from "src/_core/utils/utils";
import SurveyButton from "src/components/survey/SurveyButton";
import { ICON_LIFESTYLE_URL } from "src/api/apiConstants";
import AtsOutcomeCategoriesItem from "../AtsOutcomeCategoriesItem";
import AtsAutoComplete, { AtsAutoCompleteType } from "../AtsAutoComplete";

export type AtsOutcomeCategoriesProps = {
  subMenuItem: number;
  onCategoryClicked: (index: number, menuName: string) => void;
};

let filterTimeout;
export const AtsOutcomeCategories: FunctionComponent<AtsOutcomeCategoriesProps> = ({ subMenuItem, onCategoryClicked }): ReactElement => {
  const { isLoading, outcomeCategoryList, searchText } = useSelector((state: RootState) => {
    return state.ats;
  });

  const [filteredOutcomes, setFiltered] = useState<AtsAllOutcomeSubcategories[]>([]);
  // const [searchValue, setSearchValue] = useState('');
  const autoComplateItems = useMemo<AtsAutoCompleteType[]>(() => {
    const result: AtsAutoCompleteType[] = [];
    outcomeCategoryList.forEach((category) => {
      result.push({ label: category.outcomeCategory.category });
      category.subcategories.forEach((sub) => {
        result.push({ label: sub.subcategory });
      });
    });

    return result;
  }, [outcomeCategoryList]);

  const dispatch = useDispatch();

  const matchString = (str1: string, str2: string) => {
    return str1.toLowerCase().includes(str2.toLowerCase());
  };
  function handleExpand(category: string, subcategory: string, scroll = false) {
    dispatch(setOutcomeAccordionSelected(category));

    if (subcategory && scroll) {
      setTimeout(() => {
        return scrollToID(subcategory.replace(" ", ""));
      }, 300);
    }
  }

  const handleSearch = (searchKey: string, scroll: boolean = false) => {
    clearTimeout(filterTimeout);
    let hasScrolled = false;

    if (searchKey.length < 0) {
      setFiltered(outcomeCategoryList);
      return;
    }

    const filtered = outcomeCategoryList.filter(({ outcomeCategory, subcategories }) => {
      if (matchString(outcomeCategory.category, searchKey)) {
        if (!hasScrolled && scroll) {
          hasScrolled = true;
          scrollToID(outcomeCategory.category);
        }
        return true;
      }
      const subcategoryMatched = subcategories.find((sub) => {
        return matchString(sub.subcategory, searchKey);
      });
      if (subcategoryMatched !== undefined) {
        handleExpand(outcomeCategory.category, subcategoryMatched.subcategory, scroll);
      }
      return subcategoryMatched;
    });
    setFiltered(filtered);
  };
  const debounceHandleSearch = (searchKey: string) => {
    clearTimeout(filterTimeout);
    filterTimeout = setTimeout(() => {
      handleSearch(searchKey);
    }, 2000);
  };

  useEffect(() => {
    dispatch(sendGetAtsOutcomeCategoriesRequest());
  }, []);

  useEffect(() => {
    setFiltered(outcomeCategoryList);
  }, [outcomeCategoryList]);

  return (
    <div className="ats-outcome-categories">
      {isLoading && <SeityLoader showBackgroundMask />}

      <div className="ats-outcome-categories__item-wrapper">
        {outcomeCategoryList.map(({ outcomeCategory: outcome }, index) => {
          return (
            <span
              onClick={() => {
                return onCategoryClicked(index, outcome.category);
              }}
              className={index === subMenuItem ? "selected" : ""}
              key={outcome.outcomeCategoryID}
            >
              {outcome.category}
            </span>
          );
        })}
      </div>
      <span className="ats-outcome-categories-text">
        A lifestyle can be defined as an integrated set of practices that an individual embraces. It can be organized into 4 layers and 18
        lifestyle practices.
      </span>

      <div className="ats-corevalues__searchbar">
        <AtsAutoComplete
          placeholder="Search Lifestyle Practice..."
          items={autoComplateItems}
          value={searchText}
          shouldItemRender={(item, value) => {
            return item.label.toLowerCase().indexOf(value.toLowerCase()) > -1;
          }}
          onChange={(val) => {
            dispatch(setSearchText(val || ""));
            debounceHandleSearch(val);
          }}
          onSelect={(val) => {
            dispatch(setSearchText(val || ""));
            handleSearch(val, true);
          }}
        />
        <SurveyButton
          size="mid-ats"
          type="ats-round"
          onClick={() => {
            downloadImageFromUrl(ICON_LIFESTYLE_URL, "LifeStylePracticesIcons.zip");
          }}
          label="Download All (Zip)"
        />
      </div>
      {filteredOutcomes.map(({ outcomeCategory: outcome, subcategories }) => {
        return (
          <AtsOutcomeCategoriesItem
            id={outcome.category}
            outcomeCategory={outcome}
            key={outcome.outcomeCategoryID}
            outcomeSubcategories={subcategories}
          />
        );
      })}
    </div>
  );
};

export default AtsOutcomeCategories;
