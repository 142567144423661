import moment from "moment";
import CoreStrings from "src/_core/strings/strings";
import {
  AttentionCode,
  Company,
  Entity,
  GenderID,
  GradeLevel,
  Race,
  WBNFilterOptionType,
  WbnFilter,
  WbnViewFilter
} from "src/api/wbn/wbnTypes";
import { getNumberWithOrdinal } from "src/app/utils";

export const checkOptionSelected = (filterType: WBNFilterOptionType, optionId: number, filterData: WbnFilter) => {
  let selected = false;

  switch (filterType) {
    case WBNFilterOptionType.Grade: {
      selected =
        filterData.gradeLevels.filter((g) => {
          return g.gradeLevelID === optionId;
        }).length > 0;
      break;
    }
    case WBNFilterOptionType.AttentionCode: {
      selected =
        filterData.attentionCodes.filter((g) => {
          return g.attentionCodeID === optionId;
        }).length > 0;
      break;
    }
    case WBNFilterOptionType.Race: {
      selected =
        filterData.races.filter((g) => {
          return g.raceID === optionId;
        }).length > 0;
      break;
    }
    case WBNFilterOptionType.Gender: {
      selected =
        filterData.genderIDs.filter((g) => {
          return g.genderID === optionId;
        }).length > 0;
      break;
    }
  }

  return selected;
};

export const buildSelFilterData = (selFilterData: WbnFilter, allFilterData: WbnFilter) => {
  Object.freeze(allFilterData);
  const temp = { ...allFilterData };
  const newEntities = temp.entities.map((te) => {
    const ent = selFilterData.entities.find((e) => {
      return e.entityID === te.entityID;
    });
    if (ent?.companies.length === te.companies.length) {
      te = { ...te, intermediate: false, selected: true };
    } else if (!ent || ent?.companies.length === 0) {
      te = { ...te, intermediate: false, selected: false };
    } else {
      te = { ...te, intermediate: true, selected: false };
    }
    const newttc = te.companies.map((tc) => {
      const cmp = ent?.companies.find((c) => {
        return c.companyID === tc.companyID;
      });
      tc = { ...tc, selected: cmp ? true : false };
      return tc;
    });
    te.companies = [...newttc];

    return te;
  });
  const newGrades = temp.gradeLevels.map((tg) => {
    tg = { ...tg, selected: checkOptionSelected(WBNFilterOptionType.Grade, tg.gradeLevelID, selFilterData) };
    return tg;
  });
  const newAttend = temp.attentionCodes.map((ta) => {
    ta = { ...ta, selected: checkOptionSelected(WBNFilterOptionType.AttentionCode, ta.attentionCodeID, selFilterData) };
    return ta;
  });
  const newRace = temp.races.map((tr) => {
    tr = { ...tr, selected: checkOptionSelected(WBNFilterOptionType.Race, tr.raceID, selFilterData) };
    return tr;
  });
  const newGender = temp.genderIDs.map((tg) => {
    tg = { ...tg, selected: checkOptionSelected(WBNFilterOptionType.Gender, tg.genderID, selFilterData) };
    return tg;
  });

  temp.entities = newEntities;
  temp.gradeLevels = newGrades;
  temp.attentionCodes = newAttend;
  temp.races = newRace;
  temp.genderIDs = newGender;
  temp.lastCheckinDate = selFilterData.lastCheckinDate;
  return temp;
};

export const getUpdatedFiltersDataOnSchoolSelection = (selFilterData: WbnFilter, entityId: number, companyId?: number) => {
  const tempD = { ...selFilterData };
  if (companyId) {
    const temp = selFilterData.entities.map((e) => {
      if (e.entityID === entityId) {
        let selCount = 0;
        const tc = e.companies.map((c) => {
          if (c.companyID === companyId) {
            c.updatedAt = Date.now();
            c.selected = !c.selected;
          }
          if (c.selected) {
            selCount++;
          }
          return c;
        });
        e.selected = selCount === e.companies.length;
        e.intermediate = selCount > 0 && selCount < e.companies.length ? true : false;
        e.updatedAt = Date.now();
        e.companies = [...tc];
      }
      return e;
    });
    tempD.entities = temp;
  } else {
    const temp = selFilterData.entities.map((e) => {
      if (e.entityID === entityId) {
        const val = !e.selected;
        e.selected = val;
        const tc = e.companies.map((c) => {
          c.selected = val;
          c.updatedAt = Date.now();
          return c;
        });
        e.intermediate = false;
        e.updatedAt = Date.now();
        e.companies = [...tc];
      }
      return e;
    });
    tempD.entities = temp;
  }

  return tempD;
};

export const getUpdatedFiltersOnSelection = (
  filterType: WBNFilterOptionType,
  selIds: Array<number>,
  filterData: WbnFilter,
  selId: number
) => {
  const temp = { ...filterData };

  switch (filterType) {
    case WBNFilterOptionType.Grade: {
      const tg = temp.gradeLevels.map((g) => {
        let ttg;
        if (selIds.includes(g.gradeLevelID)) {
          ttg = { ...g, selected: true };
        } else {
          ttg = { ...g, selected: false };
        }
        if (g.gradeLevelID === selId && ttg.selected) {
          ttg = { ...ttg, updatedAt: Date.now() };
        }
        return ttg;
      });
      temp.gradeLevels = [...tg];
      break;
    }
    case WBNFilterOptionType.AttentionCode: {
      const tg = temp.attentionCodes.map((g) => {
        let ttg;
        if (selIds.includes(g.attentionCodeID)) {
          ttg = { ...g, selected: true };
        } else {
          ttg = { ...g, selected: false };
        }
        if (g.attentionCodeID === selId && ttg.selected) {
          ttg = { ...ttg, updatedAt: Date.now() };
        }

        return ttg;
      });
      temp.attentionCodes = [...tg];
      break;
    }
    case WBNFilterOptionType.Race: {
      const tg = temp.races.map((g) => {
        let ttg;
        if (selIds.includes(g.raceID)) {
          ttg = { ...g, selected: true };
        } else {
          ttg = { ...g, selected: false };
        }
        if (g.raceID === selId && ttg.selected) {
          ttg = { ...ttg, updatedAt: Date.now() };
        }
        return ttg;
      });
      temp.races = [...tg];
      break;
    }
    case WBNFilterOptionType.Gender: {
      const tg = temp.genderIDs.map((g) => {
        let ttg;
        if (selIds.includes(g.genderID)) {
          ttg = { ...g, selected: true };
        } else {
          ttg = { ...g, selected: false };
        }
        if (g.genderID === selId && ttg.selected) {
          ttg = { ...ttg, updatedAt: Date.now() };
        }
        return ttg;
      });
      temp.genderIDs = [...tg];
      break;
    }
  }

  return temp;
};

export const getUpdatedFiltersOnRemove = (filterType: WBNFilterOptionType, filterData: WbnFilter, selId: number) => {
  const temp = { ...filterData };

  switch (filterType) {
    case WBNFilterOptionType.Grade: {
      const tg = temp.gradeLevels.map((g) => {
        if (g.gradeLevelID === selId && g.selected) {
          return { ...g, selected: false };
        }
        return g;
      });
      temp.gradeLevels = [...tg];
      break;
    }
    case WBNFilterOptionType.AttentionCode: {
      const tg = temp.attentionCodes.map((g) => {
        if (g.attentionCodeID === selId && g.selected) {
          return { ...g, selected: false };
        }
        return g;
      });
      temp.attentionCodes = [...tg];
      break;
    }
    case WBNFilterOptionType.Race: {
      const tg = temp.races.map((g) => {
        if (g.raceID === selId && g.selected) {
          return { ...g, selected: false };
        }
        return g;
      });
      temp.races = [...tg];
      break;
    }
    case WBNFilterOptionType.Gender: {
      const tg = temp.genderIDs.map((g) => {
        if (g.genderID === selId && g.selected) {
          return { ...g, selected: false };
        }
        return g;
      });
      temp.genderIDs = [...tg];
      break;
    }
    case WBNFilterOptionType.Entity: {
      const tg = temp.entities.map((g) => {
        if (g.entityID === selId && g.selected) {
          const gg = g.companies.map((ttg) => {
            return { ...ttg, selected: false };
          });
          return { ...g, selected: false, companies: gg };
        }
        return g;
      });
      temp.entities = [...tg];
      break;
    }
    case WBNFilterOptionType.Company: {
      const tg = temp.entities.map((g) => {
        const gg = g.companies.map((c) => {
          if (c.companyID === selId) {
            return { ...c, selected: false };
          }
          return c;
        });
        return { ...g, companies: gg };
      });
      temp.entities = [...tg];
      break;
    }
    case WBNFilterOptionType.CheckinData: {
      temp.lastCheckinDate.startDate = null;
      temp.lastCheckinDate.endDate = null;
      break;
    }
  }

  return temp;
};

export const getAttentionCodeDesc = (id: number) => {
  switch (id) {
    case 1:
      return ">70%";
    case 2:
      return "50-69%";
    case 3:
      return "<50%";
    case 4:
      return CoreStrings.wbnAttentionCodeStr;
    default:
      return "";
  }
};

export const getUpdatedColumns = (selIds: Array<number>, columns: any[]) => {
  const ret = columns.map((g) => {
    if (selIds.includes(g.id)) {
      return { ...g, selected: true };
    } else {
      return { ...g, selected: false };
    }
  });
  return ret;
};

export const convertStrToDate = (str: string | null, format: string) => {
  if (str) {
    return moment(str, format).toDate();
  }
  return null;
};

export const getSelFilterOptions = (selFilterData: WbnFilter) => {
  const tmp: any[] = [];
  selFilterData.entities.forEach((e) => {
    if (e.selected) {
      tmp.push({ type: WBNFilterOptionType.Entity, id: e.entityID, name: e.name, count: e.companies.length, updatedAt: e.updatedAt });
    } else {
      e.companies
        .filter((c) => {
          return c.selected;
        })
        .forEach((cc) => {
          tmp.push({ type: WBNFilterOptionType.Company, id: cc.companyID, name: cc.name, updatedAt: cc.updatedAt });
        });
    }
  });

  selFilterData.genderIDs.forEach((e) => {
    if (e.selected) {
      tmp.push({ type: WBNFilterOptionType.Gender, id: e.genderID, name: e.description, updatedAt: e.updatedAt });
    }
  });

  selFilterData.gradeLevels.forEach((e) => {
    if (e.selected) {
      tmp.push({ type: WBNFilterOptionType.Grade, id: e.gradeLevelID, name: getNumberWithOrdinal(e.gradeLevelID), updatedAt: e.updatedAt });
    }
  });

  selFilterData.races.forEach((e) => {
    if (e.selected) {
      tmp.push({ type: WBNFilterOptionType.Race, id: e.raceID, name: e.description, updatedAt: e.updatedAt });
    }
  });

  selFilterData.attentionCodes.forEach((e) => {
    if (e.selected) {
      tmp.push({
        type: WBNFilterOptionType.AttentionCode,
        id: e.attentionCodeID,
        name: getAttentionCodeDesc(e.attentionCodeID),
        updatedAt: e.updatedAt
      });
    }
  });

  const startD = selFilterData.lastCheckinDate?.startDate ? selFilterData.lastCheckinDate?.startDate.split("T")[0] : null;
  const endD = selFilterData.lastCheckinDate?.endDate ? selFilterData.lastCheckinDate?.endDate.split("T")[0] : null;
  if (startD || endD) {
    let name = startD ?? "" + endD ?? "";
    if (startD && endD) {
      name = startD + " - " + endD;
    }
    tmp.push({
      type: WBNFilterOptionType.CheckinData,
      name: name,
      updatedAt: selFilterData.lastCheckinDate.updatedAt
    });
  }

  const ret = tmp.sort((a, b) => {
    return (a.updatedAt ?? 0) - (b.updatedAt ?? 0);
  });

  return ret;
};

export const buildWbnDataApiParams = (selFilterData: WbnFilter) => {
  const companyIDs: number[] = [];
  const grades: number[] = [];
  const genders: number[] = [];
  const races: number[] = [];
  const attentionCodes: number[] = [];

  selFilterData.entities.forEach((e) => {
    if (e.selected) {
      e.companies.forEach((ec) => {
        companyIDs.push(ec.companyID);
      });
    } else {
      e.companies
        .filter((c) => {
          return c.selected;
        })
        .forEach((cc) => {
          companyIDs.push(cc.companyID);
        });
    }
  });

  selFilterData.genderIDs.forEach((e) => {
    if (e.selected) {
      genders.push(e.genderID);
    }
  });

  selFilterData.gradeLevels.forEach((e) => {
    if (e.selected) {
      grades.push(e.gradeLevelID);
    }
  });

  selFilterData.races.forEach((e) => {
    if (e.selected) {
      races.push(e.raceID);
    }
  });

  selFilterData.attentionCodes.forEach((e) => {
    if (e.selected) {
      attentionCodes.push(e.attentionCodeID);
    }
  });

  return {
    companyIDs,
    grades,
    races,
    genders,
    attentionCodes,
    lastCheckinDate: selFilterData.lastCheckinDate
  };
};

export const buildSavedViewData = (selFilterData: WbnFilter, viewName: string) => {
  const entities: Entity[] = [];
  const gradeLevels: GradeLevel[] = [];
  const genderIDs: GenderID[] = [];
  const races: Race[] = [];
  const attentionCodes: AttentionCode[] = [];

  selFilterData.entities.forEach((e) => {
    if (e.selected) {
      const companies: Company[] = [];
      e.companies.forEach((ec) => {
        companies.push({ companyID: ec.companyID });
      });
      entities.push({ entityID: e.entityID, companies });
    } else {
      const companies: Company[] = [];
      e.companies
        .filter((c) => {
          return c.selected;
        })
        .forEach((cc) => {
          companies.push({ companyID: cc.companyID });
        });
      if (companies.length > 0) {
        entities.push({ entityID: e.entityID, companies });
      }
    }
  });

  selFilterData.genderIDs.forEach((e) => {
    if (e.selected) {
      genderIDs.push({ genderID: e.genderID });
    }
  });

  selFilterData.gradeLevels.forEach((e) => {
    if (e.selected) {
      gradeLevels.push({ gradeLevelID: e.gradeLevelID });
    }
  });

  selFilterData.races.forEach((e) => {
    if (e.selected) {
      races.push({ raceID: e.raceID });
    }
  });

  selFilterData.attentionCodes.forEach((e) => {
    if (e.selected) {
      attentionCodes.push({ attentionCodeID: e.attentionCodeID });
    }
  });

  return {
    viewName,
    entities,
    gradeLevels,
    races,
    genderIDs,
    attentionCodes,
    lastCheckinDate: selFilterData.lastCheckinDate
  };
};

export const compareSavedViews = (firstView: WbnViewFilter, secondView: WbnViewFilter) => {
  let changed = false;
  const compare = (arr1, arr2, key) => {
    if (arr1.length === arr2.length) {
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i][key] !== arr2[i][key]) {
          return true;
        }
      }
    } else {
      return true;
    }
    return false;
  };

  changed = compare(firstView.entities, secondView.entities, "entityID");
  if (!changed) {
    for (let i = 0; i < firstView.entities.length; i++) {
      changed = compare(firstView.entities[i].companies, secondView.entities[i].companies, "companyID");
      if (changed) {
        break;
      }
    }
  }
  if (!changed) {
    changed = compare(firstView.gradeLevels, secondView.gradeLevels, "gradeLevelID");
  }
  if (!changed) {
    changed = compare(firstView.attentionCodes, secondView.attentionCodes, "attentionCodeID");
  }
  if (!changed) {
    changed = compare(firstView.races, secondView.races, "raceID");
  }
  if (!changed) {
    changed = compare(firstView.genderIDs, secondView.genderIDs, "genderID");
  }
  if (!changed) {
    changed =
      firstView.lastCheckinDate?.startDate !== secondView.lastCheckinDate?.startDate ||
      firstView.lastCheckinDate?.endDate !== secondView.lastCheckinDate?.endDate;
  }

  return changed;
};
