import { DepartmentItem } from "src/api/groupsAndDepartments/departmentsTypes";
import { GroupItem } from "src/api/groupsAndDepartments/groupsTypes";
import { AudienceUserType } from "./types";

export const getMergedMembers = (departments: Array<DepartmentItem>, groups: Array<GroupItem>) => {
  const result: Array<AudienceUserType> = [];
  departments.map((depart) => {
    return depart.members.map((member) => {
      return result.push({
        name: member.fullName,
        type: "depart",
        orgName: depart.departmentName,
        id: member.accountID,
        orgId: depart.departmentId
      });
    });
  });

  groups.map((group) => {
    return group.members.map((member) => {
      return result.push({
        name: member.accountName,
        type: "group",
        orgName: group.groupName,
        id: member.accountID,
        orgId: group.groupID
      });
    });
  });

  return result;
};

export const getUpdatedDeparts = (
  toAdd: Array<DepartmentItem>,
  toRemove: Array<DepartmentItem>,
  item: DepartmentItem,
  actionType: "remove" | "add"
) => {
  let ret = {
    toAdd: Array<DepartmentItem>(),
    toRemove: Array<DepartmentItem>()
  };
  if (actionType === "add") {
    const temp = [...toRemove];

    const isExist = temp.find((t) => {
      return t.departmentId === (item as DepartmentItem).departmentId;
    });
    if (!isExist) {
      temp.push(item as DepartmentItem);
    }

    const tempToAdd = toAdd.filter((d) => {
      return d.departmentId !== (item as DepartmentItem).departmentId;
    });

    ret = {
      toAdd: tempToAdd,
      toRemove: temp
    };
  } else {
    const temp = [...toAdd];

    const isExist = temp.find((t) => {
      return t.departmentId === (item as DepartmentItem).departmentId;
    });
    if (!isExist) {
      temp.push(item as DepartmentItem);
    }

    const tempToRemove = toRemove.filter((d) => {
      return d.departmentId !== (item as DepartmentItem).departmentId;
    });

    ret = {
      toAdd: temp,
      toRemove: tempToRemove
    };
  }
  return ret;
};

export const getUpdatedGroups = (toAdd: Array<GroupItem>, toRemove: Array<GroupItem>, item: GroupItem, actionType: "remove" | "add") => {
  let ret = {
    toAdd: Array<GroupItem>(),
    toRemove: Array<GroupItem>()
  };
  if (actionType === "add") {
    const temp = [...toRemove];

    const isExist = temp.find((t) => {
      return t.groupID === (item as GroupItem).groupID;
    });
    if (!isExist) {
      temp.push(item as GroupItem);
    }

    const tempToAdd = toAdd.filter((d) => {
      return d.groupID !== (item as GroupItem).groupID;
    });

    ret = {
      toAdd: tempToAdd,
      toRemove: temp
    };
  } else {
    const temp = [...toAdd];

    const isExist = temp.find((t) => {
      return t.groupID === (item as GroupItem).groupID;
    });
    if (!isExist) {
      temp.push(item as GroupItem);
    }

    const tempToRemove = toRemove.filter((d) => {
      return d.groupID !== (item as GroupItem).groupID;
    });

    ret = {
      toAdd: temp,
      toRemove: tempToRemove
    };
  }
  return ret;
};

// Make up Department Data with department ids from api
export const getInitialDepartData = (departIds: Array<number> | undefined, departList: Array<DepartmentItem>) => {
  if (departIds && departIds.length > 0) {
    const toRemove = departList.filter((d) => {
      return departIds.includes(d.departmentId);
    });
    const toAdd = departList.filter((d) => {
      return !departIds.includes(d.departmentId);
    });
    return {
      toAdd,
      toRemove
    };
  }
  return {
    toAdd: departList,
    toRemove: []
  };
};

export const getInitialGroupData = (groupIds: Array<number> | undefined, groupList: Array<GroupItem>) => {
  if (groupIds && groupIds.length > 0) {
    const toRemove = groupList.filter((d) => {
      return groupIds.includes(d.groupID);
    });
    const toAdd = groupList.filter((d) => {
      return !groupIds.includes(d.groupID);
    });
    return {
      toAdd,
      toRemove
    };
  }
  return {
    toAdd: groupList,
    toRemove: []
  };
};
