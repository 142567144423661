/* eslint-disable */
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "src/reducers";
import { sendGetAccountInfoRequest } from "src/reducers/auth/accountSlice";
import { logOut } from "src/reducers/auth/authSlice";

import { SeityButton } from "src/components/analytic/SeityButton";

import strings from "src/_core/strings/strings";
import "./styles.scss";
import { SeityAppContext } from "src/app/SeityAppContext";

export const Profile = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const appContext = useContext(SeityAppContext);

  const { accountInfo } = useSelector((state: RootState) => {
    return state.account;
  });

  useEffect(() => {
    dispatch(sendGetAccountInfoRequest());
  }, []);

  const logOutClicked = () => {
    dispatch(logOut());
    history.push("/login");
  };

  const toChangePassword = () => {
    history.push("/changePassword");
  };

  const toMaintenance = (maintenanceNumber: string) => {
    history.push(`/maintenance${maintenanceNumber}`);
  };

  if (accountInfo) {
    return (
      <div className="profile-container">
        <h1>{strings.myAccount}</h1>
        <h4 onClick={toChangePassword}>{strings.changePassword}</h4>
        <div className="profile-logout-button">
          <SeityButton type="button" disabled={false} label="Log Out" testId="logoutbutton" onClick={logOutClicked} />
        </div>
        <h2>{strings.profile}</h2>
        <hr />
        <div className="profile-bubble">
          <div
            className="profile-bubble-edit-link"
            onClick={() => {
              toMaintenance("One");
            }}
          >
            {strings.edit}
          </div>

          <label>{strings.name}</label>
          <p>
            {`${accountInfo?.firstName} `}
            {accountInfo?.lastName}
          </p>

          <label>{strings.emailLabel}</label>
          <p>{accountInfo?.eMailAddress}</p>
        </div>
        <div className="profile-bubble">
          <div
            className="profile-bubble-edit-link"
            onClick={() => {
              toMaintenance("Two");
            }}
          >
            {strings.edit}
          </div>

          <label>{strings.dateOfBirthLabel}</label>
          <p>{accountInfo?.dateOfBirth ? accountInfo.dateOfBirth : strings.notProvided}</p>

          <label>{strings.genderLabel}</label>
          <p>{accountInfo?.gender ? accountInfo.gender : strings.notProvided}</p>

          <label>{strings.maritalStatusLabel}</label>
          <p>{accountInfo?.maritalStatus ? accountInfo.maritalStatus : strings.notProvided}</p>

          <label>{strings.cellPhoneLabel}</label>
          <p>{accountInfo?.cellPhone ? accountInfo.cellPhone : strings.notProvided}</p>

          <label>{strings.zipcodeLabel}</label>
          <p>{accountInfo?.zipcode ? accountInfo.zipcode : strings.notProvided}</p>

          <label>{strings.accountTypeLabel}</label>
          <p>{accountInfo?.accountStatus ? accountInfo.accountStatus : strings.notProvided}</p>
        </div>
        <div className="profile-bubble">
          <div
            className="profile-bubble-edit-link"
            onClick={() => {
              toMaintenance("Three");
            }}
          >
            {strings.edit}
          </div>

          <label>{strings.workEdStatusLabel}</label>
          <p>{accountInfo?.status ?? strings.notProvided}</p>

          <label>{strings.educationLabel}</label>
          <p>{accountInfo?.education ?? strings.notProvided}</p>

          <label>{strings.occupationLabel}</label>
          <p>{accountInfo?.occupation ?? strings.notProvided}</p>

          {/* <label>{strings.language}</label>
                    <p>{appContext.language}</p> */}
        </div>
      </div>
    );
  }
  return <></>;
};

export default Profile;
