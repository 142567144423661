import axios from "axios";
import {
  PBI_EMBED_TOKEN,
  PBI_UPDATE_DATASET,
  PBI_RESET
  // PBI_UPDATE_DATASET_SUCCESS,
  // PBI_UPDATE_DATASET_FAILURE
} from "./types";

const apiUrl = process.env.REACT_APP_PBI_CLIENT_API ?? "https://ase-powerbiservice-dev-westus2.azurewebsites.net/";

export const resetPBI = () => {
  return {
    type: PBI_RESET
  };
};

export const setPBIEmbedTokenLoading = () => {
  return {
    type: PBI_EMBED_TOKEN,
    payload: {
      isLoading: true
    }
  };
};

export const createPBIEmbedTokenSuccess = (data) => {
  console.log("PBI Token Successfully created!");
  return {
    type: PBI_EMBED_TOKEN,
    payload: {
      pbiEmbedToken: data.embed_token,
      pbiAccessToken: data.access_token,
      staffEmbedUrl: data.staffEmbedUrl,
      staffDatasetId: data.staffDatasetId,
      studentEmbedUrl: data.studentEmbedUrl,
      studentDatasetId: data.studentDatasetId,
      cvStaffEmbedUrl: data.cvStaffEmbedUrl,
      cvStaffDatasetId: data.cvStaffDatasetId,
      cvStudentEmbedUrl: data.cvStudentEmbedUrl,
      cvStudentDatasetId: data.cvStudentDatasetId,
      v1StaffEmbedUrl: data.v1StaffEmbedUrl,
      v1StaffDatasetId: data.v1StaffDatasetId,
      v1StudentEmbedUrl: data.v1StudentEmbedUrl,
      v1StudentDatasetId: data.v1StudentDatasetId,
      surveyEmbedUrl: data.surveyEmbedUrl,
      isLoading: false
    }
  };
};

export const createPBIEmbedTokenFailure = (error) => {
  console.log("createPBIEmbedTokenFailure:", error);
  return {
    type: PBI_EMBED_TOKEN,
    payload: {
      isLoading: false
    }
  };
};

export const setUpdateDatasetTokenLoading = () => {
  console.log("setUpdateDatasetTokenLoading:");
  return {
    type: PBI_UPDATE_DATASET,
    payload: {
      isLoading: true
    }
  };
};

export const updateDatasetTokenSuccess = () => {
  console.log("updateDatasetTokenSuccess:");
  return {
    type: PBI_UPDATE_DATASET,
    payload: {
      isLoading: false
    }
  };
};

export const updateDatasetTokenFailure = (error) => {
  console.log("updateDatasetTokenFailure:", error);
  return {
    type: PBI_UPDATE_DATASET,
    payload: {
      isLoading: false
    }
  };
};

export const createPBIEmbedToken = (groupID: string) => {
  return (dispatch) => {
    if (!groupID) {
      console.log("groupID is empty, try relogin");
      dispatch(createPBIEmbedTokenFailure("Invalid Group Id"));
    }

    dispatch(setPBIEmbedTokenLoading());
    return axios({
      method: "get",
      url: `${apiUrl}/getPBIEmbedToken`,
      params: {
        groupID
      }
    })
      .then((response) => {
        // handle success
        if (response.data.success) {
          dispatch(createPBIEmbedTokenSuccess(response.data.data));
          console.log("PBIEmbedToken success");
        } else {
          dispatch(createPBIEmbedTokenFailure(response.data.error));
          console.log("PBIEmbedToken failed");
        }
      })
      .catch((response) => {
        // handle error
        console.log("getPBIEmbedToken error", response);
      });
  };
};

export const updateDatasetToken = (accessToken, datasetId, pToken) => {
  // console.log('createPBIToken started:', process.env.PBI_CLIENT_ID);
  return (dispatch) => {
    dispatch(setUpdateDatasetTokenLoading());
    return axios({
      method: "post",
      params: {
        accessToken,
        datasetId,
        pToken
      },
      url: `${apiUrl}/updateDatasetToken`
    })
      .then((response) => {
        // handle success
        if (response.data.success) {
          dispatch(updateDatasetTokenSuccess());
        } else {
          dispatch(updateDatasetTokenFailure({ error: "error occurred" }));
        }
      })
      .catch((response) => {
        // handle error
        console.log(response);
        dispatch(updateDatasetTokenFailure(response));
      });
  };
};
