export const EVERY = [
  {
    label: "1",
    value: 1
  },
  {
    label: "2",
    value: 2
  }
];
export const daysOfWeek = [
  { value: 0, label: "Sunday" },
  { value: 1, label: "Monday" },
  { value: 2, label: "Tuesday" },
  { value: 3, label: "Wednesday" },
  { value: 4, label: "Thursday" },
  { value: 5, label: "Friday" },
  { value: 6, label: "Saturday" }
];
export const siteArray = [{ label: "SCOE", value: "SCOE" }];
export const exampleData = [{ label: "All", value: "all" }];
export const surveyWizardFrequency = [
  {
    value: 1,
    label: "One-Time"
  },
  {
    value: 2,
    label: "Daily"
  },
  {
    value: 3,
    label: "Weekly"
  }
];

export const defaultValue = { label: "All", value: 0 };
