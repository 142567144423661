import React, { FunctionComponent, ReactElement } from "react";

import AutoComplete from "react-autocomplete";
import "./styles.scss";

export type AtsAutoCompleteType = { label: string };

export type AtsAutoCompleteProps = {
  onChange?: (val: string) => void;
  onSelect?: (val: string) => void;
  value?: string;
  items?: AtsAutoCompleteType[];
  shouldItemRender?: (item, value) => boolean;
  renderInput?: (props: any) => JSX.Element;
  className?: string;
  placeholder?: string;
};

export const AtsAutoComplete: FunctionComponent<AtsAutoCompleteProps> = ({
  items = [],
  value = "",
  onChange,
  onSelect,
  shouldItemRender,
  renderInput,
  placeholder
}): ReactElement => {
  const handleOnChange = (val: string) => {
    if (onChange) {
      onChange(val);
    }
  };

  const handleOnSelect = (val: string) => {
    if (onSelect) {
      onSelect(val);
    }
  };

  return (
    <div className="ats-autocomplete">
      <AutoComplete
        inputProps={{ placeholder }}
        getItemValue={(item) => {
          return item.label;
        }}
        items={items}
        shouldItemRender={shouldItemRender}
        renderItem={(item, isHighlighted) => {
          return (
            <div key={item.label} style={{ background: isHighlighted ? "lightgray" : "white" }}>
              {item.label}
            </div>
          );
        }}
        value={value}
        renderInput={renderInput}
        onChange={(e) => {
          return handleOnChange(e.target.value);
        }}
        onSelect={(val) => {
          return handleOnSelect(val);
        }}
        menuStyle={{
          borderRadius: "rem(3)",
          boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
          background: "rgba(255, 255, 255, 0.9)",
          padding: "10px",
          fontSize: "22px",
          position: "absolute",
          overflow: "auto",
          maxHeight: "300px",
          top: "rem(37)",
          width: "100%",
          left: 0
        }}
      />
    </div>
  );
};

export default AtsAutoComplete;
