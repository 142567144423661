import React, { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StepWizardChildProps } from "react-step-wizard";

import "./styles.scss";
import { sendGetDepartmentsRequest } from "src/reducers/groupsAndDepartments/departmentsSlice";
import { sendGetGroupsRequest } from "src/reducers/groupsAndDepartments/groupsSlice";
import { RootState } from "src/reducers";
import { DepartmentItem } from "src/api/groupsAndDepartments/departmentsTypes";
import { GroupItem } from "src/api/groupsAndDepartments/groupsTypes";
import { sendPostAudienceRequest, sendPutAudienceRequest } from "src/reducers/survey/surveyAudienceSlice";
import { SurveyAudience, SURVEY_AUDIENCE_STAFF } from "src/api/survey/surveyTypes";
import { getDateDifferenceInDaysWithTime } from "src/_core/utils/dateUtils/dateUtils";
import AudienceListBox from "./AudienceListBox";
import AudienceListResult from "./AudienceListResult";
import { SelectStaffProps, AudienceUserType, SurveyAudienceProps } from "./types";
import { getInitialDepartData, getInitialGroupData, getMergedMembers, getUpdatedDeparts, getUpdatedGroups } from "./helpers";

export const SelectStaff: FunctionComponent<SelectStaffProps & SurveyAudienceProps & Partial<StepWizardChildProps>> = (
  props
): ReactElement => {
  const { toggleNextBtn, onUpdateAudience, onSurveyScheduled, onSurveyActive, curSurveyAudience, curSurveySchedule } = props;

  const { proSettings } = useSelector((store: RootState) => {
    return store.account;
  });
  const { departmentsList } = useSelector((state: RootState) => {
    return state.departments;
  });
  const { groupsList } = useSelector((state: RootState) => {
    return state.groups;
  });

  const [orgWideCheck, setOrgWideCheck] = useState(false);
  const [, setTotalMembers] = useState<Array<AudienceUserType>>([]);
  const [addedUsers, setAddedUsers] = useState<Array<AudienceUserType>>([]);
  const [departData, setDepartData] = useState({
    toAdd: Array<DepartmentItem>(),
    toRemove: Array<DepartmentItem>()
  });
  const [groupData, setGroupData] = useState({
    toAdd: Array<GroupItem>(),
    toRemove: Array<GroupItem>()
  });

  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const nextPressed = () => {
    const departIds = addedUsers
      .filter((u) => {
        return u.type === "depart";
      })
      .map((d) => {
        return d.orgId;
      });
    const groupIds = addedUsers
      .filter((u) => {
        return u.type === "group";
      })
      .map((d) => {
        return d.orgId;
      });

    let param: SurveyAudience = {
      surveyWizardScheduleID: curSurveySchedule?.surveyWizardScheduleID || 0,
      surveyWizardAudienceTypeID: SURVEY_AUDIENCE_STAFF,
      companyID: 0,
      teacherID: 0,
      classID: 0,
      departmentIDs: departIds,
      groupIDs: groupIds.filter((item, index) => {
        return groupIds.indexOf(item) === index;
      })
    };
    if (orgWideCheck) {
      param = {
        ...param,
        companyID: proSettings.companyID,
        departmentIDs: [],
        groupIDs: []
      };
    }

    const startDateDiffFromNow = getDateDifferenceInDaysWithTime(new Date(), new Date(curSurveySchedule?.startDateTime || ""));
    if (curSurveyAudience) {
      dispatch(
        sendPutAudienceRequest(param, (success) => {
          if (success) {
            if (startDateDiffFromNow <= 0) {
              onSurveyActive();
            } else {
              onSurveyScheduled();
            }
          }
        })
      );
    } else {
      dispatch(
        sendPostAudienceRequest(param, (success) => {
          if (success) {
            if (startDateDiffFromNow <= 0) {
              onSurveyActive();
            } else {
              onSurveyScheduled();
            }
          }
        })
      );
    }
  };

  useEffect(() => {
    dispatch(sendGetDepartmentsRequest());
    dispatch(sendGetGroupsRequest());
  }, []);

  useEffect(() => {
    toggleNextBtn(nextPressed);
  }, [toggleNextBtn, orgWideCheck, addedUsers]);

  useEffect(() => {
    setTotalMembers(getMergedMembers(departmentsList, groupsList));
    setDepartData(getInitialDepartData(curSurveyAudience?.departmentIDs, departmentsList));
    setGroupData(getInitialGroupData(curSurveyAudience?.groupIDs, groupsList));
  }, [departmentsList, groupsList]);

  useEffect(() => {
    if (!orgWideCheck) {
      const mergedMember = getMergedMembers(departData.toRemove, groupData.toRemove);
      setAddedUsers(mergedMember);
      onUpdateAudience(mergedMember);
    }
  }, [orgWideCheck, departData, groupData]);

  return (
    <div className="survey-selectstaff">
      <div className="survey-audience-panel org-wide">
        <a
          onClick={() => {
            setOrgWideCheck(!orgWideCheck);
          }}
        >
          <img
            alt="Organization wide"
            src={
              orgWideCheck ? require("src/assets/survey/audience-orgwide-checked.png") : require("src/assets/survey/audience-orgwide.png")
            }
          />
          <h5>Organization wide</h5>
        </a>
        {/* <span>{totalMembers.length} Members</span> */}
      </div>
      <div className={`survey-selectstaff__content ${orgWideCheck ? "disabled" : ""}`}>
        <div className="listbox-wrap">
          <div>
            <AudienceListBox
              boxType="Add"
              audienceType="Department"
              audienceList={departData.toAdd}
              onClickAll={() => {
                setDepartData({
                  toAdd: [],
                  toRemove: departmentsList
                });
              }}
              onClickElement={(item) => {
                setDepartData(getUpdatedDeparts(departData.toAdd, departData.toRemove, item as DepartmentItem, "add"));
              }}
            />
            <AudienceListBox
              boxType="Remove"
              audienceType="Department"
              audienceList={departData.toRemove}
              onClickAll={() => {
                setDepartData({
                  toAdd: departmentsList,
                  toRemove: []
                });
              }}
              onClickElement={(item) => {
                setDepartData(getUpdatedDeparts(departData.toAdd, departData.toRemove, item as DepartmentItem, "remove"));
              }}
            />
          </div>
          <div>
            <AudienceListBox
              boxType="Add"
              audienceType="Group"
              audienceList={groupData.toAdd}
              onClickAll={() => {
                setGroupData({
                  toAdd: [],
                  toRemove: groupsList
                });
              }}
              onClickElement={(item) => {
                setGroupData(getUpdatedGroups(groupData.toAdd, groupData.toRemove, item as GroupItem, "add"));
              }}
            />
            <AudienceListBox
              boxType="Remove"
              audienceType="Group"
              audienceList={groupData.toRemove}
              onClickAll={() => {
                setGroupData({
                  toAdd: groupsList,
                  toRemove: []
                });
              }}
              onClickElement={(item) => {
                setGroupData(getUpdatedGroups(groupData.toAdd, groupData.toRemove, item as GroupItem, "remove"));
              }}
            />
          </div>
        </div>
        <div className="selected-users-wrap">
          <AudienceListResult userList={addedUsers} />
        </div>
      </div>
    </div>
  );
};

export default SelectStaff;
