import React, { ChangeEventHandler, FunctionComponent, ReactElement, useRef } from "react";

import "./styles.scss";

export type SurveyTitleInputProps = {
  onChange?: ChangeEventHandler<HTMLInputElement> | undefined;
  title?: string;
  className?: string;
};

export const SurveyTitleInput: FunctionComponent<SurveyTitleInputProps> = ({ onChange, title, className }): ReactElement => {
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <>
      <div
        onClick={() => {
          inputRef?.current?.focus();
        }}
        className={`survey-input-title-container ${className || ""}`}
      >
        <input
          ref={inputRef}
          value={title}
          placeholder="Survey title"
          className="survey-input-title"
          onChange={onChange}
          style={{ backgroundImage: 'url("../../../assets/survey/input-title-bg.png")' }}
        />
      </div>
      <a
        onClick={() => {
          inputRef?.current?.focus();
        }}
      >
        <img src={require("src/assets/survey/icon-pen.png")} alt="survey icon pen" style={{ paddingLeft: 5 }} />
      </a>
    </>
  );
};

export default SurveyTitleInput;
