export enum WBNFilterOptionType {
  Entity = 0,
  Company,
  Grade,
  AttentionCode,
  Race,
  Gender,
  CheckinData
}

export type OptionBaseType = {
  selected?: boolean;
  updatedAt?: number;
};

export type Company = OptionBaseType & {
  companyID: number;
  name?: string;
};

export type Entity = OptionBaseType & {
  entityID: number;
  name?: string;
  intermediate?: boolean;
  companies: Array<Company>;
};

export type GradeLevel = OptionBaseType & {
  gradeLevelID: number;
  description?: string;
};

// this is the wellbeing score, but named differently in the database
export type AttentionCode = OptionBaseType & {
  attentionCodeID: number;
  iconName?: string;
};

export type Race = OptionBaseType & {
  raceID: number;
  description?: string;
};

export type GenderID = OptionBaseType & {
  genderID: number;
  description?: string;
};

export type CheckinData = OptionBaseType & {
  startDate: string | null;
  endDate: string | null;
};

export type WbnFilter = {
  entities: Array<Entity>;
  gradeLevels: Array<GradeLevel>;
  lastCheckinDate: CheckinData;
  attentionCodes: Array<AttentionCode>;
  races: Array<Race>;
  genderIDs: Array<GenderID>;
};

export type WbnViewFilter = WbnFilter & {
  wbnViewFilterID?: number;
  viewName: string;
};

export type WbnTooltipType = "basic" | "component";

export type WbnTooltipTemplate = {
  type?: WbnTooltipType;
  // Displayed if 'basic' type
  message?: string;
  // Title and content passed in as prop if 'component' type
  title?: string;
  content?: [
    {
      text: string;
      iconSize?: number;
      icon?: string;
      align?: string;
    }
  ];
};

export type WbnFilterOption = {
  type: WBNFilterOptionType;
  id: number;
  name: string;
  updatedAt: number;
  count?: number;
};

export type WbnFiltersResponse = {
  success: boolean;
  message: string | null;
  data: WbnFilter;
};

export type WbnRowData = {
  accountID: number;
  firstName: string;
  lastName: string;
  lastCheckinDate: string | null;
  attentionCode: {
    description: string;
    icon: string;
    id: number;
    score: number;
  }
  wellbeingScore: string | null;
  outreachReason: {
    id: number | null;
    icon: string | null;
    text: string | null;
  };
  wbnOutreach: number;
  district: string;
  school: string;
  grade: string;
  raceID: number;
  genderID: number;
  raceDescription: string;
  genderDescription: string;
};

export type WbnDataResponse = {
  success: boolean;
  message: string | null;
  data: {
    wellnessNavigatorData: Array<WbnRowData>;
  };
};
