import React, { FunctionComponent } from "react";

import SurveyModal from "src/components/survey/SurveyModal";
import SurveyButton from "src/components/survey/SurveyButton";

import "./styles.scss";

export type SurveyCreatedModalProps = {
  isOpen: boolean;
  onSchedule: () => void;
  onScheduleLater: () => void;
};

export const SurveyCreatedModal: FunctionComponent<SurveyCreatedModalProps> = ({ isOpen = false, onSchedule, onScheduleLater }) => {
  return (
    <SurveyModal isOpen={isOpen}>
      <div className="survey-created-modal">
        <h1>Survey Created</h1>
        <img src={require("../../assets/images/graphics/survey-created-modal.png")} alt="survey created art" />
        <h4>Would you like to schedule your survey now?</h4>
        <SurveyButton customClass="survey-created-modal-button mb10" label="Schedule my survey now" onClick={onSchedule} />
        <SurveyButton customClass="survey-created-modal-button" type="outline" label="Save and schedule later" onClick={onScheduleLater} />
      </div>
    </SurveyModal>
  );
};

export default SurveyCreatedModal;
