/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { RootState } from "src/reducers";
import { validPassword } from "src/_core/utils/accountUtils/accountUtils";
import { sendChangePasswordRequest, setAccountError, clearAccountError, setChangePasswordSuccessful } from "src/reducers/auth/accountSlice";

import { SeityButton } from "src/components/analytic/SeityButton";
import { SeityFormTextInput } from "src/components/analytic/SeityTextInput/SeityFormTextInput";
import { AnalyticModal } from "src/components/analytic/AnalyticModal";
import { SeityPrimaryCancelButtons } from "src/components/analytic/SeityNavigationButtons";

import strings from "src/_core/strings/strings";
import "./maintenance.scss";

export const ChangePassword = ({}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { accountError, isPasswordChangeSuccessful } = useSelector((state: RootState) => {
    return state.account;
  });

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const cancelClicked = () => {
    dispatch(setChangePasswordSuccessful({ passwordChangeSuccessful: false }));
    history.push("/profile");
  };
  const changePasswordClicked = () => {
    if (!validPassword(newPassword)) {
      dispatch(setAccountError({ accountError: strings.invalidPassword + strings.passwordCriteria }));
    } else if (currentPassword === newPassword) {
      dispatch(setAccountError({ accountError: strings.passwordsTheSame }));
    } else {
      dispatch(sendChangePasswordRequest(currentPassword, newPassword));
    }
  };

  useEffect(() => {
    dispatch(clearAccountError());
  }, []);

  const isFormInvalid = () => {
    if (currentPassword === "" || newPassword === "") {
      return true;
    }
    return false;
  };

  return (
    <div className="maintenance-container">
      <h1>{strings.changePassword}</h1>
      <h4>{strings.allFieldsAreRequired}</h4>
      <div className="maintenance-content-wrap">
        <SeityFormTextInput
          inputLabelText={strings.currentPassword}
          value={currentPassword}
          onChange={(e) => {
            return setCurrentPassword(e.target.value);
          }}
          type="password"
        />
        <SeityFormTextInput
          inputLabelText={strings.newPassword}
          value={newPassword}
          onChange={(e) => {
            return setNewPassword(e.target.value);
          }}
          type="password"
          moreInfoText={strings.passwordCriteria}
          formStyle={{ marginTop: "20px" }}
        />
      </div>
      <AnalyticModal isOpen={isPasswordChangeSuccessful} onClose={cancelClicked}>
        <div className="maintenance-modal">
          <h2>{strings.changePasswordModalHeading}</h2>
          <SeityButton label={strings.changePasswordModalButtonText} type="next" onClick={cancelClicked} />
        </div>
      </AnalyticModal>
      <SeityPrimaryCancelButtons
        cancelAction={cancelClicked}
        cancelTitle={strings.cancelButtonText}
        primaryAction={changePasswordClicked}
        primaryTitle={strings.saveButtonText}
        primaryDisabled={isFormInvalid()}
        errorMessage={accountError}
        showCancel
      />
    </div>
  );
};

export default ChangePassword;
