import React, { FunctionComponent, ReactElement } from "react";
import Switch, { ReactSwitchProps } from "react-switch";
import "./styles.scss";

export type OptionType = { label: string | undefined; value: any };
export type OptionsType = Array<OptionType>;

export type SurveySwitchProps = {
  className?: string;
  label?: string;
} & ReactSwitchProps;

export const SurveySwitch: FunctionComponent<SurveySwitchProps> = ({
  className,
  label,
  uncheckedIcon = false,
  checkedIcon = false,
  ...props
}): ReactElement => {
  return (
    <div className={`survey-switch-container ${className || ""}`}>
      <Switch {...props} uncheckedIcon={uncheckedIcon} checkedIcon={checkedIcon} />
      {label && <span>{label}</span>}
    </div>
  );
};

export default SurveySwitch;
