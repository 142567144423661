/* eslint-disable max-len */
import React, { FunctionComponent, ReactElement, useRef } from "react";
import { useToasts } from "react-toast-notifications";

import { AtsOutcomeCategory, AtsOutcomeSubcategory } from "src/api/ats/atsTypes";
import { getFullImageUrl } from "src/_core/utils/utils";

import "./styles.scss";
import AtsOutcomeCategoryItemAccordion from "./AtsOutcomeCategoriesItemAccordion";

export type AtsOutcomeCategoriesItemProps = {
  outcomeCategory: AtsOutcomeCategory;
  outcomeSubcategories?: AtsOutcomeSubcategory[];
  id: string;
};

export type AtsOutcomeCategoriesCaptionProps = {
  title: string;
  description: string;
  iconFileName: string;
};

const AtsOutcomeCategoriesCaption: FunctionComponent<AtsOutcomeCategoriesCaptionProps> = ({ title, description }): ReactElement => {
  const { addToast } = useToasts();

  return (
    <div className="ats-outcomecategoriesitem__caption">
      <div className="ats-outcomecategoriesitem__caption__header">
        <h5>{title}</h5>
      </div>

      <div className="ats-outcomecategoriesitem__caption__description" dangerouslySetInnerHTML={{ __html: description }} />
      <a
        title="Copy"
        className="btn-copy"
        onClick={() => {
          navigator.clipboard.writeText(description);
          addToast("Copied to clipboard!", { appearance: "success", autoDismiss: true });
        }}
      >
        <img src={require("src/assets/ats/btn-copy.png")} alt="Click to copy text" />
      </a>
    </div>
  );
};

export const AtsOutcomeCategoriesItem: FunctionComponent<AtsOutcomeCategoriesItemProps> = ({
  outcomeCategory,
  outcomeSubcategories: outcomeSubcategory,
  id
}): ReactElement => {
  const imageUrl = getFullImageUrl(outcomeCategory.iconFileName);
  const contentRef = useRef<HTMLDivElement>(null);

  return (
    <div className="ats-outcomecategoriesitem" id={id}>
      <a className="ats-outcomecategoriesitem__header">
        <h2>{outcomeCategory.category}</h2>
      </a>
      <div className="ats-outcomecategoriesitem__content-wrap" ref={contentRef}>
        <div className="ats-outcomecategoriesitem__content-wrap__container">
          <div className="outcomecategory-icon">
            <img src={imageUrl} alt="OutcomeCategory Icon" width={143} />
          </div>
          <AtsOutcomeCategoriesCaption
            iconFileName={outcomeCategory.iconFileName}
            title="Definition"
            description={outcomeCategory.description}
          />
        </div>
        {outcomeCategory.outcomeCategoryID && (
          <AtsOutcomeCategoryItemAccordion
            outcomeSubcategories={outcomeSubcategory}
            outcomeCategory={outcomeCategory.category}
            outcomeCategoryID={outcomeCategory.outcomeCategoryID}
          />
        )}
      </div>
    </div>
  );
};

export default AtsOutcomeCategoriesItem;
