// Imports
import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { TeacherList } from "src/api/analytics/types/analyticsTypes";
import { RootState } from "src/reducers";
import { sendGetAccountInfoRequest } from "src/reducers/auth/accountSlice";
import { getIsAdmin, getIsIndividualCompanyAccess } from "src/utils/helper";

import "./styles.scss";

export interface SeityBreadCrumbsProps {
  reportType: "Student" | "Staff";
  companyName: string;
}

export const SeityBreadCrumbs = (props: SeityBreadCrumbsProps) => {
  const { reportType, companyName } = props;

  const dispatch = useDispatch();

  const { curTeacherClass, curTeacherSchool, curTeacher } = useSelector((state: RootState) => {
    return state.analytics;
  });

  const { accountInfo } = useSelector((state: RootState) => {
    return state.account;
  });

  const isAdmin = getIsAdmin();
  const isIndividualCompanyAccess = getIsIndividualCompanyAccess();

  let teacherName = "";
  if (isAdmin) {
    if (curTeacher && (curTeacher as TeacherList).accountID) {
      teacherName = `${(curTeacher as TeacherList).firstName} ${(curTeacher as TeacherList).lastName}`;
    }
  } else {
    teacherName = `${accountInfo.firstName} ${accountInfo.lastName}`;
  }

  useEffect(() => {
    if (accountInfo.firstName === "") {
      dispatch(sendGetAccountInfoRequest());
    }
  }, []);
  
  return (
    <div className="breadcrumbs-container">
      {reportType === "Student" ? (
        <>
          {curTeacherSchool && (
            <div className="school-wrap">
              <div className="school-wrap__content">
                <span className="title">School</span>
                <span className="desc">{curTeacherSchool?.schoolName}</span>
              </div>
            </div>
          )}
          {((!isAdmin && !isIndividualCompanyAccess ) || (isAdmin && curTeacher)|| (isIndividualCompanyAccess && curTeacher)) && (
            <div className={`teacher-wrap ${curTeacherSchool ? "" : "no-background"}`}>
              <div className={`teacher-wrap__content ${curTeacherSchool || curTeacherClass ? "" : "top-right-radius"}`}>
                <span className="title">Teacher</span>
                <span className="desc">{`${teacherName}`}</span>
              </div>
            </div>
          )}
          {curTeacherClass && (
            <div className="class-wrap">
              <div className="class-wrap__content">
                <span className="title">Class</span>
                <span className="desc">{curTeacherClass.classDescription}</span>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="school-wrap">
          <div className="school-wrap__content">
            <span className="title">Company</span>
            <span className="desc">{companyName}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default SeityBreadCrumbs;
