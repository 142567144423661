import { FunctionComponent, ReactElement, useState } from "react";
import "./styles.scss";
import WbnCheckbox from "../WbnCheckbox";
import SeityModal from "src/components/SeityModal";
import WbnButton from "../WbnButton";
import CoreStrings from "src/_core/strings/strings";

export type WbnButtonMenuModalProps = {
  position?: { top: number; left: number };
  data: any[];
  isOpen: boolean;
  title?: string;
  selectedColumns: any[];
  setSelectedColumns: (selectedColumns: any) => void;
  onApply: () => void;
  wrapperClassName?: string;
};

// The following style is declared here because it must override styles
// that are elsewhere flagged as !important.
const resetButtonStyle = {
  color: "black",
  padding: 0,
  marginRight: 8,
  fontWeight: "normal"
};

export const WbnButtonMenuModal: FunctionComponent<WbnButtonMenuModalProps> = ({
  position,
  isOpen = false,
  title = "Show",
  data,
  selectedColumns,
  setSelectedColumns,
  onApply
}): ReactElement => {
  const [options, setOptions] = useState(selectedColumns);

  const handleOptionsSelection = (item: any) => {
    if (options.includes(item.id)) {
      setOptions(
        options.filter((id) => {
          return id !== item.id;
        })
      );
    } else {
      setOptions([...options, item.id]);
    }
  };

  const parseOptions = (arr) => {
    return arr.map((elem, index) => {
      return (
        <WbnCheckbox
          key={index}
          label={elem.title}
          onClick={() => {
            handleOptionsSelection(elem);
          }}
          checked={options.includes(elem.id)}
        />
      );
    });
  };

  const style = {
    position: "absolute",
    top: position?.top,
    left: position?.left,
    height: window.innerHeight - (position?.top ?? 0) - 30
  };

  return (
    <SeityModal
      overlayClassName={"wbn-disabled-overlay"}
      isOpen={isOpen}
      wrapperClassName="wbn-button-menu-modal"
      style={position && style}
      onClose={() => {
        onApply();
      }}
    >
      <div className="wbn-modal-children-holder">
        {title?.length > 0 && <h3>{title}</h3>}
        <div className="wbn-modal-content-wrap">{parseOptions(data)}</div>
        <div className="wbn-modal-btn-wrap">
          <WbnButton
            type="plain-text"
            label={CoreStrings.reset}
            customClass="wbn-modal-button"
            style={{
              ...resetButtonStyle
            }}
            onClick={() => {
              const allOptions = data.map((c) => {
                return c.id;
              });
              setOptions(allOptions);
              setSelectedColumns(allOptions);
              onApply();
            }}
          />
          <WbnButton
            type="submit"
            label={CoreStrings.apply}
            customClass="wbn-modal-button"
            onClick={() => {
              setSelectedColumns(options);
              onApply();
            }}
          />
        </div>
      </div>
    </SeityModal>
  );
};

export default WbnButtonMenuModal;
