import React, { ReactElement, useMemo, useState } from "react";

import { useHistory } from "react-router-dom";

import StaffReports from "src/features/StaffReports";

import titleImg from "src/assets/images/graphics/welcome-title.png";
import studentImg from "src/assets/images/graphics/img-student.png";
import staffImg from "src/assets/images/graphics/img-staff.png";
import reportImg from "src/assets/images/graphics/img-reports.png";
import surveyImg from "src/assets/images/graphics/img-survey.png";
import atsImg from "src/assets/images/graphics/img-ats.png";
import wbnImg from "src/assets/images/graphics/img-wbn.png";

import SeityAlert from "src/components/analytic/SeityAlert";
import { ProSettings } from "../../../api/account/types/accountTypes";
import CoreStrings from "src/_core/strings/strings";

export type WelcomeProps = {
  proSettings: ProSettings;
  embedToken: string;
  updateDataset?: () => {};
};

export const Welcome = ({ proSettings, embedToken }): ReactElement => {
  const history = useHistory();
  const [showUnauthAlert, setShowUnauthAlert] = useState(false);
  const [showNoDataAlert, setShowNoDataAlert] = useState(false);
  const [showReports, setShowReports] = useState(false);

  const handleReportClick = (type: "Student" | "Staff") => {
    if (embedToken && embedToken.length > 0 && ((type == "Staff") || 
      (type == "Student" && proSettings.teacherSiteDataPresent && proSettings.teacherSiteDataPresent))) {
      history.push("/home", { reportType: type });
    } else if(type == "Student" && (!proSettings.teacherSiteDataPresent || !proSettings.teacherClassDataPresent) ) {
      setShowNoDataAlert(true);
    } else {
      setShowUnauthAlert(true);
    }
  };

  const welcomeTiles = useMemo(() => {
    return [
      {
        title: CoreStrings.studentAnalytics,
        img: studentImg,
        enabled: proSettings?.studentAnalytics ?? false,
        onClick: () => {
          handleReportClick("Student");
        }
      },
      {
        title: CoreStrings.wellbeingNavigator,
        img: wbnImg,
        enabled: proSettings?.wbn ?? false,
        onClick: () => {
          history.push("/wbn");
        }
      },
      {
        title: CoreStrings.staffAnalytics,
        img: staffImg,
        enabled: proSettings?.staffAnalytics ?? false,
        onClick: () => {
          handleReportClick("Staff");
        }
      },
      {
        title: CoreStrings.staffReports,
        img: reportImg,
        enabled: proSettings?.staffReportsEnabled ?? false,
        onClick: () => {
          setShowReports(true);
        }
      },
      {
        title: CoreStrings.surveyWizard,
        img: surveyImg,
        enabled: proSettings?.surveyWizard ?? false,
        onClick: () => {
          history.push("/survey");
        }
      },
      {
        title: CoreStrings.referenceGuide,
        img: atsImg,
        enabled: true,
        onClick: () => {
          history.push("/atshome");
        }
      }
    ];
  }, [proSettings, embedToken]);

  return (
    <div className="welcome-wrap">
      <h1>Welcome to</h1>
      <img src={titleImg} width={260} alt="Welcome Title" />
      <div>
        <h3 className="company-title">{proSettings?.companyName}</h3>
        <div className="button-wrap">
          {welcomeTiles.map((tile, index) => {
            return (
              <button key={index} onClick={tile.onClick} className={`${tile.enabled ? "" : "button-disabled"}`}>
                <img src={tile.img} alt="Tile Img" />
                <h3>{tile.title}</h3>
              </button>
            );
          })}
        </div>
      </div>
      <StaffReports
        isOpen={showReports}
        onClose={() => {
          setShowReports(false);
        }}
      />
      <SeityAlert
        visible={showUnauthAlert}
        onToggle={() => {
          setShowUnauthAlert(false);
        }}
        title={"The data you are trying to access is not available at this time."}
        subTitle=""
      />
       <SeityAlert
        visible={showNoDataAlert}
        onToggle={() => {
          setShowNoDataAlert(false);
        }}
        title={"There currently is no data for you to view at this time."}
        subTitle=""
      />
    </div>
  );
};

export default Welcome;
