import { FunctionComponent, ReactElement } from "react";
import "./styles.scss";
import { Tooltip } from "react-tooltip";
import WbnTooltipInterior from "./WbnTooltipInterior";
import { WbnTooltipType } from "src/api/wbn/wbnTypes";

export type WbnTooltipProps = {
  content?: any[];
  title?: string;
  type?: WbnTooltipType;
  message?: string;
  id: string;
};

export const WbnTooltip: FunctionComponent<WbnTooltipProps> = ({ content = [], title, type = "basic", message, id }): ReactElement => {
  const classNameByType = {
    basic: "wbn-tooltip-basic",
    component: "wbn-tooltip-component"
  };

  return (
    <div className="wbn-tooltip-holder">
      <Tooltip className={classNameByType[type]} id={id} noArrow={true} place={type === "component" ? "bottom" : "top"}>
        {type === "basic" && <p>{message}</p>}
        {type === "component" && <WbnTooltipInterior title={title} content={content} />}
      </Tooltip>
    </div>
  );
};

export default WbnTooltip;
