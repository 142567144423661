//all commented out code in this file was commented out to avoid compile errors and will need to be put back in once LS or Cookie is
//implemented

import { createSlice } from "@reduxjs/toolkit";
import { login, forgotPassword, signup, getNewToken } from "../../api/seityHealthAPI-Auth";
import { removeTokens } from "./useSessionStorageToken";
import { getExpirationTime, getRefreshKey, getToken, saveExpiration, saveRefreshKey, cacheToken, getRefreshedToken } from "./useSessionStorageToken";
import { resetRegisterSlice } from "./registerSlice";
import { resetAccountSlice, sendGetAccountInfoRequest } from "./accountSlice";
import { resetAnalyticsSlice } from "./analyticsSlice";
import { resetSurveySlice } from "../survey/surveySlice";
import { resetPBI } from "src/actions/pbi";
import {persistor} from "../index";


export interface AuthState {
  readonly authError: string | null;
  readonly isLoading: boolean;
  readonly token: string;
  readonly refreshKey: string;
  readonly accountID: number;
  readonly companyName: string;
  readonly groupID: string | null; // PowerBI group ID.
  readonly expirationTime: number;
  readonly resetPasswordSuccess: boolean;
  readonly shouldRedirectToLogin: boolean;
  readonly changePasswordPermissionsEnabled: boolean;
}

export const setInitialState = {
  authError: null,
  isLoading: false,
  token: "",
  refreshKey: "",
  accountID: -1,
  groupID: null,
  expirationTime: -1,
  resetPasswordSuccess: false,
  shouldRedirectToLogin: false,
  changePasswordPermissionsEnabled: false
} as AuthState;

const authSlice = createSlice({
  name: "auth",
  initialState: setInitialState,
  reducers: {
    loginRequest(state, action) {
      const { token, refreshKey, accountID, companyName, groupID, expirationTime, changePasswordPermissionsEnabled} = action.payload;
      state.accountID = accountID;
      state.companyName = companyName;
      state.groupID = groupID;
      state.token = token;
      state.refreshKey = refreshKey;
      state.expirationTime = expirationTime;
      state.isLoading = false;
      state.authError = null;
      state.changePasswordPermissionsEnabled = changePasswordPermissionsEnabled;
    },
    logoutRequest(state, action) {
      state.token = "";
      state.refreshKey = "";
      state.accountID = -1;
      state.groupID = null;
      state.expirationTime = -1;
    },
    forgotPasswordRequestSuccess(state) {
      state.isLoading = false;
      state.authError = null;
      state.resetPasswordSuccess = true;
    },
    setTokens(state, action) {
      const { token, refreshKey, expirationTime } = action.payload;
      state.token = token;
      state.refreshKey = refreshKey;
      state.expirationTime = expirationTime;
      state.isLoading = false;
    },
    refreshToken(state, action) {
      const { token, refreshKey, expirationTime } = action.payload;
      state.token = token;
      state.refreshKey = refreshKey;
      state.expirationTime = expirationTime;
    },
    setRedirectToLogin(state, action) {
      state.isLoading = false;
      state.shouldRedirectToLogin = action.payload.shouldRedirectToLogin;
    },
    signupRequest(state, action) {
      const { token, refreshKey, accountID, expirationTime } = action.payload;
      state.accountID = accountID;
      state.token = token;
      state.refreshKey = refreshKey;
      state.expirationTime = expirationTime;
      state.isLoading = false;
      state.authError = null;
    },
    apiError(state, action) {
      const { authError } = action.payload;
      state.authError = authError;
      state.isLoading = false;
      state.resetPasswordSuccess = false;
    },
    clearApiError(state, action) {
      state.authError = null;
    },
    setIsLoading(state, action) {
      state.isLoading = true;
    },
    resetAuthSlice: () => setInitialState
  }
});
// Be sure to add new slices here to
// be able to access them directly
export const {
  loginRequest,
  apiError,
  clearApiError,
  setIsLoading,
  forgotPasswordRequestSuccess,
  signupRequest,
  setTokens,
  refreshToken,
  setRedirectToLogin,
  logoutRequest,
  resetAuthSlice
} = authSlice.actions;
export default authSlice.reducer;

// THUNK FUNCTIONS
export const sendLoginRequest = (username: string, password: string) => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({}));
    dispatch(clearApiError({}));
    const loginResponse = await login(username, password);
    if (loginResponse === null || loginResponse.data === null) {
      dispatch(apiError({ authError: "There was an error logging in, please try again." }));
      return;
    }

    if (loginResponse.data && loginResponse.data.token) {
      cacheToken(loginResponse.data.token);
      saveRefreshKey(loginResponse.data.refreshKey);
      saveExpiration(loginResponse.data.expirationTime);
    }
    dispatch(loginRequest(loginResponse.data));
    dispatch(sendGetAccountInfoRequest());
  } catch (err: any) {
    handleError(err, dispatch);
  }
};

export const sendLogoutRequest = () => (dispatch: any) => {
  removeTokens();
  dispatch(logoutRequest({}));
};

export const sendForgotPasswordRequest = (email: string) => async (dispatch: any) => {
  try {
    dispatch(setIsLoading({}));
    dispatch(clearApiError({}));
    await forgotPassword(email);
    dispatch(forgotPasswordRequestSuccess());
  } catch (err) {
    dispatch(apiError({ authError: "There was an error sending your forgot password request, please try again." }));
  }
};

export const sendSignupRequest =
  (email: string, password: string, firstName: string, lastName: string, groupActivationCode: string) => async (dispatch: any) => {
    try {
      dispatch(setIsLoading({}));
      dispatch(clearApiError({}));
      const signupResponse = await signup(email, password, firstName, lastName, groupActivationCode);
      if (signupResponse === null || signupResponse.success === false || signupResponse.message) {
        dispatch(apiError({ authError: "There was an error signing you up, please try again." }));
        return;
      }
      if (signupResponse.data && signupResponse.data.returnCode === 1) {
        dispatch(apiError({ authError: "The email address is already registered." }));
        return;
      }
      if (signupResponse.data && signupResponse.data.returnCode === 2) {
        dispatch(apiError({ authError: "The activation code entered is invalid." }));
        return;
      }
      if (signupResponse.data && signupResponse.data.token) {
        console.log("Signup success.");
        cacheToken(signupResponse.data.token);
        saveRefreshKey(signupResponse.data.refreshKey);
        saveExpiration(signupResponse.data.expirationTime);
      }
      dispatch(signupRequest(signupResponse.data));
    } catch (err) {
      console.error(JSON.stringify(err));
      handleError(err, dispatch);
    }
  };

export const getSavedToken = (callback?: () => void) => async (dispatch: any) => {
  dispatch(setIsLoading({}));
  const token = await getToken();
  const refreshKey = await getRefreshKey();
  const expirationTime = await getExpirationTime();

  if (token && expirationTime) {
    try {
      if (!expirationTime || isNaN(expirationTime)) {
        dispatch(setRedirectToLogin({ shouldRedirectToLogin: true }));
        return;
      }
      const expirationDate = new Date(expirationTime * 1000);
      if (new Date(new Date().getTime() + 30 * 60 * 1000) > expirationDate) {
        console.log("Saved token expired, attempting to refresh token...");
        dispatch(sendRefreshToken());
        return;
      }
      dispatch(setTokens({ token, refreshKey, expirationTime }));
      if (callback) {
        callback();
      }
    } catch (err) {
      console.error(err);
      dispatch(setRedirectToLogin({ shouldRedirectToLogin: true }));
    }
  } else {
    console.log("Missing token or expiration time:", refreshKey, expirationTime);
    dispatch(setRedirectToLogin({ shouldRedirectToLogin: true }));
  }
};

export const sendRefreshToken = () => async (dispatch: any) => {
  dispatch(setIsLoading({}));
  const refreshKey = await getRefreshKey();
  console.log("refreshKey:", refreshKey);
  const token = await getToken();
  if (!refreshKey) {
    dispatch(setRedirectToLogin({ shouldRedirectToLogin: true }));
    return;
  }
  try {
    const response = await getNewToken(refreshKey, token ?? "");
    if (response.success === false) {
      removeTokens();
      dispatch(setRedirectToLogin({ shouldRedirectToLogin: true }));
      return;
    }
    if (response.data && response.data.token) {
      console.log("Token refreshed successfully.");
      cacheToken(response.data.token);
      saveRefreshKey(response.data.refreshKey);
      saveExpiration(response.data.expirationTime);
      dispatch(refreshToken(response.data));
    } else {
      removeTokens();
      dispatch(setRedirectToLogin({ shouldRedirectToLogin: true }));
    }
  } catch (err) {
    console.error(err);
    removeTokens();
    dispatch(setRedirectToLogin({ shouldRedirectToLogin: true }));
  }
};

export const checkToken = () => async (dispatch: any) => {
  const { token } = await getRefreshedToken();
  if (token === null) {
    dispatch(setRedirectToLogin({ shouldRedirectToLogin: true }));
  }
};

export const logOut = () => async (dispatch: any) => {
  dispatch(resetRegisterSlice());
  dispatch(resetAuthSlice());
  dispatch(resetAccountSlice());
  dispatch(resetAnalyticsSlice());
  dispatch(resetSurveySlice());
  dispatch(resetPBI());
  removeTokens();

  await persistor.purge();
};

function handleError(err: any, dispatch: any) {
  console.error(err);
  dispatch(apiError({ authError: err.message }));
}
