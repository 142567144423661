export const getFullImageUrl = (iconFileName: string) => {
  return `https://cdne-shcdn-global-001.azureedge.net/graphics/${iconFileName.toLowerCase()}`;
};

export const downloadImageFromUrl = (url: string, filename: string) => {
  fetch(url, {
    method: "GET",
    headers: {}
  })
    .then((response) => {
      response.arrayBuffer().then((buffer) => {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename); // or any other extension
        document.body.appendChild(link);
        link.click();
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const scrollToID = (cssID?: string, opt?: ScrollOptions) => {
  if (!cssID) return;
  const section = document.querySelector(`#${cssID}`);

  section?.scrollIntoView(opt || { behavior: "smooth", block: "center" });
};
