import React, { FunctionComponent, ReactElement } from "react";
import { useDispatch } from "react-redux";

import SortSelect from "react-select";
import { setSortBy, SorttByType } from "src/reducers/survey/surveySlice";

export type SurveySortBySelectProps = {
  selectedIdx: number;
  onClick: (selIndex: number) => void;
};

type SortByOptsType = { label: string; value: SorttByType }[];
const SortByOpts: SortByOptsType = [
  { label: "Date Created", value: "dateCreated" },
  { label: "Date Modified", value: "dateModified" }
];

const customStyles = {
  control: () => {
    return {
      display: "flex"
    };
  },
  menu: (styles) => {
    return {
      ...styles,
      "backgroundColor": "#fff8eb",
      "padding": "5px",
      "border": "solid 1px #495459",
      ":hover": {
        color: "#b7b5ab"
      }
    };
  }
};

export const SurveySortBySelect: FunctionComponent<SurveySortBySelectProps> = (): ReactElement => {
  const dispatch = useDispatch();

  const changeSort = (value: SorttByType) => {
    if (!value) return;
    dispatch(setSortBy(value));
  };

  return (
    <>
      <SortSelect
        styles={customStyles}
        onChange={(value) => {
          return changeSort(value?.value || null);
        }}
        options={SortByOpts}
        className="sortby-selector"
      />
    </>
  );
};

export default SurveySortBySelect;
