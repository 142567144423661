import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation as useRouterLocation, Link} from "react-router-dom";
import seityHeaderBg from "src/assets/images/img-header-bg.png";
import seityHeaderBgRound from "src/assets/images/img-header-bg-round.png";
import SeitySavedViews from "src/components/analytic/SeitySavedViews";
import {RootState} from "src/reducers";
import {sendGetAccountInfoRequest} from "src/reducers/auth/accountSlice";

import "./styles.scss";
import SeityTooltip from "../SeityTooltip";

const HIDE_HEADER_ROUTES = ["/login"];

export interface SeityHeaderProps {
}

export const SeityHeader = () => {
    const dispatch = useDispatch();

    const {accountInfo} = useSelector((state: RootState) => {
        return state.account;
    });

    const {proSettings} = useSelector((state: RootState) => {
        return state.account;
    });

    const {changePasswordPermissionsEnabled} = useSelector((state: RootState) => {
        return state.auth;
    });

    useEffect(() => {
        if (accountInfo.firstName === "") {
            dispatch(sendGetAccountInfoRequest());
        }
    }, []);
    const [showSavedViews, setShowSavedViews] = useState(false);

    const routerLocation = useRouterLocation();

    const groupsAndDepartmentsTooltip = (
        <div className="gad-tooltip">
            {proSettings?.groupManagement && (
                <Link to="/groups" className="gad-tooltip-item">
                    Groups
                </Link>
            )}
            {proSettings?.departmentManagement && (
                <Link to="/departments" className="gad-tooltip-item">
                    Departments
                </Link>
            )}
        </div>
    );

    const adminTooltip = (
        <div className="gad-tooltip">
            <Link to="/resetPassword" className="gad-tooltip-item">
                Reset Password
            </Link>
        </div>
    );

    const menuItems = () => {
        return (
            <ul>
                <li>
                    <span>SEITY PRO</span>
                </li>
                <li>
                    <Link to="/">Home</Link>
                </li>
                {(proSettings?.groupManagement || proSettings?.departmentManagement) && (
                    <li>
                        <SeityTooltip content={groupsAndDepartmentsTooltip} direction="bottom" delay={200}>
                            <a>Setup</a>
                        </SeityTooltip>
                    </li>
                )}
                <li>
                    <Link to="/profile">Account</Link>
                </li>
                {changePasswordPermissionsEnabled && <li>
                  <li>
                    <SeityTooltip content={adminTooltip} direction="bottom" delay={200}>
                      <a>Admin</a>
                    </SeityTooltip>
                  </li>
                </li>}
            </ul>
        );
    };

    return (
        <>
            {!HIDE_HEADER_ROUTES.includes(routerLocation.pathname) ? (
                <header className="seity_header" aria-label="header-container">
                    <img
                        src={routerLocation.pathname.includes("atshome") ? seityHeaderBg : seityHeaderBgRound}
                        className="seity-header__bg"
                        alt="header bg"
                    />
                    <div className="seity-header__main">
                        <div className="seity-header__main__center">{menuItems()}</div>
                        <div
                            className="seity-header__main__right">{`${accountInfo.firstName} ${accountInfo.lastName}`}</div>
                    </div>
                </header>
            ) : null}
            <SeitySavedViews
                isOpen={showSavedViews}
                onClose={() => {
                    setShowSavedViews(false);
                }}
            />
        </>
    );
};
