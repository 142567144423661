import React, { FunctionComponent, ReactElement } from "react";
import "./styles.scss";

export type SurveySectionProps = {
  className?: string;
  style?: {};
};

export const SurveySection: FunctionComponent<SurveySectionProps> = ({ children, className, style = {} }): ReactElement => {
  return (
    <div className={`survey-section ${className || ""}`} style={style}>
      {children}
    </div>
  );
};

export default SurveySection;
