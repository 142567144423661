import { getRefreshedToken } from "./auth/useSessionStorageToken";
import { setRedirectToLogin } from "./auth/authSlice";
import { SeityAuthenticationError } from "../api/authTypes";

export const validateToken = async (dispatch: any) => {
  const { token } = await getRefreshedToken();
  if (token === null) {
    dispatch(setRedirectToLogin({ shouldRedirectToLogin: true }));
    // the function will not return token since it is blocked here and redirect to the login page.
    throw new SeityAuthenticationError();
  }
  return token;
};
