import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { arrayMove } from "react-sortable-hoc";
import { sendGetSurveyWizardRequest } from "src/reducers/survey/surveySlice";
import {
  sendGetSurveyWizardGroupRequest,
  sendPostCreateSurveyRequest,
  sendUpdateSurveyRequest
} from "src/reducers/survey/surveyQuestionSlice";
import { StepWizardChildProps } from "react-step-wizard";

import strings from "src/_core/strings/strings";
import AnalyticModal from "src/components/analytic/AnalyticModal";
import SeityButton from "src/components/analytic/SeityButton";
import SurveyButton from "src/components/survey/SurveyButton";
import SurveySection from "src/components/survey/SurveySection";
import SeityTextArea from "src/components/survey/SurveyTextArea";
import { RootState } from "src/reducers";
import SurveySwitch from "src/components/survey/SurveySwitch";
import SurveySetupHeader from "./SurveySetupHeader";
import { SurveyQuestionList } from "./SurveyQuestion";
import SurveyPreview from "./SurveyPreview";

import { SurveyQuestionType } from "./types";
import "./styles.scss";

export type SurveyQuestionSetupProps = {
  onSaveDraft: () => void;
  onUpdateDraft: () => void;
  setCurrentWizardID: (surveyWizardID: number | null) => void;
  onTogglePreview: (isOpen: boolean) => void;
  surveyWizardID: number | null;
  index: number;
};

const emptyQuestion = {
  surveyWizardQuestionID: 0,
  question: "",
  surveyWizardAnswerGroupID: 0,
  sortOrder: 0,
  id: "1"
};

export const SurveyQuestionSetup: FunctionComponent<SurveyQuestionSetupProps & Partial<StepWizardChildProps>> = ({
  onSaveDraft,
  onUpdateDraft,
  setCurrentWizardID,
  onTogglePreview,
  surveyWizardID,
  index,
  nextStep
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [title, setTitle] = useState("");
  const [addLottoEntry, setAddLottoEntry] = useState(false);
  const [randomizeQuestions, setRandomizeQuestions] = useState(false);
  const [description, setDescription] = useState("");
  const [showPreview, setPreview] = useState(false);
  const [showConfirmCancel, setShowConfirmCancel] = useState(false);
  const [questions, setQuestions] = useState<SurveyQuestionType[]>([
    {
      surveyWizardQuestionID: 0,
      question: "",
      surveyWizardAnswerGroupID: 0,
      sortOrder: 0
    }
  ]);
  const [showPreviewBtn] = useState(true);
  const [surveyIsLoading, setSurveyIsLoading] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [showSaved] = useState(false);

  const [isTitleEmpty, setIsTitleEmpty] = useState(false);
  const [isDescriptionEmpty, setIsDescriptionEmpty] = useState(false);
  const [questionsExists, setQuestionsExists] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const { currentSurvey, surveyWizardAnswerGroup } = useSelector((state: RootState) => {
    return state.survey;
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setQuestions(arrayMove(questions, oldIndex, newIndex));
  };

  const handleChangeText = (text: any, type: "Select" | "Text", i: any) => {
    const questionsChanged = questions.map((surveyQuestion, currIndex) => {
      if (currIndex === i) {
        if (type === "Text") {
          return {
            ...surveyQuestion,
            question: text
          };
        }
        return {
          ...surveyQuestion,
          surveyWizardAnswerGroupID: text.value
        };
      }
      return surveyQuestion;
    });

    setQuestions(questionsChanged);
  };

  const saveSurvey = () => {};

  const addDuplicate = (arr: SurveyQuestionType[], indexTarget: number, question: SurveyQuestionType) => {
    const arrayResult: SurveyQuestionType[] = [];

    let sortPosition = 0;
    arr.forEach((item, i) => {
      arrayResult.push({ ...item, sortOrder: sortPosition });
      sortPosition += 1;
      if (i === indexTarget) {
        arrayResult.push({ ...question, sortOrder: sortPosition });
        sortPosition += 1;
      }
    });

    return arrayResult;
  };

  const cleanForm = () => {
    setTitle("");
    setIsTitleEmpty(false);
    setDescription("");
    setIsDescriptionEmpty(false);
    setQuestions([emptyQuestion]);
    setQuestionsExists(false);
    setRandomizeQuestions(false);
    setAddLottoEntry(false);
  };

  const onRequestClose = () => {
    // cleanForm();
    // onClose();
    if (nextStep) {
      window.scrollTo(0, 0);
      nextStep();
    }
  };

  const checkTitle = () => {
    if (!title) {
      setIsTitleEmpty(true);
      throw new Error("Please complete all required fields.");
    } else setIsTitleEmpty(false);
  };

  const checkDescription = () => {
    if (!description) {
      setIsDescriptionEmpty(true);
      throw new Error("Please complete all required fields.");
    } else {
      setIsDescriptionEmpty(false);
    }
  };

  const checkQuestions = () => {
    const questionContentObj = {};
    let questionMsg = "";
    questions.forEach((q) => {
      if (!q.surveyWizardAnswerGroupID || !q.question) {
        questionMsg = "Question cannot be empty";
        return;
      }

      if (questionContentObj[q.question]) {
        setQuestionsExists(true);
        questionMsg = "Questions must be different from each other.";
      } else {
        questionContentObj[q.question] = true;
        setQuestionsExists(false);
      }
    });

    if (questionMsg) {
      setQuestionsExists(false);
      throw new Error(questionMsg);
    }
  };

  const validateForm = () => {
    checkTitle();
    checkDescription();
    checkQuestions();
  };

  const saveDraft = () => {
    try {
      validateForm();

      const questionsOrdered = questions.map((q, i) => {
        return {
          ...q,
          sortOrder: i
        };
      });

      if (questions.length === 0) {
        setErrorMessage("Questions are required");
        return;
      }

      setErrorMessage("");
      setIsSaveLoading(true);
      if (surveyWizardID) {
        dispatch(
          sendUpdateSurveyRequest(
            surveyWizardID,
            title,
            description,
            questionsOrdered,
            index,
            addLottoEntry,
            randomizeQuestions,
            (surveyWizardUpdated) => {
              setIsSaveLoading(false);
              if (surveyWizardUpdated) {
                onRequestClose();
                onUpdateDraft();
              }
            }
          )
        );
      } else {
        dispatch(
          sendPostCreateSurveyRequest(title, description, questionsOrdered, addLottoEntry, randomizeQuestions, (surveyWizardAdded) => {
            setIsSaveLoading(false);
            if (surveyWizardAdded) {
              onRequestClose();
              onSaveDraft();
              setCurrentWizardID(surveyWizardAdded.surveyWizardID || null);
            }
          })
        );
      }
    } catch (err) {
      setIsSaveLoading(false);
      console.log(err);
      setErrorMessage(`${err}`);
    }
  };

  const handleDuplicate = (value: SurveyQuestionType) => {
    let temp = [...questions];
    const questionToDupIndex = temp.findIndex((item) => {
      return item.id === value.id;
    });
    const { surveyWizardQuestionID, ...toDup } = { ...temp[questionToDupIndex] };
    toDup.id = (questions.length + 1).toString();

    if (toDup) {
      temp = addDuplicate(temp, questionToDupIndex, toDup);
    } else {
      console.log("Can't find this element");
    }
    setQuestions(temp);
  };
  const handleRemove = (value: SurveyQuestionType) => {
    if (questions.length === 1) {
      setQuestions([emptyQuestion]);
      return;
    }
    const temp = questions.filter((q) => {
      return q.surveyWizardQuestionID !== value.surveyWizardQuestionID;
    });
    setQuestions(temp);
  };

  const addQuestion = () => {
    const questionAdded = [
      ...questions,
      {
        id: questions.length.toString(),
        question: "",
        surveyWizardAnswerGroupID: 0,
        sortOrder: questions.length
      }
    ];
    setQuestions(questionAdded);
  };

  useEffect(() => {
    if (surveyWizardID) {
      dispatch(sendGetSurveyWizardRequest(surveyWizardID));
      setSurveyIsLoading(true);
    }
    if (!surveyWizardID) {
      cleanForm();
    }
  }, [surveyWizardID]);

  useEffect(() => {
    // Need to check if surveyWizardID is null, so that you can know that whether it is Survey Create or Update
    if (currentSurvey && surveyWizardID) {
      setTitle(currentSurvey.name);
      setDescription(currentSurvey.description);

      const questionIDAdded = currentSurvey?.questions?.map((q, i) => {
        return { ...q, id: i.toString() };
      });

      setQuestions(questionIDAdded || [emptyQuestion]);
      setRandomizeQuestions(currentSurvey.areQuestionsInRandomOrder || false);
      setAddLottoEntry(currentSurvey.isDrawingEligible || false);
      setSurveyIsLoading(false);
    }
  }, [currentSurvey]);

  useEffect(() => {
    if (surveyWizardAnswerGroup.length === 0) {
      dispatch(sendGetSurveyWizardGroupRequest());
    }
  }, [surveyWizardAnswerGroup]);

  if (showPreview) {
    return (
      <SurveyPreview
        surveyTitle={title}
        surveyDescription={description}
        handleClosePreview={() => {
          onTogglePreview(false);
          setPreview(false);
        }}
        questionData={questions}
      />
    );
  }

  return (
    <div className="survey-question">
      <ul className="question-content">
        <li>
          <SurveySetupHeader
            title={title}
            onTitleChange={(e) => {
              setTitle(e.target.value);
              setIsTitleEmpty(false);
            }}
            handleShowPreview={() => {
              onTogglePreview(true);
              setPreview(true);
            }}
            onSaveClicked={() => {
              return saveSurvey();
            }}
            showSaved={showSaved}
            showPreview={showPreviewBtn}
            isTitleEmpty={isTitleEmpty}
          />
        </li>
        <li className="switch-wrap">
          {/*       Turn off until vetted
          <SurveySwitch
            onChange={(val) => { return setAddLottoEntry(val); }}
            label="Add Lotto Entry"
            checked={addLottoEntry}
          /> */}

          <SurveySwitch
            className="randomize-questions"
            onChange={(val) => {
              return setRandomizeQuestions(val);
            }}
            label="Randomize Questions"
            checked={randomizeQuestions}
          />
        </li>
        <li>
          <SurveySection className={`${isDescriptionEmpty ? "question-item__error" : "question-item"}`}>
            <SeityTextArea
              label="Survey Description"
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              value={description}
            />
          </SurveySection>
        </li>
        {!surveyIsLoading && (
          <li>
            <SurveyQuestionList
              items={questions}
              onSortEnd={onSortEnd}
              pressDelay={100}
              helperClass="sortableHelper"
              onChangeInput={(text, type, i) => {
                return handleChangeText(text, type, i);
              }}
              handleDuplicate={handleDuplicate}
              handleRemove={handleRemove}
            />
          </li>
        )}
        <li>
          <SurveyButton size="mid" label="+ Add Question" onClick={addQuestion} />
        </li>
      </ul>
      <div className="question-footer">
        {errorMessage.length > 0 && <p>{errorMessage}</p>}
        <SurveyButton
          type="text"
          onClick={() => {
            setShowConfirmCancel(true);
          }}
          customClass="cancel-button"
          label="Cancel"
        />
        <SurveyButton
          onClick={saveDraft}
          label={surveyWizardID ? "Schedule Survey" : "Schedule Survey"}
          disabled={questionsExists}
          loading={isSaveLoading}
        />
      </div>
      <AnalyticModal isOpen={showConfirmCancel} classNameOverlay="survey-modal-error" onClose={() => {}}>
        <div className="survey-confirm-cancel">
          <h3>{strings.surveyCancelConfirm}</h3>
          <div>
            <SeityButton
              label="Yes"
              onClick={() => {
                history.push("/survey");
              }}
            />
            <SeityButton
              label="No"
              type="cancel"
              onClick={() => {
                setShowConfirmCancel(false);
              }}
            />
          </div>
        </div>
      </AnalyticModal>
    </div>
  );
};

export default SurveyQuestionSetup;
