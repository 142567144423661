import React from "react";

import "./styles.scss";

export const SeityLoader = ({ showBackgroundMask = true }) => {
  return (
    <div className="loader-container">
      {showBackgroundMask && <div className="loader-mask" />}
      <div className="loader-content">
        <img src={require("../../assets/images/graphics/spinner.gif")} alt="Spinner Gif" width={50} height={50} />
      </div>
    </div>
  );
};

export default SeityLoader;
