import {
  createSurveyWizard,
  getSurveyWizardAnswer,
  getSurveyWizardAnswerGroups,
  updateSurveyWizard
} from "src/api/survey/seityHealthAPI-survey";
import { SurveyQuestion, SurveyWizard } from "src/api/survey/surveyTypes";
import {
  addSurveyWizardToList,
  updateSurveyWizardByIndex,
  handleError,
  setIsLoading,
  setSurveyWizardAnswer,
  setSurveyWizardAnswerGroup
} from "src/reducers/survey/surveySlice";
import { validateToken } from "src/reducers/utils";

export const sendGetSurveyWizardAnswerRequest = (surveyWizardAnswerGroupID: number) => {
  return async (dispatch: any) => {
    try {
      dispatch(setIsLoading(true));
      const token = await validateToken(dispatch);
      const surveyWizardAnswerResponse = await getSurveyWizardAnswer(surveyWizardAnswerGroupID, token);
      if (surveyWizardAnswerResponse.success) {
        dispatch(
          setSurveyWizardAnswer({
            groupAnswerID: surveyWizardAnswerGroupID,
            answers: surveyWizardAnswerResponse.data
          })
        );
        return;
      }
    } catch (err) {
      console.error(err);
      handleError(err, dispatch);
    }
  };
};

export const sendGetSurveyWizardGroupRequest = () => {
  return async (dispatch: any) => {
    try {
      dispatch(setIsLoading(true));
      const token = await validateToken(dispatch);
      const surveyWizardScheduleResponse = await getSurveyWizardAnswerGroups(token);
      if (surveyWizardScheduleResponse.success) {
        dispatch(setSurveyWizardAnswerGroup(surveyWizardScheduleResponse.data));
        return;
      }
    } catch (err) {
      console.error(err);
      handleError(err, dispatch);
    }
  };
};

// SurveyWizard - Question - Create/Update
export const sendPostCreateSurveyRequest = (
  name: string,
  description: string,
  questions: SurveyQuestion[],
  isDrawingEligible: boolean,
  areQuestionsInRandomOrder: boolean,
  callback?: (survey?: SurveyWizard) => void
) => {
  return async (dispatch: any) => {
    try {
      dispatch(setIsLoading(true));
      const token = await validateToken(dispatch);
      const createSurveyWizardResponse = await createSurveyWizard(
        name,
        description,
        questions,
        isDrawingEligible,
        areQuestionsInRandomOrder,
        token
      );

      if (createSurveyWizardResponse.success) {
        const newSurvey = {
          ...createSurveyWizardResponse.data,
          numberOfQuestions: createSurveyWizardResponse.data.questions?.length || 0
        };
        dispatch(addSurveyWizardToList(newSurvey));

        if (callback) {
          callback(newSurvey);
        }
        return;
      }
    } catch (err) {
      console.error(err);
      if (callback) {
        callback();
      }
      handleError(err, dispatch);
    }
  };
};
export const sendUpdateSurveyRequest = (
  surveyWizardID: number,
  name: string,
  description: string,
  questions: SurveyQuestion[],
  index: number,
  isDrawingEligible: boolean,
  areQuestionsInRandomOrder: boolean,
  callback?: (survey?: SurveyWizard) => void
) => {
  return async (dispatch: any) => {
    try {
      dispatch(setIsLoading(true));
      const token = await validateToken(dispatch);
      const updateSurveyWizardResponse = await updateSurveyWizard(
        surveyWizardID,
        name,
        description,
        questions,
        isDrawingEligible,
        areQuestionsInRandomOrder,
        token
      );

      if (updateSurveyWizardResponse.success) {
        const newSurvey = {
          ...updateSurveyWizardResponse.data,
          numberOfQuestions: updateSurveyWizardResponse.data.questions?.length || 0
        };
        dispatch(updateSurveyWizardByIndex({ index, survey: newSurvey }));

        if (callback) {
          callback(newSurvey);
        }
        return;
      }
    } catch (err) {
      console.error(err);
      if (callback) {
        callback();
      }
      handleError(err, dispatch);
    }
  };
};
