import React, { useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { RootState } from "src/reducers";
import { useSelector, useDispatch } from "react-redux";
import { sendLoginRequest, clearApiError, setRedirectToLogin } from "src/reducers/auth/authSlice";
import { SeityButton } from "src/components/analytic/SeityButton";
import { SeityAlert } from "src/components/analytic/SeityAlert";
import SeityLoader from "src/components/SeityLoader";

import { SeityTextInput } from "src/components/analytic/SeityTextInput/SeityTextInput";
import { resetRegisterSlice } from "src/reducers/auth/registerSlice";
import { resetAccountSlice } from "src/reducers/auth/accountSlice";

import SeityLogo from "src/assets/images/web-icons/seity_logo.png";
import MailIcon from "src/assets/images/web-icons/mail_icon.png";
import LockIcon from "src/assets/images/web-icons/lock_icon.svg";

import "./styles.scss";

export const Login = () => {
  const dispatch = useDispatch();
  const { accountID, token, authError, shouldRedirectToLogin, isLoading } = useSelector((state: RootState) => {
    return state.auth;
  });
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showUnauthAlert, setShowUnauthAlert] = useState(false);

  useEffect(() => {
    if (shouldRedirectToLogin) {
      // Reset this to avoid a endless loop after login
      dispatch(setRedirectToLogin({ shouldRedirectToLogin: false }));
    }
  });
  // Clears any message when leaving the
  // component
  useEffect(() => {
    return () => {
      dispatch(clearApiError({}));
    };
  }, []);

  // useEffect(() => {
  //   if (authError !== null) {
  //     setShowUnauthAlert(true); // hotfix 
  //   }
  // }, [authError]);

  const history = useHistory();

  if (token !== "" && accountID !== -1) {
    return (
      <Redirect
        to={{
          pathname: "/",
          state: { from: "/login" }
        }}
      />
    );
  }

  const loginError = (
    <div style={{ color: "#DC2F02" }} data-testid="error-message">
      <h1>Try Again?</h1>
      <h2>Correct any typos, or select "Forgot Password"</h2>
    </div>
  );

  const loginDefaultText = (
    <div>
      <h1>Let's get started</h1>
      <h2>Sign in with your email address and password</h2>
    </div>
  );

  const loginClicked = (e: any) => {
    e.preventDefault();
    dispatch(sendLoginRequest(username, password));
  };

  const forgotPasswordClicked = () => {
    history.push("/forgot-password");
  };

  const signupClicked = () => {
    dispatch(resetRegisterSlice());
    dispatch(resetAccountSlice());
    history.push("/register/registerOne");
  };

  const isTextInvalid = false;//authError !== null;
  return (
    <div className="login-container" style={{ backgroundImage: 'url("../../assets/images/graphics/web_pattern_login.png")' }}>
      {isLoading && <SeityLoader showBackgroundMask />}
      <div className="login-bubble-wrap">
        <div className="login-bubble-wrap__top">
          <img src={SeityLogo} alt="seity-logo" className="login-bubble-logo" />
          {authError ? loginError : loginDefaultText}
          <div className="login-bubble-wrap__top__signup">
            <div className="d-flex flex-direction-row mb-3">
              <span>New to Seity?</span>
              <span className="ml-2" onClick={signupClicked}>
                <u>Sign up for an account</u>
              </span>
            </div>
          </div>
        </div>
        <div className="login-bubble-wrap__down">
          <SeityTextInput
            value={username}
            onChange={(e) => {
              return setUsername(e.target.value);
            }}
            placeholder="Your Email Address"
            isInvalid={isTextInvalid}
            leftIcon={MailIcon}
          />
          <div className="mt-3" style={{ width: "100%", marginTop: "1rem" }}>
            <SeityTextInput
              value={password}
              type="password"
              onChange={(e) => {
                return setPassword(e.target.value);
              }}
              placeholder="Your Password"
              className="login-bubble-input-password"
              leftIcon={LockIcon}
              isInvalid={isTextInvalid}
            />
          </div>
          <a onClick={forgotPasswordClicked} className="login-bubble-forget-password">
            <u style={{ cursor: "default" }}>Forgot Password?</u>
          </a>
          <div className="login-bubble-button">
            <SeityButton type="submit" disabled={!(username && password)} label="Log In" onClick={loginClicked} testId="loginButton" />
          </div>
        </div>
      </div>
      <footer className="login-footer">
        <span>
          <u
            onClick={() => {
              window.open("https://www.seityhealth.com/privacy-policy", "_blank");
            }}
          >
            Privacy and Terms
          </u>
        </span>
        <span className="login-footer-copyright">{`Seity Health ${new Date().getFullYear()}`}</span>
      </footer>
      <SeityAlert
        visible={showUnauthAlert}
        onToggle={() => {
          setShowUnauthAlert(false);
        }}
        title={"Oops!\nYou do not have access to this site!"}
        // eslint-disable-next-line max-len
        subTitle="You will be logged out, please contact Seity Customer Support at support@seityhealth.com if you need further assistance."
      />
    </div>
  );
};

export default Login;
