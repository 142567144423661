import { SeityResponse } from "../../baseTypes";

export class SeityAccountStatusListResponse extends SeityResponse {
  data: SeityAccountStatusListPayloadResponse | null;

  constructor(success: boolean, message: string | null, data: SeityAccountStatusListPayloadResponse | null) {
    super(success, message);
    this.data = data;
  }
}

export class SeityAccountStatusListPayloadResponse {
  accountStatusId: number;
  description: string;

  constructor(accountStatusId: number, description: string) {
    this.accountStatusId = accountStatusId;
    this.description = description;
  }
}

export class SeityFullAccountUpdateResponse extends SeityResponse {
  data: SeityFullAccountUpdatePayloadResponse | null;

  constructor(success: boolean, message: string | null, data: SeityFullAccountUpdatePayloadResponse | null) {
    super(success, message);
    this.data = data;
  }
}

export class SeityFullAccountUpdatePayloadResponse {
  operationMessage: string;

  constructor(operationMessage: string) {
    this.operationMessage = operationMessage;
  }
}

export class SeityGetAccountInformationResponse extends SeityResponse {
  data: SeityGetAccountInformationPayloadResponse | null;

  constructor(success: boolean, message: string | null, data: SeityGetAccountInformationPayloadResponse | null) {
    super(success, message);
    this.data = data;
  }
}

export class SeityGetAccountInformationPayloadResponse {
  dateOfBirth: string; // MM/dd/yyyy
  status: string;
  lastName: string;
  firstName: string;
  occupation: string;
  maritalStatusID: number;
  accountStatusID: number;
  accountStatus: string;
  statusID: number;
  zipcode: string;
  maritalStatus: string;
  eMailAddress: string;
  genderID: number;
  occupationID: number;
  education: string;
  educationID: number;
  gender: string;
  cellPhone: string;
  accountID: number;
  groupID: string;
  assessmentLevelID: number;
  isCVOnly: boolean;

  constructor(
    dateOfBirth: string,
    status: string,
    lastName: string,
    firstName: string,
    occupation: string,
    maritalStatusId: number,
    accountStatusId: number,
    accountStatus: string,
    statusId: number,
    zipCode: string,
    maritalStatus: string,
    emailAddress: string,
    genderId: number,
    occupationId: number,
    education: string,
    educationId: number,
    gender: string,
    cellPhone: string,
    accountId: number,
    groupID: string,
    assessmentLevelID: number,
    isCVOnly: boolean
  ) {
    this.dateOfBirth = dateOfBirth;
    this.status = status;
    this.lastName = lastName;
    this.firstName = firstName;
    this.occupation = occupation;
    this.maritalStatusID = maritalStatusId;
    this.accountStatusID = accountStatusId;
    this.accountStatus = accountStatus;
    this.statusID = statusId;
    this.zipcode = zipCode;
    this.maritalStatus = maritalStatus;
    this.eMailAddress = emailAddress;
    this.genderID = genderId;
    this.occupationID = occupationId;
    this.education = education;
    this.educationID = educationId;
    this.gender = gender;
    this.cellPhone = cellPhone;
    this.accountID = accountId;
    this.groupID = groupID;
    this.assessmentLevelID = assessmentLevelID;
    this.isCVOnly = isCVOnly;
  }
}

export enum SeityAccountListType {
  ACCOUNT_STATUS = 1,
  STATUS = 2,
  EDUCATION = 3,
  OCCUPATION = 4,
  GENDER = 5,
  MARITAL_STATUS = 6
}

export class SeityAccountListsResponse extends SeityResponse {
  data: SeityAccountListsPayloadResponse | null;

  constructor(success: boolean, message: string | null, data: SeityAccountListsPayloadResponse | null) {
    super(success, message);
    this.data = data;
  }
}

export class SeityAccountListsPayloadResponse {
  accountLists: [SeityAccountListsItemResponse];

  constructor(accountLists: [SeityAccountListsItemResponse]) {
    this.accountLists = accountLists;
  }
}

export class SeityAccountListsItemResponse {
  accountListsType: SeityAccountListType;
  listID: number;
  description: string;

  constructor(accountListsType: SeityAccountListType, listID: number, description: string) {
    this.accountListsType = accountListsType;
    this.listID = listID;
    this.description = description;
  }
}

export class SeityAccountMaintenancePayloadRequest {
  firstName: string | null;
  lastName: string | null;
  eMailAddress: string | null;
  accountStatusID: number | null;
  dateOfBirth: string | null;
  statusID: number | null;
  educationID: number | null;
  occupationID: number | null;
  genderID: number | null;
  maritalStatusID: number | null;
  zipcode: string | null;
  cellPhone: string | null;

  constructor(
    firstName: string | null,
    lastName: string | null,
    eMailAddress: string | null,
    accountStatusID: number | null,
    dateOfBirth: string | null,
    statusID: number | null,
    educationID: number | null,
    occupationID: number | null,
    genderID: number | null,
    maritalStatusID: number | null,
    zipcode: string | null,
    cellPhone: string | null
  ) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.eMailAddress = eMailAddress;
    this.accountStatusID = accountStatusID;
    this.dateOfBirth = dateOfBirth;
    this.statusID = statusID;
    this.educationID = educationID;
    this.occupationID = occupationID;
    this.genderID = genderID;
    this.maritalStatusID = maritalStatusID;
    this.zipcode = zipcode;
    this.cellPhone = cellPhone;
  }
}
