import React, {useRef, useState} from "react";

import classNames from "classnames";
import ShowEye from "src/assets/images/web-icons/eye_show.png";
import CloseEye from "src/assets/images/web-icons/eye_hide.png";

import "./styles.scss";

export interface SeityTextInputProps {
  testID?: string;
  type?: "password" | "text" | "number" | "date" | "email" | "tel";
  onChange?: (e: any) => void;
  placeholder?: string;
  inputStyle?: {};
  value?: string | number;
  autoComplete?: "on" | "off";
  className?: string;
  leftIcon?: string;
  isInvalid?: boolean;
  hasCopyToClipboardIcon?: boolean;
  handleCopyClipboardClick?: (e: any) => void;
}

export const SeityTextInput = (props: SeityTextInputProps) => {
  const { onChange, placeholder, inputStyle, value, className, leftIcon, isInvalid, hasCopyToClipboardIcon, handleCopyClipboardClick } = props;
  const { type } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [inputType, setInputType] = useState({ type });
  const inputRef = useRef<HTMLInputElement>(null);

  const handleEyeClick = () => {
    if (!showPassword) {
      setInputType({ type: "text" });
    } else {
      setInputType({ type: "password" });
    }
    setShowPassword(!showPassword);
  };

  const handleInternalCopyClipboardClick = () => {
    const inputValue = inputRef.current !== null ? inputRef.current.value : "";

    if (handleCopyClipboardClick) {
      handleCopyClipboardClick(inputValue);
    }

  };

  const hasRightIcon = type === "password";
  const hasLeftIcon = leftIcon;

  const inputClasses = classNames({
    "seity-input": true,
    "seity-input-padding-left": hasLeftIcon,
    "seity-input-padding-right": hasRightIcon,
    "seity-input-error": isInvalid
  });

  return (
    <div className="seity-input-wrapper">
      {hasLeftIcon && <img className="seity-input-left-icon" src={leftIcon} />}
      <input
        ref={inputRef}
        value={value}
        placeholder={placeholder}
        className={`${inputClasses} ${className}`}
        type={inputType.type}
        onChange={onChange}
        style={inputStyle}
      />
      {hasRightIcon && !hasCopyToClipboardIcon && <img className="seity-input-right-icon" onClick={handleEyeClick} alt="right icon" src={showPassword ? CloseEye : ShowEye} />}

      {hasCopyToClipboardIcon && <img className="seity-input-copy-clipboard" onClick={handleInternalCopyClipboardClick} src={require("src/assets/ats/copy-clipboard.png")} alt="Click to copy text" />}

    </div>
  );
};
