import React, { FunctionComponent, ReactElement, useEffect } from "react";

import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/reducers";
import { sendGetSurveyWizardAnswerRequest } from "src/reducers/survey/surveyQuestionSlice";

import SurveyRadioButtonItem from "./SurveyRadioButtonItem";

export type RadioOptions = {
  value: string | number;
  label: string;
};
export type SurveyRadioButtonGroupProps = {
  className?: string;
  onChange: (value: string | number) => void;
  type: string;
  selected: string | number;
};

export const SurveyRadioButtonGroup: FunctionComponent<SurveyRadioButtonGroupProps> = ({
  className,
  onChange,
  type,
  selected
}): ReactElement => {
  const { surveyWizardAnswer } = useSelector((state: RootState) => {
    return state.survey;
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (type) {
      if (surveyWizardAnswer[type] === undefined) {
        dispatch(sendGetSurveyWizardAnswerRequest(Number(type)));
      }
    }
  }, [type]);
  return (
    <div className={`survey-radio-button-group-wrapper ${className || ""}`}>
      {surveyWizardAnswer[type]?.map((option, index) => {
        return (
          <SurveyRadioButtonItem
            key={index.toString()}
            label={option.text}
            value={option.text}
            checked={selected === option.text}
            onChange={onChange}
          />
        );
      })}
    </div>
  );
};

export default SurveyRadioButtonGroup;
