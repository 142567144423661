import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import pbiReducer from "./pbiReducer";
import authReducer from "./auth/authSlice";
import accountReducer from "./auth/accountSlice";
import registerReducer from "./auth/registerSlice";
import analyticsReducer from "./auth/analyticsSlice";
import surveySlice from "./survey/surveySlice";
import atsReducer from "./ats/atsSlice";
import groupsReducer from "./groupsAndDepartments/groupsSlice";
import departmentsReducer from "./groupsAndDepartments/departmentsSlice";
import wbnSlice from "./wbnSlice";
import { AuthTransform } from "./transform/authTransform";

// Configuration for persisting the authReducer
const authPersistConfig = {
  key: 'auth',
  storage: storage,
  transforms: [AuthTransform]
};

// Combining all reducers, with authReducer being persisted
const rootReducer = combineReducers({
  pbi: pbiReducer,
  auth: persistReducer(authPersistConfig, authReducer),
  account: accountReducer,
  register: registerReducer,
  analytics: analyticsReducer,
  survey: surveySlice,
  ats: atsReducer,
  groups: groupsReducer,
  departments: departmentsReducer,
  wbn: wbnSlice
});

const rootPersistConfig = {
  key: 'root',
  storage: storage
};

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

// Creating the store with the persisted reducer
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE', 'persist/PURGE']
        }
      })
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
