/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { RootState } from "src/reducers";
import {
  sendGetAccountListsRequest,
  sendGetAccountInfoRequest,
  sendAccountMaintenanceUpdateRequest,
  clearAccountError,
  setAccountUpdateSuccessful
} from "src/reducers/auth/accountSlice";
import { SeityAccountListsItemResponse, SeityAccountListType } from "src/api/account/types/maintenanceTypes";

import { SeityDatePicker } from "src/components/analytic/SeityDatePicker/SeityDatePicker";
import { SeityFormSelectBox } from "src/components/analytic/SeitySelectBox/SeityFormSelectBox";
import { SeityFormTextInput } from "src/components/analytic/SeityTextInput/SeityFormTextInput";
import { SeityPrimaryCancelButtons } from "src/components/analytic/SeityNavigationButtons";
import InputMask from "react-input-mask";
import strings from "src/_core/strings/strings";
import { getFullDate } from "../../../_core/utils/dateUtils/dateUtils";
import "./maintenance.scss";

function MaintenanceTwo() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { accountLists, accountInfo, accountError, isAccountUpdateSuccessful } = useSelector((state: RootState) => {
    return state.account;
  });

  useEffect(() => {
    dispatch(clearAccountError());
    dispatch(sendGetAccountInfoRequest());
    dispatch(sendGetAccountListsRequest(true, true, true, true, true, true));
  }, []);

  useEffect(() => {
    setmaritalStatusID(accountInfo?.maritalStatusID ?? 0);
    setCellPhone(accountInfo?.cellPhone ? accountInfo?.cellPhone : "None");
    setzipcode(accountInfo?.zipcode ? accountInfo?.zipcode : "None");
    setGenderID(accountInfo?.genderID ?? 0);
    setAccountStatus(accountInfo?.accountStatusID ?? 0);
    setGender(accountInfo?.gender ? accountInfo?.gender : "None");
  }, [accountInfo]);

  const accountStatusList: SeityAccountListsItemResponse[] = [];
  const statusList: SeityAccountListsItemResponse[] = [];
  const educationList: SeityAccountListsItemResponse[] = [];
  const occupationList: SeityAccountListsItemResponse[] = [];
  const maritalList: SeityAccountListsItemResponse[] = [];
  const genderList: SeityAccountListsItemResponse[] = [];

  accountStatusList.push({ description: "None", listID: -1, accountListsType: SeityAccountListType.ACCOUNT_STATUS });
  statusList.push({ description: "None", listID: -1, accountListsType: SeityAccountListType.STATUS });
  educationList.push({ description: "None", listID: -1, accountListsType: SeityAccountListType.EDUCATION });
  occupationList.push({ description: "None", listID: -1, accountListsType: SeityAccountListType.OCCUPATION });
  maritalList.push({ description: "None", listID: -1, accountListsType: SeityAccountListType.MARITAL_STATUS });
  genderList.push({ description: "None", listID: -1, accountListsType: SeityAccountListType.GENDER });

  accountLists?.forEach((list) => {
    switch (list.accountListsType) {
      case SeityAccountListType.ACCOUNT_STATUS:
        accountStatusList.push(list);
        break;
      case SeityAccountListType.STATUS:
        statusList.push(list);
        break;
      case SeityAccountListType.EDUCATION:
        educationList.push(list);
        break;
      case SeityAccountListType.OCCUPATION:
        occupationList.push(list);
        break;
      case SeityAccountListType.MARITAL_STATUS:
        maritalList.push(list);
        break;
      case SeityAccountListType.GENDER:
        genderList.push(list);
        break;
      default:
        break;
    }
  });

  const [dateOfBirth, setDateOfBirth] = useState(new Date());
  const [genderID, setGenderID] = useState(accountInfo?.genderID ?? 0);
  const [gender, setGender] = useState(accountInfo?.gender ? accountInfo?.gender : "None");
  const [maritalStatusID, setmaritalStatusID] = useState(accountInfo?.maritalStatusID ? accountInfo.maritalStatusID : 0);
  const [zipcode, setzipcode] = useState(accountInfo?.zipcode ? accountInfo?.zipcode : "None");
  const [cellPhone, setCellPhone] = useState("");
  const [accountStatusID, setAccountStatus] = useState(accountInfo?.accountStatusID ?? 0);

  const updateAccountInfo = () => {
    dispatch(
      sendAccountMaintenanceUpdateRequest({
        firstName: null,
        lastName: null,
        eMailAddress: null,
        accountStatusID,
        dateOfBirth: getFullDate(dateOfBirth),
        statusID: null,
        educationID: null,
        occupationID: null,
        genderID,
        maritalStatusID,
        zipcode,
        cellPhone
      })
    );
  };

  const genderHandler = (e) => {
    setGenderID(parseInt(e.target.value));
    setGender(e.target.value);
  };

  if (isAccountUpdateSuccessful) {
    dispatch(setAccountUpdateSuccessful({ accountUpdateSuccessful: false }));
    history.push("/profile");
  }

  const cancelButtonHandler = () => {
    history.push("/profile");
  };

  const disabledHandler = () => {
    if (
      dateOfBirth >= new Date() ||
      genderID == -1 ||
      0 ||
      zipcode.length < 5 ||
      cellPhone.includes("_") ||
      maritalStatusID == -1 ||
      0 ||
      accountStatusID == -1 ||
      0
    ) {
      return true;
    }
    return false;
  };

  const validateBirthdayParams = () => {
    return dateOfBirth.getTime() > new Date().getTime();
  };

  if (accountLists) {
    return (
      <div className="maintenance-container">
        <h1>{strings.edit}</h1>
        <div className="maintenance-content-wrap">
          <div style={{ marginTop: "-20px" }}>
            <label>{strings.dateOfBirth}</label>
            <div style={{ textAlign: "center" }}>
              <SeityDatePicker
                retriveDate={setDateOfBirth}
                setDate={accountInfo ? new Date(accountInfo.dateOfBirth) : new Date()}
                invalid={validateBirthdayParams()}
              />
            </div>
          </div>
          <SeityFormSelectBox
            isInvalid={genderID === -1}
            inputLabelText={strings.genderLabel}
            value={genderID !== -1 ? genderList[genderID].description : "None"}
            onChange={(e) => {
              return genderHandler(e);
            }}
          >
            <ul>
              {genderList.map((item) => {
                return (
                  <li key={item.listID} value={item.listID}>
                    {item.description}
                  </li>
                );
              })}
            </ul>
          </SeityFormSelectBox>
          <SeityFormSelectBox
            isInvalid={maritalStatusID === -1}
            controlId="marital-status"
            inputLabelText={strings.maritalStatusLabel}
            value={maritalStatusID !== -1 ? maritalList[maritalStatusID].description : "None"}
            onChange={(e) => {
              return setmaritalStatusID(parseInt(e.target.value));
            }}
          >
            <ul>
              {maritalList.map((item) => {
                return (
                  <li key={item.listID} value={item.listID}>
                    {item.description}
                  </li>
                );
              })}
            </ul>
          </SeityFormSelectBox>
          <InputMask
            mask="999-999-9999"
            onChange={(e) => {
              return setCellPhone(e.target.value);
            }}
            value={cellPhone}
          >
            <SeityFormTextInput isInvalid={cellPhone.includes("_")} type="text" inputLabelText={strings.cellPhoneLabel} />
          </InputMask>
          <SeityFormTextInput
            isInvalid={zipcode.length < 5}
            type="text"
            inputLabelText={strings.zipcodeLabel}
            value={zipcode}
            onChange={(e) => {
              return setzipcode(e.target.value);
            }}
          />
          <SeityFormSelectBox
            isInvalid={accountStatusID === -1}
            controlId="account-status"
            inputLabelText={strings.accountTypeLabel}
            value={
              accountStatusID !== -1 && accountStatusID < accountStatusList.length ? accountStatusList[accountStatusID].description : "None"
            }
            inputStyle={{ marginBottom: "-20px" }}
            onChange={(e) => {
              return setAccountStatus(parseInt(e.target.value));
            }}
          >
            <ul>
              {accountStatusList.map((item) => {
                return (
                  <li key={item.listID} value={item.listID}>
                    {item.description}
                  </li>
                );
              })}
            </ul>
          </SeityFormSelectBox>
        </div>
        <SeityPrimaryCancelButtons
          cancelAction={cancelButtonHandler}
          cancelTitle={strings.cancelButtonText}
          primaryAction={updateAccountInfo}
          primaryTitle={strings.saveButtonText}
          primaryDisabled={disabledHandler()}
          errorMessage={accountError}
          showCancel
        />
      </div>
    );
  }
  return <div />;
}

export default MaintenanceTwo;
