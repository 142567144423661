import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import {store, persistor} from "src/reducers";
import SeityRouter from "src/app/SeityRouter";
import favicon from "src/assets/images/logo.png";

export const App = () => {
  return (
    <div>
      <Helmet>
        <title>Seity Dashboard</title>
        <link rel="icon" type="image/png" href={favicon} />
        {/* All meta data goes here */}
        <meta name="description" content="Page description. No longer than 155 characters." />
      </Helmet>

      {/*
        Comment out StrictMode -
          To prevent react-dom warning (https://reactjs.org/docs/strict-mode.
          html#warning-about-deprecated-finddomnode-usage) in SurveySection.tsx
        */}

      {/* <React.StrictMode> */}
      <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <SeityRouter />
          </PersistGate>
      </Provider>
      {/* </React.StrictMode> */}
    </div>
  );
};
