// Imports
import React from "react";
import "./styles.scss";

export interface ButtonProps {
  disabled?: boolean;
  type?: "submit" | "reset" | "button" | "back" | "next" | "cancel" | "finish" | "dark";
  onClick?: ((event: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined;
  label?: string;
  testId?: string;
  title?: string;
  style?: object;
}

export const SeityButton = (props: ButtonProps) => {
  const { disabled, type, onClick, label, testId, title, style } = props;
  let buttonClassName;

  switch (type) {
    case "cancel":
      buttonClassName = "seity-analytic-button__cancel ";
      break;
    case "dark":
      buttonClassName = "seity-analytic-button__dark";
      break;
    default:
      buttonClassName = "seity-analytic-button";
      break;
  }

  return (
    <button
      type="button"
      style={style}
      disabled={disabled}
      title={title}
      onClick={onClick}
      className={buttonClassName}
      data-testid={testId}
    >
      {label}
    </button>
  );
};

export default SeityButton;
