import React, { FunctionComponent, ReactElement } from "react";

import { TeacherClass, TeacherList, TeacherSchool } from "src/api/analytics/types/analyticsTypes";
import useLockBodyScroll from "src/hooks/Layout";
import AnalyticModal from "../AnalyticModal";
import { SeityButton } from "../SeityButton";

export type SelectBoxProps = {
  type: "Site" | "Teacher" | "Class" | null;
  options: TeacherList[] | TeacherSchool[] | TeacherClass[];
  selIdx: number;
  onClose: ((event: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined;
  onChange: (selIdx: number) => void;
};

export const SelectBox: FunctionComponent<SelectBoxProps> = ({ type, options, selIdx, onClose, onChange }): ReactElement => {
  const allCopy = {
    Site: "All Sites",
    Teacher: "All Teachers",
    Class: "All Classes"
  };

  const handleSel = (e) => {
    onChange(e.target.value);
    if (onClose) {
      onClose(e);
    }
  };

  useLockBodyScroll();

  const getKeyAndVal = (item) => {
    if (type === "Site") {
      return { key: item.companyID, val: item.schoolName };
    }
    if (type === "Teacher") {
      return { key: item.accountID, val: `${item.firstName} ${item.lastName}` };
    }
    if (type === "Class") {
      return { key: item.class01ID, val: item.classDescription };
    }
    return null;
  };

  return (
    <AnalyticModal isOpen={!!type}>
      <>
        <span className="selectbox-title">{`Select ${type}`}</span>
        <div className="selectbox-content">
          <ul>
            {type && type !== "Site" && (
              <li key={-1} value={-1} onClick={handleSel} className={`${selIdx === -1 ? "active" : ""}`}>
                {allCopy[type]}
              </li>
            )}
            {options &&
              options.map((item, index) => {
                const obj = getKeyAndVal(item);
                if (obj) {
                  return (
                    <li key={obj.key} value={index} onClick={handleSel} className={`${selIdx === index ? "active" : ""}`}>
                      {obj.val}
                    </li>
                  );
                }
                return "";
              })}
          </ul>
        </div>
        <SeityButton
          label="Cancel"
          type="cancel"
          style={{
            height: 35,
            width: 90,
            padding: 0,
            marginRight: 10,
            position: "absolute",
            bottom: 10
          }}
          onClick={(e) => {
            if (onClose) {
              onClose(e);
            }
          }}
        />
      </>
    </AnalyticModal>
  );
};

export default SelectBox;
