import React, { FunctionComponent, ReactElement } from "react";

import "./styles.scss";

type WbnButtonProp = {
  disabled?: boolean;
  type?: "select" | "normal" | "plain-text" | "submit";
  onClick?: ((event: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined;
  label?: string;
  image?: string;
  style?: object;
  isSel?: boolean;
  customClass?: string;
};

export const WbnButton: FunctionComponent<WbnButtonProp> = (props): ReactElement => {
  const { disabled, type = "normal", onClick, label, image, style, isSel, customClass = "" } = props;

  return (
    <button style={style} disabled={disabled} onClick={onClick} className={`wbn-button ${type} ${isSel ? "selected" : ""} ${customClass}`}>
      {image && image.length > 0 && <img className="wbn-button__icon" src={image} />}
      {label}
      {type === "select" && <img className="wbn-button__caret" src={require("src/assets/wbn/caret-down-black.png")} />}
    </button>
  );
};

export default WbnButton;
