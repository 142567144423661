/* eslint-disable max-len */
import React, { FunctionComponent, ReactElement } from "react";

import "./styles.scss";
import AtsBrandTypographyItem from "../AtsBrandTypographyItem";

export type AtsBrandTypographyProps = {
  onCategoryClicked: (index: number, menuName: string) => void;
};

export const AtsBrandTypography: FunctionComponent<AtsBrandTypographyProps> = ({ onCategoryClicked }): ReactElement => {
  return (
    <div className="ats-brand-typography">
      <div className="ats-brand-typography__item-wrapper">
        <span
          onClick={() => {
            return onCategoryClicked(0, "Typography");
          }}
          className="selected"
        >
          Typography
        </span>
        <span
          onClick={() => {
            return onCategoryClicked(1, "Colors");
          }}
        >
          Colors
        </span>
      </div>
      <span className="ats-brand-typography-text">
        These are the only fonts that appear in our User Interfaces across all applications while keeping it easy to read, ownable, and
        highly recognizable. Typography
      </span>

      <AtsBrandTypographyItem title="Headline" subtitle="DM Serif Display - Bold" size={30} fontFamily="dmSerif" />
      <AtsBrandTypographyItem title="Headline" subtitle="Source Serif Pro- Bold" size={30} fontFamily="sourceSerif" />
      <AtsBrandTypographyItem title="Body" subtitle="Source Sans Pro - Regular" size={18} fontFamily="sourceSans" />
    </div>
  );
};

export default AtsBrandTypography;
