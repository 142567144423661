import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";

import { createPBIEmbedToken } from "src/actions/pbi";
// import SeityFiltersNav from 'src/components/SeityFiltersNav';
import SurveyPbiReport from "src/components/survey/SurveyPbiReport";
import { RootState } from "src/reducers";

import "./styles.scss";

const SurveyReport = ({ embedToken, createPBIToken, isPBITokenLoading }) => {
  const { groupID } = useSelector((state: RootState) => {
    return state.auth;
  });

  useEffect(() => {
    createPBIToken(groupID);
  }, []);

  return (
    <div className="survey-report">
      {!isPBITokenLoading && embedToken ? (
        <>
          <SurveyPbiReport
            reportType="Single"
            embedToken={embedToken}
            filterVal={null}
            tokenExpiredHandler={() => {}}
            filterValsHandler={(filterVals) => {
              console.log(filterVals);
            }}
          />
          <SurveyPbiReport
            reportType="Single"
            embedToken={embedToken}
            filterVal={null}
            tokenExpiredHandler={() => {}}
            filterValsHandler={(filterVals) => {
              console.log(filterVals);
            }}
          />
        </>
      ) : null}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    createPBIToken: (groupID: string) => {
      return dispatch(createPBIEmbedToken(groupID));
    }
  };
};

const mapStateToProps = (state) => {
  return {
    embedToken: state.pbi.pbiEmbedToken,
    isPBITokenLoading: state.pbi.isLoading
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyReport);
