import { FunctionComponent, ReactElement } from "react";

import "./styles.scss";
import WbnCheckbox from "../WbnCheckbox";
import { GRAPHICS_URL } from "src/api/apiConstants";

type WbnImageCheckListProps = {
  data: any[];
  selOptions: any[];
  onSelOptions: Function;
};

/**
 * to access the filtered list from the parent use the following in the parent
 * const [selOptions, onSelOptions] = useState<any[]>([]);
 */

export const WbnImageCheckList: FunctionComponent<WbnImageCheckListProps> = (props): ReactElement => {
  const { data, selOptions, onSelOptions } = props;

  const getImage = (name: string) => {
    return GRAPHICS_URL + name;
  };

  const handleOnClick = (item: any) => {
    if (selOptions.includes(item.id)) {
      onSelOptions(
        selOptions.filter((id) => {
          return id !== item.id;
        })
      );
    } else {
      onSelOptions([...selOptions, item.id], item.id);
    }
  };

  return (
    <div className="wbn-image-check-list">
      {data.map((item, index) => {
        return (
          <WbnCheckbox
            key={index}
            className="filter-list"
            label={item.title}
            onClick={() => {
              handleOnClick(item);
            }}
            checked={selOptions.includes(item.id)}
            image={item.image ? getImage(item.image) : undefined}
          />
        );
      })}
    </div>
  );
};

export default WbnImageCheckList;
