/* eslint no-param-reassign: "error" */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DepartmentItem } from "src/api/groupsAndDepartments/departmentsTypes";
import { getDepartments, postDepartment, putDepartment, deleteDepartment } from "src/api/groupsAndDepartments/seityHealthAPI-departments";

import { validateToken } from "../utils";

export interface State {
  isListLoading: boolean;
  departmentsList: Array<DepartmentItem>;
  error: object;
}

const setInitialState = {
  isLoading: false,
  isListLoading: false,
  groupsList: [],
  departmentsList: [],
  error: {}
} as State;

const departmentsSlice = createSlice({
  name: "departments",
  initialState: setInitialState,
  reducers: {
    setIsListLoading(state, action: PayloadAction<boolean>) {
      state.isListLoading = action.payload;
    },
    setDepartmentsList(state, action: PayloadAction<Array<DepartmentItem>>) {
      state.departmentsList = action.payload;
    },
    addDepartmentToDepartmentsList(state, action: PayloadAction<DepartmentItem>) {
      state.departmentsList = [...state.departmentsList, action.payload];
    },
    setError(state, action: PayloadAction<object>) {
      state.error = action.payload;
    }
  }
});

export const { setIsListLoading, setDepartmentsList, addDepartmentToDepartmentsList, setError } = departmentsSlice.actions;

export default departmentsSlice.reducer;

function handleListError(err: any, dispatch: any) {
  console.error(err);
  dispatch(setIsListLoading(false));
  dispatch(setError({ error: err.toString() }));
}

// API Requests
export const sendGetDepartmentsRequest = () => {
  return async (dispatch: any) => {
    try {
      dispatch(setIsListLoading(true));
      const token = await validateToken(dispatch);
      const response = await getDepartments(token);
      dispatch(setIsListLoading(false));
      if (response.success) {
        dispatch(setDepartmentsList(response.data));
        return;
      }
    } catch (err) {
      handleListError(err, dispatch);
    }
  };
};

export const sendPostDepartmentRequest = (departmentName: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(setIsListLoading(true));
      const token = await validateToken(dispatch);
      const response = await postDepartment(departmentName, token);
      dispatch(setIsListLoading(false));
      if (response.success) {
        dispatch(addDepartmentToDepartmentsList(response.data));
        return;
      }
    } catch (err) {
      handleListError(err, dispatch);
    }
  };
};

export const sendPutDepartmentRequest = (departmentId: number, newName: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(setIsListLoading(true));
      const token = await validateToken(dispatch);
      const response = await putDepartment(departmentId, newName, token);
      dispatch(setIsListLoading(false));
      if (response.success) {
        dispatch(sendGetDepartmentsRequest());
        return;
      }
    } catch (err) {
      handleListError(err, dispatch);
    }
  };
};

export const sendDeleteDepartmentRequest = (departmentId: number) => {
  return async (dispatch: any) => {
    try {
      dispatch(setIsListLoading(true));
      const token = await validateToken(dispatch);
      const response = await deleteDepartment(departmentId, token);
      dispatch(setIsListLoading(false));
      if (response.success) {
        dispatch(sendGetDepartmentsRequest());
        return;
      }
    } catch (err) {
      console.error(err);
      handleListError(err, dispatch);
    }
  };
};
