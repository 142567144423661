/* eslint no-param-reassign: "error" */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GroupItem, MemberItem } from "src/api/groupsAndDepartments/groupsTypes";
import {
  getGroups,
  postGroup,
  putGroup,
  deleteGroup,
  postAssignToGroup,
  getMembersNotInGroup
} from "src/api/groupsAndDepartments/seityHealthAPI-groups";

import { validateToken } from "../utils";

export interface State {
  isLoading: boolean;
  isListLoading: boolean;
  groupsList: Array<GroupItem>;
  usersNotInGroup: Array<MemberItem>;
  searchedUsersNotInGroup: Array<MemberItem>;
  usersInGroup: Array<MemberItem>;
  error: object;
}

const setInitialState = {
  isLoading: false,
  isListLoading: false,
  groupsList: [],
  usersInGroup: [],
  searchedUsersNotInGroup: [],
  usersNotInGroup: [],
  error: {}
} as State;

const groupsSlice = createSlice({
  name: "groups",
  initialState: setInitialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setIsListLoading(state, action: PayloadAction<boolean>) {
      state.isListLoading = action.payload;
    },
    setGroupsList(state, action: PayloadAction<Array<GroupItem>>) {
      state.groupsList = action.payload;
    },
    addGroupToGroupsList(state, action: PayloadAction<GroupItem>) {
      state.groupsList = [...state.groupsList, action.payload];
    },
    setUsersInGroup(state, action: PayloadAction<Array<MemberItem>>) {
      state.usersInGroup = action.payload;
    },
    setSearchedUsersNotInGroup(state, action: PayloadAction<Array<MemberItem>>) {
      state.searchedUsersNotInGroup = action.payload;
    },
    filterSearchedUsersNotInGroup(state, action: PayloadAction<string>) {
      state.searchedUsersNotInGroup = state.usersNotInGroup.filter((user: MemberItem) => {
        return user.accountName.toLowerCase().includes(action.payload.toLowerCase());
      });
    },
    resetSearchUsersNotInGroup(state) {
      state.searchedUsersNotInGroup = state.usersInGroup;
    },
    addUserToGroup(state, action: PayloadAction<MemberItem>) {
      state.usersNotInGroup = state.usersNotInGroup.filter((user) => {
        return user.accountID !== action.payload.accountID;
      });
      state.usersInGroup.unshift(action.payload);
    },
    addAllUsersToGroup(state) {
      state.usersInGroup = [...state.usersInGroup, ...state.usersNotInGroup];
      state.usersNotInGroup = [];
    },
    removeAllUsersFromGroup(state) {
      state.usersNotInGroup = [...state.usersNotInGroup, ...state.usersInGroup];
      state.usersInGroup = [];
    },
    removeUserFromGroup(state, action: PayloadAction<MemberItem>) {
      state.usersInGroup = state.usersInGroup.filter((user) => {
        return user.accountID !== action.payload.accountID;
      });
      state.usersNotInGroup.unshift(action.payload);
    },
    setUsersNotInGroup(state, action: PayloadAction<Array<MemberItem>>) {
      state.usersNotInGroup = action.payload;
    },

    setError(state, action: PayloadAction<object>) {
      state.error = action.payload;
    }
  }
});

export const {
  setIsLoading,
  setIsListLoading,
  setGroupsList,
  addGroupToGroupsList,
  setUsersInGroup,
  setUsersNotInGroup,
  addUserToGroup,
  removeUserFromGroup,
  addAllUsersToGroup,
  removeAllUsersFromGroup,
  setSearchedUsersNotInGroup,
  resetSearchUsersNotInGroup,
  filterSearchedUsersNotInGroup,
  setError
} = groupsSlice.actions;

export default groupsSlice.reducer;

function handleError(err: any, dispatch: any) {
  console.error(err);
  dispatch(setIsLoading(false));
  dispatch(setError({ error: err.toString() }));
}

function handleListError(err: any, dispatch: any) {
  console.error(err);
  dispatch(setIsListLoading(false));
  dispatch(setError({ error: err.toString() }));
}

// API Requests
export const sendGetGroupsRequest = () => {
  return async (dispatch: any) => {
    try {
      dispatch(setIsListLoading(true));
      const token = await validateToken(dispatch);
      const response = await getGroups(token);
      dispatch(setIsListLoading(false));
      if (response.success) {
        dispatch(setGroupsList(response.data));
        return;
      }
    } catch (err) {
      handleListError(err, dispatch);
    }
  };
};

export const sendPostGroupRequest = (groupName: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(setIsListLoading(true));
      const token = await validateToken(dispatch);
      const response = await postGroup(groupName, token);
      if (response.success) {
        dispatch(addGroupToGroupsList(response.data));
        dispatch(setIsListLoading(false));
        return;
      }
      dispatch(setIsListLoading(false));
    } catch (err) {
      handleListError(err, dispatch);
    }
  };
};

export const sendPutGroupRequest = (groupID: number, newGroupName: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(setIsListLoading(true));
      const token = await validateToken(dispatch);
      const response = await putGroup(groupID, newGroupName, token);
      dispatch(setIsListLoading(false));
      if (response.success) {
        dispatch(sendGetGroupsRequest());
        return;
      }
    } catch (err) {
      handleListError(err, dispatch);
    }
  };
};

export const sendDeleteGroupRequest = (groupID: number) => {
  return async (dispatch: any) => {
    try {
      dispatch(setIsListLoading(true));
      const token = await validateToken(dispatch);
      const response = await deleteGroup(groupID, token);
      dispatch(setIsListLoading(false));
      if (response.success) {
        dispatch(sendGetGroupsRequest());
        return;
      }
    } catch (err) {
      handleListError(err, dispatch);
    }
  };
};

export const sendGetUsersNotInGroupRequest = (groupID: number) => {
  return async (dispatch: any) => {
    try {
      dispatch(setIsLoading(true));
      const token = await validateToken(dispatch);
      const response = await getMembersNotInGroup(groupID, token);
      dispatch(setIsLoading(false));
      if (response.success) {
        dispatch(setUsersNotInGroup(response.data));
        dispatch(setSearchedUsersNotInGroup(response.data));
        return;
      }
    } catch (err) {
      console.error(err);
      handleError(err, dispatch);
    }
  };
};

export const sendPostAssignToGroupRequest = (accountID: Array<number>, groupID: number) => {
  return async (dispatch: any) => {
    try {
      dispatch(setIsLoading(true));
      const token = await validateToken(dispatch);
      const response = await postAssignToGroup(accountID, groupID, token);
      dispatch(setIsLoading(false));
      if (response.success) {
        dispatch(sendGetGroupsRequest());
        return;
      }
    } catch (err) {
      console.error(err);
      handleError(err, dispatch);
    }
  };
};
