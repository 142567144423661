import React, { FunctionComponent, ReactElement } from "react";

export type FilterButtonProps = {
  title: string;
  onClick: ((event: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined;
};

export const FilterButton: FunctionComponent<FilterButtonProps> = ({ title, onClick }): ReactElement => {
  const correctImg = () => {
    return <img alt="Caret" src={require("src/assets/images/web-icons/select_caret.png")} />;
  };

  return (
    <a
      className="filter-button-wrap"
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }
      }}
    >
      <span className="filter-button-wrap__title">{title}</span>
      {correctImg()}
    </a>
  );
};

export default FilterButton;
