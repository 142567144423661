import React, { FunctionComponent, ReactElement, useState } from "react";

import "./styles.scss";

export type Props = {
  direction?: "top" | "bottom" | "left" | "right" | "topRight" | "topLeft" | "bottomLeft" | "bottomRight";
  delay?: number;
  children: React.ReactNode;
  content: React.ReactNode;
};

export const SeityTooltip: FunctionComponent<Props> = ({ direction, delay, children, content }): ReactElement => {
  let timeout: ReturnType<typeof setTimeout>;
  const [active, setActive] = useState(false);

  const showTip = () => {
    clearInterval(timeout);
    setActive(true);
  };

  const hideTip = () => {
    clearInterval(timeout);
    timeout = setTimeout(() => {
      setActive(false);
    }, delay || 400);
  };

  return (
    <div className="tooltip-wrapper" onMouseEnter={showTip} onMouseLeave={hideTip}>
      {children}
      {active && <div className={`tooltip ${direction || "top"}`}>{content}</div>}
    </div>
  );
};

export default SeityTooltip;
