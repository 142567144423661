import React, { ReactElement, useState } from "react";
import Modal from "react-modal";

import { validateToken } from "src/reducers/utils";
import {
  getChallengeStatusReport,
  getSeityProAccessReport,
  getUserAccountListReport,
  getUserGroupListReport
} from "src/api/staffReports/seityHealthAPI-staffReports";
import { createAndDownloadPdfFile } from "src/_core/utils/dataUtils/dataUtils";

import "./styles.scss";

const closeIcon = require("../../assets/images/web-icons/close.svg").default;
const circleLoader = require("../../assets/images/gifs/circleLoader.gif");

export type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const StaffReports = ({ isOpen, onClose }): ReactElement => {
  const [isAccountReportLoading, setIsAccountReportLoading] = useState(false);
  const [isGroupReportLoading, setIsGroupReportLoading] = useState(false);
  const [isChallengeReportLoading, setIsChallengeReportLoading] = useState(false);
  const [isSeityProReportLoading, setIsSeityProReportLoading] = useState(false);
  const [error, setError] = useState(false);

  const onUserAccountBtnClick = async (dispatch: any) => {
    setIsAccountReportLoading(true);
    setError(false);
    const token = await validateToken(dispatch);
    const response = await getUserAccountListReport(token);
    if (response.success) {
      await createAndDownloadPdfFile(response.data.contents, response.data.fileName);
    }
    if (!response.success) {
      setError(true);
    }
    setIsAccountReportLoading(false);
  };

  const onUserGroupBtnClick = async (dispatch: any) => {
    setIsGroupReportLoading(true);
    setError(false);

    const token = await validateToken(dispatch);
    const response = await getUserGroupListReport(token);
    if (response.success) {
      await createAndDownloadPdfFile(response.data.contents, response.data.fileName);
    }
    if (!response.success) {
      setError(true);
    }
    setIsGroupReportLoading(false);
  };

  const onChallengeStatusListBtnClick = async (dispatch: any) => {
    setIsChallengeReportLoading(true);
    setError(false);

    const token = await validateToken(dispatch);
    const response = await getChallengeStatusReport(token);
    if (response.success) {
      await createAndDownloadPdfFile(response.data.contents, response.data.fileName);
    }
    if (!response.success) {
      setError(true);
    }
    setIsChallengeReportLoading(false);
  };

  const onSeityProAccessBtnClick = async (dispatch: any) => {
    setIsSeityProReportLoading(true);
    setError(false);

    const token = await validateToken(dispatch);
    const response = await getSeityProAccessReport(token);
    if (response.success) {
      await createAndDownloadPdfFile(response.data.contents, response.data.fileName);
    }
    if (!response.success) {
      setError(true);
    }
    setIsSeityProReportLoading(false);
  };

  const onCloseHandler = () => {
    onClose();
    setError(false);
  };

  return (
    <Modal isOpen={isOpen} className="sr-modal" overlayClassName="sr-modal-overlay">
      <div className="sr-wrapper">
        <div className="sr-header">
          <div className="sr-header-title">Staff Reports</div>
          <button onClick={onCloseHandler} type="button" className="sr-close-btn">
            <img src={closeIcon} alt="close" />
          </button>
        </div>
        <div className="sr-main">
          Please select the report you would like to download.
          <div className="sr-main-error" style={{ display: error ? "flex" : "none" }}>
            Unable to download report at this time
          </div>
          <div className="sr-main-actions">
            <button onClick={onUserAccountBtnClick} className="sr-report-btn" type="button">
              {isAccountReportLoading ? <img className="sr-main-loader" src={circleLoader} alt="loader" /> : "User account list"}
            </button>
            <button onClick={onUserGroupBtnClick} className="sr-report-btn" type="button">
              {isGroupReportLoading ? <img className="sr-main-loader" src={circleLoader} alt="loader" /> : "User group list"}
            </button>
            <button onClick={onChallengeStatusListBtnClick} className="sr-report-btn" type="button">
              {isChallengeReportLoading ? <img className="sr-main-loader" src={circleLoader} alt="loader" /> : "Challenge status list"}
            </button>
            <button onClick={onSeityProAccessBtnClick} className="sr-report-btn" type="button">
              {isSeityProReportLoading ? <img className="sr-main-loader" src={circleLoader} alt="loader" /> : "Seity Pro access list"}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default StaffReports;
