
export const getUpdatedUserGroups = (selIds: Array<number>, columns: any[]) => {
  const ret = columns.map((g) => {
    if (selIds.includes(g.companyID)) {
      return { ...g, selected: true };
    } else {
      return { ...g, selected: false };
    }
  });
  return ret;
};
