import React, { FunctionComponent, ReactElement } from "react";
import { useHistory } from "react-router-dom";
import { SurveyRecurrence } from "src/api/survey/surveyTypes";
import { getFullDate } from "src/_core/utils/dateUtils/dateUtils";

import "./styles.scss";

export type SurveyResultsSideMenuProps = {
  recurrenceData: Array<SurveyRecurrence>;
  title: string;
  selIdx: number;
  height: number;
  handleMenuPress: (menuIndex: number, surveyWizardRecurrenceID: number) => void;
};

export const SurveyResultsSideMenu: FunctionComponent<SurveyResultsSideMenuProps> = ({
  recurrenceData,
  title,
  selIdx,
  height,
  handleMenuPress,
  children
}): ReactElement => {
  const history = useHistory();

  return (
    <div className="surveyresults-sidemenu">
      <a
        className="surveyresults-sidemenu__back"
        onClick={() => {
          history.push("/survey");
        }}
      >
        <img alt="Caret" src={require("src/assets/survey/icon-back.png")} />
        <span>Back to Dashboard</span>
      </a>
      <h1>{title}</h1>
      <ul style={{ height }}>
        {recurrenceData.map((item, index) => {
          return (
            <li
              value={index}
              onClick={() => {
                handleMenuPress(index, item.surveyWizardRecurrenceID);
              }}
              key={`${item.surveyWizardRecurrenceID}`}
            >
              <a className={`sidemenu-item ${index === selIdx ? "active" : ""}`}>
                <div className="sidemenu-item__desc">
                  <p className="desc-title">{item.recurrenceText}</p>
                  <p className="desc-title desc-subtitle">
                    {getFullDate(new Date(`${item.startDateTime}Z`))} - {getFullDate(new Date(`${item.endDateTime}Z`))}
                  </p>
                  <p className="desc-status">{item.status}</p>
                </div>
              </a>
            </li>
          );
        })}
      </ul>
      {children}
    </div>
  );
};

export default SurveyResultsSideMenu;
