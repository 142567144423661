import React, { FunctionComponent, ReactElement, useEffect, useRef } from "react";
import { SurveyState } from "src/api/survey/surveyTypes";
import "./styles.scss";

export type SurveyItemMenuProps = {
  isLiked: boolean;
  status: SurveyState;
  onMenuClosed: () => void;
  onScheduleClicked: () => void;
  onDeleteClicked: () => void;
  onLikeClicked: () => void;
  onDuplicateClicked: () => void;
  onResultsClicked: () => void;
  onEditClicked: () => void;
};

const SurveyItemMenu: FunctionComponent<SurveyItemMenuProps> = ({
  onMenuClosed,
  onScheduleClicked,
  onDeleteClicked,
  onLikeClicked,
  onDuplicateClicked,
  onResultsClicked,
  onEditClicked,
  isLiked,
  status
}): ReactElement => {
  const menuRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if (menuRef.current) {
      menuRef.current.focus();
    }
  }, []);
  return (
    <ul
      ref={menuRef}
      className="survey-item-menu-wrapper"
      tabIndex={0}
      onBlur={() => {
        onMenuClosed();
      }}
    >
      <li
        onClick={() => {
          onMenuClosed();
          onLikeClicked();
        }}
      >
        <img
          src={
            isLiked ? require("src/assets/images/graphics/favorite_white.png") : require("src/assets/images/graphics/favorite_white.png")
          }
          alt="survey action"
        />
        <span>Favorite</span>
      </li>
      {status !== "Active" && status !== "Complete" && (
        <li
          onClick={() => {
            onEditClicked();
            onMenuClosed();
          }}
        >
          <img src={require("src/assets/images/graphics/edit_white.png")} alt="survey action" />
          <span>Edit</span>
        </li>
      )}
      {status !== "Active" && status !== "Complete" && (
        <li
          onClick={() => {
            onMenuClosed();
            onScheduleClicked();
          }}
        >
          <img src={require("src/assets/images/graphics/schedule_white.png")} alt="survey action" />
          <span>Schedule</span>
        </li>
      )}

      <li
        onClick={() => {
          onMenuClosed();
          onDuplicateClicked();
        }}
      >
        <img src={require("src/assets/images/graphics/duplicate_white.png")} alt="survey action" />
        <span>Duplicate</span>
      </li>

      <li
        onClick={() => {
          onMenuClosed();
          onDeleteClicked();
        }}
      >
        <img src={require("src/assets/images/graphics/delete_white.png")} alt="survey action" />
        <span>Delete</span>
      </li>

      {(status === "Complete" || status === "Active") && (
        <li
          onClick={() => {
            onMenuClosed();
            onResultsClicked();
          }}
        >
          <img src={require("src/assets/images/graphics/result_white.png")} alt="survey action" />
          <span>Results</span>
        </li>
      )}
    </ul>
  );
};

export default SurveyItemMenu;
