/* eslint-disable max-len */
import React, { FunctionComponent, ReactElement, useRef } from "react";
import { useToasts } from "react-toast-notifications";

import { AtsOutcomeSubcategory } from "src/api/ats/atsTypes";
import { getFullImageUrl } from "src/_core/utils/utils";

import "./styles.scss";

export type AtsOutcomeCategoriesItemProps = {
  outComeSubcategory: AtsOutcomeSubcategory;
};

export type AtsOutcomeCategoriesCaptionProps = {
  title: string;
  description: string;
};

const AtsOutcomeCategoriesCaption: FunctionComponent<AtsOutcomeCategoriesCaptionProps> = ({ title, description }): ReactElement => {
  const { addToast } = useToasts();

  return (
    <div className="ats-outcomecategorieselement__caption">
      <div className="ats-outcomecategorieselement__caption__header">
        <h5>{title}</h5>
      </div>

      <div className="ats-outcomecategorieselement__caption__description" dangerouslySetInnerHTML={{ __html: description }} />
      <a
        title="Click to copy text"
        className="btn-copy"
        onClick={() => {
          navigator.clipboard.writeText(description);
          addToast("Copied to clipboard!", { appearance: "success", autoDismiss: true });
        }}
      >
        <img src={require("src/assets/ats/btn-copy.png")} alt="Click to copy text" />
      </a>
    </div>
  );
};

export const AtsOutcomeCategoriesItem: FunctionComponent<AtsOutcomeCategoriesItemProps> = ({ outComeSubcategory }): ReactElement => {
  const imageUrl = getFullImageUrl(outComeSubcategory.iconFileName);
  const contentRef = useRef<HTMLDivElement>(null);

  return (
    <div className="ats-outcomecategorieselement" id={outComeSubcategory.subcategory.replace(" ", "")}>
      <a className="ats-outcomecategorieselement__header">
        <h2>{outComeSubcategory.subcategory}</h2>
      </a>
      <div className="ats-outcomecategorieselement__content-wrap" ref={contentRef}>
        <div className="ats-outcomecategorieselement__content-wrap__container">
          <div className="outcomecategorieselement-icon">
            <img src={imageUrl} alt="Corevalue Icon" />
          </div>
          <AtsOutcomeCategoriesCaption title="Description" description={outComeSubcategory.description} />
          <AtsOutcomeCategoriesCaption title="Short Description" description={outComeSubcategory.shortDescription} />
        </div>
      </div>
    </div>
  );
};

export default AtsOutcomeCategoriesItem;
