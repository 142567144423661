/* eslint-disable max-len */
import React, { FunctionComponent, ReactElement, useState, useRef, useLayoutEffect } from "react";
import { useToasts } from "react-toast-notifications";

import { AtsCoreValue } from "src/api/ats/atsTypes";
import { getFullImageUrl } from "src/_core/utils/utils";

import "./styles.scss";

export type AtsCoreValueItemProps = {
  corevalueData: AtsCoreValue;
};

export type AtsCoreValueCaptionProps = {
  title: string;
  description: string;
};

const AtsCoreValueCaption: FunctionComponent<AtsCoreValueCaptionProps> = ({ title, description }): ReactElement => {
  const { addToast } = useToasts();

  return (
    <div className="ats-corevalueitem__caption">
      <h5>{title}</h5>
      <div className="ats-corevalueitem__caption__description" dangerouslySetInnerHTML={{ __html: description }} />
      <a
        title="Click to copy text"
        className="btn-copy"
        onClick={() => {
          navigator.clipboard.writeText(description);
          addToast("Copied to clipboard!", { appearance: "success", autoDismiss: true });
        }}
      >
        <img src={require("src/assets/ats/btn-copy.png")} alt="Click to copy text" />
      </a>
    </div>
  );
};

export const AtsCoreValueItem: FunctionComponent<AtsCoreValueItemProps> = ({ corevalueData }): ReactElement => {
  const imageUrl = getFullImageUrl(corevalueData.iconFileName);
  const [isExpand, setExpand] = useState(false);
  const [height, setHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (contentRef.current && contentRef.current.clientHeight) {
      const hei = contentRef.current.clientHeight;
      setHeight(hei + 85);
    }
  });

  return (
    <div className={`ats-corevalueitem ${!isExpand ? "shrink" : ""}`} style={isExpand ? { maxHeight: height } : {}}>
      <a
        className="ats-corevalueitem__header"
        onClick={() => {
          setExpand(!isExpand);
        }}
      >
        <img
          src={isExpand ? require("src/assets/ats/icon-dash.png") : require("src/assets/ats/icon-plus.png")}
          width={32}
          height={33}
          alt="expand icon"
        />
        <h2>{corevalueData.coreValue}</h2>
      </a>
      <div className="ats-corevalueitem__content-wrap" ref={contentRef}>
        <div className="corevalue-icon">
          <img src={imageUrl} alt="Corevalue Icon" width={143} />
        </div>
        <AtsCoreValueCaption title="Definition" description={corevalueData.definition} />
        <AtsCoreValueCaption title="Explanation" description={corevalueData.description} />
        <AtsCoreValueCaption title="Quote" description={corevalueData.quote} />
      </div>
    </div>
  );
};

export default AtsCoreValueItem;
