import React, { FunctionComponent, ReactElement, useState } from "react";
import { useDispatch } from "react-redux";
import Modal from "react-modal";

import { sendPutGroupRequest } from "src/reducers/groupsAndDepartments/groupsSlice";

import "./styles.scss";

const closeIcon = require("../../../assets/groupsAndDepartments/close.svg").default;

export type ModalProps = {
  isOpen: boolean;
  onClose: Function;
  focusedID: number | undefined;
};

export const EditGroupModal: FunctionComponent<ModalProps> = ({ isOpen, onClose, focusedID }): ReactElement => {
  const dispatch = useDispatch();

  const [groupName, setGroupName] = useState("");

  const onCloseHandler = () => {
    onClose();
    setGroupName("");
  };

  const onSaveHandler = () => {
    if (focusedID) {
      dispatch(sendPutGroupRequest(focusedID, groupName));
      setGroupName("");
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} className="gad-modal" overlayClassName="gad-modal-overlay">
      <div className="gad-wrapper">
        <div className="gad-header">
          Edit Group Name
          <button onClick={onCloseHandler} type="button" className="gad-close-btn">
            <img src={closeIcon} alt="close" />
          </button>
        </div>
        <div className="gad-main">
          <label className="gad-input-form" htmlFor="groupName">
            Group Name
            <input
              placeholder="Group Name"
              onChange={(e) => {
                setGroupName(e.target.value);
              }}
              value={groupName}
              className="gad-input"
              id="groupName"
              type="text"
            />
          </label>
        </div>
        <div className="gad-footer">
          <button onClick={onCloseHandler} className="gad-cancel-btn" type="button">
            Cancel
          </button>
          <button disabled={groupName.length === 0} onClick={onSaveHandler} className="gad-create-btn" type="submit">
            Save
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default EditGroupModal;
