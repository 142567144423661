import React, { FunctionComponent } from "react";
import { getFullDate } from "src/_core/utils/dateUtils/dateUtils";

import "./styles.scss";

export type ResultsHeaderProps = {
  surveyTitle: string | undefined;
  startDate: string | undefined;
  endDate: string | undefined;
};

export const ResultsHeader: FunctionComponent<ResultsHeaderProps> = ({ surveyTitle, startDate, endDate }) => {
  return (
    <div className="survey-results-header">
      <h1>{surveyTitle}</h1>
      <h2>Survey Results</h2>
      <span>
        {getFullDate(new Date(`${startDate}Z`))} - {getFullDate(new Date(`${endDate}Z`))}
      </span>
    </div>
  );
};

export default ResultsHeader;
