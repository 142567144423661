/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo, useState } from "react";
import { useSelector, connect } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { RootState } from "src/reducers";

import { models } from "powerbi-client";

import SeityButton from "src/components/analytic/SeityButton";
import SeityBreadCrumbs from "src/components/analytic/SeityBreadCrumbs";
import { SeityFormSelectBox } from "src/components/analytic/SeitySelectBox/SeityFormSelectBox";
import SeityEditLocation from "src/components/analytic/SeityEditLocation";
// import SeityFiltersNav from 'src/components/SeityFiltersNav';
import SeityPbiReport from "src/components/analytic/SeityPbiReport";
import { getPbiFilterData } from "src/utils/helper";

import "./styles.scss";
import { getPageName } from "./helpers";

const ReportHome = ({
  embedToken,
  staffEmbedUrl,
  studentEmbedUrl,
  cvStaffEmbedUrl,
  cvStudentEmbedUrl,
  v1StaffEmbedUrl,
  v1StudentEmbedUrl,
  isPBITokenLoading
}) => {
  const location = useLocation();
  const history = useHistory();

  const [bookmarkList, setBookmarkList] = useState<models.IReportBookmark[] | null>(null);
  const [viewLayout, setViewLayout] = useState(0);
  const [showEditLocation, setShowEditLocation] = useState(false);
  const [showLanding, setShowLanding] = useState(true);

  const { curTeacherClass, curTeacher, curTeacherSchool, teacherSchoolList, analyticsView } = useSelector((state: RootState) => {
    return state.analytics;
  });

  const { proSettings, accountInfo } = useSelector((state: RootState) => {
    return state.account;
  });

  const { embedUrl, pageList } = useMemo(() => {
    let url = "";
    let pages;
    if (location.state.reportType === "Staff") {
      url = v1StaffEmbedUrl;
      const pageName = getPageName(proSettings?.analyticsView);
      if (pageName) {
        pages = [{ displayName: "Group View", name: pageName }];
      }
    } else {
      url = v1StudentEmbedUrl;
      const pageName = getPageName(curTeacherSchool?.analyticsView);
      if (pageName) {
        pages = [
          { displayName: "Group View", name: pageName },
          { displayName: "Group Roster", name: "GroupRoster" }
        ];
      }
    }

    return {
      embedUrl: url,
      pageList: pages
    };
  }, [accountInfo, analyticsView, curTeacherSchool, proSettings]);

  const onChangeViewLayout = async (e) => {
    setViewLayout(parseInt(e.target.value, 10));
  };

  if (!proSettings || !proSettings.companyID) {
    history.push("/");
  }

  return (
    <div className="home-container">
      {location.state.reportType === "Staff" || !showLanding ? (
        <>
          <div className="home-header">
            {location.state.reportType !== "Staff" && (
              <SeityButton
                label="Select population"
                style={{ height: 48 }}
                onClick={() => {
                  setShowEditLocation(!showEditLocation);
                }}
              />
            )}
            <SeityBreadCrumbs reportType={location.state.reportType} companyName={proSettings?.companyName} />
            {pageList?.length > 0 && (
              <>
                <span>ANALYTICS VIEW</span>
                <div className={`analytics-view-wrap ${pageList?.length === 1 && "disabled"}`}>
                  <SeityFormSelectBox
                    value={pageList[viewLayout].displayName}
                    onChange={(e) => {
                      onChangeViewLayout(e);
                    }}
                  >
                    <ul>
                      {pageList.map((item, index) => {
                        return (
                          <li key={index} value={index}>
                            {item.displayName}
                          </li>
                        );
                      })}
                    </ul>
                  </SeityFormSelectBox>
                </div>
              </>
            )}
          </div>
          {!isPBITokenLoading && embedToken && embedUrl ? (
            <SeityPbiReport
              pageName={pageList?.length > 0 ? pageList[viewLayout].name : null}
              embedToken={embedToken}
              embedUrl={embedUrl}
              curBookmark={null} // Disabled all bookmark features since v1 update, keep it just in case
              filterVal={getPbiFilterData(
                curTeacherSchool,
                curTeacher,
                curTeacherClass,
                location.state.reportType === "Staff",
                proSettings.companyID,
                teacherSchoolList,
                proSettings.allStudentAccess
              )}
              reportType={location.state.reportType}
              bookmarkListHandler={(bkList) => {
                setBookmarkList(bkList.slice(0, 2));
              }}
              tokenExpiredHandler={() => {}}
              filterValsHandler={(filterVals) => {
                console.log(filterVals);
              }}
            />
          ) : (
            <h1 className="error_log">No reports available for your company</h1>
          )}
          {showEditLocation && (
            <SeityEditLocation
              isOpen={showEditLocation}
              isLanding={false}
              reportType={location.state.reportType}
              onClose={() => {
                setShowEditLocation(false);
              }}
              onCancel={() => {
                setShowEditLocation(false);
              }}
            />
          )}
        </>
      ) : (
        <>
          <h1 className="landing-title">Please select the desired student population to view their Analytics data.</h1>
          <div className="editloc-wrap">
            <SeityEditLocation
              isOpen
              isLanding
              reportType={location.state.reportType}
              onClose={() => {
                setShowLanding(false);
              }}
              onCancel={() => {
                history.push("/");
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    embedToken: state.pbi.pbiEmbedToken,
    accessToken: state.pbi.pbiAccessToken,
    staffEmbedUrl: state.pbi.staffEmbedUrl,
    studentEmbedUrl: state.pbi.studentEmbedUrl,
    v1StaffEmbedUrl: state.pbi.v1StaffEmbedUrl,
    v1StudentEmbedUrl: state.pbi.v1StudentEmbedUrl,
    cvStaffEmbedUrl: state.pbi.cvStaffEmbedUrl,
    cvStudentEmbedUrl: state.pbi.cvStudentEmbedUrl,
    isPBITokenLoading: state.pbi.isLoading
  };
};

export default connect(mapStateToProps)(ReportHome);
