import React, {useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, Switch, Route, BrowserRouter } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import styled from "styled-components";
import { RootState } from "src/reducers";
import {getSavedToken, logOut} from "src/reducers/auth/authSlice";

import { SeityHeader } from "src/components/SeityHeader";
import ReportHome from "src/features/ReportHome";
import Landing from "src/features/ReportHome/Landing";
import { Login } from "src/features/Login";
import { Account } from "src/features/Account";
import { NotFound404 } from "src/features/404";
import MaintenanceOne from "src/features/Account/maintenance/MaintenanceOne";
import MaintenanceTwo from "src/features/Account/maintenance/MaintenanceTwo";
import MaintenanceThree from "src/features/Account/maintenance/MaintenanceThree";
import changePassword from "src/features/Account/maintenance/changePassword";
import ComingSoon from "src/features/comingsoon";
import ForgotPassword from "src/features/Account/ForgotPassword";

// Survey
import SurveyDash from "src/features/SurveyDash";
import SurveyReport from "src/features/SurveyReport";
import SurveyResults from "src/features/SurveyResults";
import SurveyCreate from "src/features/SurveyCreate";

// ATS Guide
import AtsHome from "src/features/AtsHome";

// Group and Departments
import Groups from "src/features/GroupsAndDepartments/Groups";
import Departments from "src/features/GroupsAndDepartments/Departments";

// Wellbeing Navigator
import WellbeingNavigator from "src/features/WellbeingNavigator";

import Examples from "src/features/Examples";
import SeityAppContextProvider from "./SeityAppContext";

import ResetPassword from "src/features/ResetPassword";

const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast-icon {
    width: 40px;
  }
`;

const ToastErrorContainer = styled(ToastContainer)`
  .Toastify__toast-theme--light {
    color: #343b43;
  }
`;

const Main = styled.main`
  display: inline-flex;
  flex-direction: row;
`;

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  opacity: 1 !important;
  position: relative;
  transition: opacity 0.5s;
`;

const PrivateRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const { token, shouldRedirectToLogin } = useSelector((state: RootState) => {
    return state.auth;
  });

  if (token === "") {
    dispatch(getSavedToken(() => {}));
  }

  useEffect(() => {
    if (shouldRedirectToLogin) {
      dispatch(logOut());
    }

  }, [shouldRedirectToLogin]);

  return (
    <Route
      {...rest}
      render={(props) => {
        return shouldRedirectToLogin ? <Redirect to={{ pathname: "/login", state: { from: props.location } }} /> : <Component {...props} />;
      }}
    />
  );
};

function SeityRouter() {
  return (
    <BrowserRouter>
      <SeityAppContextProvider>
        <AppWrapper className="App">
          <StyledToastContainer enableMultiContainer containerId="cv-noti" />
          <ToastErrorContainer enableMultiContainer containerId="general-noti" style={{ width: "568px" }} />
          <SeityHeader />
          <Main className="seity-main">
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route exact path="/forgot-password" component={ForgotPassword} />
              <Route exact path="/404" component={NotFound404} />
              <Route exact path="/comingsoon" component={ComingSoon} />
              <Route exact path="/examples" component={Examples} /> {/* //Provisional route added to test all the components created */}
              <PrivateRoute exact path="/" component={Landing} />
              <PrivateRoute exact path="/home" component={ReportHome} />
              <PrivateRoute path="/profile" component={Account} />
              <PrivateRoute path="/maintenanceOne" component={MaintenanceOne} />
              <PrivateRoute path="/maintenanceTwo" component={MaintenanceTwo} />
              <PrivateRoute path="/maintenanceThree" component={MaintenanceThree} />
              <PrivateRoute path="/changePassword" component={changePassword} />
              <PrivateRoute path="/resetPassword" component={ResetPassword} />
              {/* Survey */}
              <PrivateRoute exact path="/survey" component={SurveyDash} />
              <PrivateRoute path="/surveyCreate" component={SurveyCreate} />
              <PrivateRoute exact path="/surveyReport" component={SurveyReport} />
              <PrivateRoute exact path="/surveyResults" component={SurveyResults} />
              {/* ATS Guide */}
              <PrivateRoute exact path="/atshome" component={AtsHome} />
              {/* Groups and Departments */}
              <PrivateRoute exact path="/groups" component={Groups} />
              <PrivateRoute exact path="/departments" component={Departments} />
              {/* Wellbeing Navigator */}
              <PrivateRoute exact path="/wbn" component={WellbeingNavigator} />
              <Redirect to={{ pathname: "/404" }} />
            </Switch>
          </Main>
        </AppWrapper>
      </SeityAppContextProvider>
    </BrowserRouter>
  );
}

export default SeityRouter;
