import React, {FunctionComponent, ReactElement, useCallback, useEffect, useMemo, useRef, useState} from "react";
import CoreStrings from "../../_core/strings/strings";
import "./styles.scss";
import {useResetPasswordData} from "./hooks";
import SeityLoader from "../../components/SeityLoader";
import ResetPasswordGrid from "../../components/ResetPassword/ResetPasswordGrid";
import {COLUMNS_DATA, COLUMNS_DATA_WITHOUT_STUDENT_ID} from "./data";
import ResetPasswordTextInput from "../../components/ResetPassword/ResetPasswordTextInput";
import ResetPasswordButton from "../../components/ResetPassword/ResetPasswordButton";
import {getUpdatedUserGroups} from "./helpers";
import ResetPasswordButtonMenuModal from "../../components/ResetPassword/ResetPasswordButtonMenuModal";
import ResetPasswordNumberInput from "../../components/ResetPassword/ResetPasswordNumberInput";
import ResetPasswordOffCanvas from "../../components/ResetPassword/ResetPasswordOffCanvas";
import {
    ResetPasswordCompaniesResponseItem,
    ResetPasswordAccountsResponseItem
} from "../../api/resetPassword/resetPasswordTypes";
import {ToastProvider} from 'react-toast-notifications';

const HANGON_SCROLL_POS = 120;

const defaultRow: ResetPasswordAccountsResponseItem = {
    accountID: 0,
    companyID: 0,
    companyName: "",
    companyNameShort: "",
    emailAddress: "",
    firstName: "",
    lastName: "",
    studentID: 0,
    companyTypeID: 0,
    generalEMailEnabled: false,
    gender: "",
    dateOfBirth: "",
    doNotAllowPasswordChange: false,
    lastPasswordChangeDate: "",
    changePassword: false,
    sendTemporaryPassword: false,
    manuallyChangePassword: false,
    autoGeneratePassword: false,
    grade: 0
};

export const ResetPassword: FunctionComponent = (): ReactElement => {

    const [columns, setColumns] = useState(COLUMNS_DATA);
    const [hangon, setHangon] = useState(false);
    const [lastNameFilter, setLastNameFilter] = useState<string>("");
    const [firstNameFilter, setFirstNameFilter] = useState<string>("");
    const [emailFilter, setEmailFilter] = useState<string>("");
    const [studentIDFilter, setStudentIDFilter] = useState<string | null>(null);
    const [showUserGroupsModal, setShowUserGroupsModal] = useState(false);
    const [showResetPasswordOffCanvas, setShowResetPasswordOffCanvas] = useState(false);
    const userGroupsRef = useRef<HTMLDivElement>(null);
    const [scrollPos, setScrollPos] = useState(0);
    const [selectedUserGroups, setSelectedUserGroups] = useState<any>([]);
    const [selectedRow, setSelectedRow] = useState<ResetPasswordAccountsResponseItem>(defaultRow);
    const [showCompanyDropdown, setShowCompanyDropdown] = useState(false);
    const [showStudentIdFilter, setShowStudentIdFilter] = useState(false);
    const [showStudentIdColumn, setShowStudentIdColumn] = useState(false);
    const [searchButtonEnabled, setSearchButtonEnabled] = useState(true);
    const [clearFilterTriggered, setClearFilterTriggered] = useState(false);

    const {
        isLoading,
        resetPasswordRows,
        resetPasswordUserGroups,
        fetchData,
        sendAutoGeneratePasswordRequest,
        autoGeneratedPassword,
        setAutoGeneratedPassword,
        sendTemporaryPasswordRequest,
        sendManualResetPasswordRequest
    } = useResetPasswordData();

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPos(position);
        setHangon(position > HANGON_SCROLL_POS);
    };

    const countSelectedUserGroups = selectedUserGroups.filter(group => group.selected).length;

    useEffect(() => {

        let disableSearchButton = (
            !lastNameFilter &&
            !firstNameFilter &&
            !emailFilter &&
            studentIDFilter === null &&
            !countSelectedUserGroups
        )

        if (disableSearchButton) {
            setSearchButtonEnabled(false);
        } else {
            setSearchButtonEnabled(true);
        }

    }, [lastNameFilter, firstNameFilter, emailFilter, studentIDFilter, selectedUserGroups]);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {

        const modifiedUserGroups = resetPasswordUserGroups.map(group => {
            return {
                ...group,
                selected: true
            };
        });

        setSelectedUserGroups(modifiedUserGroups);

        if (modifiedUserGroups.length >= 1) {
            setShowCompanyDropdown(true);
        }

        const hasCompanyTypeID2 = resetPasswordUserGroups.some(item => item.companyTypeID === 2);

        setShowStudentIdFilter(hasCompanyTypeID2);
        setShowStudentIdColumn(hasCompanyTypeID2);

    }, [resetPasswordUserGroups]);

    useEffect(() => {

        if (clearFilterTriggered) {
            let companyIDs = selectedUserGroups.filter((obj) => {
                return obj.selected;
            }).map((obj) => {
                return obj.companyID;
            });

            fetchData(emailFilter, firstNameFilter, lastNameFilter, companyIDs, studentIDFilter);
            setClearFilterTriggered(false);
        }

    }, [emailFilter, firstNameFilter, lastNameFilter, selectedUserGroups, studentIDFilter, clearFilterTriggered]);


    const handleSearchButtonPress = useCallback(() => {

        let companyIDs = selectedUserGroups.filter((obj) => {
            return obj.selected;
        }).map((obj) => {
            return obj.companyID;
        });
        fetchData(emailFilter, firstNameFilter, lastNameFilter, companyIDs, studentIDFilter)

    }, [selectedUserGroups, lastNameFilter, firstNameFilter, emailFilter, studentIDFilter]);

    const onRowClick = useCallback((row: any) => {
        setSelectedRow(row);
        setShowResetPasswordOffCanvas(true);
    }, []);

    const GridTable = useMemo(() => {
        return resetPasswordRows ?
            <ResetPasswordGrid data={resetPasswordRows} columns={columns} onRowClick={onRowClick}
                               showStudentIdColumn={showStudentIdColumn}/> : undefined;
    }, [columns, resetPasswordRows, showStudentIdColumn]);

    return (
        <ToastProvider placement="top-right">
            <div className="reset-password">
                {isLoading && <SeityLoader/>}
                <h1>{CoreStrings.resetPasswordHeader}</h1>
                <div className="reset-password__main">
                    <h2 className="reset-password__searchTitle">{CoreStrings.search}</h2>
                    <div className="reset-password__top">
                        <div className="reset-password__filter">
                            <label>Last name</label>
                            <ResetPasswordTextInput
                                placeholder={""}
                                value={lastNameFilter}
                                onChange={(value, clear) => {
                                    setLastNameFilter(value)
                                    setClearFilterTriggered(clear)
                                }}
                            />
                        </div>
                        <div className="reset-password__filter">
                            <label>First name</label>
                            <ResetPasswordTextInput
                                placeholder={""}
                                value={firstNameFilter}
                                onChange={(value, clear) => {
                                    setFirstNameFilter(value)
                                    setClearFilterTriggered(clear)
                                }}
                            />
                        </div>
                        <div className="reset-password__filter">
                            <label>Email</label>
                            <ResetPasswordTextInput
                                placeholder={""}
                                value={emailFilter}
                                onChange={(value, clear) => {
                                    setEmailFilter(value);
                                    setClearFilterTriggered(clear)
                                }}
                            />
                        </div>
                        {showStudentIdFilter &&
                          <div className="reset-password__filter">
                            <label>Student ID</label>
                            <ResetPasswordNumberInput
                              maxLen={50}
                              placeholder={""}
                              value={studentIDFilter}
                              onChange={(value) => {
                                  setStudentIDFilter(value === "" ? null : value);
                              }}
                            />
                          </div>
                        }

                        {showCompanyDropdown &&
                          <div className="reset-password__filter" ref={userGroupsRef}>
                            <ResetPasswordButton
                              type="select"
                              isSel={showUserGroupsModal}
                              label={CoreStrings.userGroup}
                              customClass="reset-password__btn"
                              onClick={() => {
                                  setShowUserGroupsModal(!showUserGroupsModal);
                              }}
                            >
                              <div className="reset-password__bubble-count">{countSelectedUserGroups}</div>
                            </ResetPasswordButton>
                          </div>
                        }

                        <div className="reset-password__filter">
                            <ResetPasswordButton
                                disabled={!searchButtonEnabled}
                                type="submit"
                                label={CoreStrings.search}
                                customClass="reset-password__btn"
                                onClick={() => {
                                    handleSearchButtonPress();
                                }}
                            />
                        </div>
                    </div>
                    <div className="reset-password__body">
                        {resetPasswordRows?.length !== 0 && GridTable}

                        {(resetPasswordRows?.length === 0 && !isLoading) && 'There are no users matching your search. Please try other search criteria.'}
                    </div>
                </div>

                {showUserGroupsModal && (
                    <ResetPasswordButtonMenuModal
                        position={{
                            top: (userGroupsRef.current?.offsetTop ?? 0) - (hangon ? scrollPos : 0) + 40,
                            left: (userGroupsRef.current?.offsetLeft ?? 0) - 50
                        }}
                        data={selectedUserGroups}
                        isOpen={showUserGroupsModal}
                        selectedOptions={selectedUserGroups
                            .filter((c) => {
                                return c?.selected;
                            })
                            .map((cc) => {
                                return cc.companyID;
                            })}
                        setSelectedOptions={(options) => {
                            setSelectedUserGroups(getUpdatedUserGroups(options, selectedUserGroups));
                        }}
                        onApply={() => {
                            setShowUserGroupsModal(false);
                        }}
                    />
                )}

                <ResetPasswordOffCanvas
                    isOpen={showResetPasswordOffCanvas}
                    onClose={() => setShowResetPasswordOffCanvas(false)}
                    accountID={selectedRow?.accountID}
                    firstName={selectedRow?.firstName}
                    lastName={selectedRow?.lastName}
                    emailAddress={selectedRow?.emailAddress}
                    companyName={selectedRow?.companyName}
                    companyNameShort={selectedRow?.companyNameShort}
                    companyID={selectedRow?.companyID}
                    studentID={selectedRow?.studentID}
                    companyTypeID={selectedRow?.companyTypeID}
                    gender={selectedRow?.gender}
                    dateOfBirth={selectedRow?.dateOfBirth}
                    doNotAllowPasswordChange={selectedRow?.doNotAllowPasswordChange}
                    setShowResetPasswordOffCanvas={setShowResetPasswordOffCanvas}
                    lastPasswordChangeDate={selectedRow?.lastPasswordChangeDate}
                    sendAutoGeneratePasswordRequest={sendAutoGeneratePasswordRequest}
                    autoGeneratedPassword={autoGeneratedPassword}
                    sendManualResetPasswordRequest={sendManualResetPasswordRequest}
                    sendTemporaryPasswordRequest={sendTemporaryPasswordRequest}
                    setAutoGeneratedPassword={setAutoGeneratedPassword}
                    changePassword={selectedRow?.changePassword}
                    sendTemporaryPassword={selectedRow?.sendTemporaryPassword}
                    manuallyChangePassword={selectedRow?.manuallyChangePassword}
                    autoGeneratePassword={selectedRow?.autoGeneratePassword}
                    generalEmailEnabled={selectedRow?.generalEMailEnabled}
                    grade={selectedRow?.grade}
                />
            </div>
        </ToastProvider>
    );
};

export default ResetPassword;
