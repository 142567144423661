/* eslint-disable */
import React, { useEffect, useState } from "react";
import strings from "src/_core/strings/strings";

import classNames from "classnames";

import "./styles.scss";

export interface SeitySelectBoxProps {
  children: JSX.Element | JSX.Element[];
  id?: string;
  onChange?: ((event: React.ChangeEvent<HTMLSelectElement>) => void) | undefined;
  value?: any;
  className?: string;
  isInvalid?: boolean;
  inputStyle?: {};
}

export const SeitySelectBox = (props: SeitySelectBoxProps) => {
  const { children, id, onChange, value, className, isInvalid, inputStyle } = props;
  const [showOptions, setShowOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState(value || strings.choose);

  useEffect(() => {
    setSelectedOption(value || strings.choose);
  }, [value]);

  const handleOnChange = (e) => {
    if (onChange) {
      onChange(e);
    }
  };

  const correctImg = () => {
    if (showOptions) {
      return require("src/assets/images/graphics/checkmark-green.png");
    }
    return require("src/assets/images/web-icons/select_caret.png");
  };

  const handleSelectOption = (e) => {
    setShowOptions(false);
    setSelectedOption(e.target.innerText);
    handleOnChange(e);
  };

  const selectBoxTriggerClasses = classNames({
    "seity-select-box-trigger": true,
    "seity-select-box-trigger-error": isInvalid,
    "seity-select-box-trigger-options": showOptions
  });

  return (
    <div className="seity-select-box-wrapper">
      <div
        style={inputStyle}
        className="seity-select-box"
        onChange={(e) => {
          return handleOnChange(e);
        }}
      >
        <div
          className={selectBoxTriggerClasses}
          onClick={() => {
            setShowOptions(!showOptions);
          }}
        >
          <div>{selectedOption}</div>
          <img src={correctImg()} />
        </div>
        {showOptions && (
          <div
            onClick={(e) => {
              return handleSelectOption(e);
            }}
            className="seity-select-box-options"
          >
            {children}
          </div>
        )}
      </div>
    </div>
  );
};
