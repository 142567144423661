import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import StepWizard from "react-step-wizard";
import { sendGetSurveyRequest } from "src/reducers/survey/surveySlice";
import { sendGetSurveyFrequencyRequest } from "src/reducers/survey/surveyScheduleSlice";

import { SurveySchedule } from "src/api/survey/surveyTypes";
import SurveyScheduler from "./stepComponents/SurveyScheduler";
import SurveyActiveModal from "../SurveyDash/SurveyActiveModal";
import SurveyCreatedModal from "../SurveyDash/SurveyCreatedModal";
import SurveySavedModal from "../SurveyDash/SurveySavedModal";
import SurveyScheduledModal from "../SurveyDash/SurveyScheduledModal";
import SurveyQuestionSetup from "./stepComponents/SurveyQuestionSetup";
import SurveyAudience from "./stepComponents/SurveyAudience";
import "./styles.scss";
import SurveyStepper from "./SurveyStepper";
import { SurveyScheduleDate } from "./types";

export type SurveyCreateProps = {};

export const SurveyCreate: FunctionComponent<SurveyCreateProps> = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const index = location.state ? location.state.index : 0;
  const surveyId = location.state ? location.state.surveyId : null;
  const surveyScheduleId = location.state ? location.state.scheduleId : null;
  const step = location.state ? location.state.step : 1;

  const [currentSurveyID, setCurrentSurveyID] = useState<number | null>(surveyId);
  const [currentSurveyScheduleID, setCurrentSurveyScheduleID] = useState<number | null>(surveyScheduleId);

  const [showActiveModal, setShowActiveModal] = useState(false);
  const [showScheduledModal, setShowScheduledModal] = useState(false);
  const [showCreatedModal, setShowCreatedModal] = useState(false);
  const [showSavedModal, setShowSavedModal] = useState(false);
  const [showWizardNav, setShowWizardNav] = useState(true);
  const [curSurveyData, setCurSurveyData] = useState<{
    scheduleInfo: SurveyScheduleDate;
    surveySchedule: SurveySchedule | null;
  }>({
    scheduleInfo: {
      startDate: "",
      endDate: "",
      frequency: 1,
      startDay: undefined,
      startDayLabel: ""
    },
    surveySchedule: null
  });

  useEffect(() => {
    dispatch(sendGetSurveyFrequencyRequest(() => {}));
  }, []);

  return (
    <div className="survey-create">
      <StepWizard nav={showWizardNav ? <SurveyStepper /> : <></>} initialStep={step}>
        <SurveyQuestionSetup
          surveyWizardID={currentSurveyID}
          setCurrentWizardID={(surveyWizardID) => {
            setCurrentSurveyID(surveyWizardID);
          }}
          index={index}
          onSaveDraft={() => {
            setShowCreatedModal(true);
          }}
          onUpdateDraft={() => {
            console.log("onupdate");
          }}
          onTogglePreview={(isShow) => {
            setShowWizardNav(!isShow);
          }}
        />
        <SurveyScheduler
          surveyWizardID={currentSurveyID || 0}
          surveyWizardScheduleID={currentSurveyScheduleID}
          onClose={() => {
            setCurrentSurveyScheduleID(null);
          }}
          onSchedule={(dateObj, curSurveySchedule) => {
            setCurSurveyData({
              scheduleInfo: dateObj,
              surveySchedule: curSurveySchedule
            });
            // Update currentSurveyScheduleID so that SurveyScheduler can notice that the survey
            // has been scheduled to prevent the post api call
            // (sendPostSurveyWizardScheduleRequest) to the survey already created.
            setCurrentSurveyScheduleID(curSurveySchedule.surveyWizardScheduleID);
          }}
          onSurveyActive={() => {
            window.scrollTo({ top: 0, left: 0 });
            dispatch(sendGetSurveyRequest());
            setShowActiveModal(true);
          }}
          onSurveyScheduled={() => {
            setShowScheduledModal(true);
            setCurrentSurveyScheduleID(null);
          }}
        />
        <SurveyAudience
          onClose={() => {
            setCurrentSurveyScheduleID(null);
          }}
          curSurveySchedule={curSurveyData.surveySchedule}
          onSurveyActive={() => {
            window.scrollTo({ top: 0, left: 0 });
            dispatch(sendGetSurveyRequest());
            setShowActiveModal(true);
          }}
          onSurveyScheduled={() => {
            window.scrollTo({ top: 0, left: 0 });
            setShowScheduledModal(true);
            setCurrentSurveyScheduleID(null);
          }}
        />
      </StepWizard>
      <SurveyActiveModal
        isOpen={showActiveModal}
        onClose={() => {
          setShowActiveModal(false);
          history.push("/survey");
        }}
        startDate={curSurveyData.scheduleInfo.startDate}
        endDate={curSurveyData.scheduleInfo.endDate}
        startDay={curSurveyData.scheduleInfo.startDayLabel || ""}
        frequency={curSurveyData.scheduleInfo.frequency}
      />
      <SurveyScheduledModal
        isOpen={showScheduledModal}
        onClose={() => {
          setShowScheduledModal(false);
          history.push("/survey");
        }}
        startDate={curSurveyData.scheduleInfo.startDate}
        endDate={curSurveyData.scheduleInfo.endDate}
        startDay={curSurveyData.scheduleInfo.startDayLabel || ""}
        frequency={curSurveyData.scheduleInfo.frequency}
      />
      <SurveyCreatedModal
        isOpen={showCreatedModal}
        onSchedule={() => {
          setShowCreatedModal(false);
        }}
        onScheduleLater={() => {
          setShowCreatedModal(false);
          setShowSavedModal(true);
        }}
      />
      <SurveySavedModal
        isOpen={showSavedModal}
        onClose={() => {
          setShowSavedModal(false);
          history.push("/survey");
        }}
      />
    </div>
  );
};

export default SurveyCreate;
