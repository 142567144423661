import { useEffect, useState } from "react";
import { WbnDataResponse, WbnFilter, WbnFilterOption, WbnFiltersResponse, WbnRowData } from "src/api/wbn/wbnTypes";
import { FILTER_OPTIONS_SAMPLE } from "./data";
import { buildSelFilterData, buildWbnDataApiParams, getSelFilterOptions } from "./helpers";
import apiClientWithAuth from "src/api/apiClient";

export const useWbnData = () => {
  const [filterOptions, setFilterOptions] = useState<Array<WbnFilterOption>>([]);
  const [isLoading, setLoading] = useState(false);
  const [selFilterData, setSelFilterData] = useState<WbnFilter>(FILTER_OPTIONS_SAMPLE);
  const [wbnApiData, setWbnApiData] = useState<WbnFilter>(FILTER_OPTIONS_SAMPLE);
  const [primWbnRows, setPrimWbnRows] = useState<Array<WbnRowData>>();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const res = await apiClientWithAuth.get<WbnFiltersResponse>("/WellbeingNavigatorData/filters");

      if (res.data.success) {
        setSelFilterData(buildSelFilterData(FILTER_OPTIONS_SAMPLE, res.data.data));
        setWbnApiData(res.data.data);
      }

      setLoading(false);
    };

    fetchData();
  }, []);
  
  useEffect(() => {
    setFilterOptions([...getSelFilterOptions(selFilterData)]);

    const fetchWbnData = async () => {
      const params = buildWbnDataApiParams(selFilterData);
      // if (
      //   params.companyIDs.length === 0 &&
      //   params.genders.length === 0 &&
      //   params.grades.length === 0 &&
      //   (!params.lastCheckinDate || !params.lastCheckinDate.startDate || !params.lastCheckinDate.endDate) &&
      //   params.races.length === 0 &&
      //   params.attentionCodes.length === 0
      // ) {
      //   // No filter options selected will display blank on Grid Table
      //   setPrimWbnRows([]);
      // } else {
        setLoading(true);
        const res = await apiClientWithAuth.post<WbnDataResponse>("/WellbeingNavigatorData", params);
        if (res.data.success) {
          setPrimWbnRows(res.data.data.wellnessNavigatorData);
        }
        setLoading(false);
      // }
    };

    fetchWbnData();
  }, [selFilterData]);

  return {
    isLoading,
    filterOptions,
    wbnApiData,
    primWbnRows,
    selFilterData,
    setSelFilterData
  };
};
