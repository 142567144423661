/* eslint-disable */
import React from "react";
import { SeityButton } from "../SeityButton";
import "./styles.scss";

interface SeityPrimaryCancelButtonsProps {
  cancelAction?: () => void | null;
  cancelTitle?: string;
  primaryAction?: () => void;
  primaryTitle?: string;
  errorMessage?: string | null;
  primaryDisabled?: boolean;
  showCancel: boolean;
}

export const SeityPrimaryCancelButtons = (props: SeityPrimaryCancelButtonsProps) => {
  return (
    <div className="seity-primary-cancel-nav-container">
      <div className="seity-primary-cancel-nav-container-error">{props.errorMessage !== null ? props.errorMessage : ""}</div>
      <div>
        {props.showCancel && (
          <SeityButton onClick={props.cancelAction} type="cancel" disabled={false} label={props.cancelTitle} style={{ marginRight: 20 }} />
        )}
        <SeityButton onClick={props.primaryAction} type="next" disabled={props.primaryDisabled} label={props.primaryTitle} />
      </div>
    </div>
  );
};

export default SeityPrimaryCancelButtons;
