export const getPageName = (analyticView?: number) => {
  /*
    GroupView11 = 1,
    GroupView01 = 2,
    GroupView10 = 3,
    GroupView00 = 4
  */
  switch (analyticView) {
    case 1:
      return "GroupView11";
    case 2:
      return "GroupView01";
    case 3:
      return "GroupView10";
    case 4:
      return "GroupView00";
    default:
      return null;
  }
};
