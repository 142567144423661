import React, { useEffect, useState } from "react";
import { SeitySelectBox } from "../SeitySelectBox/SeitySelectBox";

import "./styles.scss";

export interface SeityDatePickerProps {
  retriveDate: (date) => void;
  setDate?: Date | null;
  invalid?: boolean;
}

export const SeityDatePicker = (props: SeityDatePickerProps) => {
  const today = new Date();
  const currentMonth = today.getMonth() + 1;
  const setMonth = props.setDate ? props.setDate.getMonth() + 1 : null;
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const monthElements = months.map((item) => {
    return <li>{item}</li>;
  });

  const yearSpan = 100;
  const currentYear = today.getFullYear();
  const setYear = props.setDate ? props.setDate.getFullYear() : today.getFullYear();
  const startYear = currentYear - yearSpan;

  const years = Array.from(Array(yearSpan * 2), (_, index) => {
    return index + startYear;
  });
  const yearElements = years.map((item) => {
    return <li>{item}</li>;
  });

  // }

  const currentDay = today.getDate();
  const setDay = props.setDate ? props.setDate.getDate() : null;

  const days = Array.from(Array(new Date(currentYear, currentMonth, 0).getDate()), (_, index) => {
    return index + 1;
  });
  let dayElements = days.map((item) => {
    return <li>{item}</li>;
  });

  // }

  const [mm, setMm] = useState(setMonth ?? currentMonth);
  const [dd, setDd] = useState(setDay ?? currentDay);
  const [yyyy, setYyyy] = useState(setYear ?? currentYear);
  const [daysInMonth, setDaysInMonth] = useState(dayElements);

  useEffect(() => {
    const result = new Date(yyyy, mm - 1, dd);
    props.retriveDate(result);
  }, []);

  useEffect(() => {
    const date = new Date(yyyy, mm, 0);
    const dayss = Array.from(Array(date.getDate()), (_, index) => {
      return index + 1;
    });
    dayElements = dayss.map((item) => {
      return <li>{item}</li>;
    });

    setDaysInMonth(dayElements);

    const result = new Date(yyyy, mm - 1, dd);
    props.retriveDate(result);
  }, [mm, dd, yyyy]);

  return (
    <div className="seity-date-picker">
      <SeitySelectBox
        inputStyle={{ width: "85%" }}
        isInvalid={props.invalid}
        onChange={(e) => {
          return setMm(Number(e.target.innerText));
        }}
        value={mm}
      >
        <ul>{monthElements}</ul>
      </SeitySelectBox>
      <SeitySelectBox
        inputStyle={{ width: "85%" }}
        isInvalid={props.invalid}
        onChange={(e) => {
          return setDd(Number(e.target.innerText));
        }}
        value={dd}
      >
        <ul>{daysInMonth}</ul>
      </SeitySelectBox>
      <SeitySelectBox
        inputStyle={{ width: "100%" }}
        isInvalid={props.invalid}
        onChange={(e) => {
          return setYyyy(Number(e.target.innerText));
        }}
        value={yyyy}
      >
        <ul>{yearElements}</ul>
      </SeitySelectBox>
    </div>
  );
};
