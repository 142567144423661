import React, { FunctionComponent, ReactElement, useState } from "react";
import { useDispatch } from "react-redux";
import Modal from "react-modal";

import { sendPutDepartmentRequest } from "src/reducers/groupsAndDepartments/departmentsSlice";

import "./styles.scss";

const closeIcon = require("../../../assets/groupsAndDepartments/close.svg").default;

export type ModalProps = {
  isOpen: boolean;
  onClose: Function;
  focusedID: number | undefined;
};

export const EditDepartmentModal: FunctionComponent<ModalProps> = ({ isOpen, onClose, focusedID }): ReactElement => {
  const dispatch = useDispatch();

  const [departmentName, setDepartmentName] = useState("");

  const onCloseHandler = () => {
    onClose();
    setDepartmentName("");
  };

  const onSaveHandler = () => {
    if (focusedID) {
      dispatch(sendPutDepartmentRequest(focusedID, departmentName));
      setDepartmentName("");
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} className="gad-modal" overlayClassName="gad-modal-overlay">
      <div className="gad-wrapper">
        <div className="gad-header">
          Edit Department Name
          <button onClick={onCloseHandler} type="button" className="gad-close-btn">
            <img src={closeIcon} alt="close" />
          </button>
        </div>
        <div className="gad-main">
          <label className="gad-input-form" htmlFor="departmentName">
            Department Name
            <input
              placeholder="Department Name"
              onChange={(e) => {
                setDepartmentName(e.target.value);
              }}
              value={departmentName}
              className="gad-input"
              id="departmentName"
              type="text"
            />
          </label>
        </div>
        <div className="gad-footer">
          <button onClick={onCloseHandler} className="gad-cancel-btn" type="button">
            Cancel
          </button>
          <button disabled={departmentName.length === 0} onClick={onSaveHandler} className="gad-create-btn" type="submit">
            Save
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default EditDepartmentModal;
