import React, { FunctionComponent, ReactElement } from "react";
import { SurveyResultQuestionAnswer } from "src/api/survey/surveyTypes";

import "./styles.scss";
import SurveyChartItem from "./SurveyChartItem";

export type SurveyChartProps = {
  sequence: number;
  answerData: Array<SurveyResultQuestionAnswer>;
  question: string;
};
export const SurveyChart: FunctionComponent<SurveyChartProps> = ({ sequence, answerData, question }): ReactElement => {
  let maxIdx = 0;
  const maxAnswer =
    answerData &&
    answerData.reduce((op, item, currentIndex) => {
      maxIdx = currentIndex;
      // eslint-disable-next-line no-return-assign
      return op.answerCount > item.answerCount ? op : item;
    }, answerData[0]);
  const getWidth = (count: number) => {
    if (count === 0) {
      return 0;
    }
    return (count / maxAnswer.answerCount) * 100;
  };
  const CHART_COLORS = ["#9AC8CA", "#BBDADB", "#CBADBC", "#F38FC2", "#DFB65C", "#FDCB5D", "#7AC161", "#6EE2AB", "#FF8934", "#FDA891"];
  const bgColor = maxAnswer.answerCount === 0 ? "#BEBFBA" : CHART_COLORS[maxIdx % 10];

  return (
    <div className="survey-chart">
      <div className="survey-chart__header">
        <h4>{`${sequence}. ${question}`}</h4>
        <span style={{ backgroundColor: bgColor }}>
          {maxAnswer.answerCount}
          <br />
          {maxAnswer.answer}
        </span>
      </div>
      {answerData.map((answer, index) => {
        return <SurveyChartItem answerData={answer} color={CHART_COLORS[index % 10]} width={getWidth(answer.answerCount)} key={index} />;
      })}
    </div>
  );
};

export default SurveyChart;
