import React, { FunctionComponent, ReactElement, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { RootState } from "src/reducers";
import { sendGetGroupsRequest, sendGetUsersNotInGroupRequest, setUsersInGroup } from "src/reducers/groupsAndDepartments/groupsSlice";
import { sendGetProSettingsRequest } from "src/reducers/auth/accountSlice";
import { GroupItem } from "src/api/groupsAndDepartments/groupsTypes";

import SeityAlert from "src/components/analytic/SeityAlert";
import CreateGroupModal from "./CreateGroupModal";
import DeleteGroupModal from "./DeleteGroupsModal";
import EditGroupModal from "./EditGroupModal";
import EditMembersModal from "./EditMembersModal";

import "./styles.scss";

const plusIcon = require("../../../assets/groupsAndDepartments/plus_icon.svg").default;
const editIcon = require("../../../assets/groupsAndDepartments/edit.svg").default;
const addMemberIcon = require("../../../assets/groupsAndDepartments/add_member.svg").default;
const deleteIcon = require("../../../assets/groupsAndDepartments/delete.svg").default;
const loaderDots = require("../../../assets/groupsAndDepartments/loader_dots.gif");

export const Groups: FunctionComponent = (): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [showUnauthAlert, setShowUnauthAlert] = useState(false);
  const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);
  const [showEditGroupModal, setShowEditGroupModal] = useState(false);
  const [showDeleteGroupModal, setShowDeleteGroupModal] = useState(false);
  const [showEditMembersModal, setShowEditMembersModal] = useState(false);
  const [focusedItem, setFocusedItem] = useState<GroupItem>();

  const onEditHandler = (item: GroupItem) => {
    setFocusedItem(item);
    setShowEditGroupModal(true);
  };

  const onEditMembersHandler = async (item: GroupItem) => {
    setFocusedItem(item);
    await dispatch(sendGetUsersNotInGroupRequest(item.groupID));
    await dispatch(setUsersInGroup(item.members));
    setShowEditMembersModal(true);
  };

  const onDeleteHandler = (item: GroupItem) => {
    setFocusedItem(item);
    setShowDeleteGroupModal(true);
  };

  const { isListLoading, isLoading, groupsList } = useSelector((state: RootState) => {
    return state.groups;
  });

  const { proSettings } = useSelector((state: RootState) => {
    return state.account;
  });

  useEffect(() => {
    dispatch(sendGetGroupsRequest());
  }, []);

  useEffect(() => {
    if (proSettings) {
      if (proSettings.groupManagement === false) {
        setShowUnauthAlert(true);
      }
    }
    if (!proSettings) {
      dispatch(sendGetProSettingsRequest());
    }
  }, [proSettings]);

  return (
    <div className="gv-container">
      {showUnauthAlert ? (
        <SeityAlert
          visible={showUnauthAlert}
          onToggle={() => {
            setShowUnauthAlert(false);
            history.push("/");
          }}
          title={"Oops!\nYou do not have access to this page!"}
          subTitle="You will be sent to the home page,
          please contact Seity Customer Support at support@seityhealth.com if you need further assistance."
        />
      ) : (
        <>
          <div className="gv-header">
            <div className="gv-header-left">
              <h1>Groups</h1>
              <h4>{groupsList.length} Groups</h4>
            </div>
            <div className="gv-header-right">
              <button
                onClick={() => {
                  setShowCreateGroupModal(true);
                }}
                className="gv-create-btn"
                type="button"
              >
                <img className="gv-plus" src={plusIcon} alt="plus" />
                Create Group
              </button>
            </div>
          </div>
          <div className="gv-main">
            {isListLoading ? (
              <img className="gv-main-loader" src={loaderDots} alt="loader" />
            ) : (
              groupsList.map((groupItem: GroupItem) => {
                return (
                  <div key={groupItem.groupID} className="gv-item-wrapper">
                    <div className="gv-item-left">
                      <div>{groupItem.groupName}</div>
                      <button
                        onClick={() => {
                          onEditHandler(groupItem);
                        }}
                        className="gv-edit-btn"
                        type="button"
                      >
                        <img src={editIcon} alt="edit group name" />
                      </button>
                    </div>
                    <div className="gv-item-center">
                      <div>{groupItem.members.length} Members</div>
                      <button
                        onClick={() => {
                          onEditMembersHandler(groupItem);
                        }}
                        className="gv-add-btn"
                        type="button"
                      >
                        <img src={addMemberIcon} alt="add member" />
                      </button>
                    </div>
                    <div className="gv-item-right">
                      <button
                        onClick={() => {
                          onDeleteHandler(groupItem);
                        }}
                        className="gv-delete-btn"
                        type="button"
                      >
                        <img src={deleteIcon} alt="delete group" />
                        Delete
                      </button>
                    </div>
                  </div>
                );
              })
            )}
          </div>
          <CreateGroupModal
            onClose={() => {
              setShowCreateGroupModal(false);
            }}
            isOpen={showCreateGroupModal}
          />
          <EditGroupModal
            focusedID={focusedItem?.groupID}
            onClose={() => {
              setShowEditGroupModal(false);
            }}
            isOpen={showEditGroupModal}
          />
          <DeleteGroupModal
            focusedID={focusedItem?.groupID}
            groupName={focusedItem?.groupName}
            isOpen={showDeleteGroupModal}
            onClose={() => {
              setShowDeleteGroupModal(false);
            }}
          />
          <EditMembersModal
            focusedItem={focusedItem}
            isLoading={isLoading}
            onClose={() => {
              setShowEditMembersModal(false);
            }}
            isOpen={showEditMembersModal}
          />
        </>
      )}
    </div>
  );
};

export default Groups;
