export const BASE_URL = process.env.REACT_APP_SEITYRIVER_API || "https://ase-seityproapi-dev-westus2.azurewebsites.net";
export const PLATFORM_ID = 10;

export const IMAGE_URL = "https://seityhealthstorage01.blob.core.windows.net/image/";
export const GRAPHICS_URL = "https://stshprodassets01.blob.core.windows.net/graphics/";
export const VIDEO_URL = "https://seityhealthstorage01.blob.core.windows.net/video/";

export const ICON_COREVALUES_URL = "https://cdne-shcdn-global-001.azureedge.net/graphics/CoreValueIcons.zip";
export const ICON_LIFESTYLE_URL = "https://cdne-shcdn-global-001.azureedge.net/graphics/LifeStylePracticesIcons.zip";
export const ICON_WELLBEING_URL = "https://cdne-shcdn-global-001.azureedge.net/graphics/WellBeingIcons.zip";
