import React, { FunctionComponent, ReactElement } from "react";
import { SurveyTextInputProps } from "./SurveyTextInput";
import "./styles.scss";

export type SurveyFormTextInputProps = SurveyTextInputProps & {
  label: string;
  name?: string;
};

export const SurveyFormTextInput: FunctionComponent<SurveyFormTextInputProps> = ({
  className,
  inputStyle,
  onChange,
  placeholder,
  type,
  value,
  label,
  name = "survey-form-text-input"
}): ReactElement => {
  return (
    <div className={`survey-text-input-container ${className || ""}`}>
      <label htmlFor={name}>{label}</label>
      <input value={value} placeholder={placeholder} type={type} name={name} onChange={onChange} style={inputStyle} />
    </div>
  );
};

export default SurveyFormTextInput;
