import React, { FunctionComponent, ReactElement } from "react";
import { SurveyResultQuestionAnswer } from "src/api/survey/surveyTypes";

import "./styles.scss";

export type SurveyChartItemProps = {
  answerData: SurveyResultQuestionAnswer;
  color: string;
  width: number;
};
export const SurveyChartItem: FunctionComponent<SurveyChartItemProps> = ({ answerData, color, width }): ReactElement => {
  return (
    <div className="survey-chart-item">
      <div className="survey-chart-item__line">
        <div style={{ width: `${width}%`, backgroundColor: color }} />
      </div>
      <span className="survey-chart-item__count">{answerData.answerCount}</span>
      <span className="survey-chart-item__answer">{answerData.answer}</span>
    </div>
  );
};

export default SurveyChartItem;
