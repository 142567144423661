/* eslint-disable max-len */
import React, { FunctionComponent, ReactElement, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "src/reducers";

import { sendGetAtsWellBeingCheckInCategoriesRequest } from "src/reducers/ats/atsSlice";

import SeityLoader from "src/components/SeityLoader";
import "./styles.scss";
import SurveyButton from "src/components/survey/SurveyButton";
import { downloadImageFromUrl } from "src/_core/utils/utils";
import { ICON_WELLBEING_URL } from "src/api/apiConstants";
import AtsWellBeingCheckInCategoriesItem from "../AtsOutcomeCategoriesItem";

export type AtsWellBeingCheckInCategoriesProps = {};

export const AtsWellBeingCheckInCategories: FunctionComponent<AtsWellBeingCheckInCategoriesProps> = (): ReactElement => {
  const { isLoading, wellBeingCheckInCategoryList } = useSelector((state: RootState) => {
    return state.ats;
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(sendGetAtsWellBeingCheckInCategoriesRequest());
  }, []);

  return (
    <div className="ats-wellbeing-categories">
      {isLoading && <SeityLoader showBackgroundMask />}

      <span className="ats-wellbeing-categories-text">
        Health is best described as an experience of wellbeing that is made up of five measurable factors.
      </span>
      <div className="ats-corevalues__searchbar">
        <SurveyButton
          size="mid-ats"
          type="ats-round"
          onClick={() => {
            downloadImageFromUrl(ICON_WELLBEING_URL, "WellBeingIcons.zip");
          }}
          label="Download All (Zip)"
        />
      </div>

      {wellBeingCheckInCategoryList.map((wellbeingCheckIn) => {
        return (
          <AtsWellBeingCheckInCategoriesItem
            id={wellbeingCheckIn.category}
            outcomeCategory={wellbeingCheckIn}
            key={wellbeingCheckIn.category}
          />
        );
      })}
    </div>
  );
};

export default AtsWellBeingCheckInCategories;
