import React, { FunctionComponent } from "react";

import SurveyButton from "../SurveyButton";
import "./styles.scss";

export type SurveyConfirmModalProps = {
  isOpen: boolean;
  title: string;
  message: string;
  onClickYes?: () => void;
  onClickNo?: () => void;
  className?: string;
};

export const SurveyConfirmModal: FunctionComponent<SurveyConfirmModalProps> = ({
  isOpen = false,
  title,
  message,
  onClickYes,
  onClickNo,
  className
}) => {
  return isOpen ? (
    <div className={`survey-confirm-container ${className || ""}`}>
      <div className="survey-confirm-overlay" />

      <div className="survey-confirm">
        <h3>{title}</h3>
        <p>{message}</p>
        <div className="survey-confirm__button-wrap">
          {onClickNo && <SurveyButton customClass="button-no" label="No" onClick={onClickNo} />}
          {onClickYes && <SurveyButton label="Yes" onClick={onClickYes} />}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default SurveyConfirmModal;
