import React, { useState } from "react";

export type SeityAppContextType = {
  showHeader: boolean;
  setShowHeader: (value: boolean) => void;
  showBackButton: boolean;
  setShowBackButton: (value: boolean) => void;
  backButtonPath: string;
  setBackButtonPath: (path: string) => void;
  showNavMenuItems: boolean;
  setShowNavMenuItems: (value: boolean) => void;
  navigationMenuButtonText: string;
  setNavigationMenuButtonText: (value: string) => void;
  onNavigationMenuButtonPress: any;
  setOnNavigationMenuButtonPress: (value: Function) => void;
  showTodo: boolean;
  setShowTodo: (value: boolean) => void;
  language: string;
  setLanguage: (value: string) => void;
};

export const SeityAppContext = React.createContext<SeityAppContextType>(null as any);

const SeityAppContextProvider = ({ children }) => {
  function useFunctionAsState(fn: any) {
    const [val, setVal] = useState(() => {
      return fn;
    });
    function setFunc(fnn: any) {
      setVal(() => {
        return fnn;
      });
    }
    return [val, setFunc];
  }

  const [showHeader, setShowHeader] = useState(true);
  const [showBackButton, setShowBackButton] = useState(true);
  const [backButtonPath, setBackButtonPath] = useState("/");
  const [showNavMenuItems, setShowNavMenuItems] = useState(true);
  const [navigationMenuButtonText, setNavigationMenuButtonText] = useState("");
  const [onNavigationMenuButtonPress, setOnNavigationMenuButtonPress] = useFunctionAsState(() => {});
  const [showTodo, setShowTodo] = useState(true);
  const [language, setLanguage] = useState("");

  return (
    <SeityAppContext.Provider
      value={{
        showHeader,
        setShowHeader,
        showBackButton,
        setShowBackButton,
        backButtonPath,
        setBackButtonPath,
        navigationMenuButtonText,
        setNavigationMenuButtonText,
        showNavMenuItems,
        setShowNavMenuItems,
        onNavigationMenuButtonPress,
        setOnNavigationMenuButtonPress,
        showTodo,
        setShowTodo,
        language,
        setLanguage
      }}
    >
      {children}
    </SeityAppContext.Provider>
  );
};

export default SeityAppContextProvider;
