export const COLUMNS_DATA = [
  {
    id: 2,
    title: "Last Name",
    colName: "lastName",
    selected: true
  },
  {
    id: 3,
    title: "First Name",
    colName: "firstName",
    selected: true
  },
  {
    id: 4,
    title: "Email",
    colName: "emailAddress",
    selected: true
  },
  {
    id: 1,
    title: "StudentID",
    colName: "studentID",
    selected: true
  },
  {
    id: 5,
    title: "User Group",
    colName: "companyName",
    selected: true
  }
];

export const COLUMNS_DATA_WITHOUT_STUDENT_ID = [
  {
    id: 2,
    title: "Last Name",
    colName: "lastName",
    selected: true
  },
  {
    id: 3,
    title: "First Name",
    colName: "firstName",
    selected: true
  },
  {
    id: 4,
    title: "Email",
    colName: "emailAddress",
    selected: true
  },
  {
    id: 5,
    title: "User Group",
    colName: "companyName",
    selected: true
  }
];
