import { FunctionComponent, ReactElement, useState } from "react";
import "./styles.scss";
import SeityModal from "src/components/SeityModal";
import { WbnViewFilter } from "src/api/wbn/wbnTypes";
import WbnButton from "../WbnButton";
import CoreStrings from "src/_core/strings/strings";

export type WbnSavedViewsModalProps = {
  position?: { top: number; left: number };
  data: WbnViewFilter[];
  isOpen: boolean;
  title?: string;
  savedViewIdx: number;
  setSavedViewIdx: (selIdx: number) => void;
  onApply: () => void;
  onDelete: (idx: number) => void;
  wrapperClassName?: string;
};

// The following style is declared here because it must override styles
// that are elsewhere flagged as !important.
const resetButtonStyle = {
  color: "black",
  fontWeight: "normal"
};

export const WbnSavedViewsModal: FunctionComponent<WbnSavedViewsModalProps> = ({
  position,
  isOpen = false,
  title = "Show",
  data,
  savedViewIdx,
  setSavedViewIdx,
  onApply,
  onDelete
}): ReactElement => {
  const [selIdx, setSelIdx] = useState(savedViewIdx);

  const handleOptionsSelection = (idx: number) => {
    setSelIdx(idx);
  };

  const parseOptions = (arr) => {
    return arr.map((elem, index) => {
      return (
        <div key={index} className={`${index === selIdx ? "sel" : ""}`}>
          <button
            onClick={() => {
              handleOptionsSelection(index);
            }}
          >
            <img
              src={
                index === selIdx ? require("../../../assets/wbn/img-option-sel.png") : require("../../../assets/wbn/img-option-unsel.png")
              }
            />
            <div>
              <p>{elem.viewName}</p>
            </div>
          </button>
          <button
            onClick={() => {
              onDelete(index);
            }}
          >
            <img src={require("../../../assets/wbn/img-savedView-del.png")} />
          </button>
        </div>
      );
    });
  };

  const style = {
    position: "absolute",
    top: position?.top,
    left: position?.left,
    height: data.length > 0 ? window.innerHeight - (position?.top ?? 0) - 30 : 200
  };

  return (
    <SeityModal
      overlayClassName={"wbn-disabled-overlay"}
      isOpen={isOpen}
      wrapperClassName="wbn-savedViews-modal"
      style={position && style}
      onClose={() => {
        onApply();
      }}
    >
      <div className="wbn-modal-children-holder">
        {title?.length > 0 && <h3>{title}</h3>}
        <div className="wbn-modal-content-wrap">{parseOptions(data)}</div>
        <div>
          <p></p>
        </div>
        <div className="wbn-modal-btn-wrap">
          <WbnButton
            type="plain-text"
            label={CoreStrings.cancel}
            customClass="wbn-modal-button"
            style={{
              ...resetButtonStyle
            }}
            onClick={() => {
              onApply();
            }}
          />
          <WbnButton
            disabled={selIdx === -1}
            label={CoreStrings.switchView}
            type="submit"
            customClass="wbn-modal-button"
            onClick={() => {
              setSavedViewIdx(selIdx);
              onApply();
            }}
          />
        </div>
      </div>
    </SeityModal>
  );
};

export default WbnSavedViewsModal;
