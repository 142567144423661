import React, { FunctionComponent, ReactElement } from "react";

import "./styles.scss";

export type SurveyRadioButtonItemProps = {
  label: string;
  className?: string;
  checked?: boolean;
  onChange: (value: string | number) => void;
  value: string | number;
};

export const SurveyRadioButtonItem: FunctionComponent<SurveyRadioButtonItemProps> = ({
  checked,
  label,
  className,
  onChange,
  value
}): ReactElement => {
  return (
    <div
      className={`survey-radio-button-item-wrapper ${className || ""}`}
      onClick={() => {
        return onChange(value);
      }}
    >
      <div className={`survey-radio-button ${checked ? "checked" : ""}`}>
        <div className="survey-radio-button-fill" />
      </div>

      <span>{label}</span>
    </div>
  );
};

export default SurveyRadioButtonItem;
