import { FunctionComponent, ReactElement } from "react";
import DatePicker from "react-datepicker";

import CoreStrings from "src/_core/strings/strings";
import calendar from "src/assets/images/graphics/calendar.png";
import "./styles.scss";

type WbnDatePickerProps = {
  fromDate: Date | null;
  toDate: Date | null;
  dateFormat: string;
  onChangeFromDate: (date: Date) => void;
  onChangeToDate: (date: Date) => void;
};

export const WbnDatePicker: FunctionComponent<WbnDatePickerProps> = (props): ReactElement => {
  const { fromDate, toDate, dateFormat, onChangeFromDate, onChangeToDate } = props;

  return (
    <div className="wbn-datepicker">
      <div className="wbn-datepicker__from-date">
        <h2 className="wbn-datepicker__title">{CoreStrings.from}</h2>
        <div className="wbn-datepicker__date-input">
          <img src={calendar} alt="Calendar Icon" />
          <DatePicker
            selected={fromDate}
            onChange={(e) => {
              onChangeFromDate(e);
            }}
            dateFormat={dateFormat}
            customInput={<input className="wbn-datepicker__input" />}
          />
        </div>
      </div>
      <div className="wbn-datepicker__to-date">
        <h2 className="wbn-datepicker__title">{CoreStrings.to}</h2>
        <div className="wbn-datepicker__date-input">
          <img src={calendar} alt="Calendar Icon" />
          <DatePicker
            selected={toDate}
            dateFormat={dateFormat}
            onChange={(e) => {
              onChangeToDate(e);
            }}
            customInput={<input className="wbn-datepicker__input" />}
          />
        </div>
      </div>
    </div>
  );
};

export default WbnDatePicker;
