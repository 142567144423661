import CoreStrings from "src/_core/strings/strings";
import { INPUT_MAX_LEN } from ".";
import WbnTextInput from "../WbnTextInput";

export const getModalContent = (type, viewName, setViewName) => {
  if (type === "SaveView") {
    return {
      buttons: [
        {
          label: CoreStrings.cancel,
          type: "plain-text",
          disable: false
        },
        {
          label: "Save",
          type: "submit",
          disable: viewName.length > INPUT_MAX_LEN || viewName.length < 1
        }
      ],
      content: () => {
        return (
          <>
            <h3>{CoreStrings.viewName}</h3>
            <WbnTextInput
              value={viewName}
              maxlen={INPUT_MAX_LEN}
              placeholder={CoreStrings.viewName}
              onChange={(val) => {
                setViewName(val);
              }}
            />
          </>
        );
      }
    };
  } else if (type === "DeleteView") {
    return {
      buttons: [
        {
          label: CoreStrings.cancel,
          type: "plain-text"
        },
        {
          label: CoreStrings.delete,
          type: "submit"
        }
      ],
      content: () => {
        return (
          <>
            <p>{`${CoreStrings.deleteViewText1} ${viewName}?`}</p>
            <p>{CoreStrings.deleteViewText2}</p>
          </>
        );
      }
    };
  } else if (type === "DupView") {
    return {
      buttons: [
        {
          label: CoreStrings.cancel,
          type: "plain-text"
        },
        {
          label: CoreStrings.overwrite,
          type: "submit"
        }
      ],
      content: () => {
        return (
          <>
            <p>{CoreStrings.dupViewText.replace("[ViewName]", viewName)}</p>
          </>
        );
      }
    };
  } else if (type === "LeaveView") {
    return {
      buttons: [
        {
          label: CoreStrings.discardChanges,
          type: "plain-text"
        },
        {
          label: CoreStrings.saveAsNew,
          type: "cancel"
        },
        {
          label: CoreStrings.save,
          type: "submit"
        }
      ],
      content: () => {
        return (
          <>
            <p>{CoreStrings.leaveViewText1.replace("[ViewName]", viewName)}</p>
            <p>{CoreStrings.leaveViewText2}</p>
          </>
        );
      }
    };
  }
};
