/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AnalyticModal from "src/components/analytic/AnalyticModal";
import { RootState } from "src/reducers";
import { apiError, sendForgotPasswordRequest } from "src/reducers/auth/authSlice";
import { SeityTextInput } from "src/components/analytic/SeityTextInput/SeityTextInput";
import { SeityButton } from "src/components/analytic/SeityButton";
import { SeityPrimaryCancelButtons } from "src/components/analytic/SeityNavigationButtons";

import strings from "../../../_core/strings/strings";

import "./styles.scss";

export const ForgotPassword = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { authError, resetPasswordSuccess } = useSelector((state: RootState) => {
    return state.auth;
  });

  const [emailOne, setEmailOne] = useState("");
  const [isRequestSent, setRequestSent] = useState(false);

  const handleForgotPasswordCancelClick = () => {
    setRequestSent(false);
    history.push("/login");
  };

  const handleForgotPasswordClicked = () => {
    sendPressed();
  };

  const sendPressed = () => {
    // Validate email
    if (emailOne === "") {
      dispatch(apiError({ authError: strings.resetPassword }));
      return;
    }
    setRequestSent(true);
    dispatch(sendForgotPasswordRequest(emailOne));
  };

  const successModal = (
    <div className="fp-succuess-modal">
      <p>{strings.success}</p>
      <SeityButton
        label="Ok"
        type="next"
        style={{
          height: 48,
          width: 108,
          padding: 0,
          marginRight: 10
        }}
        onClick={handleForgotPasswordCancelClick}
      />
    </div>
  );

  return (
    <div className="fp-container">
      <h1>{strings.forgotPassword}</h1>
      <p>{strings.forgotPasswordDescription}</p>
      <h5>{strings.allFields}</h5>
      <div className="fp-email">
        <h3>{strings.email}</h3>
        <SeityTextInput
          type="email"
          onChange={(e) => {
            return setEmailOne(e.target.value);
          }}
          value={emailOne}
          isInvalid={false}
        />
      </div>
      <SeityPrimaryCancelButtons
        primaryTitle={strings.sendButtonLabel}
        primaryAction={handleForgotPasswordClicked}
        cancelTitle={strings.cancelButtonLabel}
        primaryDisabled={!emailOne}
        errorMessage={authError}
        showCancel
        cancelAction={handleForgotPasswordCancelClick}
      />
      <AnalyticModal isOpen={isRequestSent && resetPasswordSuccess} children={successModal} />
    </div>
  );
};

export default ForgotPassword;
