/* eslint-disable max-len */
import React, { FunctionComponent, ReactElement, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "src/reducers";
import { sendGetAtsCoreValuesRequest } from "src/reducers/ats/atsSlice";
import { AtsCoreValue } from "src/api/ats/atsTypes";

import SeityLoader from "src/components/SeityLoader";
import SurveyButton from "src/components/survey/SurveyButton";
import { downloadImageFromUrl } from "src/_core/utils/utils";
import { ICON_COREVALUES_URL } from "src/api/apiConstants";
import AtsCoreValueItem from "../AtsCoreValueItem";
import "./styles.scss";
import AtsAutoComplete, { AtsAutoCompleteType } from "../AtsAutoComplete";

export type AtsCoreValuesProps = {};
let filterTimeout;

export const AtsCoreValues: FunctionComponent<AtsCoreValuesProps> = (): ReactElement => {
  const { isLoading, coreValuesList } = useSelector((state: RootState) => {
    return state.ats;
  });
  const [filteredCoreValues, setFiltered] = useState<AtsCoreValue[]>([]);
  const [searchValue, setSearchValue] = useState("");

  const autoComplateItems = useMemo<AtsAutoCompleteType[]>(() => {
    return coreValuesList.map((core) => {
      return { label: core.coreValue };
    });
  }, [coreValuesList]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(sendGetAtsCoreValuesRequest());
  }, []);

  useEffect(() => {
    setFiltered(coreValuesList);
  }, [coreValuesList]);

  const handleSearch = (searchKey: string) => {
    clearTimeout(filterTimeout);

    if (searchKey.length > 0) {
      const filtered = coreValuesList.filter((c) => {
        return c.coreValue.toLowerCase().includes(searchKey.toLowerCase());
      });
      setFiltered(filtered);
    } else {
      setFiltered(coreValuesList);
    }
  };
  const debounceHandleSearch = (searchKey: string) => {
    clearTimeout(filterTimeout);
    filterTimeout = setTimeout(() => {
      handleSearch(searchKey);
    }, 2000);
  };

  return (
    <div className="ats-corevalues">
      {isLoading && <SeityLoader showBackgroundMask />}
      <span className="ats-corevalues-text">
        At the deepest layer of every person is their incredible and unique core. This is where your inspiration, motivation, and truest
        identity come from. We map everyone’s Core Values to consistently reinforce a positive sense of self and identify what most
        motivates and moves people.
      </span>
      <div className="ats-corevalues__searchbar">
        <AtsAutoComplete
          placeholder="Search Core Value..."
          items={autoComplateItems}
          value={searchValue}
          shouldItemRender={(item, value) => {
            return item.label.toLowerCase().indexOf(value.toLowerCase()) > -1;
          }}
          onChange={(val) => {
            setSearchValue(val);
            debounceHandleSearch(val);
          }}
          className="searchfield"
          onSelect={(val) => {
            setSearchValue(val);
            handleSearch(val);
          }}
        />
        <SurveyButton
          size="mid-ats"
          type="ats-round"
          onClick={() => {
            downloadImageFromUrl(ICON_COREVALUES_URL, "CoreValueIcons.zip");
          }}
          label="Download All (Zip)"
        />
      </div>
      {filteredCoreValues.map((corevalue) => {
        return <AtsCoreValueItem corevalueData={corevalue} key={corevalue.coreValueID} />;
      })}
    </div>
  );
};

export default AtsCoreValues;
