import React, { ReactElement, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { RootState } from "src/reducers";

import SurveySideMenu from "src/components/survey/SurveySideMenu";
import SurveyItem from "src/components/survey/SurveyItem";

import { useDispatch, useSelector } from "react-redux";
import {
  selectSurveyWizardListFiltered,
  sendCopySurveyWizardGroupRequest,
  sendDeleteSurveyWizardRequest,
  sendGetSurveyRequest,
  sendToggleLikeSurveyWizardRequest,
  setFilterByStatus
} from "src/reducers/survey/surveySlice";
import { useWindowSize } from "src/hooks/Layout";

import { sendGetAccountInfoRequest, sendGetProSettingsRequest } from "src/reducers/auth/accountSlice";
import { SurveyState } from "src/api/survey/surveyTypes";
import SeityLoader from "src/components/SeityLoader";
import SurveyConfirmModal from "src/components/survey/SurveyConfirmModal";
import SurveySortBySelect from "./SurveySortBySelect";
import SurveyItemMenu from "./SurveyItemMenu";
import SurveyTabMenu from "./SurveyTabMenu";
import SurveySavedModal from "./SurveySavedModal";

import "./styles.scss";

export const SurveyDash = (): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [selMenu, setMenuItem] = useState(0);
  const [selTabMenu, setTabMenu] = useState(0);
  const [showShadow, setShadow] = useState(false);
  const [currentWizardID, setCurrentWizardID] = useState<number | null>(null);
  const [showSavedModal, setShowSavedModal] = useState(false);
  const surveyWizardList = useSelector(selectSurveyWizardListFiltered);
  const [showContextMenu, setShowContextMenu] = useState({});
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const size = useWindowSize();
  // Calculate menu item height for the window browser low height
  let menuItemHeight = 100;
  if (size.height < 830) {
    menuItemHeight = (size.height - 262 - 75) / 5;
  }
  const contentListHeight = size.height - menuItemHeight - 123 - 75;

  const { accountInfo, proSettings } = useSelector((state: RootState) => {
    return state.account;
  });

  const { isLoading } = useSelector((state: RootState) => {
    return state.survey;
  });

  const handleMenuPress = (index: number, status: SurveyState | null) => {
    setMenuItem(index);
    dispatch(setFilterByStatus(status));
  };
  const handlePressCreateSurvey = () => {
    setCurrentWizardID(0);
    history.push("/surveyCreate", { surveyId: null, index: 0 });
  };

  const onResultsClickedHandler = (surveyWizardID: number | undefined) => {
    history.push("/surveyResults", { surveyWizardID });
  };

  const handleScroll = (event) => {
    const { scrollTop } = event.target;
    if (scrollTop > 5 && !showShadow) {
      setShadow(true);
    } else if (scrollTop < 5 && showShadow) {
      setShadow(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    dispatch(sendGetSurveyRequest());
    if (accountInfo.firstName === "") {
      dispatch(sendGetAccountInfoRequest());
    }
  }, []);

  useEffect(() => {
    if (!proSettings) {
      dispatch(sendGetProSettingsRequest());
    }
  }, [proSettings]);

  return (
    <div className="survey-container">
      {isLoading && <SeityLoader showBackgroundMask />}
      <div className="sidemenu-wrap">
        <SurveySideMenu
          title="Surveys"
          selIdx={selMenu}
          itemHeight={menuItemHeight}
          handleMenuPress={handleMenuPress}
          handlePressCreateSurvey={handlePressCreateSurvey}
        />
      </div>
      <div className="survey-main">
        <div className="content-wrap">
          <div className="content-wrap__header">
            <div className="survey-tabmenu-wrap">
              <SurveyTabMenu
                selectedIdx={selTabMenu}
                onClick={(selTabIdx) => {
                  setTabMenu(selTabIdx);
                }}
              />
            </div>
          </div>
          <div className={`content-wrap__menu ${showShadow ? "shadow" : ""}`} style={{ height: menuItemHeight }}>
            <span>Results: {surveyWizardList.length} Surveys</span>
            <div className="sortby-wrap">
              <span>Sort By</span>
              <SurveySortBySelect onClick={() => {}} selectedIdx={0} />
            </div>
          </div>
          <div className="content-wrap__list" style={{ height: contentListHeight }} onScroll={handleScroll}>
            <div className="list-content">
              {surveyWizardList.map((survey, index) => {
                return (
                  <div key={`${survey.surveyWizardID}-${index}`} className="survey-item-conteiner-wrap">
                    <SurveyItem
                      surveyWizardID={survey.surveyWizardID || 0}
                      title={survey.name}
                      dateCreated={survey.createDate || new Date().toString()}
                      isLiked={survey.isLiked}
                      questionCount={survey.numberOfQuestions}
                      surveyState={survey.status}
                      listItem={survey.listItem}
                      onScheduleSurvey={(surveyWizardId) => {
                        setCurrentWizardID(surveyWizardId);
                      }}
                      onShowMenu={() => {
                        setShowContextMenu({ [index]: true });
                      }}
                    />
                    {showContextMenu[index] && (
                      <SurveyItemMenu
                        onMenuClosed={() => {
                          return setShowContextMenu({});
                        }}
                        onScheduleClicked={() => {
                          history.push("/surveyCreate", {
                            surveyId: survey.surveyWizardID,
                            scheduleId: survey.surveyWizardScheduleID,
                            index,
                            step: 2
                          });
                        }}
                        isLiked={survey.isLiked}
                        status={survey.status}
                        onDuplicateClicked={() => {
                          return dispatch(sendCopySurveyWizardGroupRequest(survey.surveyWizardID || 0));
                        }}
                        onLikeClicked={() => {
                          dispatch(sendToggleLikeSurveyWizardRequest(survey.surveyWizardID || 0));
                        }}
                        onDeleteClicked={() => {
                          setCurrentWizardID(survey.surveyWizardID || 0);
                          setShowConfirmModal(true);
                        }}
                        onResultsClicked={() => {
                          onResultsClickedHandler(survey.surveyWizardID);
                        }}
                        onEditClicked={() => {
                          history.push("/surveyCreate", {
                            surveyId: survey.surveyWizardID,
                            scheduleId: survey.surveyWizardScheduleID,
                            index
                          });
                        }}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <SurveySavedModal
        isOpen={showSavedModal}
        onClose={() => {
          setShowSavedModal(false);
        }}
      />
      <SurveyConfirmModal
        isOpen={showConfirmModal}
        title="Confirmation"
        message="Are you sure you want to delete it?"
        onClickNo={() => {
          setShowConfirmModal(false);
        }}
        onClickYes={() => {
          setShowConfirmModal(false);
          return dispatch(sendDeleteSurveyWizardRequest(currentWizardID!));
        }}
      />
    </div>
  );
};

export default SurveyDash;
