import React, { ChangeEventHandler, FunctionComponent, ReactElement } from "react";

import SurveyButton from "src/components/survey/SurveyButton";
import SurveyTitleInput from "src/components/survey/SurveyTitleInput";

import "./styles.scss";

export type SurveySetupHeaderProps = {
  title?: string;
  onTitleChange?: ChangeEventHandler<HTMLInputElement> | undefined;
  handleShowPreview?: () => void;
  onSaveClicked?: () => void;
  showSaved: boolean;
  showPreview: boolean;
  isTitleEmpty: boolean;
};

export const SurveySetupHeader: FunctionComponent<SurveySetupHeaderProps> = ({
  title,
  onTitleChange,
  handleShowPreview,
  showSaved,
  showPreview,
  onSaveClicked,
  isTitleEmpty
}): ReactElement => {
  return (
    <div className="question-header">
      <SurveyTitleInput className={`${isTitleEmpty ? "question-item__error" : ""}`} title={title} onChange={onTitleChange} />
      <div className="question-header__right" onClick={onSaveClicked}>
        {showSaved && (
          <>
            <img src={require("src/assets/survey/icon-saved.png")} alt="Survey Question Saved Icon" />
            <span>Saved...</span>
          </>
        )}
        {showPreview && <SurveyButton label="Preview" type="outline" onClick={handleShowPreview} />}
      </div>
    </div>
  );
};

export default SurveySetupHeader;
