export function createAndDownloadPdfFile(data: string, fileName: string) {
  const binaryString = window.atob(data);
  const bytes = new Uint8Array(binaryString.length);
  const arrayBuffer = bytes.map((byte, i) => binaryString.charCodeAt(i));
  const file = new Blob([arrayBuffer], { type: "application/pdf" });
  const fileURL = URL.createObjectURL(file);
  const link = document.createElement("a");
  // Browsers that support HTML5 download attribute
  if (link.download !== undefined) {
    link.href = fileURL;
    link.download = fileName;
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
