import {createTransform} from "redux-persist";
import {AuthState, setInitialState} from "../auth/authSlice";

export const AuthTransform = createTransform(
    (inboundState: AuthState, key): Partial<AuthState> => {
        return {changePasswordPermissionsEnabled: inboundState.changePasswordPermissionsEnabled};
    },

    (outboundState: any, key): AuthState => {
        return {...setInitialState, ...outboundState};
    },
    {whitelist: ['auth']}
);
