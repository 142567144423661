import React, { FunctionComponent, ReactElement } from "react";
import { SurveyState } from "src/api/survey/surveyTypes";
import SurveyAction from "../SurveyAction";

import "./styles.scss";

export type SubSurveyItemProps = {
  title: string;
  startDate: string;
  endDate: string;
  surveyState: SurveyState;
};

export const SubSurvey: FunctionComponent<SubSurveyItemProps> = ({ endDate, startDate, surveyState, title }): ReactElement => {
  return (
    <div>
      <div className="sub-survey-container-item">
        <div className="survey-item-section line-space">
          <span className="survey-title">{title}</span>
        </div>

        <div className="survey-item-section line-space">
          <div className="survey-question-container">
            <span className="survey-question">Start: {startDate} </span>
            <span>End: {endDate}</span>
          </div>
        </div>

        <div className="survey-item-section line-space">
          <span className={`survey-status-${surveyState}`}>{surveyState} </span>
        </div>

        <div className="survey-item-section actions">
          {surveyState !== "Scheduled" && <SurveyAction icon={require("src/assets/images/graphics/results_btn.png")} onClick={() => {}} />}
        </div>
      </div>
    </div>
  );
};

export default SubSurvey;
