/* eslint-disable max-len */
import React, { FunctionComponent, ReactElement, useState, useRef, useLayoutEffect, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AtsOutcomeSubcategory } from "src/api/ats/atsTypes";
import { RootState } from "src/reducers";
import { setOutcomeAccordionSelected } from "src/reducers/ats/atsSlice";
import AtsLifeStylePracticesItem from "../AtsOutcomeCategoriesElement";

import "./styles.scss";

export type AtsLifeStylePracticeItemAccordionProps = {
  outcomeCategoryID: number;
  outcomeCategory: string;
  outcomeSubcategories?: AtsOutcomeSubcategory[];
};

export const AtsLifeStylePracticeItemAccordion: FunctionComponent<AtsLifeStylePracticeItemAccordionProps> = ({
  outcomeCategory,
  outcomeSubcategories
}): ReactElement => {
  const [height, setHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);
  const { outcomeAccordionSelected, searchText } = useSelector((state: RootState) => {
    return state.ats;
  });
  const [subcategoriesFiltered, setSubcategoriesFiltered] = useState<AtsOutcomeSubcategory[]>([]);
  const dispatch = useDispatch();

  function isExpanded() {
    return outcomeAccordionSelected === outcomeCategory;
  }

  function handleExpand() {
    const selectCategory = outcomeCategory === outcomeAccordionSelected ? "" : outcomeCategory;
    dispatch(setOutcomeAccordionSelected(selectCategory));
  }

  useLayoutEffect(() => {
    if (contentRef.current && contentRef.current.clientHeight) {
      const hei = contentRef.current.clientHeight;
      setHeight(hei + 85);
    }
  });

  useEffect(() => {
    if (!outcomeSubcategories) return;

    if (searchText.length > 0) {
      const filtered = outcomeSubcategories.filter((sub) => {
        return sub.subcategory.toLowerCase().includes(searchText.toLowerCase());
      });

      if (filtered.length === 0) {
        setSubcategoriesFiltered(outcomeSubcategories);
        return;
      }
      setSubcategoriesFiltered(filtered);
    } else {
      setSubcategoriesFiltered(outcomeSubcategories);
    }
  }, [searchText]);

  return (
    <div className={`ats-outcomecategoriesaccordion ${isExpanded() ? "" : "shrink"}`} style={isExpanded() ? { maxHeight: height } : {}}>
      <div
        className="ats-outcomecategoriesaccordion__header"
        onClick={() => {
          handleExpand();
        }}
      >
        <span>Lifestyle Practices</span>
        <img
          className="ats-outcomecategoriesaccordion__expandicon"
          src={isExpanded() ? require("src/assets/ats/icon-arrow-up.png") : require("src/assets/ats/icon-arrow-down.png")}
          alt="expand icon"
        />
      </div>

      <div className="ats-outcomecategoriesaccordion__content-wrap" ref={contentRef}>
        {subcategoriesFiltered?.map((outComeSubcategory) => {
          return <AtsLifeStylePracticesItem outComeSubcategory={outComeSubcategory} />;
        })}
      </div>
    </div>
  );
};

export default AtsLifeStylePracticeItemAccordion;
