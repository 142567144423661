import React, { FunctionComponent, ReactElement } from "react";
import "./styles.scss";
import SurveyTimePicker, { SurveyTimePickerProps } from "./SurveyDatePicker";

export type SurveyFormTimePickerProps = SurveyTimePickerProps & {
  label: string;
  name?: string;
};

export const SurveyFormTimePicker: FunctionComponent<SurveyFormTimePickerProps> = ({
  className,
  label,
  name = "survey-form-select-box",
  ...props
}): ReactElement => {
  return (
    <div className={`survey-timepicker-form-container ${className || ""}`}>
      <label htmlFor={name}>{label}</label>
      <SurveyTimePicker {...props} className="survey-child-timepickerform" />
    </div>
  );
};

export default SurveyFormTimePicker;
