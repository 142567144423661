/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/comma-dangle */
import { FunctionComponent, ReactElement, useEffect, useMemo, useRef, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import infoIcon from "src/assets/images/web-icons/info.png";
import "./styles.scss";
import { Icon } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import WbnTooltip from "../WbnTooltip";
import { getTooltipFromID } from "../WbnTooltip/helpers";
import { WbnRowData } from "src/api/wbn/wbnTypes";
import { getNumberWithOrdinal } from "src/app/utils";
import CoreStrings from "src/_core/strings/strings";
import { GRAPHICS_URL } from "src/api/apiConstants";
import { useWindowSize } from "src/hooks/Layout";

type WbnGridProps = {
  data: Array<WbnRowData>;
  columns: Array<any>;
  hangon: boolean;
};

const sortCondition = {
  isSorted: true,
  sortColumn: "firstName",
  sortOrder: "ascending"
};

export const WbnGrid: FunctionComponent<WbnGridProps> = (props): ReactElement => {
  const { data, columns, hangon } = props;
  const [tooltipData, setTooltipData] = useState({});
  const gridRef = useRef<HTMLDivElement>(null);
  const winSize = useWindowSize();

  const handleTooltipUpdate = (id) => {
    setTooltipData(getTooltipFromID(id));
  };

  const headerCell = ({ header }) => {
    return (
      <div className="grid-header">
        <p>{header?.column?.columnDef?.header ?? ""}</p>
        {/* Leave it for now just in case if we need to customize sort button
        <div className="sort-button">
          {sortCondition.sortOrder === "descending" ? (
            // <Icon
            //   className={sortCondition.sortColumn === header?.column?.columnDef?.id ? "black" : ""}
            //   component={ArrowDropDownIcon}
            //   fontSize="medium"
            //   onClick={() => {}}
            // />
          ) : (
            // <Icon
            //   className={sortCondition.sortColumn === header?.column?.columnDef?.id ? "black" : ""}
            //   component={ArrowDropUpIcon}
            //   fontSize="medium"
            //   onClick={() => {}}
            // />
          )}
        </div> */}
        {header?.column?.columnDef?.hasInfoIcon && (
          <img
            src={infoIcon}
            data-tooltip-id="wbn-grid-tooltip"
            onMouseEnter={() => {
              handleTooltipUpdate(header?.column?.columnDef?.tooltipData);
            }}
          />
        )}
      </div>
    );
  };

  const gridCell = ({ cell }: { cell: any }) => {
    if (cell?.column?.id === "attentionCode") {
      const cellVal = cell.getValue();
      const score = cellVal.score;

      return (
        <div className="grid-cell">
          <img
            src={GRAPHICS_URL + cellVal.icon}
            style={{ width: 32, height: 32, marginRight: 16 }}
            data-tooltip-id="wbn-grid-tooltip"
            onMouseEnter={() => {
              setTooltipData({
                type: "basic",
                message: cellVal.description
              });
            }}
          />
          <p>{`${score !== null && score >= 0 ? score : "--"}%`}</p>
        </div>
      );
    }

    if (cell?.column?.id === "outreachReason") {
      const cellVal = cell.getValue();
      if (cellVal.icon) {
        return (
          <div className="grid-cell">
            <img
              src={GRAPHICS_URL + cellVal.icon}
              style={{ width: 15, height: 17, position: "relative" }}
              onMouseEnter={() => {
                setTooltipData({
                  type: "basic",
                  message: cellVal.text ?? ""
                });
              }}
              data-tooltip-id="wbn-grid-tooltip"
            />
          </div>
        );
      } else {
        return <></>;
      }
    }

    if (cell?.column?.id === "grade") {
      return (
        <div className="grid-cell">
          <p>{getNumberWithOrdinal(parseInt(cell.getValue()))}</p>
        </div>
      );
    }

    // if (cell?.column?.id === "emailsent") {
    //   const status = cell.getValue();
    //   return (
    //     <div className="grid-cell">{status && <img src={require(`src/assets/wbn/tick.png`)} style={{ width: 17, height: 13 }} />}</div>
    //   );
    // }

    return (
      <div className="grid-cell">
        <p>{cell.getValue()}</p>
      </div>
    );
  };

  const columnsData = useMemo(() => {
    return columns
      .filter((c) => {
        return c.selected;
      })
      .map((c) => {
        let ret = {
          accessorKey: c.colName,
          id: c.colName,
          header: c.title,
          Header: headerCell,
          Cell: gridCell,
          hasInfoIcon: false,
          tooltipData: ""
        };
        if (c.colName === "attentionCode") {
          ret = { ...ret, hasInfoIcon: true, tooltipData: "attentionCode" };
        }
        if (c.colName === "outreachReason") {
          ret = { ...ret, hasInfoIcon: true, tooltipData: "outreachReason" };
        }
        return ret;
      });
  }, [columns]);

  //optionally, you can manage any/all of the table state yourself
  const [rowSelection, setRowSelection] = useState({});

  useEffect(() => {
    // do something when the row selection changes
  }, [rowSelection]);

  //Or, optionally, you can get a reference to the underlying table instance
  const tableInstanceRef = useRef(null);

  // const someEventHandler = () => {
  //   //read the table state during an event from the table instance ref
  //   console.log(tableInstanceRef?.current.getState().sorting);
  // };
  const gridHeight = winSize.height - (gridRef.current?.offsetTop ?? 250) + 250;

  return (
    <div className="wbn-grid" ref={gridRef} style={{ height: gridHeight }}>
      <WbnTooltip id="wbn-grid-tooltip" {...tooltipData} />
      <MaterialReactTable
        columns={columnsData as any}
        data={data}
        muiTablePaginationProps={{
          rowsPerPageOptions: [5, 10, 15],
          showFirstButton: true,
          showLastButton: true
        }}
        enableColumnOrdering //enable some features
        enableColumnDragging={false}
        enableColumnActions={false}
        enableColumnFilters={false}
        enableStickyHeader
        initialState={{ columnPinning: { left: ["lastName", "firstName"] } }}
        enableSorting={true}
        // enableBottomToolbar={false}
        enableTopToolbar={false}
        enablePagination={true} //disable a default feature
        onRowSelectionChange={setRowSelection} //hoist internal state to your own state (optional)
        state={{ rowSelection }} //manage your own state, pass it back to the table (optional)
        tableInstanceRef={tableInstanceRef} //get a reference to the underlying table instance (optional)
        muiTableBodyRowProps={{ hover: false }}
      />
    </div>
  );
};

export default WbnGrid;
