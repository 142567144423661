import React from "react";

export const ComingSoon = () => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        marginTop: "100px"
      }}
    >
      <h1 style={{ color: "#314947" }}>Coming Soon...</h1>
    </div>
  );
};

export default ComingSoon;
