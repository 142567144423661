import {FunctionComponent, ReactElement, useEffect, useMemo, useRef, useState} from "react";
import {MaterialReactTable} from "material-react-table";
import "./styles.scss";
import {ResetPasswordAccountsResponseItem} from "../../../api/resetPassword/resetPasswordTypes";

type ResetPasswordGridProps = {
    data: Array<ResetPasswordAccountsResponseItem>;
    columns: Array<any>;
    onRowClick: (param: any) => void;
    showStudentIdColumn: boolean;
};

export const ResetPasswordGrid: FunctionComponent<ResetPasswordGridProps> = ({
                                                                                 data,
                                                                                 columns,
                                                                                 onRowClick,
                                                                                 showStudentIdColumn
                                                                             }): ReactElement => {
    const gridRef = useRef<HTMLDivElement>(null);
    const [rowSelection, setRowSelection] = useState({});

    const headerCell = ({header}) => {
        return (
            <div className="grid-header">
                <p>{header?.column?.columnDef?.header ?? ""}</p>
            </div>
        );
    };

    const gridCell = ({cell}: { cell: any }) => {
        return (
            <div className="grid-cell">
                <p>{cell.getValue()}</p>
            </div>
        );
    };

    const columnsData = useMemo(() => {
        return columns
            .filter((c) => {
                // Exclude the 'studentID' column if showStudentIdColumn is false
                return c.selected && (showStudentIdColumn || c.colName !== 'studentID');
            })
            .map((c) => {
                let ret = {
                    accessorKey: c.colName,
                    id: c.colName,
                    header: c.title,
                    Header: headerCell,
                    Cell: gridCell,
                    hasInfoIcon: false,
                    tooltipData: ""
                };
                if (c.colName === "attentionCode") {
                    ret = {...ret, hasInfoIcon: true, tooltipData: "attentionCode"};
                }
                if (c.colName === "outreachReason") {
                    ret = {...ret, hasInfoIcon: true, tooltipData: "outreachReason"};
                }
                return ret;
            });
    }, [columns, showStudentIdColumn]);


    const tableInstanceRef = useRef(null);

    return (
        <div className="wbn-grid" ref={gridRef}>
            <MaterialReactTable
                columns={columnsData as any}
                data={data}
                muiTablePaginationProps={{
                    rowsPerPageOptions: [5, 10, 15],
                    showFirstButton: true,
                    showLastButton: true
                }}
                enableColumnOrdering
                enableColumnDragging={false}
                enableColumnActions={false}
                enableColumnFilters={false}
                enableStickyHeader
                initialState={{columnPinning: {left: ["lastName", "firstName"]}}}
                enableSorting={true}
                enableTopToolbar={false}
                enablePagination={true}
                onRowSelectionChange={setRowSelection}
                state={{rowSelection}}
                tableInstanceRef={tableInstanceRef}
                muiTableBodyRowProps={({row}) => ({
                    onClick: () => {
                        const text = window.getSelection()?.toString();

                        if (!text || text.length === 0) {
                            onRowClick(row.original);
                        }
                    }
                })}
            />
        </div>
    );
};

export default ResetPasswordGrid;
