import React, { FunctionComponent, ReactElement } from "react";

import "./styles.scss";

export type AtsBrandTypographyItemProps = {
  title: string;
  subtitle: string;
  fontFamily: "sourceSerif" | "dmSerif" | "sourceSans";
  size: number;
};

export const AtsBrandTypographyItem: FunctionComponent<AtsBrandTypographyItemProps> = ({
  title,
  subtitle,
  size,
  fontFamily
}): ReactElement => {
  return (
    <div className="ats-typography-item">
      <h1 className="ats-typography-item__headline">{title}</h1>
      <span className="ats-typography-item__family-name">{subtitle} </span>

      <div className="ats-typography-item__font-container">
        <div className="ats-typography-item__font-description">
          <h1 className="ats-typography-item__font">{title}</h1>
          <span className={`ats-typography-item__font-example ${fontFamily}`}>Aa</span>
        </div>

        <div className={`ats-typography-item__font-list ${fontFamily}`}>
          ABCDEFGHIJKLMNOPQRSTUVQXYZ <br />
          abcdefghijklmnopqrstuvwxyz <br />
          123456789(.;:?!$&*)
        </div>
        <div className="ats-typography-item__font-size-container">
          <span className="size-text">Size</span>
          <span className="size">{size}</span>
        </div>
      </div>
    </div>
  );
};

export default AtsBrandTypographyItem;
