import React, { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StylesConfig } from "react-select";
import { StepWizardChildProps } from "react-step-wizard";

import {
  sendGetSurveyScheduleRequest,
  sendPostSurveyWizardScheduleRequest,
  sendPutUpdateSurveyWizardScheduleRequest
} from "src/reducers/survey/surveyScheduleSlice";
import { getDateStringFromDate, getTimeStringFromTimeString } from "src/_core/utils/dateUtils/dateUtils";
import { RootState } from "src/reducers";
import { SurveySchedule } from "src/api/survey/surveyTypes";
import { format, isToday } from "date-fns";
import strings from "src/_core/strings/strings";
import SeityAlert from "src/components/analytic/SeityAlert";
import SurveyButton from "src/components/survey/SurveyButton";
import SurveyFormDatePicker from "src/components/survey/SurveyDatePicker/SurveyFormDatePicker";
import SurveyFormSelectBox from "src/components/survey/SurveySelectBox/SurveyFormSelectBox";
import SurveySelectBox, { OptionType } from "src/components/survey/SurveySelectBox/SurveySelectBox";
import SurveyFormTimePicker from "src/components/survey/SurveyTimePicker/SurveyFormTimePicker";
import { EVERY, daysOfWeek } from "./surveySchedulerConst";
import "./styles.scss";
import { SurveyScheduleDate } from "../../types";

export type SurveySchedulerProps = {
  onClose: () => void;
  onSurveyActive: () => void;
  onSurveyScheduled: () => void;
  surveyWizardScheduleID: number | null;
  onSchedule: (dateObj: SurveyScheduleDate, curSurveySchedule: SurveySchedule) => void;
  surveyWizardID: number;
};

export const SurveyScheduler: FunctionComponent<SurveySchedulerProps & Partial<StepWizardChildProps>> = ({
  onSurveyActive,
  onSchedule,
  surveyWizardID,
  surveyWizardScheduleID,
  nextStep,
  previousStep
}): ReactElement => {
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [startTime, setStartTime] = useState("00:00");
  const [endTime, setEndTime] = useState("23:59");
  const [frequency, setFrequency] = useState<OptionType | null>(null);
  const [every, setEvery] = useState({ label: "1", value: 1 });
  const [daySelected, setDaySelected] = useState<OptionType>({ label: "Sunday", value: 0 });
  const [errorMessage, setErrorMessage] = useState("");
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [surSchedule, setSurSchedule] = useState<SurveySchedule>();

  const { surveyFrequencyList } = useSelector((store: RootState) => {
    return store.survey;
  });

  const { proSettings } = useSelector((store: RootState) => {
    return store.account;
  });
  // EntityTypeID 2 means that the user's company is a School so it can go to 3rd step for selecting Students and Staff
  // Even though EntityTypeID is not 2, if the user is allowed with department and group selection, it can go to 3rd step only for Staff
  const canGoAudience = proSettings?.entityTypeID === 2 || (proSettings?.departmentEnabled && proSettings?.groupEnabled);

  const customSelectStyle: StylesConfig = {
    control: (provided) => {
      return { ...provided, height: 48 };
    }
  };

  const scheduledText = (frequencyValue: number) => {
    let frequencyText: string;
    switch (frequencyValue) {
      case 1: {
        frequencyText = `Survey will start, ${getDateStringFromDate(startDate)} at 
        ${getTimeStringFromTimeString(startTime)}. Survey will end on ${getDateStringFromDate(endDate)} at ${getTimeStringFromTimeString(
          endTime
        )}.`;
        break;
      }
      case 2: {
        frequencyText = `Survey will start, ${getDateStringFromDate(startDate)} at 
        ${getTimeStringFromTimeString(startTime)}. Survey will repeat daily and end on ${getDateStringFromDate(endDate)} 
        at ${getTimeStringFromTimeString(endTime)}.`;
        break;
      }
      case 3: {
        frequencyText = `Survey will start, ${getDateStringFromDate(startDate)} at 
        ${getTimeStringFromTimeString(startTime)}. Survey will repeat every ${every.value === 1 ? "week" : "2 weeks"} on ${
          daySelected.label
        } and end 
        on ${getDateStringFromDate(endDate)} at ${getTimeStringFromTimeString(endTime)}.`;
        break;
      }
      default:
        frequencyText = "Survey";
        break;
    }
    return frequencyText;
  };

  // function resetForm() {
  //   setStartDate(new Date());
  //   setEndDate(new Date());
  //   setStartTime('00:00');
  //   setEndTime('23:59');
  //   setFrequency(surveyFrequencyList[0] || null);
  //   setEvery({ label: '1', value: 0 });
  //   setDaySelected({ label: 'Sunday', value: 0 });
  // }

  async function fillFormUpdateSchedule(survey: SurveySchedule) {
    const startDateFormated = new Date(survey.startDateTime);
    const endDateFormated = new Date(survey.endDateTime);
    const currFrequency = surveyFrequencyList.find((frec) => {
      return frec.value === survey.surveyWizardFrequencyID;
    });
    const currDaySelected = daysOfWeek.find((day) => {
      return day.value === survey.dayOfWeek;
    });
    const currEverySelected = EVERY.find((ever) => {
      return ever.value === survey.everyWeeks;
    });

    setStartDate(startDateFormated);
    setEndDate(endDateFormated);
    setStartTime(format(startDateFormated, "HH:mm"));
    setEndTime(format(endDateFormated, "HH:mm"));

    setFrequency(currFrequency || null);
    setDaySelected(currDaySelected || daysOfWeek[0]);
    setEvery(currEverySelected || EVERY[0]);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function onSaveSurveySchedule(scheduledSurvey: SurveySchedule) {
    // const startDateDiffFromNow = getDateDifferenceInDaysWithTime(new Date(), new Date(startDateParam));

    const scheduleInfo = {
      startDate: scheduledSurvey.startDateTime,
      endDate: scheduledSurvey.endDateTime,
      startDay: daysOfWeek[scheduledSurvey.dayOfWeek].value,
      startDayLabel: daysOfWeek[scheduledSurvey.dayOfWeek].label,
      frequency:
        surveyFrequencyList.find((x) => {
          return x.value === scheduledSurvey.surveyWizardFrequencyID;
        })?.value || 0
    };
    // This updates the current SurveySchedule info for the next steps
    onSchedule(scheduleInfo, scheduledSurvey);

    if (canGoAudience && nextStep) {
      nextStep();
    } else {
      onSurveyActive();
    }
  }

  function nextPressed() {
    const startTimeSplit = startTime.split(":");
    const endTimeSplit = endTime.split(":");
    let startDateFormated: Date | number | string = new Date(startDate).setHours(
      parseInt(startTimeSplit[0], 10),
      parseInt(startTimeSplit[1], 10)
    );
    let endDateFormated: Date | number | string = new Date(endDate).setHours(parseInt(endTimeSplit[0], 10), parseInt(endTimeSplit[1], 10));

    if (startDateFormated > endDateFormated) {
      setErrorMessage("Start Date cannot be greater than End Date");
      return;
    }
    startDateFormated = new Date(startDateFormated).toISOString();
    endDateFormated = new Date(endDateFormated).toISOString();

    setIsSaveLoading(true);
    if (surveyWizardScheduleID) {
      if (surSchedule?.startDateTime) {
        const start = new Date(surSchedule?.startDateTime);
        // Not able to update the survey that is already active
        if (isToday(start)) {
          onSaveSurveySchedule(surSchedule);
          return;
        }
      }
      dispatch(
        sendPutUpdateSurveyWizardScheduleRequest(
          surveyWizardScheduleID,
          surveyWizardID,
          frequency?.value,
          startDateFormated,
          endDateFormated,
          every?.value || 0,
          daySelected.value,
          (scheduledSurvey) => {
            setIsSaveLoading(false);
            if (scheduledSurvey) {
              onSaveSurveySchedule(scheduledSurvey);
            } else {
              setErrorMessage(strings.apiErrorMsg);
            }
          }
        )
      );
    } else {
      dispatch(
        sendPostSurveyWizardScheduleRequest(
          surveyWizardID,
          frequency?.value,
          startDateFormated,
          endDateFormated,
          every?.value || 0,
          daySelected.value,
          !canGoAudience,
          (scheduledSurvey) => {
            setIsSaveLoading(false);
            if (scheduledSurvey) {
              onSaveSurveySchedule(scheduledSurvey);
            } else {
              setErrorMessage(strings.apiErrorMsg);
            }
          }
        )
      );
    }
  }

  useEffect(() => {
    if (surveyWizardScheduleID) {
      dispatch(
        sendGetSurveyScheduleRequest(surveyWizardScheduleID, (surveySchedule) => {
          if (surveySchedule) {
            setSurSchedule(surveySchedule);
            fillFormUpdateSchedule(surveySchedule);
          }
        })
      );
    }
  }, [surveyWizardScheduleID]);

  useEffect(() => {
    console.log("schedule surveyFrequencyList:", surveyFrequencyList);
    if (surveyFrequencyList.length > 0) {
      setFrequency(surveyFrequencyList[0]);
    }
  }, [surveyFrequencyList]);

  return (
    <div className="survey-scheduler-wrapper">
      <div className="form-container">
        <h2 className="survey-modal-scheduler-title">Select the day you want to send out your survey and frequency</h2>
        <div className="survey-input-inline">
          <SurveyFormDatePicker
            className="survey-input-class-base"
            value={startDate}
            onChange={(val) => {
              return setStartDate(val);
            }}
            label="Start Date"
          />
          <SurveyFormTimePicker
            className="survey-input-class-base"
            onChange={(vall) => {
              setStartTime(vall);
            }}
            label="Time"
            value={startTime}
          />
        </div>

        <div className="survey-input-inline input-spacing">
          <SurveyFormDatePicker
            className="survey-input-class-base"
            value={endDate}
            onChange={(val) => {
              return setEndDate(val);
            }}
            label="End Date"
          />
          <SurveyFormTimePicker
            className="survey-input-class-base"
            onChange={(vall) => {
              setEndTime(vall);
            }}
            label="Time"
            value={endTime}
          />
        </div>

        <div className="survey-input-inline input-spacing">
          <SurveyFormSelectBox
            className="survey-input-class-base select-repeat"
            options={surveyFrequencyList}
            value={frequency}
            selectOption={(val) => {
              return setFrequency(val);
            }}
            styles={customSelectStyle}
            hideActionButtons
            label="Repeat"
          />
        </div>

        {frequency?.value === 3 && (
          <div className=" input-spacing survey-checkbox-group">
            <span>On</span>
            <div className="survey-input-inline">
              <SurveySelectBox
                value={daySelected}
                options={daysOfWeek}
                styles={customSelectStyle}
                defaultValue={{ label: "Sunday", value: 0 }}
                selectOption={(value) => {
                  return setDaySelected(value);
                }}
                className="survey-input-class-base select-day-of-week"
              />
            </div>
          </div>
        )}
      </div>

      <div className="modal-footer-container">
        <p>{scheduledText(frequency?.value)}</p>
        <div className="question-footer">
          <SurveyButton
            customClass="back-button"
            type="outline"
            onClick={() => {
              if (previousStep) {
                previousStep();
              }
            }}
            label="← Back"
          />
          {/* <SurveyButton
            type="text"
            onClick={() => {

            }}
            label="Save and continue later"
          /> */}
          <SurveyButton
            onClick={() => {
              return nextPressed();
            }}
            label={canGoAudience ? "Select Audience" : "Done"}
            loading={isSaveLoading}
          />
        </div>
      </div>
      <SeityAlert
        visible={!!errorMessage}
        onToggle={() => {
          setErrorMessage("");
        }}
        title="Error Message"
        classNameOverlay="survey-modal-error"
        subTitle={errorMessage}
      />
    </div>
  );
};

export default SurveyScheduler;
