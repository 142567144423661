import axios from "axios";
import { BASE_URL } from "../apiConstants";
import {
  GetGroupsResponse,
  PostGroupResponse,
  PutGroupResponse,
  DeleteGroupResponse,
  PostAssignToGroupResponse,
  PostUnassignToGroupResponse,
  GetUsersNotInGroupResponse
} from "./groupsTypes";

const GROUPSECTION = "/Group/";

export async function getGroups(token: string): Promise<GetGroupsResponse> {
  const url = BASE_URL + GROUPSECTION;
  try {
    const response = await axios.get<GetGroupsResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}

export async function postGroup(groupName: string, token: string): Promise<PostGroupResponse> {
  const url = BASE_URL + GROUPSECTION;
  try {
    const response = await axios.post<PostGroupResponse>(
      url,
      {
        groupName
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}

export async function putGroup(groupID: number, newGroupName: string, token: string): Promise<PutGroupResponse> {
  const url = BASE_URL + GROUPSECTION;
  try {
    const response = await axios.put<PutGroupResponse>(
      url,
      {
        groupID,
        newGroupName
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}

export async function deleteGroup(groupID: number, token: string): Promise<DeleteGroupResponse> {
  const url = BASE_URL + GROUPSECTION;
  try {
    const response = await axios.delete<DeleteGroupResponse>(url, {
      data: { groupID },
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}

export async function getMembersNotInGroup(groupID: number, token: string): Promise<GetUsersNotInGroupResponse> {
  const url = BASE_URL + GROUPSECTION + `UsersNotInGroup?groupId=${groupID}`;
  try {
    const response = await axios.get<GetUsersNotInGroupResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}

export async function postAssignToGroup(accountID: Array<number>, groupID: number, token: string): Promise<PostAssignToGroupResponse> {
  const url = BASE_URL + GROUPSECTION + "Assign";
  try {
    const response = await axios.post<PostAssignToGroupResponse>(
      url,
      {
        accountID,
        groupID
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}

export async function postUnassignToGroup(accountID: number, groupID: number, token: string): Promise<PostUnassignToGroupResponse> {
  const url = BASE_URL + GROUPSECTION + "Unassign";
  try {
    const response = await axios.post<PostUnassignToGroupResponse>(
      url,
      {
        accountID: accountID,
        groupID: groupID
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}
