import { SeityResponse } from "../baseTypes";

export type SurveyState = "Active" | "Scheduled" | "Draft" | "Complete";

export interface SurveyListItem extends Pick<Survey, "title" | "surveyState"> {
  startDate: string;
  endDate: string;
}

export class Survey {
  constructor(
    public title: string,
    public questionCount: number,
    public dateCreated: string,
    public isLiked: boolean,
    public surveyState: SurveyState,
    public canEdit: boolean,
    public listItem?: SurveyListItem[]
  ) {}
}
export class CreateSurveyWizardResponse {
  constructor(public success: boolean, public message: string | null, public data: SurveyWizard) {}
}
export class SurveyWizardListResponse {
  constructor(public success: boolean, public message: string | null, public data: SurveyWizard[]) {}
}

export class SurveyWizardResponse {
  constructor(public success: boolean, public message: string | null, public data: SurveyWizard) {}
}

export class SingleSurveyWizardResponse {
  constructor(public success: boolean, public message: string | null, public data: SurveyWizard) {}
}

export class SurveyFrequencyResponse {
  constructor(public success: boolean, public message: string | null, public data: SurveyFrequency[]) {}
}

export class SurveyFrequency {
  constructor(public surveyWizardFrequencyID: number, public name: string) {}
}

export class SurveyWizardAnswerGroupResponse {
  constructor(public success: boolean, public message: string | null, public data: SurveyWizardAnswerGroup[]) {}
}

export class SurveyWizardAnswerResponse {
  constructor(public success: boolean, public message: string | null, public data: SurveyWizardAnswer[]) {}
}

export class SurveyWizardAnswer {
  constructor(public surveyWizardAnswerListID: number, public text: string) {}
}

export class SurveyWizardAnswerGroup {
  constructor(public surveyWizardAnswerGroupID: number, public description: string) {}
}

export class SurveyWizard {
  constructor(
    public name: string,
    public numberOfQuestions: number,
    public createDate: string,
    public updateDate: string,
    public isLiked: boolean,
    public canEdit: boolean,
    public status: SurveyState,
    public description: string,
    public surveyWizardScheduleID: number | null,
    public isDrawingEligible: boolean,
    public areQuestionsInRandomOrder: boolean,
    public listItem?: SurveyListItem[],
    public surveyWizardID?: number,
    public questions?: SurveyQuestion[]
  ) {}
}

export class SurveyQuestion {
  constructor(
    public question: string,
    public surveyWizardAnswerGroupID: number,
    public sortOrder: number,
    public surveyWizardQuestionID?: number
  ) {}
}

export class SurveyQuestionDeleteResponse {
  constructor(public data: boolean, public success: boolean, public message: string | null) {}
}

export class SurveyWizardDeleteResponse {
  constructor(public data: boolean, public success: boolean, public message: string | null) {}
}

export class IsLiked {
  constructor(public isLiked: boolean) {}
}

export class IsLikedResponse {
  constructor(public success: boolean, public message: string | null, public data: IsLiked) {}
}

// -------------- SurveyWizardSchedule Types -----------------
export type SurveySchedule = {
  surveyWizardID: number;
  surveyWizardFrequencyID: number;
  surveyWizardScheduleID: number;
  entityID: number;
  startDateTime: string;
  endDateTime: string;
  everyWeeks: number;
  dayOfWeek: number;
};

export type SurveyScheduleResponse = {
  success: boolean;
  message: string | null;
  data: SurveySchedule;
};

export type SurveyResultQuestionAnswer = {
  answer: string;
  answerCount: number;
};

export type SurveyResultQuestion = {
  question: string;
  answerResults: SurveyResultQuestionAnswer[];
};

export type SurveyResults = {
  surveyWizardID: number;
  recurrence: number;
  questionNumber: number;
  participantNumber: number;
  startDate: string;
  endDate: string;
  questionResults: SurveyResultQuestion[];
};

export type SurveyResultsResponse = {
  success: boolean;
  message: string | null;
  data: SurveyResults;
};

export type SurveyRecurrence = {
  surveyWizardID: number;
  surveyWizardRecurrenceID: number;
  recurrence: number;
  recurrenceText: string;
  status: string;
  startDateTime: string;
  endDateTime: string;
};

export type SurveyRecurrenceResponse = {
  success: boolean;
  message: string | null;
  data: SurveyRecurrence[];
};

// -------------- SurveyWizardAudience Types -----------------

export const SURVEY_AUDIENCE_STAFF = 1;
export const SURVEY_AUDIENCE_STUDENTS = 2;

export type SurveyAudience = {
  surveyWizardScheduleID: number;
  surveyWizardAudienceTypeID: 0 | 1 | 2; // 0: no selection, 1: staff, 2: students
  companyID: number;
  teacherID: number;
  classID: number;
  departmentIDs: Array<number>;
  groupIDs: Array<number>;
};

export type SurveyAudienceResponse = SeityResponse & {
  data: SurveyAudience;
};
