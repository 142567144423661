/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import isEmail from "validator/es/lib/isEmail";

import { RootState } from "src/reducers";
import {
  sendGetAccountInfoRequest,
  sendAccountMaintenanceUpdateRequest,
  clearAccountError,
  setAccountUpdateSuccessful,
  setAccountError
} from "src/reducers/auth/accountSlice";

import { SeityPrimaryCancelButtons } from "src/components/analytic/SeityNavigationButtons";
import { SeityFormTextInput } from "src/components/analytic/SeityTextInput/SeityFormTextInput";

import strings from "src/_core/strings/strings";
import "./maintenance.scss";

function MaintenanceOne() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { accountInfo, accountError, isAccountUpdateSuccessful } = useSelector((state: RootState) => {
    return state.account;
  });

  const [firstName, setfirstName] = useState(accountInfo?.firstName || "");
  const [lastName, setlastName] = useState(accountInfo?.lastName || "");
  const [eMailAddress, setEmailAddress] = useState(accountInfo?.eMailAddress || "");
  const [hasEMailAddressChanced, setHasEMailAddressChanged] = useState(false);

  useEffect(() => {
    dispatch(sendGetAccountInfoRequest());
    dispatch(clearAccountError());
  }, []);

  useEffect(() => {
    setEmailAddress(accountInfo?.eMailAddress ?? "");
    setfirstName(accountInfo?.firstName ?? "");
    setlastName(accountInfo?.lastName ?? "");
  }, [accountInfo]);

  const updateAccountInfo = () => {
    if (!isEmail(eMailAddress)) {
      dispatch(setAccountError({ accountError: strings.emailError }));
      return;
    }

    dispatch(
      sendAccountMaintenanceUpdateRequest({
        firstName,
        lastName,
        eMailAddress: hasEMailAddressChanced ? eMailAddress : null,
        accountStatusID: null,
        dateOfBirth: null,
        statusID: null,
        educationID: null,
        occupationID: null,
        genderID: null,
        maritalStatusID: null,
        zipcode: null,
        cellPhone: null
      })
    );
  };

  if (isAccountUpdateSuccessful) {
    dispatch(setAccountUpdateSuccessful({ accountUpdateSuccessful: false }));
    history.push("/profile");
  }

  const CancelMaintenanceOneHandler = () => {
    history.push("/profile");
  };

  const fieldsFilled = () => {
    return !!(firstName === "" || lastName === "" || eMailAddress === "");
  };

  const onEmailChanged = (email: string) => {
    setHasEMailAddressChanged(true);
    setEmailAddress(email);
  };
  return (
    <div className="maintenance-container">
      <h1>{strings.edit}</h1>
      <div className="maintenance-content-wrap">
        <div className="form-container-upper">
          <SeityFormTextInput
            inputLabelText={strings.firstNameLabel}
            inputStyle={{ width: "90%", marginRight: "10%" }}
            type="text"
            onChange={(e) => {
              return setfirstName(e.target.value);
            }}
            value={firstName}
            isInvalid={firstName.length === 0}
            className="input-first-name"
          />
          <SeityFormTextInput
            inputLabelText={strings.lastNameLabel}
            inputStyle={{ width: "90%" }}
            value={lastName}
            onChange={(e) => {
              return setlastName(e.target.value);
            }}
            type="text"
            isInvalid={lastName.length === 0}
          />
        </div>
        <div className="form-container-lower">
          <SeityFormTextInput
            inputLabelText={strings.emailLabel}
            value={eMailAddress}
            inputStyle={{ width: "125%" }}
            onChange={(e) => {
              return onEmailChanged(e.target.value);
            }}
            type="text"
            isInvalid={eMailAddress.length === 0}
            className="input-email"
          />
        </div>
      </div>
      <SeityPrimaryCancelButtons
        cancelAction={CancelMaintenanceOneHandler}
        cancelTitle={strings.cancelButtonText}
        primaryAction={updateAccountInfo}
        primaryTitle={strings.saveButtonText}
        primaryDisabled={fieldsFilled()}
        showCancel
        errorMessage={accountError}
      />
    </div>
  );
}

export default MaintenanceOne;
