import { useSelector } from "react-redux";
import { RootState } from "src/reducers";
import { PbiFilter } from "src/features/ReportHome/types";
import { TeacherClass, TeacherList, TeacherSchool } from "src/api/analytics/types/analyticsTypes";

export const getIsAdmin = () => {
  const { proSettings } = useSelector((state: RootState) => {
    return state.account;
  });

  return proSettings && proSettings.allStudentAccess;
};

export const getIsIndividualCompanyAccess = () => {
  const { proSettings } = useSelector((state: RootState) => {
    return state.account;
  });

  return proSettings && proSettings.individualCompanyAccess;
};

export const getPbiFilterData = (
  curSchool: TeacherSchool | null,
  curTeacher: TeacherList | "All" | null,
  curClass: TeacherClass | null,
  isStaff: boolean,
  staffCompId: number,
  teacherSchoolList: TeacherSchool[],
  allStudentAccess: boolean
) => {
  console.log("teacherScoolList: ", teacherSchoolList);
  if (isStaff) {
    return null;
    // No needs to add filter for Staff now
    const filter: PbiFilter = { type: "School", value: [staffCompId] };
    return filter;
  }
  if (curClass && curClass.class01ID) {
    const filter: PbiFilter = { type: "Class", value: [curClass.class01ID] };
    return filter;
  }
  if (curTeacher && curTeacher !== "All" && curTeacher.staffID) {
    const filter: PbiFilter = { type: "Teacher", value: [curTeacher.staffID] };
    return filter;
  }
  if (curSchool && curSchool.companyID > 0) {
    // All Schools is companyID = 0
    const filter: PbiFilter = { type: "School", value: [curSchool.companyID] };
    return filter;
  }
  if (teacherSchoolList && curSchool?.companyID == 0 && !allStudentAccess) {
    const companyIDs: number[] = teacherSchoolList.map(item => {
      return item.companyID;
    });
    const filter: PbiFilter = { type: "School", value: companyIDs };
    return filter;
  }
  return null;
};

export const compareFilter = (filter: PbiFilter | null, filterVal: PbiFilter | null) => { 
  if (!filter && !filterVal) {
    return true;
  }
  if (
    filter &&
    filterVal &&
    filter.type === filterVal.type &&
    filter.value !== null &&
    filterVal.value !== null &&
    filter.value.length === filterVal.value.length &&
    filter.value.every((val, index) => {
      return filterVal.value !== null && val === filterVal.value[index];
    })
  ) {
    return true;
  }
  return false;
};
