import axios from "axios";
import { BASE_URL } from "../apiConstants";
import {
  CreateSurveyWizardResponse,
  SurveyFrequencyResponse,
  SurveyQuestion,
  SurveyWizardAnswerGroupResponse,
  SurveyWizardListResponse,
  SurveyQuestionDeleteResponse,
  IsLikedResponse,
  SingleSurveyWizardResponse,
  SurveyWizardDeleteResponse,
  SurveyScheduleResponse,
  SurveyWizardResponse,
  SurveyResultsResponse,
  SurveyRecurrenceResponse,
  SurveyWizardAnswerResponse
} from "./surveyTypes";

const SECTION = "/SurveyWizard/";

export async function getSurveyWizard(token: string): Promise<SurveyWizardListResponse> {
  const url = BASE_URL + SECTION + "SurveyWizardList";
  try {
    const surveyResponse = await axios.get<SurveyWizardListResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return surveyResponse.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}

export async function getSurveyWizardById(surveyWizardID: number, token: string): Promise<SurveyWizardResponse> {
  const url = BASE_URL + SECTION + "SurveyWizard?surveyWizardID=" + surveyWizardID;
  try {
    const surveyResponse = await axios.get<SurveyWizardResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return surveyResponse.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}

export async function getSurveyFrequency(token: string): Promise<SurveyFrequencyResponse> {
  const url = BASE_URL + SECTION + "SurveyFrequency";
  try {
    const surveyResponse = await axios.get<SurveyFrequencyResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return surveyResponse.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}

export async function createSurveyWizard(
  name: string,
  description: string,
  questions: SurveyQuestion[],
  isDrawingEligible: boolean,
  areQuestionsInRandomOrder: boolean,
  token: string
): Promise<CreateSurveyWizardResponse> {
  const url = BASE_URL + SECTION + "CreateSurveyWizard";
  try {
    const surveyResponse = await axios.post<CreateSurveyWizardResponse>(
      url,
      {
        name,
        description,
        questions,
        isDrawingEligible,
        areQuestionsInRandomOrder
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return surveyResponse.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}

export async function updateSurveyWizard(
  surveyWizardID: number,
  name: string,
  description: string,
  questions: SurveyQuestion[],
  isDrawingEligible: boolean,
  areQuestionsInRandomOrder: boolean,
  token: string
): Promise<CreateSurveyWizardResponse> {
  const url = BASE_URL + SECTION + "UpdateSurveyWizard";
  try {
    const surveyResponse = await axios.put<CreateSurveyWizardResponse>(
      url,
      {
        surveyWizardID,
        name,
        description,
        questions,
        isDrawingEligible,
        areQuestionsInRandomOrder
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return surveyResponse.data;
  } catch (err: any) {
    console.log("updateSurveyWizard:", err);
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}

export async function getSurveyWizardAnswerGroups(token: string): Promise<SurveyWizardAnswerGroupResponse> {
  const url = BASE_URL + SECTION + "SurveyWizardAnswerGroups";
  try {
    const surveyResponse = await axios.get<SurveyWizardAnswerGroupResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return surveyResponse.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}

export async function getSurveyWizardAnswer(surveyWizardAnswerGroupID: number, token: string): Promise<SurveyWizardAnswerResponse> {
  const url = BASE_URL + SECTION + "SurveyWizardAnswers?surveyWizardAnswerGroupID=" + surveyWizardAnswerGroupID;
  try {
    const surveyResponse = await axios.get<SurveyWizardAnswerResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return surveyResponse.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}

export async function deleteSurveyWizardQuestion(surveyWizardQuestionID: number, token: string): Promise<SurveyQuestionDeleteResponse> {
  const url = BASE_URL + SECTION + "DeleteSurveyWizardQuestion?surveyWizardQuestionID=" + surveyWizardQuestionID;
  try {
    const surveyResponse = await axios.delete<SurveyQuestionDeleteResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return surveyResponse.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}

export async function deleteSurveyWizard(surveyWizardID: number, token: string): Promise<SurveyQuestionDeleteResponse> {
  const url = BASE_URL + SECTION + "DeleteSurveyWizard?surveyWizardID=" + surveyWizardID;
  try {
    const surveyResponse = await axios.delete<SurveyWizardDeleteResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return surveyResponse.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}

export async function postSurveyWizardQuestion(
  surveyWizardID: number,
  question: string,
  surveyWizardAnswerGroupID: number,
  sortOrder: number,
  token: string
): Promise<SurveyFrequencyResponse> {
  const url = BASE_URL + SECTION + "CreateSurveyWizardQuestion";
  try {
    const surveyResponse = await axios.post<SurveyFrequencyResponse>(
      url,
      {
        surveyWizardID,
        question,
        surveyWizardAnswerGroupID,
        sortOrder
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return surveyResponse.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}

export async function copySurveyWizard(surveyWizardID: number, token: string): Promise<SingleSurveyWizardResponse> {
  const url = BASE_URL + SECTION + "CopySurveyWizard?surveyWizardID=" + surveyWizardID;
  try {
    const surveyResponse = await axios.get<SingleSurveyWizardResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return surveyResponse.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}

export async function toggleLikeSurveyWizard(surveyWizardID: number, token: string): Promise<IsLikedResponse> {
  const url = BASE_URL + SECTION + "ToggleSurveyWizardIsLiked?surveyWizardID=" + surveyWizardID;
  try {
    const surveyResponse = await axios.put<IsLikedResponse>(
      url,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return surveyResponse.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}

export async function getSurveyRecurrenceResults(surveyWizardRecurID: number, token: string): Promise<SurveyResultsResponse> {
  const url = BASE_URL + SECTION + "SurveyWizardRecurrenceResults?surveyRecurrenceWizardID=" + surveyWizardRecurID;
  try {
    const surveyResultsResponse = await axios.get<SurveyResultsResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return surveyResultsResponse.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}

export async function getSurveyRecurrence(surveyWizardID: number, token: string): Promise<SurveyRecurrenceResponse> {
  const url = BASE_URL + SECTION + "SurveyWizardRecurrence?surveyWizardID=" + surveyWizardID;
  try {
    const surveyRecurrenceResponse = await axios.get<SurveyRecurrenceResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return surveyRecurrenceResponse.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}

// -------------- SurveyWizard Schedule Create/Update/Get ----------------

export async function createSurveyWizardSchedule(
  surveyWizardID: number,
  surveyWizardFrequencyID: number,
  startDateTime: string,
  endDateTime: string,
  everyWeeks: number,
  dayOfWeek: number,
  token: string
): Promise<SurveyScheduleResponse> {
  const url = BASE_URL + "/SurveyWizardSchedule";
  try {
    const surveyResponse = await axios.post<SurveyScheduleResponse>(
      url,
      {
        surveyWizardID,
        surveyWizardFrequencyID,
        startDateTime,
        endDateTime,
        everyWeeks,
        dayOfWeek
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return surveyResponse.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}

export async function updateSurveyWizardSchedule(
  surveyWizardScheduleID: number,
  surveyWizardID: number,
  surveyWizardFrequencyID: number,
  startDateTime: string,
  endDateTime: string,
  everyWeeks: number,
  dayOfWeek: number,
  token: string
): Promise<SurveyScheduleResponse> {
  const url = BASE_URL + "/SurveyWizardSchedule";
  try {
    const surveyResponse = await axios.put<SurveyScheduleResponse>(
      url,
      {
        surveyWizardScheduleID,
        surveyWizardID,
        surveyWizardFrequencyID,
        startDateTime,
        endDateTime,
        everyWeeks,
        dayOfWeek
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return surveyResponse.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}

export async function getSurveyWizardSchedule(surveyWizardScheduleID: number, token: string): Promise<SurveyScheduleResponse> {
  const url = BASE_URL + "/SurveyWizardSchedule?surveyWizardScheduleID=" + surveyWizardScheduleID;
  try {
    const surveyResultsResponse = await axios.get<SurveyScheduleResponse>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return surveyResultsResponse.data;
  } catch (err: any) {
    if (err.response.data.message) {
      err.message = err.response.data.message;
    }
    throw err;
  }
}
