/* eslint-disable max-len */
import React, { FunctionComponent, ReactElement } from "react";

import "./styles.scss";

export type AtsBrandOverviewProps = {};

export const AtsBrandOverview: FunctionComponent<AtsBrandOverviewProps> = (): ReactElement => {
  return (
    <div className="ats-brandoverview">
      <h2>Who We Are</h2>
      <div className="ats-brandoverview__underline" />
      <span>
        We inspire and equip people to live better. We do this by giving everyone a new way to think about themselves and what is possible.
      </span>
      <h2>Objective</h2>
      <div className="ats-brandoverview__underline" />
      <span>
        People live their best life when they understand their Core Values and live them out in healthy ways in every area of their lives.
        This way of living increases wellbeing, decreases stress, and improves performance at work, school, and play. Living from our core
        brings out the best in ourselves and others. This is our greatest chance for overcoming resistance, division, and challenges to our
        collective health.
      </span>
    </div>
  );
};

export default AtsBrandOverview;
