import React, { FunctionComponent, ReactElement } from "react";

import "./styles.scss";

export type AtsBrandColorItemProps = {
  colorName: string;
  colorHex: string;
  colorRGB: string;
};

export const AtsBrandColorItem: FunctionComponent<AtsBrandColorItemProps> = ({ colorName, colorHex, colorRGB }): ReactElement => {
  return (
    <div className="ats-brand-colors-item">
      <div className="ats-brand-colors-item__circle" style={{ backgroundColor: colorHex }} />
      <h4 className="ats-brand-colors-item__color-name">{colorName}</h4>
      <span className="ats-brand-colors-item__color-hex">{colorHex}</span>
      <span className="ats-brand-colors-item__color-rgb">{colorRGB}</span>
    </div>
  );
};

export default AtsBrandColorItem;
