import React, { FunctionComponent, ReactElement } from "react";

import "./styles.scss";

export type ButtonProps = {
  disabled?: boolean;
  loading?: boolean;
  type?: "solid" | "outline" | "round" | "ats-round" | "text";
  onClick?: ((event: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined;
  label?: string;
  title?: string;
  size?: "mid" | "big" | "mid-ats";
  customClass?: string;
};

export const SurveyButton: FunctionComponent<ButtonProps> = ({
  disabled = false,
  loading = false,
  type = "solid",
  size = "big",
  onClick,
  label,
  title,
  customClass = ""
}): ReactElement => {
  let buttonClassName;

  switch (type) {
    case "outline": {
      buttonClassName = "survey-button survey-button__cancel";
      break;
    }
    case "round": {
      buttonClassName = "survey-button survey-button__round";
      break;
    }
    case "ats-round": {
      buttonClassName = "survey-button survey-button__ats-round";
      break;
    }
    case "text": {
      buttonClassName = "survey-button survey-button__text";
      break;
    }
    default:
      buttonClassName = "survey-button";
      break;
  }

  switch (size) {
    case "mid": {
      buttonClassName += " survey-button__size-mid";
      break;
    }
    case "mid-ats": {
      buttonClassName += " survey-button__size-mid-ats";
      break;
    }
    default:
      break;
  }

  switch (customClass) {
    case "": {
      break;
    }
    default:
      buttonClassName += ` ${customClass}`;
      break;
  }

  if (loading) {
    return (
      <div className={buttonClassName}>
        <div className="dot-pulse" />
      </div>
    );
  }

  return (
    <button type="button" className={buttonClassName} disabled={disabled} onClick={onClick} title={title}>
      {label}
    </button>
  );
};

export default SurveyButton;
